import React from 'react';
import moment from 'moment';

export default class TepDinhKem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataPagi: [],
            openModel: false,
            recordsPerPage: 0,
        }
    }
    componentDidUpdate() {
        if (this.props.files !== undefined && this.props.files !== this.state.data) {
            this.state.data = this.props.files;
            this.setState(this.state);
            this.handleSearch();
        }
    }
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.setState({
            ...this.state,
            recordsPerPage: number
        })
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }
    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>
                <br></br>
                <div className="row">
                    <table class="table table-bordered">
                        <thead>
                            <tr className="table-secondary text-center">
                                <th>STT</th>
                                <th>Tên tập tin</th>
                                <th>Người đính</th>
                                <th>Tập tin</th>
                                <th>Ngày đính kèm</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                        <td>{item.name}</td>
                                        <td></td>
                                        <td></td>
                                        <td className="cell-number">{moment(new Date(item.createdDate)).format('DD-MM-yyyy')}</td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div className="">
                    <button type="button" class="btn btn-secondary-custom btn-sm">In ấn</button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary-custom btn-sm">Trở về</button>
                </div> */}
                <br></br>
            </div>
        )
    }
}