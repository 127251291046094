import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import { HoSoCuaToiMgtService } from '../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import StringUtil from '../../../utils/stringUtils';
import { TypeField } from '../../commons/components/eform/thongTinChuyenNganheForm';
import ThongTinChuyenNganhUtil from '../../commons/components/eform/thongTinChuyenNganhUtils';
import { THANH_PHO_CAN_THO } from '../../Constants/constants';
import TableCDT from '../CapPhepDungChung/CDT/TableCDT';
import FormGTDD from '../CapPhepDungChung/FormGTDD';
import FormTTDX from '../CapPhepDungChung/FormTTDX';
import TableTTTD from '../CapPhepDungChung/TableTTTD';
import FormCPDiDoiCongTrinh from './FormCPDiDoiCongTrinh';

export default class TabCapPhepDDCT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noiDungGiayPhep: {
        thongTinCongTrinh: {},
        thongTinXayDung: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
        },
        viTriDiDoi: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          soNhaDuong: "",
          duong: "",
          maPhuongXaThiTran: "",
          phuongXaThiTran: "",
          maHuyenThiThanhPho: "",
          huyenThiThanhPho: "",
          diaChiCu: "",
          ranhGioiDiDoi: "",
          idGiayPhep: "",
          maGiayPhep: "",
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          soGiayPhepCu: ''
        },
        chuDauTus: [],
        chuDauTuDrops: [],
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],
        loDatXayDungDropDTOs: [],
        loDatXayDungs: [],
        //--
        loDatXayDungDiDoi: [],
        loDatXayDungDiDoiDropDTOs: [],
        errors: {
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          huyenThiThanhPho: '',
          phuongXaThiTran: '',
          ngayCap: '',
          maSoGiayPhep: ''
        },
        donViChuNhiemThietKe: {},
      },
      eForm: []
    }
  }

  componentDidMount() {
    if (this.props.maHoSo !== undefined && this.props.maHoSo !== null && this.props.maHoSo !== '') {
      //hosocuatoi
      this.loadByMaHoSo();
    } else if (this.props.id > 0) {
      //chinh sua
      this.loadGiayPhepById(this.props.id);
    } else {
      //nhap dau ky
    }
  }

  componentDidUpdate() {
    //to do
  }


  initData = async () => {
    if (this.state.eForm.length > 0) {
      let donViHanhChinhQH = {};
      let donViHanhChinhPX = {};
      let maHuyen, tenHuyen, maXa, tenXa;
      if (!StringUtil.isNullOrEmty(ThongTinChuyenNganhUtil.getValueByKey("quanhuyen", this.state.eForm, TypeField.TYPE_STRING))) {
        let param = ThongTinChuyenNganhUtil.getValueByKey("quanhuyen", this.state.eForm, TypeField.TYPE_STRING);
        await AXIOS_INSTANCE.get(`/danh-muc-quan-huyen/code?maTinhThanh=${param}`).then(res => {
          if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
            donViHanhChinhQH = res.data.data;
            maHuyen = donViHanhChinhQH.maQuanHuyen;
            tenHuyen = donViHanhChinhQH.tenQuanHuyen;
          }
        }).catch(error => {
          console.log(error)
        });
      }
      // console.log('maHuyen : ', maHuyen)
      // console.log('tenHuyen : ', tenHuyen)
      if (!StringUtil.isNullOrEmty(ThongTinChuyenNganhUtil.getValueByKey("xaphuong", this.state.eForm, TypeField.TYPE_STRING))) {
        let param = ThongTinChuyenNganhUtil.getValueByKey("xaphuong", this.state.eForm, TypeField.TYPE_STRING);
        await AXIOS_INSTANCE.get(`/danh-muc-phuong-xa/code?maQuanHuyen=${param}`).then(res => {
          if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
            donViHanhChinhPX = res.data.data;
            maXa = donViHanhChinhPX.maPhuongXa;
            tenXa = donViHanhChinhPX.tenPhuongXa;
          }
        }).catch(error => {
          console.log(error)
        });
      }
      // console.log('maXa : ', maXa)
      // console.log('tenXa : ', tenXa)
      this.state.noiDungGiayPhep = {
        thongTinCongTrinh: {
          // tenCongTrinh: ThongTinChuyenNganhUtil.getValueByKey("phone", this.state.eForm, TypeField.TYPE_STRING), 
        },
        thongTinXayDung: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          maHuyenThiThanhPho: maHuyen,
          tenHuyenThiThanhPho: tenHuyen,
          maPhuongXaThiTran: maXa,
          phuongXaThiTran: tenXa,
          duong: ThongTinChuyenNganhUtil.getValueByKey("diadiem", this.state.eForm, TypeField.TYPE_STRING)
        },
        viTriDiDoi: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          soNhaDuong: "",
          duong: "",
          maPhuongXaThiTran: "",
          phuongXaThiTran: "",
          maHuyenThiThanhPho: "",
          huyenThiThanhPho: "",
          diaChiCu: "",
          ranhGioiDiDoi: "",
          idGiayPhep: "",
          maGiayPhep: ""
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          soGiayPhepCu: ''
        },
        chuDauTus: [],
        chuDauTuDrops: [],
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],
        loDatXayDungs: [],
        //--
        loDatXayDungDiDoi: [],
        loDatXayDungDiDoiDropDTOs: [],
        loDatXayDungDropDTOs: [],
        donViChuNhiemThietKe: {},
        errors: {
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          huyenThiThanhPho: '',
          phuongXaThiTran: '',
          ngayCap: '',
          maSoGiayPhep: ''
        },
      };
    } else {
      this.state.noiDungGiayPhep = {
        thongTinCongTrinh: {},
        thongTinXayDung: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
        },
        viTriDiDoi: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          soNhaDuong: "",
          duong: "",
          maPhuongXaThiTran: "",
          phuongXaThiTran: "",
          maHuyenThiThanhPho: "",
          huyenThiThanhPho: "",
          diaChiCu: "",
          ranhGioiDiDoi: "",
          idGiayPhep: "",
          maGiayPhep: ""
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          soGiayPhepCu: ''
        },
        chuDauTus: [],
        chuDauTuDrops: [],
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],
        loDatXayDungs: [],
        //--
        loDatXayDungDiDoi: [],
        loDatXayDungDiDoiDropDTOs: [],
        loDatXayDungDropDTOs: [],
        donViChuNhiemThietKe: {},
        errors: {
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          huyenThiThanhPho: '',
          phuongXaThiTran: '',
          ngayCap: '',
          maSoGiayPhep: ''
        },
      };
    }
    this.setState(this.state);
  }

  validation = async () => {
    let valid = true;
    this.state.errors = {};
    let checkValidation = true;
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriDiDoi?.huyenThiThanhPho)) {
      this.state.errors.huyenThiThanhPho = 'Vui lòng chọn Huyện/Thị/TP';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriDiDoi?.phuongXaThiTran)) {
      this.state.errors.phuongXaThiTran = 'Vui lòng chọn Phường/Xã/TT';
      valid = false;
    }
    if (this.props.isNhapDauKy) {
      if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.maSoGiayPhep)) {
        this.state.errors.maSoGiayPhep = 'Vui lòng nhập số giấy phép';
        valid = false;
      } else {
        checkValidation = await this.checkDuplicate();
        if (checkValidation) {
          this.state.errors.maSoGiayPhep = 'Số giấy phép đã tồn tại!';
          valid = false;
        }
      }
      if (!this.state.noiDungGiayPhep.giayPhep.ngayCap || this.state.noiDungGiayPhep.giayPhep.ngayCap === undefined || this.state.noiDungGiayPhep.giayPhep.ngayCap == 0) {
        this.state.errors.ngayCap = 'Vui lòng nhập ngày cấp';
        valid = false;
      }
    }
    this.setState(this.state);
    return valid;
  }

  loadData = async (maHoSo) => {
    await new HoSoCuaToiMgtService().chiTiet(maHoSo).then((p) => {
      if (p.data.data.thongTinChuyenNganh && p.data.data.thongTinChuyenNganh.length > 0) {
        let result = [];
        let lastResult = [];
        for (let i = 0; i < p.data.data.thongTinChuyenNganh.length; i++) {
          result = [p.data.data.thongTinChuyenNganh[i].data]
        }
        for (let index = 0; index < result.length; index++) {
          lastResult = result[index];
        }
        this.setState({
          eForm: lastResult
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  loadByMaHoSo = async () => {
    const url = 'giay-phep-xay-dung/thong-tin';
    const data = { 'maHoSo': this.props.maHoSo, 'maLoaiGiayPhep': this.props.maLoaiGiayPhep }
    await this.loadData(this.props.maHoSo);
    AXIOS_INSTANCE.post(url, data).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.state.noiDungGiayPhep = datas;
          this.setState(this.state);
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  loadGiayPhepById = (idGiayPhep) => {
    const url = 'giay-phep-xay-dung/chi-tiet-by-id';
    AXIOS_INSTANCE.post(url, { id: idGiayPhep }).then(res => {
      console.log("res : ", res)
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.setState({
            noiDungGiayPhep: {
              thongTinCongTrinh: datas.thongTinCongTrinh || {},
              thongTinXayDung: datas.thongTinXayDung || {},
              viTriXayDung: datas.viTriXayDung || {},
              viTriDiDoi: datas.viTriDiDoi || {},
              //--
              loDatXayDungDiDoi: datas.loDatXayDungDiDoi || [],
              loDatXayDungDiDoiDropDTOs: datas.loDatXayDungDiDoiDropDTOs || [],
              thongTinDeXuat: datas.thongTinDeXuat || {},
              chuDauTuDTOs: datas.chuDauTuDTOs || [],
              giayPhep: datas.giayPhep,
              chuDauTus: datas.chuDauTus || [],
              thongTinThamDinhs: datas.thongTinThamDinhs || [],
              loDatXayDungs: datas.loDatXayDungs || [],
              loDatXayDungDropDTOs: [],
              files: datas.files || [],
              fileTrash: []
            }
          })
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  handleUpdateDataState = (name, value) => {
    if (name === 'state.noiDungGiayPhep') {
      this.state.noiDungGiayPhep = value;
    } else {
      this.state.noiDungGiayPhep[name] = value;
    }
    this.setState(this.state);
  }

  onChangeStateGTDD = (value) => {
    this.state.noiDungGiayPhep.giayPhep.giayToVeDatDai = value;
    this.setState(this.state);
  }

  setVarialble = () => {
    this.state.noiDungGiayPhep.giayPhep.maHoSo = this.props.maHoSo;
    this.state.noiDungGiayPhep.giayPhep.tenLoaiBienDong = this.props.tenLoaiBienDong;
    this.state.noiDungGiayPhep.giayPhep.loaiBienDong = this.props.loaiBienDong;
    this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep = this.props.maLoaiGiayPhep;
    this.state.noiDungGiayPhep.giayPhep.tenLoaiGiayPhep = this.props.tenLoaiGiayPhep;
    if (!StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep)) {
      this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep;
    }
    this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep = "";
    this.setState(this.state);
  }


  async checkDuplicate() {
    let valid;
    await AXIOS_INSTANCE.post('giay-phep-xay-dung/kiem-tra-duplicate',
      {
        "id": this.state.noiDungGiayPhep.giayPhep.id,
        "maSoGiayPhep": this.state.noiDungGiayPhep.giayPhep.maSoGiayPhep
      }
    ).then(res => {
      if (res.data.error_code === 'DUPLICATE_CODE') {
        valid = true;
      } else {
        valid = false;
      }
    })
    return valid
  }

  addOrUpdate = async (event) => {
    const result = await this.validation();
    if (result) {
      this.setVarialble();
      let dataInfo = this.state.noiDungGiayPhep;
      dataInfo.nhapDauKy = this.props.isNhapDauKy;
      await AXIOS_INSTANCE.post('giay-phep-xay-dung/them-moi', dataInfo).then(res => {
        if (res != null || res.data.error_code == 'SUCCESSFUL') {
          if (this.state.noiDungGiayPhep.giayPhep.id == 0) {
            toastr.success('Thêm nội dung giấy phép thành công');
            this.initData();
          } else {
            toastr.success('Cập nhật nội dung giấy phép thành công');
          }
          this.loadGiayPhepById(res.data.data)
        }
      }).catch(error => {
        toastr.warning('Thêm nội dung giấy phép không thành công');
        console.log(error);
      });
    }
  }

  clearData = () => {
    if (this.state.noiDungGiayPhep.giayPhep.id > 0) {
      this.setState({
        noiDungGiayPhep:
        {
          ...this.state.noiDungGiayPhep,
          viTriDiDoi: {
            id: 0,
            soNhaDuong: '',
            duong: '',
            maPhuongXaThiTran: '',
            maHuyenThiThanhPho: '',
            tinhThanhPho: '',
            maTinhThanhPho: '',
            diaChiCu: '',
            ranhGioiDiDoi: ''
          },
          loDatXayDungDiDoiDropDTOs: this.state.noiDungGiayPhep.loDatXayDungDiDoi,
          loDatXayDungDiDoi: [],
          fileTrash: this.state.noiDungGiayPhep.files,
          files: []
        }
      })
    } else {
      this.setState({
        noiDungGiayPhep: {
          thongTinCongTrinh: {
            id: 0,
            tenCongTrinh: '',
            capCongTrinh: '',
            loaiCongTrinh: '',
            phanLoaiXayDung: '',
            donViTuVan: '',
            kyHieuThietKe: '',
            donViThamDinh: '',
            ghiChu: '',
            thoiGianCongTrinh: '',
            noiDungQuangCao: ''
          },
          thongTinXayDung: {
            id: 0,
            tongDienTichSan: '',
            dienTichXayDungTangMot: '',
            chieuCaoTangMot: '',
            chieuCaoCongTrinh: '',
            chieuCaoBienQuangCao: '',
            soTang: '',
            cotNen: '',
            matDoXayDung: '',
            heSoSuDungDat: '',
            chiGioiDuongDo: '',
            chiGioiXayDung: '',
            mauSac: '',
            chieuSauCongTrinh: '',
            giaiDoan: 0,
            dukienhoanthanh: '',
            thoihancongtrinh: '',
          },
          viTriXayDung: {
            id: 0,
            soNhaDuong: '',
            duong: '',
            maPhuongXaThiTran: '',
            maHuyenThiThanhPho: '',
            tinhThanhPho: '',
            maTinhThanhPho: '',
            diaChiCu: '',
          },
          viTriDiDoi: {
            id: 0,
            soNhaDuong: '',
            duong: '',
            maPhuongXaThiTran: '',
            maHuyenThiThanhPho: '',
            tinhThanhPho: '',
            maTinhThanhPho: '',
            diaChiCu: '',
            ranhGioiDiDoi: ''
          },
          //--
          loDatXayDungDiDoiDropDTOs: this.state.noiDungGiayPhep.loDatXayDungDiDoi,
          loDatXayDungDiDoi: [],
          thongTinDeXuat: {
            ketluan: '',
            dexuat: ''
          },
          giayPhep: {
            id: this.state.noiDungGiayPhep.giayPhep.id,
            maHoSo: this.state.noiDungGiayPhep.giayPhep.maHoSo,
            tenLoaiGiayPhep: '',
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            loaiBienDong: this.props.loaiBienDong,
            tenLoaiBienDong: this.props.tenLoaiBienDong,
            maTrangThai: this.props.maTrangThai,
            tenTrangThai: this.props.tenTrangThai,
            giayToVeDatDai: '',
            maSoGiayPhep: '',
            ngayCap: 0,
            soGiayPhepCu: ''
          },
          chuDauTuDrops: this.state.noiDungGiayPhep.chuDauTus,
          chuDauTus: [],
          thongTinThamDinhDrops: this.state.noiDungGiayPhep.thongTinThamDinhs,
          thongTinThamDinhs: [],
          loDatXayDungDropDTOs: this.state.noiDungGiayPhep.loDatXayDungs,
          loDatXayDungs: [],
          fileTrash: this.state.noiDungGiayPhep.files,
          files: []
        }
      })
    }
  }

  render() {
    return (
      <div className="dash-content">

        <br />
        <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
          CẤP PHÉP DI DỜI CÔNG TRÌNH
        </h6>
        <br></br>

        <ul class="nav nav-tabs" role="tablist">
          <li class="ml-2">&nbsp;</li>
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#ndgp" role="tab">Nội dung giấy phép</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#cdt" role="tab">Chủ đầu tư</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#gtdd" role="tab">Giấy tờ về đất đai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tttd" role="tab">Thông tin thẩm định</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#ttdx" role="tab">Thông tin đề xuất</a>
          </li>
        </ul>

        <div class="tab-content">

          <div class="tab-pane p-2 active" id="ndgp" role="tabpanel">
            <FormCPDiDoiCongTrinh
              isNhapDauKy={this.props.isNhapDauKy}
              loaiBienDong={this.props.loaiBienDong}
              errorState={this.state.errors}
              onUpdateDataState={this.handleUpdateDataState}
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              addOrUpdate={this.addOrUpdate}
              onClearData={this.clearData}
              maHoSo={this.state.noiDungGiayPhep.giayPhep.maHoSo}
              maLoaiGiayPhep={this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep}
            ></FormCPDiDoiCongTrinh>
          </div>

          <div class="tab-pane p-2" id="cdt" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? <p>Không có dữ liệu...!</p> :
              <TableCDT
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></TableCDT>
            }
          </div>

          <div class="tab-pane p-2" id="gtdd" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <FormGTDD
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onChangeStateGTDD={this.onChangeStateGTDD}
              ></FormGTDD>
            }
          </div>

          <div class="tab-pane p-2" id="tttd" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <TableTTTD
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></TableTTTD>
            }
          </div>

          <div class="tab-pane p-2" id="ttdx" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <FormTTDX
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></FormTTDX>
            }
          </div>
        </div>
      </div>
    );
  }
}

