import { CONG_TRINH_DI_DOI, DI_DOI, TINH_TRANG_DANG_HOAT_DONG } from '../../Constants/constants';
import TabCapPhepDDCT from './TabCPDiDoiCongTrinh';

function CapPhepDDCTRender({ location }) {

  const tenLoaiGiayPhep = CONG_TRINH_DI_DOI.ten;
  const maLoaiGiayPhep = CONG_TRINH_DI_DOI.ma;
  const tenTrangThai = TINH_TRANG_DANG_HOAT_DONG.ten;
  const maTrangThai = TINH_TRANG_DANG_HOAT_DONG.ma;
  const tenLoaiBienDong = DI_DOI.ten;
  const loaiBienDong = DI_DOI.ma;
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const maHoSo = location.state && location.state?.maHoSo  ? location.state?.maHoSo : "";
  const nhapDauKy = location.state && location.state?.nhapDauKy  ? location.state?.nhapDauKy : false;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TabCapPhepDDCT
            id={id}
            isNhapDauKy={nhapDauKy}
            maHoSo={maHoSo}
            maLoaiGiayPhep={maLoaiGiayPhep}
            tenLoaiGiayPhep={tenLoaiGiayPhep}
            loaiBienDong={loaiBienDong}
            tenLoaiBienDong={tenLoaiBienDong}
            tenTrangThai={tenTrangThai}
            maTrangThai={maTrangThai}
          ></TabCapPhepDDCT>
        </div>
      </div>
    </div>
  );
}
export default CapPhepDDCTRender;
