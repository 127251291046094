import React from 'react';
import 'toastr/build/toastr.min.css';
import { LIST_LOAIGIAYPHEP } from '../../../Constants/constants';

export default class SelectLoaiGiayPhep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaiGiayPhep: [],
        }
    }
    componentDidMount(){
        this.danhSachLoaiGiayPhep();
    }

    danhSachLoaiGiayPhep = () => {
        this.setState({ loaiGiayPhep : LIST_LOAIGIAYPHEP || [] });
    }

    render() {
        return (
            <div className="form-group">
            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại giấy phép</label>
            <select className="form-control" 
            id="loaiGiayPhep" 
            name="loaiGiayPhep"
            value={this.props.loaiGiayPhep}
            onChange={this.props.setParams}>
                <option value="">--Loại giấy phép--</option>
                {
                    this.state.loaiGiayPhep.map((e) => {
                        return <option value={e.ma}>{e.ten}</option>
                    })
                }
            </select>
        </div>
        )
    }
}