import React from "react";
import toastr from 'toastr';
import AXIOS_INSTANCE from "../../../../api/index";
import StringUtil from '../../../../utils/stringUtils';
import ButtonGroupThuHoiHuy from '../../CapPhepDungChung/ButtonGroupThuHoiHuy';
import ModalClearAll from "../../CapPhepDungChung/ModalClearAll";
import ChuDauTu from "../ChiTietGPXD/SubChiTietGPXD/ChuDauTu";
import GiayToVeDatDai from "../ChiTietGPXD/SubChiTietGPXD/GiayToVeDatDai";
import LichSu from "../ChiTietGPXD/SubChiTietGPXD/LichSu";
import TepDinhKem from "../ChiTietGPXD/SubChiTietGPXD/TepDinhKem";
import ThongTinDeXuat from "../ChiTietGPXD/SubChiTietGPXD/ThongTinDeXuat";
import ThongTinThamDinh from "../ChiTietGPXD/SubChiTietGPXD/ThongTinThamDinh";
import NoiDungCapPhep from "./SubChiTietGPXD/NoiDungCapPhep";


export default class NavbarGPXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noiDungGiayPhep: {
                giayPhep: {
                    giayToVeDatDai: ''
                }
            },
            data: {
                idGiayPhep: this.props.idGiayPhep,
                tenTrangThai: this.props.tenTrangThai,
                maTrangThai: this.props.maTrangThai,
                tenLoaiBienDong: this.props.tenLoaiBienDong,
                loaiBienDong: this.props.loaiBienDong,
            },

            errors: {
                soQDThuHoi: '',
                ngayThuHoi: '',
            }
        }
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
    }

 
    handleUpdateDataState = (name, value) => {
        if(!this.state.noiDungGiayPhep.thongTinThuHoi){
            this.state.noiDungGiayPhep.thongTinThuHoi = {}
        }
        if (name === "files"||name==="fileTrash") {
            if(!this.state.noiDungGiayPhep.thongTinThuHoi.files){
                this.state.noiDungGiayPhep.thongTinThuHoi.files = []
            }
            this.state.noiDungGiayPhep.thongTinThuHoi[name] = value;
        }else{
            this.state.noiDungGiayPhep[name] = value;
        }
        this.setState(this.state);
    }
    async checkDuplicate() {
        let valid=false;
        const { noiDungGiayPhep } = this.state;
        await AXIOS_INSTANCE.post('giay-phep-xay-dung/kiem-tra-duplicate-thuhoi',
            {
                "id": noiDungGiayPhep.thongTinThuHoi.id? noiDungGiayPhep.thongTinThuHoi.id : 0 ,
                "soQuyetDinh": noiDungGiayPhep.thongTinThuHoi.soQDThuHoi
            }
        ).then(res => {
            if (res.data.error_code === 'DUPLICATE_CODE') {
                valid = true;
            }
        })
        return valid
    }
    validation = async () => {
        const { noiDungGiayPhep } = this.state;
        this.state.errors = {};
        let valid = true;
        let checkValidation = true;
        if (!noiDungGiayPhep.thongTinThuHoi) {
            this.state.errors.soQDThuHoi = 'Vui lòng nhập số quyết định thu hồi';
            valid = false;
        } else if (StringUtil.isNullOrEmty(noiDungGiayPhep.thongTinThuHoi.soQDThuHoi)) {
            this.state.errors.soQDThuHoi = 'Vui lòng nhập số quyết định thu hồi';
            valid = false;
        } else {
            checkValidation = await this.checkDuplicate();
            if (checkValidation) {
                this.state.errors.soQDThuHoi = 'Số quyết định này đã tồn tại';
                valid = false;
            }
        }
        if (!noiDungGiayPhep.thongTinThuHoi || !noiDungGiayPhep.thongTinThuHoi.ngayThuHoi || noiDungGiayPhep.thongTinThuHoi.ngayThuHoi === undefined || noiDungGiayPhep.thongTinThuHoi.ngayThuHoi === 0) {
            this.state.errors.ngayThuHoi = 'Vui lòng nhập ngày thu hồi';
            valid = false;
        } 
        // else {
        //     let date = new Date();
        //     if (noiDungGiayPhep.thongTinThuHoi.ngayThuHoi > date) {
        //         this.state.errors.ngayThuHoi = 'Ngày thu hồi phải nhỏ hơn ngày hiện tại';
        //         valid = false;
        //     }
        // }
        if(noiDungGiayPhep.thongTinThuHoi.ngayThuHoi <= noiDungGiayPhep.giayPhep.ngayCap){
            this.state.errors.ngayThuHoi = 'Ngày thu hồi phải lớn hơn ngày cấp';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    addOrUpdate = async (event) => {
        const result = await this.validation();
        if (result) {
            const { data, noiDungGiayPhep } = this.state;
            const dataThuHoi = {
                dataInfo: data,
                thongTinThuHoi: noiDungGiayPhep.thongTinThuHoi,
                files: noiDungGiayPhep.thongTinThuHoi.files,
                fileTrash: noiDungGiayPhep.thongTinThuHoi.fileTrash
            }
            AXIOS_INSTANCE.post('giay-phep-xay-dung/thu-hoi', dataThuHoi).then(res => {
                if (res != null) {
                    if (res.data.error_code === 'SUCCESSFUL') {
                        toastr.success('Thu hồi giấy phép thành công');
                        console.log("Res.data.data", res.data.data);
                        this.props.onLoadThongTinGiayPhepXayDung(res.data.data)
                    } else {
                        toastr.warning(res.data.error_message);
                    }
                } else {
                    toastr.warning('Thu hồi giấy phép không thành công');
                }
            }).catch(error => {
                toastr.warning('Thu hồi giấy phép không thành công');
            });
        }
    }
    handleModalToggle = (value) => {
        this.state.openModel = !this.state.openModel;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle();
        if (!this.state.noiDungGiayPhep.thongTinThuHoi) {
            this.state.noiDungGiayPhep.thongTinThuHoi = {
                soQDThuHoi: '',
                ngayThuHoi: null,
                lyDoThuHoi: '',
                nguoiKy: '',
                ghiChu: '',
                files:[]
            }
        }
        this.state.noiDungGiayPhep.thongTinThuHoi.fileTrash =  this.state.noiDungGiayPhep.thongTinThuHoi.files
        this.state.noiDungGiayPhep.thongTinThuHoi.files =  []
        this.state.noiDungGiayPhep.thongTinThuHoi.soQDThuHoi = '';
        this.state.noiDungGiayPhep.thongTinThuHoi.ngayThuHoi = null;
        this.state.noiDungGiayPhep.thongTinThuHoi.lyDoThuHoi = '';
        this.state.noiDungGiayPhep.thongTinThuHoi.nguoiKy = '';
        this.state.noiDungGiayPhep.thongTinThuHoi.ghiChu = '';
        this.setState(this.state)
    }
    render() {
        return (

            <div className="container-fluid" style={{ fontSize: '14px' }}>
                <nav style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="py-3" style={{ height: '55px' }}>
                        <ul class="nav nav-tabs" role="tablist" style={{ fontWeight: 'bold' }}>
                            <li class="ml-2">&nbsp;</li>
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#noidungcapphep" role="tab">Nội dung cấp phép</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#chudautu" role="tab">Chủ đầu tư</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#giaytovedatdai" role="tab">Giấy tờ về đất đai</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#thongtinthamdinh" role="tab">Thông tin thẩm định</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#thongtindexuat" role="tab">Thông tin đề xuất</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tepdinhkem" role="tab">Tệp đính kèm</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#lichsu" role="tab">Lịch sử</a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane p-2 active" id="noidungcapphep" role="tabpanel">
                                <NoiDungCapPhep
                                    idGiayPhep={this.state.idGiayPhep}
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.handleUpdateDataState}
                                    errorState={this.state.errors}
                                ></NoiDungCapPhep>
                            </div>
                            <div class="tab-pane p-2" id="chudautu" role="tabpanel">
                                <ChuDauTu
                                    chuDauTus={this.state.noiDungGiayPhep.chuDauTus}
                                ></ChuDauTu>
                            </div>
                            <div class="tab-pane p-2" id="giaytovedatdai" role="tabpanel">
                                <GiayToVeDatDai
                                    giayToVeDatDai={this.state.noiDungGiayPhep.giayPhep.giayToVeDatDai}
                                ></GiayToVeDatDai>
                            </div>
                            <div class="tab-pane p-2" id="thongtinthamdinh" role="tabpanel">
                                <ThongTinThamDinh
                                    thongTinThamDinhs={this.state.noiDungGiayPhep.thongTinThamDinhs}
                                ></ThongTinThamDinh>
                            </div>
                            <div class="tab-pane p-2" id="thongtindexuat" role="tabpanel">
                                <ThongTinDeXuat
                                    thongTinDeXuat={this.state.noiDungGiayPhep.thongTinDeXuat}
                                ></ThongTinDeXuat>
                            </div>
                            <div class="tab-pane p-2" id="tepdinhkem" role="tabpanel">
                                <TepDinhKem
                                    files={this.state.noiDungGiayPhep.files}
                                ></TepDinhKem>
                            </div>
                            <div class="tab-pane p-2" id="lichsu" role="tabpanel">
                                <LichSu
                                    giayPhep={this.state.noiDungGiayPhep.giayPhep}
                                    lichSuGiayPhep={this.state.noiDungGiayPhep.lichSuGiayPhep}
                                ></LichSu>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="row">
                    <div className="col-md-12 text-left" style={{ marginTop: '20px' }}>
                        <ButtonGroupThuHoiHuy
                            addOrUpdate={this.addOrUpdate}
                            onModalToggle={this.handleModalToggle}
                        ></ButtonGroupThuHoiHuy>
                    </div>
                </div>
                <ModalClearAll
                    onClearData={this.handleClearAll}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel}
                ></ModalClearAll>
            </div>

        )
    }
}

