import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../../utils/stringUtils';
import TableHangMuc from '../SubModalCongTrinhDA/TableHangMuc';
import TableLoDatXD from '../SubModalCongTrinhDA/TableLoDatXD';
import ModalKyHieuThietKe from '../../../../Components/CapPhepDungChung/NDGP/ModalKyHieuThietKe';
import ModalDonViTuVan from '../../../../Components/CapPhepDungChung/NDGP/ModalDonViTuVan';
import { isThisTypeNode } from 'typescript';

export default class ModalCongTrinhDA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModel: '',
            congTrinhDA: {
                noiDungCongTrinhDA: {
                    id: 0,
                    tenCongTrinh: '',
                    donViTuVan: '',
                    kyHieuThietKe: '',
                    ghiChuDiaChi: '',
                    thongTinKhoiCong: '',

                    tenHangMuc: '',
                    congTrinhChinh: false,
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoTangMot: '',
                    chieuCaoCongTrinh: '',
                    soTang: '',
                    cotNen: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    mauSac: '',
                    ketCau: '',
                    noiDungKhac: '',
                },
                loDatXayDungs: [],
                hangMucs: [],
                //drop
                loDatXayDungDropDTOs: [],
                HangMucDrops: [],
            },
            show: false,
            indexEdit: -1,
            errors: {
                tenCongTrinh: '',
                tenHangMuc: ''
            }
        }
    }

    componentDidMount() {
        const { congTrinhDA } = this.state;
        if (this.props.indexEdit !== -1) {
            this.setState({
                congTrinhDA: { ...congTrinhDA, ...this.props.data }
            });
        }
    }

    componentWillUnmount() {
        this.clearData()
    }

    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle('themMoi');
    }

    /**
     * 
     * @param {event} e 
     */

    handleChange = (e) => {
        const { congTrinhDA } = this.state;
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            congTrinhDA: {
                ...congTrinhDA,
                noiDungCongTrinhDA: {
                    ...congTrinhDA.noiDungCongTrinhDA,
                    [name]: value
                }
            }
        })
    }
    handleSubmit = () => {
        if (this.validation()) {
            if (this.props.indexEdit >= 0) {
                this.props.onUpdateData(this.props.indexEdit, this.state.congTrinhDA);
                toastr.success('Cập nhật công trình dự án thành công')
                this.handleCloseModal();
            } else {
                this.props.onAddData(this.state.congTrinhDA);
                toastr.success('Thêm mới công trình dự án thành công')
                this.handleCloseModal();
            }
        }
    }
    setListParams = (key, value) => {
        if (key === 'loDatXayDungs') {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.loDatXayDungs = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
        if (key === 'loDatXayDungDropDTOs') {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.loDatXayDungDropDTOs = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
        if (key === 'hangMucs') {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.hangMucs = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
        if (key === 'hangMucDrops') {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.hangMucDrops = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
        if (key === "donViTuVan") {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.noiDungCongTrinhDA.donViTuVan = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
        if (key === "kyHieuThietKe") {
            let congTrinhDA = { ...this.state.congTrinhDA }
            congTrinhDA.noiDungCongTrinhDA.kyHieuThietKe = value
            this.setState({
                congTrinhDA: congTrinhDA
            })
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.congTrinhDA.noiDungCongTrinhDA.tenCongTrinh)) {
            this.state.errors.tenCongTrinh = 'Vui lòng nhập tên công trình';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.congTrinhDA.noiDungCongTrinhDA.tenHangMuc)) {
            this.state.errors.tenHangMuc = 'Vui lòng nhập tên hạng mục';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.setState({
            congTrinhDA: {
                noiDungCongTrinhDA: {
                    id: 0,
                    tenCongTrinh: '',
                    donViTuVan: '',
                    kyHieuThietKe: '',
                    ghiChuDiaChi: '',
                    thongTinKhoiCong: '',

                    tenHangMuc: '',
                    congTrinhChinh: false,
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoTangMot: '',
                    chieuCaoCongTrinh: '',
                    soTang: '',
                    cotNen: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    mauSac: '',
                    ketCau: '',
                    noiDungKhac: '',
                },
                loDatXayDungs: [],
                hangMucs: [],
                //drop
                loDatXayDungDropDTOs: [],
                HangMucDrops: [],
            },
            errors: {
                ten: '',
                soGiayTo: ''
            }
        });
    }
    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({
            ...this.state
        })
    }

    render() {
        const { show, congTrinhDA } = this.state;
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="ModalCreateCongTrinhDA" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI CÔNG TRÌNH</h5>
                    <button type="button" class="close" onClick={this.handleCloseModal} aria-label="Close" id="closeModal">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="tenCongTrinh" name="tenCongTrinh"
                                                style={{ fontSize: '14px' }}
                                                value={congTrinhDA?.noiDungCongTrinhDA?.tenCongTrinh}
                                                onChange={this.handleChange} />
                                        </div>
                                        <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="" style={{ marginLeft: '15px', width: '270px' }}>
                                        <div className="form-group">
                                            <label htmlFor="donViTuVan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị tư vấn</label>
                                            <p style={{ display: 'flex' }}>
                                                <input type="text"
                                                    className="form-control"
                                                    id="donViTuVan" name="donViTuVan"
                                                    style={{ fontSize: '14px' }}
                                                    value={congTrinhDA?.noiDungCongTrinhDA?.donViTuVan}

                                                    onChange={(event) => this.handleChange(event)} />
                                                <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                    onClick={() => this.handleModalToggle('tu-van')}></i></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="" style={{ marginLeft: '15px', width: '270px' }}>
                                        <label htmlFor="kyHieuThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ký hiệu thiết kế</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="kyHieuThietKe" name="kyHieuThietKe"
                                                style={{ fontSize: '14px' }}
                                                value={congTrinhDA?.noiDungCongTrinhDA?.kyHieuThietKe}
                                                onChange={(event) => this.handleChange} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('ky-hieu')}></i></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="ghiChuDiaChi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú địa chỉ</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="ghiChuDiaChi" name="ghiChuDiaChi"
                                                style={{ fontSize: '14px' }}
                                                value={congTrinhDA?.noiDungCongTrinhDA?.ghiChuDiaChi}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="thongTinKhoiCong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thông tin khởi công</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="thongTinKhoiCong" name="thongTinKhoiCong"
                                                style={{ fontSize: '14px' }}
                                                value={congTrinhDA?.noiDungCongTrinhDA?.thongTinKhoiCong}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="tableLoDatXD" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lô đất xây dựng</label>
                                            <TableLoDatXD
                                                congTrinhDA={congTrinhDA}
                                                onUpdateDataState={this.setListParams}
                                            ></TableLoDatXD>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <TableHangMuc
                            congTrinhDA={congTrinhDA}
                            onUpdateDataState={this.setListParams}
                        ></TableHangMuc>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-md-*" style={{ marginLeft: '30px' }}>
                            <div className="form-group">
                                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung hạng mục</label>
                            </div>
                        </div>
                        <div className="col-md">
                            <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tenHangMuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>Hạng mục<span style={{ color: 'red' }}>(*)</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tenHangMuc" name="tenHangMuc"
                                        style={{ fontSize: '14px' }}
                                        defaultValue={congTrinhDA?.noiDungCongTrinhDA?.tenHangMuc}
                                        onChange={this.handleChange} />
                                </div>
                                <div style={Boolean(this.state.errors?.tenHangMuc) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenHangMuc}</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" className='form-check-input' id="congTrinhChinh" name="congTrinhChinh" />
                                    <label class="form-check-label" htmlFor="congTrinhChinh">Công trình chính</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="dienTichXayDungTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>DTXD (tầng một)</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="dienTichXayDungTangMot" name="dienTichXayDungTangMot"
                                            value={congTrinhDA?.noiDungCongTrinhDA?.dienTichXayDungTangMot}
                                            onChange={this.handleChange}
                                            style={{ fontSize: '14px' }} />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng diện tích sàn</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="tongDienTichSan" name="tongDienTichSan"
                                            value={congTrinhDA?.noiDungCongTrinhDA?.tongDienTichSan}
                                            onChange={this.handleChange}
                                            style={{ fontSize: '14px' }} />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="chieuCaoTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao tầng 1</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="chieuCaoTangMot" name="chieuCaoTangMot"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.chieuCaoTangMot}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="chieuCao" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao công trình</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="chieuCaoCongTrinh" name="chieuCaoCongTrinh"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.chieuCaoCongTrinh}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="soTang" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tầng</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="soTang" name="soTang"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.soTang}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="cotNen" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cốt nền</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cotNen" name="cotNen"
                                            style={{ fontSize: '14px' }}
                                            value={congTrinhDA?.noiDungCongTrinhDA?.cotNen}
                                            onChange={this.handleChange}
                                        />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="matDoXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Mật độ xây dựng</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="matDoXayDung" name="matDoXayDung"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.matDoXayDung}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="heSoSuDungDat" style={{ fontWeight: 'bold', fontSize: '14px' }}>Hệ số sử dụng đất</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="heSoSuDungDat" name="heSoSuDungDat"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.heSoSuDungDat}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="mauSac" style={{ fontWeight: 'bold', fontSize: '14px' }}>Màu sắc</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mauSac" name="mauSac"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.mauSac}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ketCau" style={{ fontWeight: 'bold', fontSize: '14px' }}>Kết cấu</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="ketCau" name="ketCau"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.ketCau}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="noiDungKhac" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung khác / Ghi chú</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="noiDungKhac" name="noiDungKhac"
                                        style={{ fontSize: '14px' }}
                                        value={congTrinhDA?.noiDungCongTrinhDA?.noiDungKhac}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDonViTuVan
                    onUpdateParam={this.setListParams}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tu-van'}
                ></ModalDonViTuVan>
                <ModalKyHieuThietKe
                    onUpdateParam={this.setListParams}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'ky-hieu'}
                ></ModalKyHieuThietKe>
            </Modal>
        )
    }
}