import vi from 'date-fns/locale/vi';
import isEqual from 'lodash/isEqual';
import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import closeIcon from '../../../Assets/icons/u1233.png';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';

export default class CNSGPModal extends React.Component {

    closeModel = React.createRef();
    state = {
        soGiayPhepValue: "",
        ngayCapPhepValue: 0,
        ngayHetHanValue: 0,
        ghiChuValue: "",
        nguoiKyValue: "",
        mienPhiCapGiayPhepValue: false,
        idReset: 0,
        soGiayPhepError: "",
        ngayCapPhepError: "",
        mienPhiCapGiayPhepCheck: false,
        files: [],
        fileTrash: [],
        loaiKetQua: "",
    }

    componentDidMount() {

    }
    validate = {
        required: (name, value) => ({
            valid: value != null && value.length > 0,
            message: name + ' is required.',
        }),
        maxValue: (name, value, max) => ({
            valid: value != null && value.length <= max,
            message: name + ' is over ' + max + ' character',
        }),
        minValue: (character, min = 4) => character >= min,
    };

    handleDateValue = epochTime => {
        let currentDate;
        if (!!epochTime) {
            currentDate = new Date(0);
            currentDate.setMilliseconds(epochTime);
            currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset());
            return currentDate.toJSON().slice(0, 10);
        } else {
            return 0;
        }
    }

    componentDidUpdate(prevProps) {
        const { capSoGiayPhep, idReset, onUpdateDataState } = this.props;
        if (!isEqual(prevProps.idReset, idReset)) {
            this.setState({
                soGiayPhepValue: capSoGiayPhep.soGiayPhep,
                ngayCapPhepValue: this.handleDateValue(capSoGiayPhep.ngayCapPhep),
                ngayHetHanValue: this.handleDateValue(capSoGiayPhep.ngayHetHan),
                ghiChuValue: capSoGiayPhep.ghiChuCapSo,
                nguoiKyValue: capSoGiayPhep.nguoiKy,
                mienPhiCapGiayPhepValue: capSoGiayPhep.mienPhiCapGiayPhep,
                mienPhiCapGiayPhepCheck: capSoGiayPhep.mienPhiCapGiayPhep,
                soGiayPhepError: null,
                ngayCapPhepError: null,
                files: capSoGiayPhep.files,
                fileTrash: capSoGiayPhep.fileTrash,
                loaiKetQua: capSoGiayPhep.loaiKetQua,
            });
        }
    }

    setSoGiayphep = event => {
        let name = event.target.name;
        let errorname = 'soGiayPhepError';
        let value = event.target.value;
        const isRequired = this.validate.required(name, value);
        const isMaxLength = this.validate.maxValue(name, value, 255);
        let errorMsg = { [errorname]: null };

        if (!isRequired.valid) {
            errorMsg = { [errorname]: "Số giấy phép không được để trống" };
        } else if (!isMaxLength.valid) {
            errorMsg = { [errorname]: "Số giấy phép không được quá 255 kí tự" };
        }
        this.setState({
            [name]: value,
            ...errorMsg
        })
    }
    setParamCheckBox = (event) => {
        let value = event.target.checked;
        this.setState({
            mienPhiCapGiayPhepCheck: value
        });
    }

    setParamCheckBoxFinal = () => {
        const mienPhiCapGiayPhepCheck = this.state.mienPhiCapGiayPhepCheck;
        if (!mienPhiCapGiayPhepCheck) {
            this.setState({
                soGiayPhepValue: this.props.capSoGiayPhep.soGiayPhep,
                ngayCapPhepValue: this.handleDateValue(this.props.capSoGiayPhep.ngayCapPhep),
                ngayHetHanValue: this.handleDateValue(this.props.capSoGiayPhep.ngayHetHan),
                mienPhiCapGiayPhepValue: mienPhiCapGiayPhepCheck,
            });
        } else {
            this.setState({
                soGiayPhepValue: "",
                ngayCapPhepValue: 0,
                ngayHetHanValue: 0,
                mienPhiCapGiayPhepValue: mienPhiCapGiayPhepCheck,
            });
        }
    }
    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value })
    }

    setTimeParams = (selectDate) => {
        const errorname = "ngayCapPhepError";
        const errorMsg = { [errorname]: null };
        const ngayCap = selectDate.getTime() || 0;

        selectDate.setDate(selectDate.getDate());
        selectDate.setMonth(selectDate.getMonth() + 12);
        let dates = selectDate.getTime();

        const toDay = new Date(new Date().toISOString().substr(0, 10).toString()).getTime() || 0;
        if (toDay < ngayCap) {
            toastr.warning('Ngày cấp không được lớn hơn ngày hiện tại!');
            return;
        } else {
            this.setState({ ngayCapPhepValue: ngayCap, ngayHetHanValue: dates, ...errorMsg });
        }
    }

    setTimeParamsNHH = (selectDate) => {
        const errorname = "ngayHetHanError";
        const errorMsg = { [errorname]: null };
        const ngayCap = new Date(this.state.ngayCapPhepValue).getTime() || 0;
        const ngayHetHan = selectDate.getTime() || 0;
        if (ngayCap > ngayHetHan) {
            toastr.warning('Ngày cấp không được lớn hơn ngày hết hạn!');
            return;
        } else {
            this.setState({ ngayHetHanValue: ngayHetHan, ...errorMsg });
        }
    }

    onSubmit = async () => {
        const timeCSGP = new Date(this.state.ngayCapPhepValue).getTime();
        const ngayHetHan = new Date(this.state.ngayHetHanValue).getTime();
        let name = "soGiayPhepValue"
        let value = this.state.soGiayPhepValue;
        const isRequired = this.validate.required(name, value);
        const isMaxLength = this.validate.maxValue(name, value, 255);
        const ghiChu = this.state.ghiChuValue;
        const nguoiKy = this.state.nguoiKyValue;
        const mienPhiGiayPhep = this.state.mienPhiCapGiayPhepValue;
        const loaiKetQua = this.state.loaiKetQua;

        const data = {
            "id": this.props.capSoGiayPhep.id,
            "maHoSo": this.props.capSoGiayPhep.maHoSo,
            "soGiayPhep": value,
            "ngayCapPhep": timeCSGP,
            "ngayHetHan": ngayHetHan,
            "ghiChu": ghiChu,
            "nguoiKy": nguoiKy,
            "mienPhiCapGiayPhep": mienPhiGiayPhep,
            "hoatDong": 1,
            "files": this.props.capSoGiayPhep.files,
            "fileTrash": this.props.capSoGiayPhep.fileTrash,
            "loaiKetQua": loaiKetQua,
        };

        if (!isRequired.valid && mienPhiGiayPhep === false) {
            toastr.warning('Số giấy phép không được để trống!');
            return;
        }

        if (!isMaxLength.valid && mienPhiGiayPhep === false) {
            toastr.warning('Số giấy phép không được quá 255 kí tự!');
            return;
        }

        if (timeCSGP === 0 && mienPhiGiayPhep === false) {
            toastr.warning("Ngày cấp phép không được để trống!");
            return;
        }
        if (ghiChu != null && ghiChu.length >= 5000) {
            toastr.warning('Ghi chú không được vượt quá 5000 ký tự!');
            return;
        }
        if (nguoiKy != null && nguoiKy.length >= 255) {
            toastr.warning('Người ký không được vượt quá 255 ký tự!');
            return;
        }

        AXIOS_INSTANCE.post('giay-phep-xay-dung/cap-so', data).then((response) => {
            if (response != null && response.status == 200 && response.data.error_code === "SUCCESSFUL") {
                toastr.success('Cấp số giấy phép thành công!');
                this.closeModel.current.click();
            } else {
                toastr.error('Cấp số giấy phép không thành công!');
                this.closeModel.current.click();
            }
        }).catch(function (error) {
            toastr.error('Cấp số giấy phép không thành công!');
            this.closeModel.current.click();
        });
    }
    resetInfo = () => {
        this.setState({
            soGiayPhepValue: "",
            ngayCapPhepValue: 0,
            ngayHetHanValue: 0,
            ghiChuValue: "",
            nguoiKyValue: "",
            mienPhiCapGiayPhepValue: false,
            mienPhiCapGiayPhepCheck: false,
        });
    }

    handleDongBoGiayPhep =  async () => {
        // dispatchLoading(showLoading());
        await AXIOS_INSTANCE.get(`lay-ket-qua/get-by-ma-ho-so/${this.props.capSoGiayPhep.maHoSo}`).then((res) => {
            if (res != null && res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
                if (res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
                    if (res?.data?.data?.files && res?.data?.data?.files.length>0){
                        const files = [];
                        for (let i = 0 ; i<res?.data?.data?.files.length;i++){
                            const file = {
                                fileEntryId:0,
                                folderId: 0,
                                userId: "",
                                userName: "",
                                name: res.data.data.files[i].fileName,
                                createdDate: 0,
                                extension: "",
                                mimeType: "",
                                orginName: "",
                                description: "",
                                version: "",
                                docId: "",
                                fileParentId: 0,
                                hoatDong: true,
                                motCua: true,
                                url: res.data.data.files[i].url,
                            }
                           files.push(file);
                        }
                        this.props.capSoGiayPhep.files=files;
                    }
                    this.setState({
                        soGiayPhepValue: res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null,
                        ngayCapPhepValue: res.data.data.ngayCap ? res.data.data.ngayCap : null,
                        ngayHetHanValue: res.data.data.ngayHetHan ? res.data.data.ngayHetHan : this.state.ngayHetHanValue,
                        loaiKetQua : "DA_LAY_KET_QUA",
                    });
                    console.log(this.state);
                    toastr.success(res.data.error_message);
                } else{
                    toastr.error(res.data.error_message);
                }}
            }
        ).finally(() => {
            // dispatchLoading(hideLoading());
        });
    }
    render() {
        const CustomInput = ({ value, onClick }) => (
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputHetHan = ({ value, onClick }) => (
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <React.Fragment>
                <form>
                    <div class="modal fade" id="CNSGPModal" ref={this.modalRef} role="dialog" aria-labelledby="ModalCHNV" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                            <div class="modal-content">
                                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                    <h5 class="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>CẬP NHẬT VĂN BẢN</h5>
                                    <img type="button" class="close" data-dismiss="modal" aria-label="Close" src={closeIcon}></img>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-4 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số GPXD <span style={{ color: 'red' }}>(*)</span></label>
                                            <input type="text" className="form-control" name="soGiayPhepValue" value={this.state.soGiayPhepValue} onChange={this.setSoGiayphep} disabled={(this.state.mienPhiCapGiayPhepValue === true) ? true : false} />
                                            {this.state.soGiayPhepError && <span style={{ color: 'red' }} >{this.state.soGiayPhepError}</span>}
                                        </div>
                                        <div className="col-md-4 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp phép <span style={{ color: 'red' }}>(*)</span></label>
                                            <DatePicker
                                                customInput={<CustomInput />}
                                                placeholderText={"dd/mm/yyyy"}
                                                selected={this.state.ngayCapPhepValue === 0 ? null : new Date(this.state.ngayCapPhepValue)}
                                                dateFormat={"dd/MM/yyyy"}
                                                className="form-control"
                                                onChange={date => this.setTimeParams(date)}
                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                disabled={(this.state.mienPhiCapGiayPhepValue === true) ? true : false}
                                                locale={vi}
                                                popperModifiers={{
                                                    offset: {
                                                        enabled: true,
                                                        offset: "5px, 10px"
                                                    },
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: "viewport",
                                                    }
                                                }}
                                            >
                                            </DatePicker>
                                            {this.state.ngayCapPhepError && <span style={{ color: 'red' }} >{this.state.ngayCapPhepError}</span>}
                                        </div>
                                        <div className="col-md-4 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày hết hạn<span style={{ color: 'red' }}>(*)</span></label>
                                            <DatePicker
                                                customInput={<CustomInputHetHan />}
                                                name="ngayHetHanValue"
                                                placeholderText={"DD/MM/YYYY"}
                                                className="form-control"
                                                selected={this.state.ngayHetHanValue === 0 ? null : new Date(this.state.ngayHetHanValue)}
                                                dateFormat={"dd/MM/yyyy"}
                                                onChange={date => this.setTimeParamsNHH(date)}
                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                locale={vi}
                                                disabled={(this.state.mienPhiCapGiayPhepValue === true) ? true : false}
                                                popperModifiers={{
                                                    offset: {
                                                        enabled: true,
                                                        offset: "5px, 10px"
                                                    },
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: "viewport"
                                                    }
                                                }}
                                            >
                                            </DatePicker>
                                            {this.state.ngayCapPhepError && <span style={{ color: 'red' }} >{this.state.ngayCapPhepError}</span>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                                            <textarea type="text" className="form-control" name="ghiChuValue" value={this.state.ghiChuValue} onChange={this.setParams} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Người ký</label>
                                            <input type="text" className="form-control" name="nguoiKyValue" value={this.state.nguoiKyValue} onChange={this.setParams} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-left row">
                                        <div className="form-check-inline text-left" style={{ marginTop: "10px" }}>
                                            <label className="form-check-label">
                                                <input type="checkbox" data-toggle="modal" data-target="#ModalDelete" className="form-check-input" name="mienPhiCapGiayPhepValue" checked={this.state.mienPhiCapGiayPhepValue} onChange={this.setParamCheckBox} />Công trình được miễn giấy phép xây dựng
                                            </label>
                                        </div>
                                    </div>
                                    <br></br>
                                    <UploadFilesCommon
                                        files={this.props.capSoGiayPhep.files}
                                        fileTrash={this.props.capSoGiayPhep.fileTrash}
                                        onUpdateDataState={this.props.onUpdateDataState}
                                    />
                                    <br></br>
                                    <br></br>
                                    <br />
                                    <div className="col-md-12 text-left row">
                                        <button type="button" class="btn btn-primary btn-sm" onClick={this.onSubmit}>Lưu</button>&nbsp;
                                        <button type="button" ref={this.closeModel} style={{ display: 'none' }} data-dismiss="modal"></button>
                                        <button type="reset" class="btn btn-danger btn-sm" onClick={this.resetInfo}>Xóa</button>&nbsp;
                                        <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Trở về</button>&nbsp;
                                         {this.props.capSoGiayPhep.id > 0 && this.props.capSoGiayPhep.maHoSo && (
                                              <button type="button" class="btn btn-primary btn-sm" onClick={this.handleDongBoGiayPhep}>Lấy kết quả</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                    <div class="modal fade" id="ModalDelete" tabindex="-1" role="dialog" aria-labelledby="ModalDelete" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                    <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <div class="col-md-12 text-center">
                                            <h3>{this.state.mienPhiCapGiayPhepValue === false ? 'Xác nhận thông tin công trình được miễn giấy phép?' : 'Xác nhận thông tin công trình không được miễn giấy phép?'}</h3>
                                        </div>
                                        <br></br>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div className="text-right col-md-6">
                                                    <button type="button" style={{ fontSize: "14px" }} data-dismiss="modal" class="btn btn-primary btn-sm" onClick={this.setParamCheckBoxFinal}>Đồng ý</button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button data-dismiss="modal" className="btn btn-secondary btn-sm" onClick={this.resetInfo}>Hủy</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </form>
            </React.Fragment>
        )
    }
}