import { Grid, SvgIcon } from '@material-ui/core';
import { values } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { createFileType, downLoadFile } from '../../../../api/common/exportUtil';
import AXIOS_INSTANCE from '../../../../api/index';
import GiayPhepService from "../../../../api/service/CapPhepXDCTHService";
import { LOAI_THONG_KE } from '../../../Constants/constants';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";

export default class FormThongKeCPXD extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {
                kyThongKe: 2,
                // tuNgay: 0,
                // denNgay: 0,
                loaiThongKe: 1,
                maQuanHuyen: '',
                namThongKe: new Date().getFullYear()
            },
            namThongKes: [
            ],
            quanHuyen: []

        }
    }

    componentDidMount() {
        let namThongKeNew = [];
        for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear(); i++) {
            namThongKeNew.unshift(i);
        }
        this.danhSachQuanHuyenInfo();
        this.state.namThongKes = namThongKeNew;
        this.setState(this.state);
        this.loadThongKe(this.state);
    }

    danhSachQuanHuyenInfo = () => {
        const queryParam = {
            "maTinhThanh": "92"
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }

    loadThongKe = () => {
        this.props.loadData(this.state.data);
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        if (type === 'date') {
            value = new Date(value).getTime();
        }
        this.state.data[name] = value;
        this.setState(this.state);
        if (name === 'loaiThongKe') {
            this.props.setLoaiThongKe(value);
        }
        this.props.loadData(this.state.data);
    }

    downloadFile = async (extension) => {
        let request = {
            kyThongKe: this.state.data.kyThongKe,
            tuNgay: this.state.data.tuNgay,
            denNgay: this.state.data.denNgay,
            loaiThongKe: this.state.data.loaiThongKe,
            maQuanHuyen: this.state.data.maQuanHuyen,
            namThongKe: this.state.data.namThongKe,
            exportFileName: 'Thong_ke_giay_phep',
            exportType: extension
        };
        await new GiayPhepService().downloadFileThongKeGiayPhep(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Thong_ke_giay_phep');
        }).finally(() => {

        });
    }

    handleChangeNgayCapTu(date) {
        var longFormat = date * 1;
        this.state.data.tuNgay = longFormat;
        this.setState(this.state)
        // this.setState({ tuNgay: longFormat });
    }
    handleChangeNgayCapDen(date) {
        var longFormat = date * 1;
        this.state.data.denNgay = longFormat;
        this.setState(this.state)
        // this.setState({ denNgay: longFormat });
    }

    render() {
        const CustomInputNgayCapTu = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputNgayCapDen = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (

            <div className="col-md-12" style={{ float: 'left', fontSize: '14px' }}>
                <br />
                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}>THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG</h6>
                <br />
                <br />
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="kyThongKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Kỳ thống kê</label>
                            <select value={this.state.data.kyThongKe} className="form-control" id="kyThongKe" name="kyThongKe" style={{ fontSize: '14px' }} onChange={this.setParams}>
                                <option value="1">Khoảng thời gian</option>
                                <option value="2">Năm</option>
                            </select>
                        </div>
                    </div>

                    {this.state.data.kyThongKe == 2 &&
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="namThongKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Năm</label>
                                <select value={this.state.data.namThongKe} className="form-control" id="namThongKe" name="namThongKe" style={{ fontSize: '14px' }} onChange={this.setParams}>
                                    <option value="">-- Chọn năm --</option>
                                    {
                                        this.state.namThongKes.map((item) => {
                                            return (<option value={item}>{item}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    }

                    {this.state.data.kyThongKe == 1 &&
                        <>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tuNgay" style={{ fontWeight: 'bold', fontSize: '14px' }}>Từ ngày</label>
                                    {/* <input type="date" className="form-control" id="tuNgay" name="tuNgay" onChange={this.setParams}></input> */}
                                    <DatePicker
                                        customInput={<CustomInputNgayCapTu />}
                                        selected={this.state.data.tuNgay}
                                        onChange={this.handleChangeNgayCapTu.bind(this)}
                                        value={this.state.data.tuNgay === 0 ? moment(this.state.data.tuNgay).format("DD/MM/YYYY") : null}
                                        name="tuNgay"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="denNgay" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đến ngày</label>
                                    {/* <input type="date" className="form-control" id="denNgay" name="denNgay" onChange={this.setParams}></input> */}
                                    <DatePicker
                                        customInput={<CustomInputNgayCapDen />}
                                        selected={this.state.data.denNgay}
                                        onChange={this.handleChangeNgayCapDen.bind(this)}
                                        value={this.state.data.denNgay === 0 ? moment(this.state.data.denNgay).format("DD/MM/YYYY") : null}
                                        name="denNgay"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                </div>
                            </div>
                        </>
                    }

                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="loaiThongKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại thống kê</label>
                            <select className="form-control" id="loaiThongKe" name="loaiThongKe" style={{ fontSize: '14px' }}
                                onChange={this.setParams}>
                                {LOAI_THONG_KE.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>{item.ten}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                </div>

                <div className="row">
                    {this.state.data.loaiThongKe == 3 &&
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="maQuanHuyen" style={{ fontWeight: 'bold', fontSize: '14px' }}>Quận/Huyện</label>
                                <select className="form-control" id="maQuanHuyen" name="maQuanHuyen"
                                    onChange={this.setParams} style={{ fontSize: '14px' }}>
                                    <option value="">--Chọn Quận/Huyện--</option>
                                    {
                                        this.state.quanHuyen.map((e) => {
                                            return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    <div className="col-md">
                        <div className="text-right form-group">
                            <br></br>
                            <button onClick={this.loadThongKe} className='btn btn-primary btn-lg'>Tổng hợp</button>
                        </div>
                    </div>
                </div>

                <Grid item xs={12} className="grid-item" container justify="flex-end">
                    <Link title="Tải xuống" href="#" onClick={() => {
                        this.downloadFile('docx');
                    }}>
                        <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        this.downloadFile('pdf');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        this.downloadFile('xlsx');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                        </SvgIcon>
                    </Link>
                </Grid>
            </div>
        )
    }
}