import React from 'react';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';


export default class SelectPhuongXa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phuongXa: [],
            maQuanHuyen: '',
            errors: {}
        }
    }
    componentDidUpdate() {
        if (this.props.maQuanHuyen !== undefined && this.props.maQuanHuyen !== this.state.maQuanHuyen) {
            this.state.maQuanHuyen = this.props.maQuanHuyen;
            this.setState(this.state)
            this.danhSachPhuongXa();
        }
        if (this.props.errors !== undefined && this.props.errors !== this.state.errors) {
            this.state.errors = this.props.errors;
            this.setState(this.state)
        }
    }

    danhSachPhuongXa = () => {
        const queryParam = {
            "maTinhThanh": '92',
            "maQuanHuyen": this.state.maQuanHuyen
        }
        // AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maQuanHuyen}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }

    render() {
        return (
            <div className="form-group">
                {(this.props.displayLabel === undefined || this.props.displayLabel) &&
                    <label htmlFor="maPhuongXaThiTran" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phường/Xã/TT <span style={{ color: 'red' }}>(*)</span></label>
                }
                <select className="form-control" id="maPhuongXaThiTran" name='maPhuongXaThiTran'
                    disabled={this.props.disabled ? true : false}
                    value={this.props.phuongXaValue}
                    onChange={(event) => this.props.setParams(event, "viTriXayDung")}>
                    <option value="">--Chọn phường/xã/thị trấn--</option>
                    {
                        this.state.phuongXa.map((e, ind) => {
                            return <option key={e.maPhuongXa} value={e.maPhuongXa}>{e.tenPhuongXa}</option>
                        })
                    }
                </select>
                <div style={Boolean(this.state.errors?.phuongXaThiTran) ? { display: 'block' } : {}} className="invalid-feedback" >
                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.phuongXaThiTran}</span>
                </div>
            </div>
        )
    }
}