
import AXIOS_INSTANCE from "..";
import { RequestBieuDoThongKeDTO } from "../../App/Components/BieuDoThongKe/BieuDoRenderComponent";
import { BaseService } from "../baseService";

export class ThongKeService extends BaseService{

    bieuDoThongke = (requestBody : RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post('lich-su-giay-phep/bieu-do-thong-ke',requestBody);
    }

}
