import axios from "axios";
import { resetAuthenticatedSession } from "../actions/session";
import { AUTHORIZE_REQUEST } from "../App/Components/Login/sso/config";
import StringUtil from "../utils/stringUtils";
const HOST = {
  DEV: "http://localhost:5051/cpxd-apis",
  FIS: "http://118.69.123.51:5051/cpxd-apis",
  KIEM_THU: "http://14.238.93.157:8081/cpxd-apis",
  LIVE: "https://xaydung.congchuc.cantho.gov.vn/cpxd-apis"
}
const AXIOS_INSTANCE = axios.create({
  baseURL: HOST.LIVE,
  timeout: 600000,
  withCredentials: true,
  headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, csrf-token',
      'Access-Control-Expose-Headers': 'csrf-token',
      'withCredentials': true
  }
});

AXIOS_INSTANCE.interceptors.request.use((request) => {
  if (!StringUtil.isNullOrEmty(localStorage.getItem("csrf-token"))) {
      let headers = {
          ...request.headers,
          'csrf-token': localStorage.getItem("csrf-token"),
      };
      request.headers = { ...headers };
  }

  return request;
}, error => {
})

AXIOS_INSTANCE.interceptors.response.use((response) => {
  if (!StringUtil.isNullOrEmty(response.headers['csrf-token'])) {
      localStorage.removeItem("csrf-token");
      localStorage.setItem('csrf-token', response.headers['csrf-token']);
  }
  return response;
}, error => {
  if (error.response && error.response.status == 401 || error.response.status == 403 || error.response.status == 406) {
      if (localStorage.getItem("PRIVATE_LOGIN") && localStorage.getItem("PRIVATE_LOGIN") === "1") {
          window.location.href = '/login';
      } else {
          resetAuthenticatedSession();
          window.location.href = AUTHORIZE_REQUEST;
      }

  } else {
      //todo
  }
});
export default AXIOS_INSTANCE;
