import React from 'react';
import PoolFile from '../CapPhepDungChung/PoolFile/index'
import toastr from 'toastr';
import UploadFileService from "../../../api/common/UploadFile";
import { createFileType, downLoadFile } from "../../../api/common/exportUtil";

export default class UploadFiles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            noiDungGiayPhep: {
                files: [],
                fileTrash: []
            },
            uploadFileItem: ''
        }
        this.uploadFileService = new UploadFileService();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined
            && this.props.noiDungGiayPhep !== null
            && this.props.noiDungGiayPhep.files !== this.state.noiDungGiayPhep.files
        ) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined
            && this.props.noiDungGiayPhep !== null
            && this.props.noiDungGiayPhep.fileTrash !== this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
    }


    changeUpFile = (event) => {
        const formData = new FormData();
        let fileTemps = Array.from(event.target.files);
        fileTemps.forEach(item => {
            formData.append(
                item.name,
                item
            );
        });
        this.uploadFileService.upload(formData).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    if (this.state.noiDungGiayPhep.files) {
                        let files = res.data.data;
                        files.forEach(item => {
                            this.state.noiDungGiayPhep.files.push(item);
                        });
                    } else {
                        this.state.noiDungGiayPhep['files'] = res.data.data;
                    }
                    this.setState({
                        ...this.state,
                        uploadFileItem: ''
                    })
                    this.props.onUpdateDataState("files", this.state.noiDungGiayPhep.files);
                    this.functionThatResetsTheFileInput();
                } else {
                    toastr.warning(res.data.error_message);
                }
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch(error => {
            toastr.warning('Lỗi kết nối dịch vụ');
        });
    }

    deleteFile = (item, index) => {
        if (this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files.forEach(file => {
                if (item.fileParentId && item.fileParentId === file.fileParentId) {
                    if (this.state.noiDungGiayPhep.fileTrash) {
                        this.state.noiDungGiayPhep.fileTrash.push(file);
                    } else {
                        this.state.noiDungGiayPhep['fileTrash'] = [file];
                    }

                    this.props.onUpdateDataState("fileTrash", this.state.noiDungGiayPhep.fileTrash);
                }
            });
            if (index > -1) {
                this.state.noiDungGiayPhep.files.splice(index, 1);
            }
            this.setState(this.state)
            this.props.onUpdateDataState("files", this.state.noiDungGiayPhep.files);
        }
    }

    downloadFile = (file) => {
        this.uploadFileService.downloadFile(file.fileEntryId).then(res => {
            if (res != null && res.data != null) {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch(error => {
            toastr.warning('Lỗi kết nối dịch vụ');
        });
    }

    functionThatResetsTheFileInput() {
        let randomString = Math.random().toString(36);

        this.setState({
            theInputKey: randomString
        });
    }

    render() {
        const { theInputKey } = this.state;
        const acceptFiles = "image/*, .doc, .docx, .xls, .xlsx, .pdf";
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <label className="frm-lbl" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đính kèm</label>
                    </div>
                    <div className="col-9" >
                        <PoolFile files={this.state.noiDungGiayPhep.files} onDelete={this.deleteFile} downloadFile={this.downloadFile} />
                        <div className="form-group col-8 text-center"
                            style={{ borderRadius: '5px', height: '100px', marginTop: '20px', border: 'dashed 2px #A9A9A9' }}>
                            <p style={{ marginTop: '15px', color: '#A9A9A9' }}>Kéo và thả tập tin ({acceptFiles}) vào đây hoặc</p>
                            <p style={{ marginLeft: '70px', marginTop: '10px' }}>
                                <input type="file" id="myfile" name="myfile" onChange={this.changeUpFile} multiple key={theInputKey}
                                    accept={acceptFiles} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}