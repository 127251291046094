import React from 'react';
import { Modal } from 'react-bootstrap';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';
import closeIcon from "../../../../Assets/icons/u1233.png";
import editIcon from '../../../../Assets/icons/u624.png';
import deleteIcon from '../../../../Assets/icons/u626.png';
import { KIEM_TRA_NGAN_CHAN } from '../../../Constants/constants';
import ModalXacNhanXoa from '../Modals/ModalXacNhanXoa';
import PaginationCPXD from '../PaginationGPXD';
import ModalCreateKiemTraNganChan from './ModalCreateKiemTraNganChan';
import moment from 'moment';
import { Grid, SvgIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import KiemTraNganChanService from "../../../../api/service/KiemTraNganChanService";
import { createFileType, downLoadFile } from '../../../../api/common/exportUtil';
import PageKiemTraNganChan from './PageKiemTraNganChan';

export default class ModalKiemTraNganChan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInfo: {
                tenChuSuDung: '',
                diaChiChiTiet: '',
                noiDungNganChan: '',
                soTo: '',
                soThua: '',
                soGiayCN: '',
                soVaoSo: '',
                ngayNganChan: '',
                congVanNC: '',
                maTinhThanh: '92',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ghiChu: '',
                exportFileName:'',
                exportFileType:'',
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 10
            },
            list: [],
            total: 0,
            pageIndex: 0,
            pageLength: 0,
            pageNumber: 0,
            pageSize: 10,
            selectedOption: '',
            total: 0,
            show: false,
            openModel: '',
            quanHuyen: [],
            phuongXa: [],
            editIndex: -1,
            deleteIdDonViThamDinh: 0
        }
    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({ ...this.state });
    }
    clearDataModal = () => {
        this.setState({ ...this.state, editIndex: -1 });
    }

    editDonViThamDinh(id) {
        console.log("this.state.editIndex", this.state.editIndex);
        this.state.editIndex = id;
        this.setState({ ...this.state });
        this.handleModalToggle('them-hoac-cap-nhat')

    }

    handleIdDonViThamDinh(value) {
        this.state.deleteIdDonViThamDinh = value;
        this.setState({ ...this.state });
        this.handleModalToggle('xac-nhan-xoa')
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.state.list.length === 0) {
                AXIOS_INSTANCE.post(KIEM_TRA_NGAN_CHAN.urlSearch, {
                    hoatDong: 1,
                    keywords: "",
                    pageNumber: this.state.pageNumber - 1,
                    pageSize: 10
                }).then(res => {
                    if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                        const datas = res.data.data;
                        const total = datas.total;
                        const pageIndex = datas.pageIndex + 1;
                        const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                        this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
                    } else {
                        this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
                    }
                }).catch(error => {
                    this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
                });
            }
        }, 200);
        this.danhSachQuanHuyenInfo();
        this.onPagi();
    }

    /*Tìm kiếm*/
    setParams = (e) => {
        let target = e.target;
        let type = target.type;
        let name = target.name;
        let value = target.value;
        let text = '';
        if (type === "select-one") {
            text = target.selectedOptions[0].text;
        }
        if (name === "maQuanHuyen") {
            this.state.dataInfo[name] = value;
            this.state.dataInfo.tenQuanHuyen = text;
            this.danhSachPhuongXa(value);
        }
        if (name === "maPhuongXa") {
            this.state.dataInfo[name] = value;
            this.state.dataInfo.tenPhuongXa = text;
        }
        this.state.dataInfo[name] = value;
        this.setState({ ...this.state });
    }
    clickSearch = (event) => {
        this.state.dataInfo['pageNumber'] = 1;
        this.onSearch(event);
    }
    fetchSearch = keySearch => {
        AXIOS_INSTANCE.post(KIEM_TRA_NGAN_CHAN.urlSearch, keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    onPagi = () => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearch(
            {
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
    }

    onSearch = dataInfo => {
        this.setState({ dataInfo: dataInfo, pageNumber: 0, pageSize: 10 }, () => {
            this.fetchSearch({
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        });
    }
    onSelectPage = page => {
        this.setState({ pageNumber: page - 1 }, this.onPagi);
    }
    onChangePageSize = pageSize => {
        this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
    }

    handleCloseModal = (key) => {
        this.props.onModalToggle(key);
    }

    danhSachQuanHuyenInfo = maTinhThanh => {
        const queryParam = {
            "maTinhThanh": this.state.dataInfo.maTinhThanh
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }
    danhSachPhuongXa = maQuanHuyen => {
        const queryParam = {
            "maTinhThanh": this.state.dataInfo.maTinhThanh,
            "maQuanHuyen": maQuanHuyen
        }
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }
    //xuat file bao cao
    downloadFile = async (extension) => {
        const{dataInfo} = this.state;
        let request = {...dataInfo,
            hoatDong: 1,
            exportFileName: 'DS_kiem_tra_ngan_chan',
            exportType: extension
        };
        await new KiemTraNganChanService().downloadFileDanhSachKiemTraNganChan(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'DS_kiem_tra_ngan_chan');
        }).finally(() => {
        });
    }
    render() {
        return (
            <>
                <React.Fragment>
                    <Modal show={this.props.show} onHide={() => this.handleCloseModal('')} on size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                        <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                            <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>{KIEM_TRA_NGAN_CHAN.ten.toUpperCase()}</h5>
                            <button type="button" className="close" aria-label="Close" onClick={() => this.handleCloseModal('')}>
                                <span aria-hidden="true"><img src={closeIcon}></img></span>
                            </button>
                        </div>
                        <div className="modal-body">
                           <PageKiemTraNganChan
                            onModalToggle = {this.handleModalToggle}
                           ></PageKiemTraNganChan>
                        </div>
                    </Modal>
                </React.Fragment>
            </>
        )
    }
}