import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../../utils/stringUtils';

export default class ModalHangMuc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hangMuc: {
                id: 0,
                chiTiet: '',
                chieuCao: '',
                congThuc: '',
                dienTich: '',
            },
            show: false,
            errors: {
                chiTiet: '',
                chieuCao: '',
                congThuc: '',
                dienTich: '',
            }
        }
    }

    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle('themMoi');
    }
    handleChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.state.hangMuc[name] = value;
        this.state.errors = {
            chiTiet: '',
            chieuCao: '',
            congThuc: '',
            dienTich: '',
        }
        this.setState(this.state);
    }
    handleSubmit = () => {
        if (this.validation()) {
            this.props.onUpdateData(this.state.hangMuc);
            this.handleCloseModal();
            toastr.success('Thêm mới hạng mục thành công');
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.hangMuc.chiTiet)) {
            this.state.errors.chiTiet = 'Vui lòng nhập chi tiết';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.hangMuc.chieuCao)) {
            this.state.errors.chieuCao = 'Vui lòng nhập chiều cao';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.hangMuc.congThuc)) {
            this.state.errors.congThuc = 'Vui lòng nhập công thức';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.hangMuc.dienTich)) {
            this.state.errors.dienTich = 'Vui lòng nhập diện tích';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state.hangMuc = {
            id: 0,
            chiTiet: '',
            chieuCao: '',
            congThuc: '',
            dienTich: '',
        }
        this.state.errors = {
            chiTiet: '',
            chieuCao: '',
            congThuc: '',
            dienTich: '',
        }
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI HẠNG MỤC CÔNG TRÌNH</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="chiTiet" style={{ fontWeight: 'bold', fontSize: ' ' }}>Chi tiết <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.hangMuc.chiTiet} className="form-control" id="chiTiet" name="chiTiet" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.chiTiet) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.chiTiet}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="chieuCao" style={{ fontWeight: 'bold', fontSize: ' ' }}>Chiều cao (m) <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.hangMuc.chieuCao} className="form-control" id="chieuCao" name="chieuCao" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.chieuCao) ? { display: 'block' } : {}} className="invalid-feedback">
                                        {this.state.errors?.chieuCao}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="congThuc" style={{ fontWeight: 'bold', fontSize: ' ' }}>Công thức <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.hangMuc.congThuc} className="form-control" id="congThuc" name="congThuc" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.congThuc) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.congThuc}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="dienTich" style={{ fontWeight: 'bold', fontSize: ' ' }}>Diện tích (m&sup2;) <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.hangMuc.dienTich} className="form-control" id="dienTich" name="dienTich" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.dienTich) ? { display: 'block' } : {}} className="invalid-feedback">
                                        {this.state.errors?.dienTich}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}