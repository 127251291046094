import AXIOS_INSTANCE from "..";
import { KIEM_TRA_NGAN_CHAN } from "../../App/Constants/constants";

export default class KiemTraNganChanService {
    //Add By IN
    getChiTietById = (requestBody) => {
        return AXIOS_INSTANCE.get(`${KIEM_TRA_NGAN_CHAN.urlGetById + requestBody}`);
    }
    getByMaSoGiayPhep = (requestBody) => {
        return AXIOS_INSTANCE.post('giay-phep-xay-dung/get-by-ma-so-giay-phep', requestBody);
    }
    downloadFileDanhSachKiemTraNganChan = (requestBody) => {
        return AXIOS_INSTANCE.post(`${KIEM_TRA_NGAN_CHAN.urlDownLoadDS}`, requestBody, {
            responseType: 'blob',
        });
    }
    addOrUpdate = (requestBody) => {
        return AXIOS_INSTANCE.post(`${KIEM_TRA_NGAN_CHAN.urlAddorUpdate}`, requestBody);
    }
    checkDuplicateSoToAndSoThua = (requestBody) => {
        return AXIOS_INSTANCE.post(`${KIEM_TRA_NGAN_CHAN.urlKiemTraDuplicate}`, requestBody);
    }
}

