import moment from 'moment';
import React from 'react';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import InputCapCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputCapCongTrinh';
import InputLoaiCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputLoaiCongTrinh';
import InputPhanLoaiXayDung from '../CapPhepDungChung/DanhMucDungRieng/InputPhanLoaiXayDung';
import SelectPhuongXa from '../CapPhepDungChung/InputGroup/SelectPhuongXa';
import SelectQuanHuyen from '../CapPhepDungChung/InputGroup/SelectQuanHuyen';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import ModalDonViThamDinh from '../CapPhepDungChung/NDGP/ModalDonViThamDinh';
import TableLoDatXD from '../CapPhepDungChung/NDGP/TableLoDatXD';
import DropZoneCustom from '../../commons/components/CustomDropzone/DropZoneCustom';
import AXIOS_INSTANCE from '../../../api';
import { ACCEPT_FILES } from '../../Constants/constants';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import UploadFileService from "../../../api/common/UploadFile";
import { Box, Grid } from '@material-ui/core';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

export default class FormCPXDCTTuongDai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            openModel: false,
            noiDungGiayPhep: {
                thongTinCongTrinh: {},
                thongTinXayDung: {},
                viTriXayDung: {},
                loDatXayDungs: [],
                loDatXayDungDropDTOs: [],
                donViChuNhiemThietKe: {
                    ngayCapChungChiNangLuc: 0,
                    ngayCapChungChiHanhNghe: 0,
                    ngayCapGiayPhepHanhNghe: 0
                },
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
            errors: {}
        }
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinh != this.state.noiDungGiayPhep.thongTinCongTrinh) {
            this.state.noiDungGiayPhep.thongTinCongTrinh = this.props.noiDungGiayPhep.thongTinCongTrinh;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung != this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriXayDung != this.state.noiDungGiayPhep.viTriXayDung) {
            this.state.noiDungGiayPhep.viTriXayDung = this.props.noiDungGiayPhep.viTriXayDung;
            this.setState(this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungs != this.state.noiDungGiayPhep.loDatXayDungs) {
            this.state.noiDungGiayPhep.loDatXayDungs = this.props.noiDungGiayPhep.loDatXayDungs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.donViChuNhiemThietKe != this.state.noiDungGiayPhep.donViChuNhiemThietKe) {
            this.state.noiDungGiayPhep.donViChuNhiemThietKe = this.props.noiDungGiayPhep.donViChuNhiemThietKe;
            this.setState(this.state.noiDungGiayPhep.donViChuNhiemThietKe);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files != this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash != this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep != this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }
    }
    setParams = (event, key) => {
        console.log("name : ", event.target.name);
        console.log("value : ", event.target.value);
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;

        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key === 'thongTinCongTrinh') {
            this.state.noiDungGiayPhep.thongTinCongTrinh[name] = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (key === 'thongTinXayDung') {
            if (this.state.noiDungGiayPhep.thongTinXayDung === null) {
                this.state.noiDungGiayPhep.thongTinXayDung = {};
            }
            this.state.noiDungGiayPhep.thongTinXayDung[name] = value;
            this.props.onUpdateDataState('thongTinXayDung', this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (key === 'viTriXayDung') {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (key === 'donViChuNhiemThietKe') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.donViChuNhiemThietKe[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.donViChuNhiemThietKe[name] = value;
            }
            this.props.onUpdateDataState('donViChuNhiemThietKe', this.state.noiDungGiayPhep.donViChuNhiemThietKe);
        }
        if (key === 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
        if (key === "HuyenThiThanhPho") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.huyenThiThanhPho = text;
            this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (name === "maPhuongXaThiTran") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.phuongXaThiTran = text;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
    }
    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key === 'loDatXayDungDropDTOs') {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
        if (key === "donViThamDinh") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
    }
    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }
    handleChangeNhapDauKy(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }
    handleChangeCCNangLuc(date) {
        let name = "ngayCapChungChiNangLuc";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.donViChuNhiemThietKe[name] = longFormat;
        this.props.onUpdateDataState('donViChuNhiemThietKe', this.state.noiDungGiayPhep.donViChuNhiemThietKe);
    }
    handleChangeCCHanhNghe(date) {
        let name = "ngayCapChungChiHanhNghe";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.donViChuNhiemThietKe[name] = longFormat;
        this.props.onUpdateDataState('donViChuNhiemThietKe', this.state.noiDungGiayPhep.donViChuNhiemThietKe);
    }
    handleChangeGPHanhNghe(date) {
        let name = "ngayCapGiayPhepHanhNghe";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.donViChuNhiemThietKe[name] = longFormat;
        this.props.onUpdateDataState('donViChuNhiemThietKe', this.state.noiDungGiayPhep.donViChuNhiemThietKe);
    }
    render() {
        const CustomInputNhapDauKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputCCNangLuc = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "425px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputCCHanhNghe = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "425px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputGPHanhNghe = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "425px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>

                {this.props.isNhapDauKy &&
                    <div class="col-md-12">
                        <br />
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="maSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    <input type="text" className="form-control" id="maSoGiayPhep" name="maSoGiayPhep"
                                        value={this.state.noiDungGiayPhep?.giayPhep?.maSoGiayPhep} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} />
                                    <div style={Boolean(this.state.errors?.maSoGiayPhep) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.maSoGiayPhep}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap"
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap ? moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                    <DatePicker
                                        customInput={<CustomInputNhapDauKy />}
                                        selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                        onChange={this.handleChangeNhapDauKy.bind(this)}
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                        name="ngayCap"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                    <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="col-md-12" style={{ fontSize: '14px' }}>
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="tenCongTrinh" name="tenCongTrinh" style={{ fontSize: '14px' }}
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep?.thongTinCongTrinh?.tenCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")} />
                                <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tinhThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tỉnh/Thành phố <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="tinhThanhPho" style={{ fontSize: '14px' }} name="tinhThanhPho"
                                    value={this.state.noiDungGiayPhep.viTriXayDung.tinhThanhPho} disabled>
                                    <option selected>Thành phố Cần Thơ</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <SelectQuanHuyen
                                quanHuyenValue={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectQuanHuyen>
                        </div>
                        <div class="col-md-3">
                            <SelectPhuongXa
                                maQuanHuyen={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                phuongXaValue={this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectPhuongXa>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Cấp công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="capCongTrinh" name="capCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.capCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.capCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.capCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                {/* <select className="form-control" id="duong" name="duong"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}>
                                    <option value="">--Chọn đường--</option>
                                    <option value="Đường 1">Đường 1</option>
                                    <option value="Đường 2">Đường 2</option>
                                    <option value="Đường 3">Đường 3</option>
                                </select> */}
                                <input type="text" className="form-control" id="duong" name="duong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div className="form-group">
                                <label htmlFor="soNhaDuong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số nhà, đường</label>
                                <input type="text" className="form-control" id="soNhaDuong" name="soNhaDuong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.soNhaDuong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="loaiCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Loại công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="loaiCongTrinh" name="loaiCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.loaiCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn Loại công trình--</option>
                                    <InputLoaiCongTrinh></InputLoaiCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.loaiCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.loaiCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diaChiCu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                <input type="text" className="form-control" id="diaChiCu" name="diaChiCu"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.diaChiCu} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="phanLoaiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Phân loại xây dựng <span style={{ color: 'red' }}>(*)</span></label>
                                        <select className="form-control" id="phanLoaiXayDung" name="phanLoaiXayDung"
                                            disabled={this.props.disabled ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.phanLoaiXayDung} style={{ fontSize: '14px' }}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                            <option value="">--Chọn loại xây dựng--</option>
                                            <InputPhanLoaiXayDung></InputPhanLoaiXayDung>
                                        </select>
                                        <div style={Boolean(this.state.errors?.phanLoaiXayDung) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.phanLoaiXayDung}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViThamDinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị thẩm tra</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViThamDinh" name="donViThamDinh"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tham-dinh')}></i></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" >
                                    <div className="form-group">
                                        <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                                        <textarea type="text" className="form-control" id="ghiChu" name="ghiChu"
                                            readOnly={this.props.readonly ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.ghiChu}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="soNha" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lô đất xây dựng</label>
                                <TableLoDatXD
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.setParamLoDats}
                                    disabled={this.props.disabled}
                                ></TableLoDatXD>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-*" style={{ marginLeft: '30px' }}>
                        <div className="form-group">
                            <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thông tin công trình</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích xây dựng</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="tongDienTichSan" name="tongDienTichSan"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.tongDienTichSan}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao toàn công trình</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="chieuCaoCongTrinh" name="chieuCaoCongTrinh"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoCongTrinh}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="cotNen" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cốt xây dựng</label>
                                <input type="text" className="form-control" id="cotNen" name="cotNen"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.cotNen}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duKienHoanThanh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Dự kiến thời gian hoàn thành</label>
                                <input type="text" className="form-control" id="duKienHoanThanh" name="duKienHoanThanh"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.duKienHoanThanh}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div className="form-group">
                        <label htmlFor="chieuCaoBienQuangCao" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung quảng cáo</label>
                        <input type="text" className="form-control" id="chieuCaoBienQuangCao" name="chieuCaoBienQuangCao"
                            readOnly={this.props.readonly ? true : false}
                            value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoBienQuangCao}
                            onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-*" style={{ marginLeft: '30px' }}>
                        <div className="form-group">
                            <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thông tin đơn vị hoặc người chủ nhiệm thiết kế</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="tenDonVi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên đơn vị</label>
                                <input type="text" className="form-control" id="tenDonVi" name="tenDonVi"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.tenDonVi}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="soChungChiNangLuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số chứng chỉ năng lực HĐXD</label>
                                <input type="text" className="form-control" id="soChungChiNangLuc" name="soChungChiNangLuc"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.soChungChiNangLuc}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="ngayCapChungChiNangLuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                {/* <input type="date" className="form-control" id="ngayCapChungChiNangLuc" name="ngayCapChungChiNangLuc"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapChungChiNangLuc ? moment(new Date(this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapChungChiNangLuc)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    customInput={<CustomInputCCNangLuc />}
                                    selected={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiNangLuc}
                                    onChange={this.handleChangeCCNangLuc.bind(this)}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiNangLuc === 0 ? moment(this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiNangLuc).format("DD/MM/YYYY") : null}
                                    name="ngayCapChungChiNangLuc"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                                <div style={Boolean(this.state.errors?.ngayCapChungChiNangLuc) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCapChungChiNangLuc}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="tenChuNhiemThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên chủ nhiệm thiết kế</label>
                                <input type="text" className="form-control" id="tenChuNhiemThietKe" name="tenChuNhiemThietKe"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.tenChuNhiemThietKe}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="soChungChiHanhNghe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số chứng chỉ hành nghề</label>
                                <input type="text" className="form-control" id="soChungChiHanhNghe" name="soChungChiHanhNghe"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.soChungChiHanhNghe}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="ngayCapChungChiHanhNghe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                {/* <input type="date" className="form-control" id="ngayCapChungChiHanhNghe" name="ngayCapChungChiHanhNghe"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapChungChiHanhNghe ? moment(new Date(this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapChungChiHanhNghe)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    customInput={<CustomInputCCHanhNghe />}
                                    selected={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiHanhNghe}
                                    onChange={this.handleChangeCCHanhNghe.bind(this)}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiHanhNghe === 0 ? moment(this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapChungChiHanhNghe).format("DD/MM/YYYY") : null}
                                    name="ngayCapChungChiHanhNghe"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                                <div style={Boolean(this.state.errors?.ngayCapChungChiHanhNghe) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCapChungChiHanhNghe}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="soDienThoai" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số điện thoại</label>
                                <input type="text" className="form-control" id="soDienThoai" name="soDienThoai"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.soDienThoai}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="soGiayPhepHanhNghe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép hành nghề</label>
                                <input type="text" className="form-control" id="soGiayPhepHanhNghe" name="soGiayPhepHanhNghe"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.soGiayPhepHanhNghe}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="form-group">
                                <label htmlFor="ngayCapGiayPhepHanhNghe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                {/* <input type="date" className="form-control" id="ngayCapGiayPhepHanhNghe" name="ngayCapGiayPhepHanhNghe"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapGiayPhepHanhNghe ? moment(new Date(this.state.noiDungGiayPhep.donViChuNhiemThietKe?.ngayCapGiayPhepHanhNghe)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    customInput={<CustomInputGPHanhNghe />}
                                    selected={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapGiayPhepHanhNghe}
                                    onChange={this.handleChangeGPHanhNghe.bind(this)}
                                    value={this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapGiayPhepHanhNghe === 0 ? moment(this.state.noiDungGiayPhep.donViChuNhiemThietKe.ngayCapGiayPhepHanhNghe).format("DD/MM/YYYY") : null}
                                    name="ngayCapGiayPhepHanhNghe"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                                <div style={Boolean(this.state.errors?.ngayCapGiayPhepHanhNghe) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCapGiayPhepHanhNghe}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div className="form-group">
                        <label htmlFor="diaChiChuNhiemThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ</label>
                        <input type="text" className="form-control" id="diaChiChuNhiemThietKe" name="diaChiChuNhiemThietKe"
                            readOnly={this.props.readonly ? true : false}
                            value={this.state.noiDungGiayPhep.donViChuNhiemThietKe?.diaChiChuNhiemThietKe}
                            onChange={(event) => this.setParams(event, "donViChuNhiemThietKe")} style={{ fontSize: '14px' }} />
                    </div>
                </div>
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep.files}
                    fileTrash={this.state.noiDungGiayPhep.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
                <br></br>
                <br></br>
                {this.state.openModel === 'kiem-tra-ngan-chan'
                    &&
                    <ModalKiemTraNganChan
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel === 'kiem-tra-ngan-chan'}
                    ></ModalKiemTraNganChan>
                }
                <ButtonGroupGiayPhep
                    idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                    isNhapDauKy={this.props.isNhapDauKy}
                    handleModalToggle={this.handleModalToggle}
                    handleAddOrUpdate={this.props.addOrUpdate}
                    maHoSo={this.state.maHoSo}
                    maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                ></ButtonGroupGiayPhep>
                <ModalDonViThamDinh
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tham-dinh'}
                ></ModalDonViThamDinh>
                <ModalClearAll
                    onClearData={this.handleClearAll}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'clear-all'}
                ></ModalClearAll>
                <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                <br />
            </div>
        )
    }
}