import React from 'react';
import { Pagination } from '@material-ui/lab';

export default class ThongTinThamDinh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thongTinThamDinhs: [],
            pageNumber: 1,
            pageSize: 5,
            dataPagi: [],
            openModel: false,
            recordsPerPage: 0,
        }
    }
    componentDidUpdate() {
        if (this.props.thongTinThamDinhs !== undefined && this.props.thongTinThamDinhs !== this.state.thongTinThamDinhs) {
            this.state.thongTinThamDinhs = this.props.thongTinThamDinhs;
            this.setState(this.state);
            this.handleSearch();
        }
    }
    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.thongTinThamDinhs?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }
    renderFileName = (files) => {
        let fileName = '';
        if (files && files.length > 0) {
            files.forEach((item, index) => {
                if (index == 0) {
                    fileName += item.name
                } else {
                    fileName += ', ' + item.name
                }
            });
        }
        return fileName;
    }
    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br></br>
                <div className="row">
                    <table class="table table-bordered">
                        <thead>
                            <tr className="table-secondary text-center">
                                <th>STT</th>
                                <th>Nội dung</th>
                                <th>Tập tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                        <td>{item.noiDung}</td>
                                        <td width='40%'>{this.renderFileName(item.files)}</td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={3}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.thongTinThamDinhs?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}