import React from 'react';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';

export default class SelectTinhTrang extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tinhTrang: [],
        }
    }
    componentDidMount() {
        this.danhSach();
    }

    danhSach = () => {
        AXIOS_INSTANCE.get('tinh-trang').then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ tinhTrang: res.data.data || [] });
            } else {
                this.setState({ tinhTrang: [] });
            }
        }).catch(error => {
            this.setState({ tinhTrang: [] });
        });
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="tinhTrang" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tình trạng</label>
                <select className="form-control"
                    id="tinhTrang"
                    name="tinhTrang"
                    value={this.props.tinhTrangValue}
                    onChange={this.props.setParams}>
                    <option value="">--Chọn loại tình trạng--</option>
                    {
                        this.state.tinhTrang.map((e) => {
                            return <option value={e.maTrangThai}>{e.tenTrangThai}</option>
                        })
                    }
                </select>
            </div>
        )
    }
}