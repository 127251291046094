import React from 'react';
import AXIOS_INSTANCE from '../../../../api';
import moment from 'moment';
import "../../ComponentQuanTriHeThong/DateInputCustome.css";
import PaginationCustom from '../../CapPhepDungChung/PaginationGPXD';

export default class TableThongKeCPXD extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaiThongKe: 1,
            total: 0,
            pageIndex: 0,
            pageLength: 0,
            pageNumber: 0,
            pageSize: 5,
            dataInfo: {
                loaiGiayPhep: '',
                loaiBienDong: '',
                tenQuanHuyen: '',
                tenGiayPhep: '',
                loaiBienDong: '',
                pageSize: 5,
                pageNumber: 0,
                key: '',
            },
            data: [],
            resultGP: [],
            resultTT: [],
            resultDC: [],
            tongSoGP: [],
            rowTong: {
                tongCapMoi: 0,
                tongCapLai: 0,
                tongGiaHan: 0,
                tongDieuChinh: 0,
                tongThuHoi: 0,
                tongHuy: 0,
                tongDangHoatDong: 0,
                tongHetHan: 0
            }
        }
    }

    componentDidUpdate() {
        if (this.props.data !== undefined && this.props.data != this.state.data) {
            this.state.data = this.props.data;
            let rowTong = {
                tongCapMoi: 0,
                tongCapLai: 0,
                tongGiaHan: 0,
                tongDieuChinh: 0,
                tongThuHoi: 0,
                tongHuy: 0,
                tongDangHoatDong: 0,
                tongHetHan: 0
            }
            this.props.data.map(item => {
                rowTong.tongCapMoi += item.tongCapMoi;
                rowTong.tongCapLai += item.tongCapLai;
                rowTong.tongGiaHan += item.tongGiaHan;
                rowTong.tongDieuChinh += item.tongDieuChinh;
                rowTong.tongThuHoi += item.tongThuHoi;
                rowTong.tongHuy += item.tongHuy;
                rowTong.tongDangHoatDong += item.tongDangHoatDong;
                rowTong.tongHetHan += item.tongHetHan;
            })
            this.setState({ rowTong: rowTong });
        }
        if (this.props.loaiThongKe !== undefined && this.props.loaiThongKe != this.state.loaiThongKe) {
            this.state.loaiThongKe = this.props.loaiThongKe;
            this.setState(this.state);
        }
    }

    // handleLoadDataGP = (name, type) => {
    //     const queryParam = {
    //         "tenGiayPhep": name,
    //         "loaiBienDong": type,
    //     }
    //     AXIOS_INSTANCE.get(`/chi-tiet-thong-ke/get-by-loai-giay-phep?tenGiayPhep=${queryParam.tenGiayPhep}&loaiBienDong=${queryParam.loaiBienDong}`).then(res => {
    //         if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
    //             this.setState({ resultGP: res.data.data || [] });
    //         } else {
    //             this.setState({ resultGP: [] });
    //         }
    //     }).catch(error => {
    //         this.setState({ resultGP: [] });
    //     });
    // }

    // handleLoadDataTT = (name, type) => {
    //     const queryParam = {
    //         "tenGiayPhep": name,
    //         "loaiBienDong": type,
    //     }
    //     AXIOS_INSTANCE.get(`/chi-tiet-thong-ke/get-by-tinh-trang-giay-phep?tenGiayPhep=${queryParam.tenGiayPhep}&loaiBienDong=${queryParam.loaiBienDong}`).then(res => {
    //         if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
    //             this.setState({ resultTT: res.data.data || [] });
    //         } else {
    //             this.setState({ resultTT: [] });
    //         }
    //     }).catch(error => {
    //         this.setState({ resultTT: [] });
    //     });
    // }

    // handleLoadDataDC = (name, type) => {
    //     const queryParam = {
    //         "tenQuanHuyen": name,
    //         "loaiBienDong": type,
    //     }
    //     AXIOS_INSTANCE.get(`/chi-tiet-thong-ke/get-by-dia-chi-giay-phep?tenQuanHuyen=${queryParam.tenQuanHuyen}&loaiBienDong=${queryParam.loaiBienDong}`).then(res => {
    //         if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
    //             this.setState({ resultDC: res.data.data || [] });
    //         } else {
    //             this.setState({ resultDC: [] });
    //         }
    //     }).catch(error => {
    //         this.setState({ resultDC: [] });
    //     });
    // }

    // tongSoLoaiGP = (name, type) => {
    //     const queryParam = {
    //         "loaiGiayPhep": name,
    //         "loaiBienDong": type,
    //     }
    //     AXIOS_INSTANCE.get(`/chi-tiet-thong-ke/tong-so-loai-giay-phep?loaiGiayPhep=${queryParam.loaiGiayPhep}&loaiBienDong=${queryParam.loaiBienDong}`).then(res => {
    //         if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
    //             this.setState({ tongSoGP: res.data.data || [] });
    //         } else {
    //             this.setState({ tongSoGP: [] });
    //         }
    //     }).catch(error => {
    //         this.setState({ tongSoGP: [] });
    //     });
    // }

    //Pagination
    onSelectPage = page => {
        this.setState({ pageNumber: page - 1 }, this.onPagi);
    }

    onChangePageSize = pageSize => {
        this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
    }

    onPagi = () => {
        const dataInfo = this.state.dataInfo;
        if (dataInfo.key == 'GP') {
            this.fetchSearchGP({
                ...dataInfo,
                tenGiayPhep: this.state.dataInfo.tenGiayPhep,
                loaiBienDong: this.state.dataInfo.loaiBienDong,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        } else if (dataInfo.key == 'TT') {
            this.fetchSearchTT({
                ...dataInfo,
                tenGiayPhep: this.state.dataInfo.tenGiayPhep,
                loaiBienDong: this.state.dataInfo.loaiBienDong,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        } else if (dataInfo.key == 'DC') {
            this.fetchSearchDC({
                ...dataInfo,
                tenQuanHuyen: this.state.dataInfo.tenQuanHuyen,
                loaiBienDong: this.state.dataInfo.loaiBienDong,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        } else {
            this.fetchSearchTS({
                ...dataInfo,
                loaiGiayPhep: this.state.dataInfo.loaiGiayPhep,
                loaiBienDong: this.state.dataInfo.loaiBienDong,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        }

    }

    fetchSearchGP = (keySearch) => {
        AXIOS_INSTANCE.post('/chi-tiet-thong-ke/get-by-loai-giay-phep', keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ resultGP: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ resultGP: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ resultGP: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    fetchSearchTT = (keySearch) => {
        AXIOS_INSTANCE.post('/chi-tiet-thong-ke/get-by-tinh-trang-giay-phep', keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ resultTT: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ resultTT: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ resultTT: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    fetchSearchDC = (keySearch) => {
        AXIOS_INSTANCE.post('/chi-tiet-thong-ke/get-by-dia-chi-giay-phep', keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ resultDC: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ resultDC: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ resultDC: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    fetchSearchTS = (keySearch) => {
        AXIOS_INSTANCE.post('/chi-tiet-thong-ke/tong-so-loai-giay-phep', keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ tongSoGP: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ tongSoGP: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ tongSoGP: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    handleLoadDataGP = (name, type) => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearchGP({
            ...dataInfo,
            tenGiayPhep: name,
            loaiBienDong: type,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        });
        this.setState({
            dataInfo: {
                tenGiayPhep: name,
                loaiBienDong: type,
                key: 'GP'
            }
        })
    }

    handleLoadDataTT = (name, type) => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearchTT({
            ...dataInfo,
            tenGiayPhep: name,
            loaiBienDong: type,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        });
        this.setState({
            dataInfo: {
                tenGiayPhep: name,
                loaiBienDong: type,
                key: 'TT'
            }
        })
    }

    handleLoadDataDC = (name, type) => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearchDC({
            ...dataInfo,
            tenQuanHuyen: name,
            loaiBienDong: type,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        });
        this.setState({
            dataInfo: {
                tenQuanHuyen: name,
                loaiBienDong: type,
                key: 'DC'
            }
        })
    }

    tongSoLoaiGP = (name, type) => {
        // const queryParam = {
        //     "loaiGiayPhep": name,
        //     "loaiBienDong": type,
        // }
        const dataInfo = this.state.dataInfo;
        this.fetchSearchTS({
            ...dataInfo,
            loaiGiayPhep: name,
            loaiBienDong: type,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        });
        this.setState({
            dataInfo: {
                loaiGiayPhep: name,
                loaiBienDong: type,
                key: 'TS'
            }
        })
    }

    render() {
        return (
            <>
                {this.state.loaiThongKe == 1 &&
                    <div className="col-md-12" style={{ float: 'right' }} >
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                    <th>STT</th>
                                    <th>Loại giấy phép</th>
                                    <th>Cấp mới</th>
                                    <th>Cấp lại</th>
                                    <th>Gia hạn</th>
                                    <th>Điều chỉnh</th>
                                    <th>Thu hồi</th>
                                    <th>Hủy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data?.length > 0 ? this.state.data?.map((item, index) => {
                                    return (
                                        <tr style={{ fontSize: '14px' }}>
                                            <td className="cell-number text-center">{index + 1}</td>
                                            <td >{item.ten}</td>
                                            <td name="capMoi" className="text-center custom-text"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Cấp mới')}>
                                                <a href="#">{item.tongCapMoi}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Cấp lại')}>
                                                <a href="#">{item.tongCapLai}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Gia hạn')}>
                                                <a href="#">{item.tongGiaHan}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Điều chỉnh')}>
                                                <a href="#">{item.tongDieuChinh}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Thu hồi')}>
                                                <a href="#">{item.tongThuHoi}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalGP"
                                                onClick={() => this.handleLoadDataGP(item.ten, 'Hủy')}>
                                                <a href="#">{item.tongHuy}</a></td>
                                        </tr>
                                    )
                                }) :
                                    <tr>
                                        <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                    </tr>
                                }
                                {this.state.data?.length > 0 &&
                                    <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        <td></td>
                                        <td colSpan={1}>{"Tổng số"}</td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Cấp mới')}>
                                            <a href="#">{this.state.rowTong.tongCapMoi}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Cấp lại')}>
                                            <a href="#">{this.state.rowTong.tongCapLai}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Gia hạn')}>
                                            <a href="#">{this.state.rowTong.tongGiaHan}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Điều chỉnh')}>
                                            <a href="#">{this.state.rowTong.tongDieuChinh}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Thu hồi')}>
                                            <a href="#">{this.state.rowTong.tongThuHoi}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoLoai', 'Hủy')}>
                                            <a href="#">{this.state.rowTong.tongHuy}</a></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div >
                }

                {this.state.loaiThongKe == 2 &&
                    <div className="col-md-12" style={{ float: 'right' }} >
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                    <th>STT</th>
                                    <th>Loại giấy phép</th>
                                    <th>Còn hiệu lực</th>
                                    <th>Hết hạn</th>
                                    <th>Thu hồi</th>
                                    <th>Hủy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data?.length > 0 ? this.state.data?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="cell-number">{index + 1}</td>
                                            <td>{item.ten}</td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalTT"
                                                onClick={() => this.handleLoadDataTT(item.ten, 'Cấp mới')}>
                                                <a href="#">{item.tongDangHoatDong}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalTT"
                                                onClick={() => this.handleLoadDataTT(item.ten, 'Hết hạn')}>
                                                <a href="#">{item.tongHetHan}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalTT"
                                                onClick={() => this.handleLoadDataTT(item.ten, 'Thu hồi')}>
                                                <a href="#">{item.tongThuHoi}</a></td>
                                            <td className="text-center"
                                                data-toggle="modal" data-target="#modalTT"
                                                onClick={() => this.handleLoadDataTT(item.ten, 'Hủy')}>
                                                <a href="#">{item.tongHuy}</a></td>
                                        </tr>
                                    )
                                }) :
                                    <tr>
                                        <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                    </tr>
                                }
                                {this.state.data?.length > 0 &&
                                    <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        <td></td>
                                        <td colSpan={1}>{"Tổng số"}</td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoTinhTrang', 'Cấp mới')}>
                                            <a href="#">{this.state.rowTong.tongDangHoatDong}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoTinhTrang', 'Hết hạn')}>
                                            <a href="#">{this.state.rowTong.tongHetHan}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoTinhTrang', 'Thu hồi')}>
                                            <a href="#">{this.state.rowTong.tongThuHoi}</a></td>
                                        <td className="text-center"
                                            data-toggle="modal" data-target="#modalTongSoGP"
                                            onClick={() => this.tongSoLoaiGP('GPTheoTinhTrang', 'Hủy')}>
                                            <a href="#">{this.state.rowTong.tongHuy}</a></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div >
                }

                {this.state.loaiThongKe == 3 &&
                    <div className="col-md-12" style={{ float: 'right' }} >
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                    <th>STT</th>
                                    <th>Quận/huyện</th>
                                    <th>Cấp mới</th>
                                    <th>Cấp lại</th>
                                    <th>Gia hạn</th>
                                    <th>Điều chỉnh</th>
                                    <th>Thu hồi</th>
                                    <th>Hủy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data?.length > 0 ?

                                    this.state.data?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="cell-number">{index + 1}</td>
                                                <td>{item.ten}</td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Cấp mới')}>
                                                    <a href="#">{item.tongCapMoi}</a></td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Cấp lại')}>
                                                    <a href="#">{item.tongCapLai}</a></td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Gia hạn')}>
                                                    <a href="#">{item.tongGiaHan}</a></td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Điều chỉnh')}>
                                                    <a href="#">{item.tongDieuChinh}</a></td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Thu hồi')}>
                                                    <a href="#">{item.tongThuHoi}</a></td>
                                                <td className="text-center"
                                                    data-toggle="modal" data-target="#modalDC"
                                                    onClick={() => this.handleLoadDataDC(item.ten, 'Hủy')}>
                                                    <a href="#">{item.tongHuy}</a></td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                    </tr>
                                }
                                {this.state.data?.length > 0 &&
                                    <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        <td></td>
                                        <td colSpan={1}>{"Tổng số"}</td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongCapMoi}</a></td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongCapLai}</a></td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongGiaHan}</a></td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongDieuChinh}</a></td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongThuHoi}</a></td>
                                        <td className="text-center">
                                            <a href="#">{this.state.rowTong.tongHuy}</a></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div >
                }
                <div className="modal fade" id="modalGP">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Thông tin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="table-secondary text-center">
                                            <th>STT</th>
                                            <th>Loại giấy phép</th>
                                            <th>Số giấy phép</th>
                                            <th>Ngày cấp</th>
                                            <th>Chủ đầu tư</th>
                                            <th>Vị trí xây dựng</th>
                                            <th>Số lần điều chỉnh</th>
                                            <th>Số lần gia hạn</th>
                                            <th>Tình trạng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.resultGP?.length > 0 ? this.state.resultGP?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="cell-number">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                                    <td>{item.tenLoaiGiayPhep}</td>
                                                    <td>{item.maSoGiayPhep}</td>
                                                    <td>{item.ngayCap ? moment(new Date(item.ngayCap)).format("yyyy-MM-DD") : ""}</td>
                                                    <td>{item.tenChuDauTu}</td>
                                                    <td>{item.viTriXayDung}</td>
                                                    <td>{item.soLanDieuChinh}</td>
                                                    <td>{item.soLanGiaHan}</td>
                                                    <td>{item.tenTinhTrang}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <PaginationCustom
                                    pageSize={this.state.pageSize}
                                    pageLength={this.state.pageLength}
                                    pageIndex={this.state.pageIndex}
                                    total={this.state.total}
                                    pageNumber={this.state.pageNumber}
                                    onSelectPage={this.onSelectPage}
                                    onChangePageSize={this.onChangePageSize}
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalTT">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Thông tin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="table-secondary text-center">
                                            <th>STT</th>
                                            <th>Loại giấy phép</th>
                                            <th>Số giấy phép</th>
                                            <th>Ngày cấp</th>
                                            <th>Chủ đầu tư</th>
                                            <th>Vị trí xây dựng</th>
                                            <th>Số lần điều chỉnh</th>
                                            <th>Số lần gia hạn</th>
                                            <th>Tình trạng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.resultTT?.length > 0 ? this.state.resultTT?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="cell-number">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                                    <td>{item.tenLoaiGiayPhep}</td>
                                                    <td>{item.maSoGiayPhep}</td>
                                                    <td>{item.ngayCap ? moment(new Date(item.ngayCap)).format("yyyy-MM-DD") : ""}</td>
                                                    <td>{item.tenChuDauTu}</td>
                                                    <td>{item.viTriXayDung}</td>
                                                    <td>{item.soLanDieuChinh}</td>
                                                    <td>{item.soLanGiaHan}</td>
                                                    <td>{item.tenTinhTrang}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <PaginationCustom
                                    pageSize={this.state.pageSize}
                                    pageLength={this.state.pageLength}
                                    pageIndex={this.state.pageIndex}
                                    total={this.state.total}
                                    pageNumber={this.state.pageNumber}
                                    onSelectPage={this.onSelectPage}
                                    onChangePageSize={this.onChangePageSize}
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDC">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Thông tin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="table-secondary text-center">
                                            <th>STT</th>
                                            <th>Loại giấy phép</th>
                                            <th>Số giấy phép</th>
                                            <th>Ngày cấp</th>
                                            <th>Chủ đầu tư</th>
                                            <th>Vị trí xây dựng</th>
                                            <th>Số lần điều chỉnh</th>
                                            <th>Số lần gia hạn</th>
                                            <th>Tình trạng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.resultDC?.length > 0 ? this.state.resultDC?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="cell-number">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                                    <td>{item.tenLoaiGiayPhep}</td>
                                                    <td>{item.maSoGiayPhep}</td>
                                                    <td>{item.ngayCap ? moment(new Date(item.ngayCap)).format("yyyy-MM-DD") : ""}</td>
                                                    <td>{item.tenChuDauTu}</td>
                                                    <td>{item.viTriXayDung}</td>
                                                    <td>{item.soLanDieuChinh}</td>
                                                    <td>{item.soLanGiaHan}</td>
                                                    <td>{item.tenTinhTrang}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalTongSoGP">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Thông tin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="table-secondary text-center">
                                            <th>STT</th>
                                            <th>Loại giấy phép</th>
                                            <th>Số giấy phép</th>
                                            <th>Ngày cấp</th>
                                            <th>Chủ đầu tư</th>
                                            <th>Vị trí xây dựng</th>
                                            <th>Số lần điều chỉnh</th>
                                            <th>Số lần gia hạn</th>
                                            <th>Tình trạng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.tongSoGP?.length > 0 ? this.state.tongSoGP?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="cell-number">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                                    <td>{item.tenLoaiGiayPhep}</td>
                                                    <td>{item.maSoGiayPhep}</td>
                                                    <td>{item.ngayCap ? moment(new Date(item.ngayCap)).format("yyyy-MM-DD") : ""}</td>
                                                    <td>{item.tenChuDauTu}</td>
                                                    <td>{item.viTriXayDung}</td>
                                                    <td>{item.soLanDieuChinh}</td>
                                                    <td>{item.soLanGiaHan}</td>
                                                    <td>{item.tenTinhTrang}</td>
                                                </tr>
                                            )
                                        }) :
                                            <tr>
                                                <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <PaginationCustom
                                    pageSize={this.state.pageSize}
                                    pageLength={this.state.pageLength}
                                    pageIndex={this.state.pageIndex}
                                    total={this.state.total}
                                    pageNumber={this.state.pageNumber}
                                    onSelectPage={this.onSelectPage}
                                    onChangePageSize={this.onChangePageSize}
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

            </>


        )
    }
}