import React from 'react';
import closeIcon from "../../../Assets/icons/u1233.png";
import deleteIcon from '../../../Assets/icons/u626.png';
import editIcon from '../../../Assets/icons/u624.png';
import { Pagination } from '@material-ui/lab';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ModalDSHM from './ModalDSHM';
import ModalXacNhanXoaCommon from '../CapPhepDungChung/Modals/ModalXacNhanXoaCommon';

export default class TableDSHM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            openModalXacNhan: false,
            deleteItemId: '',
            recordsPerPage: 0,
            noiDungGiayPhep: {
                hangMucs: [],
                //drop
                hangMucDrops: [],
            },
            hangMuc: {
                noiDungHangMuc: {
                    id: 0,
                    tenHangMuc: '',
                    mauSac: '',
                    congTrinhChinh: false,
                    soTang: '',
                    ketCau: '',
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoCongTrinh: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    noiDungKhac: '',
                },
                chiTietHangMucs: [],
                chiTietHangMucDrops: [],
            },
            indexEdit: -1
        }
    }


    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.hangMucs != this.state.data) {
            this.state.data = this.props.noiDungGiayPhep.hangMucs;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.hangMucDrops != this.state.dataDrop) {
            this.state.dataDrop = this.props.noiDungGiayPhep.hangMucDrops;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }

    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }

    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }

    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }

    handleIdDropItem = (e, value) => {
        this.setState({
            deleteItemId: value
        })
        this.handleModalToggle('xacNhan')
    }
    handleAddItem = (value) => {
        let { data } = this.state;
        if (!data) {
            data = []
        }
        const newData = [{ ...value }, ...data];
        this.props.onUpdateDataState('hangMucs', newData);
        this.setState({
            data: [...newData],
            hangMuc: {
                noiDungHangMuc: {
                    id: 0,
                    tenHangMuc: '',
                    mauSac: '',
                    congTrinhChinh: false,
                    soTang: '',
                    ketCau: '',
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoCongTrinh: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    noiDungKhac: '',
                },
                chiTietHangMucs: [],
                chiTietHangMucDrops: [],
            }
        })
    }
    handleUpdate = (index, value) => {
        const { data } = this.state
        const newData = [...data.slice(0, index), { ...data[index], ...value }, ...data.slice(index + 1)]
        this.props.onUpdateDataState('hangMucs', newData);
    }
    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
        this.setState(this.state);
    }
    handleDropItem = (index) => {
        if (this.state.data[index]?.noiDungHangMuc.id > 0) {
            this.state.dataDrop.push(this.state.data[index]);
        }
        // this.setDataParam('congTrinhDADrops', this.state.dataDrop);
        this.props.onUpdateDataState("hangMucDrops", this.state.dataDrop);
        this.state.data.splice(index, 1);
        // this.setDataParam('congTrinhDAs', this.state.data);
        this.props.onUpdateDataState("hangMucs", this.state.data);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa hạng mục thành công')
        this.handleModalToggle('');
        // this.handleSearch();
    }
    openEdit = (index) => {
        const { indexEdit, hangMuc, data } = this.state;
        this.setState({ indexEdit: index, hangMuc: { ...data[index] } })
        this.handleModalToggle('themMoi');
    }

    openAdd = () => {
        this.state.indexEdit = -1;
        this.state.hangMuc = {
            noiDungHangMuc: {
                id: 0,
                tenHangMuc: '',
                mauSac: '',
                congTrinhChinh: false,
                soTang: '',
                ketCau: '',
                dienTichXayDungTangMot: '',
                tongDienTichSan: '',
                chieuCaoCongTrinh: '',
                matDoXayDung: '',
                heSoSuDungDat: '',
                noiDungKhac: '',
            },
            chiTietHangMucs: [],
            chiTietHangMucDrops: [],
        };
        this.handleModalToggle('themMoi');
    }
    handleModalToggle = (key) => {
        const { openModel, openModalXacNhan } = this.state
        if (key === "themMoi") {
            this.setState({ openModel: !openModel })
        }
        if (key === "xacNhan") {
            this.setState({ openModalXacNhan: !openModalXacNhan })
        }
    }
    render() {
        return (
            <>
                <div style={{ marginTop: '10px' }}>
                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                        <thead>
                            <tr className="table-secondary">
                                <th>STT</th>
                                <th>Hạng mục</th>
                                <th>Số tầng</th>
                                <th>Kết cấu</th>
                                <th>Diện tích xây dựng (m&#178;)</th>
                                <th className="text-center">Sửa</th>
                                <th className="text-center">Xóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                        <td>{item.noiDungHangMuc.tenHangMuc}</td>
                                        <td className="cell-number">{item.noiDungHangMuc.soTang}</td>
                                        <td>{item.noiDungHangMuc.ketCau}</td>
                                        <td className="cell-number">{item.noiDungHangMuc.tongDienTichSan}</td>
                                        <td width="10%" className="cell-number">
                                            <button onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index)}
                                                disabled={this.props.disabled ? true : false}>
                                                <img src={editIcon} ></img>
                                            </button>
                                        </td>
                                        <td width="10%" className="cell-number">
                                            <button onClick={(e) => this.handleIdDropItem(e, index)}
                                                disabled={this.props.disabled ? true : false}>
                                                <img src={deleteIcon}></img>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={7}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className="row">
                        {/* pagination*/}
                        <div className="col-md-6" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                            <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                            <div>
                                <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                            </div>
                        </div>
                        <div className="col-md text-right" style={{ fontSize: '14px' }}>
                            <button type="button" className="btn btn-primary btn-lg" onClick={this.openAdd}
                            disabled={this.props.disabled ? true : false}>Thêm mới</button>
                        </div>
                    </div>

                </div>
                {this.state.openModel &&
                    <ModalDSHM
                        indexEdit={this.state.indexEdit}
                        data={this.state.hangMuc}
                        onAddData={this.handleAddItem}
                        onUpdateData={this.handleUpdate}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalDSHM>
                }
                {
                    <ModalXacNhanXoaCommon
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModalXacNhan}
                        onDropItem={this.handleDropItem}
                        itemId={this.state.deleteItemId}
                    ></ModalXacNhanXoaCommon>
                }

            </>
        )
    }
}