import React from 'react';
import 'toastr/build/toastr.min.css';
import wordIcon from '../../../../Assets/icons/word.png';
import closeIcon from '../../../../Assets/icons/u1233.png';
import excelIcon from '../../../../Assets/icons/excel.png';

export default class PoolFileItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="file-item row" style={{cursor : 'pointer'}}>
                <div className="col-md-*" style={{marginLeft: '15px'}}>
                    <i >{this.getIcon(this.props.item.name)}</i>
                </div>
                <div className="col-md-6">
                    <a title="Tải xuống" onClick={this.onDownload}>       
                    <span>{this.props.item.name} </span></a>
                </div>
                <div className="col-md-1">
                    <a title="Xóa" onClick={this.onDel}><i><img src={closeIcon}></img></i></a>
                </div>
            </div>
        );
    }

    onDel = () => {
        if (this.props.delHandler == null) return;
        this.props.delHandler(this.props.item, this.props.idx);
    }

    onDownload = () => {
        if (this.props.downHandler == null) return;
        this.props.downHandler(this.props.item);
    }

    getExtFile = (filename) => {
        return filename.split('.').pop();
    }

    getIcon = (name) => {
        let ext = this.getExtFile(name);
        if (ext == "xls" || ext == "xlsx") return <img src={excelIcon}></img>;
        if (ext == "doc" || ext == "docx") return <img src={wordIcon}></img>;
        return "fa-file";
    }

}
