import React from 'react';
import { Modal } from 'react-bootstrap';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';
import closeIcon from "../../../../Assets/icons/u1233.png";
import editIcon from '../../../../Assets/icons/u624.png';
import deleteIcon from '../../../../Assets/icons/u626.png';
import { DON_VI_THAM_DINH } from '../../../Constants/constants';
import ModalCreateDanhMuc from '../Modals/ModalCreateDanhMuc';
import ModalXacNhanXoa from '../Modals/ModalXacNhanXoa';
import PaginationCPXD from '../PaginationGPXD';
import { Box, NativeSelect } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { CustomInput } from '../ComponentCustom/customInput';
export default class ModalDonViThamDinh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInfo: {
                keywords: '',
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 10
            },
            list: [],
            total: 0,
            pageIndex: 0,
            pageLength: 0,
            pageNumber: 1,
            pageSize: 10,
            selectedOption: '',
            total: 0,
            show: false,
            openModel: '',
            editItem: {
                id: 0,
                ten: '',
                trangThai: 1,
            },
            deleteIdDonViThamDinh: 0
        }
        this.radioChange = this.radioChange.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.show != this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
        // if (prevState.list !== this.state.list) {
        //     setTimeout(() => {
        //         if (this.state.list.length === 0) {
        //             AXIOS_INSTANCE.post(DON_VI_THAM_DINH.urlSearch, {
        //                 hoatDong: 1,
        //                 keywords: "",
        //                 pageNumber: this.state.pageNumber - 1,
        //                 pageSize: 10
        //             }).then(res => {
        //                 if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
        //                     const datas = res.data.data;
        //                     const total = datas.total;
        //                     const pageIndex = datas.pageIndex + 1;
        //                     const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
        //                     this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
        //                 } else {
        //                     this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        //                 }
        //             }).catch(error => {
        //                 this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        //             });
        //         }
        //     }, 200);

    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({ ...this.state });
    }
    clearDataModal = () => {
        this.setState({
            editItem: { ...this.state.editItem, id: 0, ten: '', trangthai: 1 }
        })
    }

    editDonViThamDinh=(item)=> {
        this.state.editItem = item;
        this.setState({ ...this.state });
        this.handleModalToggle('them-moi-tham-dinh');
    }

    handleIdDonViThamDinh=(value)=> {
        this.state.deleteIdDonViThamDinh = value;
        this.setState({ ...this.state });
        this.handleModalToggle('xac-nhan-xoa');
    }

    componentDidMount=() =>{
        this.onPagi();
    }
    radioChange=(e)=> {
        this.setState({
            selectedOption: e.currentTarget.value
        });
    }
    sendData = () => {
        this.props.onUpdateParam('donViThamDinh', this.state.selectedOption);
        this.handleCloseModal('')
    }

    /*Tìm kiếm*/
    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.dataInfo[name] = value;
        this.setState({ ...this.state });
    }
  
    fetchSearch = keySearch => {
        AXIOS_INSTANCE.post(DON_VI_THAM_DINH.urlSearch, keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    onPagi = () => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearch(
            {
                ...dataInfo,
                pageNumber: this.state.pageNumber +1,
                pageSize: this.state.pageSize
            });
    }

    clickSearch = (event) => {
        this.setState({ pageNumber: 0 });
        const dataInfo = this.state.dataInfo;
        this.fetchSearch(
            {
                ...dataInfo,
                pageNumber: 1,
                pageSize: this.state.pageSize,
            });
    }
    onSelectPage = page => {
        this.setState({ pageNumber: page -1}, this.onPagi);
    }
    onChangePageSize = pageSize => {
        this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
    }

    handleCloseModal = (key) => {
        this.props.onModalToggle(key);
    }
    onChange = e => {
        this.onChangePageSize(+ e.target.value);
    }
    onChangePageIndex = (event, valuePage) => {
        this.onSelectPage(valuePage);
    }
    render() {
        return (

        <>
            <Modal show={this.state.show} onHide={() => this.handleCloseModal('')} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>ĐƠN VỊ THẨM ĐỊNH</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => this.handleCloseModal('')}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung gợi ý</label>
                                <input type="text" className="form-control" id="keywords" name="keywords" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: '30px' }}>
                            <div className="form-group">
                                <button type="button" class="btn btn-primary btn-lg" onClick={this.clickSearch} >Tìm kiếm</button>&nbsp;
                                                <button type="button" data-dismiss='modal' onClick={this.sendData} class="btn btn-secondary btn-lg">Chọn</button>&nbsp;
                                                <button type="button" onClick={() => this.handleModalToggle('them-moi-tham-dinh')} class="btn btn-primary btn-lg" >Thêm mới</button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                        <thead>
                            <tr className="table-secondary">
                                <th>STT</th>
                                <th></th>
                                <th>Nội dung gợi ý</th>
                                <th className="text-center">Sửa</th>
                                <th className="text-center">Xóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list?.length > 0 ? this.state.list?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                        <td align="center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" value={item.ten} onChange={this.radioChange} />
                                            </div>
                                        </td>
                                        <td>{item.ten}</td>
                                        <td align="center"><button onClick={() => this.editDonViThamDinh(item)}><img src={editIcon}></img></button></td>
                                        <td align="center"><button onClick={() => this.handleIdDonViThamDinh(item.id)}><img src={deleteIcon}></img></button></td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {/* <PaginationCPXD
                        pageSize={this.state.pageSize}
                        pageLength={this.state.pageLength}
                        pageIndex={this.state.pageIndex}
                        total={this.state.total}
                        pageNumber={this.state.pageNumber}
                        onSelectPage={this.onSelectPage}
                        onChangePageSize={this.onChangePageSize}
                    ></PaginationCPXD> */}
                      <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination count={Math.ceil(this.state.total/this.state.pageSize)} page={this.state.pageNumber + 1} siblingCount={1} variant="outlined"
                size="small"
                color="primary" shape="rounded" showFirstButton showLastButton onChange={this.onChangePageIndex} />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box className="ml-2" component="span">Số mẫu tin trên trang</Box>
                    <NativeSelect value={this.state.pageSize} input={<CustomInput/>} onChange={this.onChange}  >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </NativeSelect>
                </Box>
                <Box className="mr-0" component="span" paddingX="5px">Mẫu tin {this.state.pageIndex} - {this.state.pageLength}/ {this.state.total}</Box>
                </Box>
                </div>
            </Modal>

            <ModalCreateDanhMuc
                urlKiemTraDuplicate={DON_VI_THAM_DINH.urlKiemTraDuplicate}
                url={DON_VI_THAM_DINH.urlAddorUpdate}
                itemName={DON_VI_THAM_DINH.ten}
                clearDataModal={this.clearDataModal}
                editItem={this.state.editItem}
                onPagi={this.onPagi}
                onModalToggle={this.handleModalToggle}
                show={this.state.openModel === 'them-moi-tham-dinh'}
            ></ModalCreateDanhMuc>

            <ModalXacNhanXoa
                onPagi={this.onPagi}
                onModalToggle={this.handleModalToggle}
                show={this.state.openModel === 'xac-nhan-xoa'}
                deleteItemId={this.state.deleteIdDonViThamDinh}
                url={DON_VI_THAM_DINH.urlDelete}
                itemName={DON_VI_THAM_DINH.ten}
            ></ModalXacNhanXoa>
        </>
        )
        
    }
}
