import React from 'react';
import { Box, NativeSelect } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { CustomInput } from '../ComponentCustom/customInput';
export default class PaginationCustom extends React.Component {
    onChange = e => {
        this.props.onChangePageSize(+ e.target.value);
    }
    onChangePageIndex = (event, valuePage) => {
        this.props.onSelectPage(valuePage);
    }
  
    render() {
        return (
            <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination count={Math.ceil(this.props.total/this.props.pageSize)} page={this.props.pageNumber + 1} siblingCount={1} variant="outlined"
                size="small"
                color="primary" shape="rounded" showFirstButton showLastButton onChange={this.onChangePageIndex} />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box className="ml-2" component="span">Số mẫu tin trên trang</Box>
                    <NativeSelect value={this.props.pageSize} input={<CustomInput/>} onChange={this.onChange}  >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </NativeSelect>
                </Box>
                <Box className="mr-0" component="span" paddingX="5px">Mẫu tin {this.props.pageIndex} - {this.props.pageLength}/ {this.props.total}</Box>
                </Box>
        );
    }
}
