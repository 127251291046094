import { Box, IconButton, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Pagination } from "@material-ui/lab";
import React, { useEffect } from "react";
import { TypeCell } from "./commonTableChuyenNganh";
import CustomCell from "./customCellTable";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Cell, Row } from "../../../App/commons/components/table/tableCommons";
import MenuDrop from "../../../App/Components/table/menuDrop";

export interface Pageable {
    total: number;
    page: number;
    rowsPerPage: number;
}

const StyledTablePagination = withStyles({
    root: {
        borderBottom: 0,
    },
    toolbar: {
        padding: "0",
        "& .MuiPagination-root": {
            order: "-1",
            marginRight: "10px",
        },
        "& .MuiTablePagination-caption:nth-last-child(2)": {
            flexGrow: 1,
            textAlign: "right",
        },
    },
    spacer: {
        flex: 0,
    },
})(TablePagination);

export interface Column<T> {
    width?: number | string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    field: String;

    title: string;
    type?: TypeCell;
}

interface CommonTablePropsI<T> {
    onDelete?: (data: T) => void;
    onEdit?: (data: T) => void;
    onView?: (data: T) => void;

    columns: Column<T>[];
    rows: T[];
    mode?: boolean;
    turnOffAction?: boolean;

    pageParams: Pageable;
    onChangePage: (value: number) => void;
    onChangeRowsPerPage: (rowsPerPage: number) => void;
}
export const CommonTableChuyenNganhLazy = <T extends object>(props: CommonTablePropsI<T>) => {
    // const { onEdit, onDelete, columns, rows, onChangePage, onChangeRowsPerPage, total, page, rowsPerPage } = props;
    const { onEdit, onDelete, columns, rows, onChangePage, onChangeRowsPerPage, pageParams, mode, turnOffAction, onView } = props;

    const handleChangePage = (newPage: number) => {
        onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChangeRowsPerPage(parseInt(event.target.value));
    };

    useEffect(() => {
        if (pageParams.page !== 0 && rows.length === 0) {
            onChangePage(pageParams.page - 1);
        }
    }, [rows.length]);

    return (
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        {columns.map((col, i) => (
                            <Cell key={i} component="th" scope="row" align={col.align ? col.align : "center"} width={col.width}>
                                {col.title}
                            </Cell>
                        ))}
                        {!Boolean(turnOffAction) && (
                            <Cell component="th" scope="row" align="center" width="5%">
                                {" "}
                                Xử lý{" "}
                            </Cell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((item: any, i) => (
                            <Row key={i}>
                                <Cell scope="row" align="center">
                                    {i + 1 + pageParams.page * pageParams.rowsPerPage}
                                </Cell>
                                {columns.map(({ field, type, align }, j) => (
                                    <CustomCell field={`${field}`} item={item} key={j} type={type} align={align} />
                                ))}
                                {!Boolean(turnOffAction) && (
                                    <Cell align="center">
                                        {!mode && (
                                            <Box display="flex" justifyContent="center">
                                                {onEdit && (
                                                    <IconButton color="primary" aria-label="edit" size="small" onClick={onEdit ? () => onEdit(item) : () => {}}>
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                                {onDelete && (
                                                    <IconButton color="secondary" aria-label="delete" size="small" onClick={onDelete ? () => onDelete(item) : () => {}}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                        {mode && <MenuDrop onDelete={onDelete} onEdit={onEdit} onView={onView} item={item} />}
                                    </Cell>
                                )}
                            </Row>
                        ))}
                    {rows.length === 0 && (
                        <TableRow>
                            <Cell colSpan={columns.length + 2} style={{ padding: "8px", textAlign: "center" }}>
                                Không có dữ liệu
                            </Cell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {/* <StyledTablePagination
                            labelRowsPerPage={"Số mẫu tin trên trang"}
                            labelDisplayedRows={({ from, to, count }) => `Mẫu tin ${from} - ${to}/${count}`}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={pageParams.total}
                            rowsPerPage={pageParams.rowsPerPage}
                            page={pageParams.page}
                            SelectProps={{
                                input: <CustomInput fullWidth={false} />,
                                inputProps: { "aria-label": "Số mẫu tin trên trang" },
                                native: true,
                            }}
                            onPageChange={(_e, newPage) => handleChangePage(newPage)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        /> */}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { onChangePage, count, rowsPerPage } = props;

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        onChangePage(null, value - 1);
    };

    return (
        <Pagination
            count={Math.ceil(count / rowsPerPage)}
            disabled={Math.ceil(count / rowsPerPage) === 1}
            defaultPage={1}
            siblingCount={1}
            variant="outlined"
            size="medium"
            color="primary"
            shape="rounded"
            showFirstButton
            showLastButton
            onChange={handleOnChangePage}
        />
    );
};
