import { InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import GiayPhepService from '../../../api/service/CapPhepXDCTHService';
import { bootstrapTextFieldHook } from '../../commons/components/input/bootstrap';
import { CONG_TRINH_CO_THOI_HAN, CONG_TRINH_DI_DOI, CONG_TRINH_DU_AN, CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN, CONG_TRINH_GIAI_DOAN_THEO_TUYEN, CONG_TRINH_KHONG_THEO_TUYEN, CONG_TRINH_NGAM, CONG_TRINH_QUANG_CAO, CONG_TRINH_SUA_CHUA, CONG_TRINH_THEO_TUYEN, CONG_TRINH_TIN_NGUONG, CONG_TRINH_TON_GIAO, CONG_TRINH_TUONG_DAI } from '../../Constants/constants';
import FormCPDiDoiCongTrinh from '../CapPhepDiDoiCongTrinh/FormCPDiDoiCongTrinh';
import FormCapPhepSuaChua from '../CapPhepSuaChua/FormCapPhepSuaChua';
import FormCapPhepCoThoiHan from '../CapPhepXDCTCoThoiHan/FormCapPhepCoThoiHan';
import FormCapPhepDuAn from '../CapPhepXDCTDA/SubTabCapPhepXDCTDA/NDGP/FormCapPhepDuAn';
import FormCapPhepKhongTheoTuyen from '../CapPhepXDCTKTT/FormCapPhepKhongTheoTuyen';
import FormCPXDCTGDKhongTheoTuyen from '../CapPhepXDCTKTTGD/FormCPXDCTGDKhongTheoTuyen';
import FormCPXDCongTrinhNgam from '../CapPhepXDCTNgam/FormCPXDCongTrinhNgam';
import FormCPXDCTQuangCao from '../CapPhepXDCTQuangCao/FormCPXDCTQuangCao';
import FormCPXDCTTheoTuyen from '../CapPhepXDCTT/FormCPXDCTTheoTuyen';
import FormCPXDCTGDTheoTuyen from '../CapPhepXDCTTGD/FormCPXDCTGDTheoTuyen';
import FormCPXDCTTinNguong from '../CapPhepXDCTTinNguong/FormCPXDCTTinNguong';
import FormCPXDCTTonGiao from '../CapPhepXDCTTonGiao/FormCPXDCTTonGiao';
import FormCPXDCTTuongDai from '../CapPhepXDCTTuongDai/FormCPXDCTTuongDai';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";

export default class FormNDGPDC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            openModel: false,
            noiDungGiayPhep: {
                thongTinCongTrinh: {},
                thongTinXayDung: {},
                viTriXayDung: {},
                loDatXayDungs: [],
                loDatXayDungDropDTOs: [],
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
            errors: {},
            soGiayPhepAuto: "",
            danhSachGiayPhepDaCap: []
        }
        //Add By IN
        this.giayPhepService = new GiayPhepService();
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinh != this.state.noiDungGiayPhep.thongTinCongTrinh) {
            this.state.noiDungGiayPhep.thongTinCongTrinh = this.props.noiDungGiayPhep.thongTinCongTrinh;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung != this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriXayDung != this.state.noiDungGiayPhep.viTriXayDung) {
            this.state.noiDungGiayPhep.viTriXayDung = this.props.noiDungGiayPhep.viTriXayDung;
            this.setState(this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungs != this.state.noiDungGiayPhep.loDatXayDungs) {
            this.state.noiDungGiayPhep.loDatXayDungs = this.props.noiDungGiayPhep.loDatXayDungs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files != this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash != this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep != this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }


    }

    setParams = (event, key) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key == 'thongTinCongTrinh') {

            this.state.noiDungGiayPhep.thongTinCongTrinh[name] = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (key == 'thongTinXayDung') {
            if (this.state.noiDungGiayPhep.thongTinXayDung === null) {
                this.state.noiDungGiayPhep.thongTinXayDung = {};
            }
            this.state.noiDungGiayPhep.thongTinXayDung[name] = value;
            this.props.onUpdateDataState('thongTinXayDung', this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (key == 'viTriXayDung') {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (key == 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
        if (key === "HuyenThiThanhPho") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
    }

    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key === 'loDatXayDungDropDTOs') {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
        if (key === "donViThamDinh") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "donViTuVan") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "kyHieuThietKe") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('clear-all');
        this.props.onClearData();
    }

    loadThongTinTheoSoGiayPhepCu = (newValue) => {
        this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = newValue.giayPhep.maSoGiayPhep;
        this.setState(this.state);
        this.props.onUpdateDataState("state.noiDungGiayPhep", {
            ...newValue,
            giayPhep: {
                ...newValue.giayPhep,
                id: 0,
                maSoGiayPhep: '',
                maLoaiGiayPhepCu: newValue.giayPhep.maLoaiGiayPhep
            },
        });
        this.setState(this.state);
    }
    onChangAutoComplete = (event) => {
        let soGiayPhep = event.target.value;
        this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = soGiayPhep;
        this.setState(this.state);
        this.giayPhepService.getByMaSoGiayPhep({ maSoGiayPhep: soGiayPhep }).then(res => {
            if (res != null && res.data.data && res.data.data?.length >= 0) {
                const danhSachGiayPhepDaCapNew = [...res.data.data];
                this.state.danhSachGiayPhepDaCap = [...danhSachGiayPhepDaCapNew];
                this.setState(this.state);
            } else {
                this.state.danhSachGiayPhepDaCap = [];
                this.setState(this.state);
            }
        });
    }

    handleChangeNgayCap(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }

    handleChangeHieuLuc(date) {
        let name = "thoiGianHieuLuc";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }

    render() {
        const CustomInputNgayCap = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputHieuLuc = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>
                <div class="col-md-12">
                    <br />
                    <div class="row">

                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép xây dựng cũ</label>
                                <Autocomplete style={{ width: '350px' }}
                                    id="soGiayPhepCu"
                                    name="soGiayPhepCu"
                                    options={this.state.danhSachGiayPhepDaCap}
                                    value={this.state.noiDungGiayPhep}
                                    getOptionLabel={(option) => option.giayPhep.maSoGiayPhep}
                                    onChange={(event, newValue) => this.loadThongTinTheoSoGiayPhepCu(newValue)}
                                    renderInput={(params) => {
                                        return <div className='' ref={params.InputProps.ref} >
                                            <InputBase style={{ width: '312px' }}
                                                readOnly={this.state.noiDungGiayPhep?.giayPhep.id > 0}
                                                {...params.inputProps}
                                                value={this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu}
                                                id="soGiayPhepCu"
                                                name="soGiayPhepCu"
                                                onChange={this.onChangAutoComplete}
                                                classes={bootstrapTextFieldHook.useInput()}
                                            />
                                            <i class="fas fa-search" style={{ marginLeft: '2px' }}></i>
                                        </div>
                                    }}
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap" disabled
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayCap ?
                                        moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    customInput={<CustomInputNgayCap />}
                                    selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                    onChange={this.handleChangeNgayCap.bind(this)}
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                    name="ngayCap"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="thongTinThayDoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thông tin thay đổi</label>
                                <input type="text" className="form-control" id="thongTinThayDoi" name="thongTinThayDoi"
                                    value={this.state.noiDungGiayPhep.giayPhep?.thongTinThayDoi}
                                    onChange={(event) => this.setParams(event, "giayPhep")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="thoiGianHieuLuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thời gian hiệu lực</label>
                                {/* <input type="date" className="form-control" id="thoiGianHieuLuc" name="thoiGianHieuLuc"
                                    value={this.state.noiDungGiayPhep.giayPhep?.thoiGianHieuLuc ? moment(new Date(this.state.noiDungGiayPhep.giayPhep?.thoiGianHieuLuc)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                <DatePicker
                                    customInput={<CustomInputHieuLuc />}
                                    selected={this.state.noiDungGiayPhep.giayPhep?.thoiGianHieuLuc}
                                    onChange={this.handleChangeHieuLuc.bind(this)}
                                    value={this.state.noiDungGiayPhep.giayPhep?.thoiGianHieuLuc === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.thoiGianHieuLuc).format("DD/MM/YYYY") : null}
                                    name="thoiGianHieuLuc"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 1 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_KHONG_THEO_TUYEN.ma &&
                    <FormCapPhepKhongTheoTuyen
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 2 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_THEO_TUYEN.ma &&
                    <FormCPXDCTTheoTuyen
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 3 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_NGAM.ma &&
                    <FormCPXDCongTrinhNgam
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 4 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN.ma &&
                    <FormCPXDCTGDKhongTheoTuyen
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 5 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_GIAI_DOAN_THEO_TUYEN.ma &&
                    <FormCPXDCTGDTheoTuyen
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 6 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_DU_AN.ma &&
                    <FormCapPhepDuAn
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 7 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_DI_DOI.ma &&
                    <FormCPDiDoiCongTrinh
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                    />}
                {/* 8 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_SUA_CHUA.ma &&
                    <FormCapPhepSuaChua
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 9 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_TON_GIAO.ma &&
                    <FormCPXDCTTonGiao
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 10 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_TIN_NGUONG.ma &&
                    <FormCPXDCTTinNguong
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 11 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_QUANG_CAO.ma &&
                    <FormCPXDCTQuangCao
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 12 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_TUONG_DAI.ma &&
                    <FormCPXDCTTuongDai
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
                {/* 13 */}
                {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhepCu === CONG_TRINH_CO_THOI_HAN.ma &&
                    <FormCapPhepCoThoiHan
                        isNhapDauKy={this.props.isNhapDauKy}
                        onUpdateDataState={this.props.onUpdateDataState}
                        addOrUpdate={this.props.addOrUpdate}
                        onClearData={this.props.onClearData}
                        maHoSo={this.props.noiDungGiayPhep}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                        errorState={this.props.errorState}
                        readonly={this.props.readonly}
                        disabled={this.props.disabled}
                    />}
            </div >
        )
    }
}