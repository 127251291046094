import React from 'react';

export default class CNSGPForm extends React.Component {

    state = {
        tenSoBienNhanValue: "",
        tenChuDauTuValue: "",
        tenCongTrinhValue: "",
        diaChiCongTrinhValue: ""
    }

    // btnRef = React.createRef();

    componentDidMount() {
       
    }

    handleSoBienNhanChange = e => {
        this.setState({ tenSoBienNhanValue: e.target.value });
    }

    handleChuDauTuChange = e => {
        this.setState({ tenChuDauTuValue: e.target.value });
    }

    handleCongTrinhChange = e => {
        this.setState({ tenCongTrinhValue: e.target.value });
    }

    handleDiaChiCongTrinhChange = e => {
        this.setState({ diaChiCongTrinhValue: e.target.value });
    }

    onSearch = () => {
        const data = {
            "tenSoBienNhan": this.state.tenSoBienNhanValue,
            "tenChuDauTu": this.state.tenChuDauTuValue,
            "tenCongTrinh": this.state.tenCongTrinhValue,
            "diaChiCongTrinh": this.state.diaChiCongTrinhValue
        };
        this.props.onSearch(data);
    }

    onChangeItem = item => {
    }

    render() {
        return (
            <React.Fragment>
                <br></br>
                <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>CẬP NHẬT SỐ GIẤY PHÉP XÂY DỰNG</h6>
                <br></br>
                <div class="row">
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số biên nhận</label>
                            <input type="text" className="form-control" id="idSoBienNhan" placeholder="Nhập số biên nhận" onChange={this.handleSoBienNhanChange} />
                        </div>
                    </div>
                    <div class="col-md-6">
                    <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chủ đầu tư</label>
                            <input type="text" className="form-control" id="idChuDauTu" placeholder="Nhập tên chủ đầu tư" onChange={this.handleChuDauTuChange} />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên công trình</label>
                            <input type="text" className="form-control" id="idTenCongTrinh" placeholder="Nhập tên công trình" onChange={this.handleCongTrinhChange} />
                        </div>
                    </div>
                    <div class="col-md-6">
                    <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ công trình</label>
                            <input type="text" className="form-control" id="idDiaChiCongTrinh" placeholder="Nhập địa chỉ công trình" onChange={this.handleDiaChiCongTrinhChange} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div className="form-group">
                            <button className="btn btn-secondary-custom btn-lg" style={{ width: '120px' }} onClick={this.onSearch}>Tìm kiếm</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}