import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../utils/stringUtils';
import closeIcon from "../../../Assets/icons/u1233.png";
import TableChiTietHangMuc from './SubModalDSHM/TableChiTietHangMuc';

export default class ModalDSHM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModel: '',
            hangMuc: {
                noiDungHangMuc: {
                    id: 0,
                    tenHangMuc: '',
                    mauSac: '',
                    congTrinhChinh: false,
                    soTang: '',
                    ketCau: '',
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoCongTrinh: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    noiDungKhac: '',
                },
                chiTietHangMucs: [],
                chiTietHangMucDrops: [],
            },
            show: false,
            errors: {
                tenHangMuc: '',
            },
            indexEdit: -1,
        }
    }
    componentDidMount() {
        const { hangMuc } = this.state;
        if (this.props.indexEdit !== -1) {
            this.setState({
                hangMuc: { ...hangMuc, ...this.props.data }
            });
        }
    }
    componentWillUnmount() {
        this.clearData()
    }
    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle('themMoi');
    }

    /**
     * 
     * @param {event} e 
     */

    handleChange = (e) => {
        const { hangMuc } = this.state;
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            hangMuc: {
                ...hangMuc,
                noiDungHangMuc: {
                    ...hangMuc.noiDungHangMuc,
                    [name]: value
                }
            }
        })
    }
    handleSubmit = () => {
        if (this.validation()) {
            if (this.props.indexEdit >= 0) {
                this.props.onUpdateData(this.props.indexEdit, this.state.hangMuc);
                toastr.success('Cập nhật hạng mục thành công')
                this.handleCloseModal();
            } else {
                this.props.onAddData(this.state.hangMuc);
                toastr.success('Thêm mới hạng mục thành công')
                this.handleCloseModal();
            }
        }
    }
    setListParams = (key, value) => {
        if (key === 'chiTietHangMucs') {
            let hangMuc = { ...this.state.hangMuc }
            hangMuc.chiTietHangMucs = value
            this.setState({
                hangMuc: hangMuc
            })
        }
        if (key === 'chiTietHangMucDrops') {
            let hangMuc = { ...this.state.hangMuc }
            hangMuc.chiTietHangMucDrops = value
            this.setState({
                hangMuc: hangMuc
            })
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.hangMuc.noiDungHangMuc.tenHangMuc)) {
            this.state.errors.tenHangMuc = 'Vui lòng nhập tên hạng mục';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.setState({
            hangMuc: {
                noiDungHangMuc: {
                    id: 0,
                    tenHangMuc: '',
                    mauSac: '',
                    congTrinhChinh: false,
                    soTang: '',
                    ketCau: '',
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoCongTrinh: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    noiDungKhac: '',
                },
                chiTietHangMucs: [],
                chiTietHangMucDrops: [],
            },
            errors: {
                tenHangMuc: '',
            }
        });
    }
    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({
            ...this.state
        })
    }

    render() {
        const { show, hangMuc } = this.state;
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI HẠNG MỤC</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tenHangMuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Hạng mục <span style={{ color: 'red' }}>(*)</span></label>
                                    <div className="input-group">
                                        <input type="text"
                                            className="form-control"
                                            id="tenHangMuc" name="tenHangMuc"
                                            value={hangMuc.noiDungHangMuc.tenHangMuc}
                                            onChange={this.handleChange}
                                            style={{ fontSize: '14px' }} />
                                        <div style={Boolean(this.state.errors?.tenHangMuc) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenHangMuc}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="mauSac" style={{ fontWeight: 'bold', fontSize: '14px' }}>Màu sắc</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="mauSac" name="mauSac"
                                            value={hangMuc.noiDungHangMuc.mauSac}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-check">
                                    <label className="form-check-label" style={{ marginTop: '40px' }}>
                                        <input type="radio" className="form-check-input" name="optradio" />Là công trình chính
                                                </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="soTang" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tầng</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="soTang" name="soTang"
                                            value={hangMuc.noiDungHangMuc.soTang}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div class="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="ketCau" style={{ fontWeight: 'bold', fontSize: '14px' }}>Kết cấu</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="ketCau" name="ketCau"
                                            value={hangMuc.noiDungHangMuc.ketCau}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="chiTietHangMuc" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chi tiết hạng mục</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TableChiTietHangMuc
                                    hangMuc={hangMuc}
                                    onUpdateDataState={this.setListParams}
                                ></TableChiTietHangMuc>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="dienTichXayDungTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>DT xây dựng tầng một</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="dienTichXayDungTangMot" name="dienTichXayDungTangMot"
                                            value={hangMuc.noiDungHangMuc.dienTichXayDungTangMot}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng DT sàn</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="tongDienTichSan" name="tongDienTichSan"
                                            value={hangMuc.noiDungHangMuc.tongDienTichSan}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="chieuCaoCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao công trình</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="chieuCaoCongTrinh" name="chieuCaoCongTrinh"
                                            value={hangMuc.noiDungHangMuc.chieuCaoCongTrinh}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                        <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="matDoXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Mật độ xây dựng (%)</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="matDoXayDung" name="matDoXayDung"
                                            value={hangMuc.noiDungHangMuc.matDoXayDung}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="heSoSuDungDat" style={{ fontWeight: 'bold', fontSize: '14px' }}>Hệ số sử dụng đất</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="heSoSuDungDat" name="heSoSuDungDat"
                                            value={hangMuc.noiDungHangMuc.heSoSuDungDat}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div className="form-group">
                                    <label htmlFor="noiDungKhac" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung khác / Ghi chú</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="noiDungKhac" name="noiDungKhac"
                                            value={hangMuc.noiDungHangMuc.noiDungKhac}
                                            onChange={this.handleChange} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}