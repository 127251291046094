import React from 'react';
import editIcon from '../../../../Assets/icons/u624.png'
import deleteIcon from '../../../../Assets/icons/u626.png'
import closeIcon from "../../../../Assets/icons/u1233.png";
import ModalCreateCDTCN from './ModalCreateCDTCN';
import ModalSearchCDT from './ModalSearchCDT';
import { Pagination } from '@material-ui/lab';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import api from '../../../../api/index';
import { HoSoCuaToiMgtService } from '../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';

export default class TableCDT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            openModelCustom: false,
            deleteLodat: 0,
            recordsPerPage: 0,
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                idGiayPhep: 0,
                tenLoaiGiayPhep: '',
                nguonChuDauTu: 1,
                gioiTinh: 0,
                duong: '',
                loaiGiayTo: '',
                tenTinhThanh: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
                keySet: 0
            },
            indexEdit: -1,
            dataValue: [],
            dataValues: [],
            check: '',
        }
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep.chuDauTus !== this.state.data && this.props.noiDungGiayPhep.chuDauTus !== undefined) {
            this.state.data = this.props.noiDungGiayPhep.chuDauTus;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.noiDungGiayPhep.chuDauTuDrops !== this.state.dataDrop && this.props.noiDungGiayPhep.chuDauTuDrops !== undefined) {
            this.state.dataDrop = this.props.noiDungGiayPhep.chuDauTuDrops;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    initData = () => {
        if (this.props.noiDungGiayPhep !== undefined) {
            this.setState({
                pageNumber: 1,
                pageSize: 5,
                data: [],
                dataDrop: [],
                dataPagi: [],
                openModel: false,
                deleteLodat: '',
                recordsPerPage: 0,
                noiDungGiayPhep: {
                    chuDauTuDTOs: this.props.noiDungGiayPhep.chuDauTus || [],
                    //drop
                    chuDauTuDropDTOs: this.props.noiDungGiayPhep.chuDauTuDrops || [],
                }
            })
        } else {
            this.state = {
                pageNumber: 1,
                pageSize: 5,
                data: [],
                dataDrop: [],
                dataPagi: [],
                openModel: false,
                deleteLodat: '',
                recordsPerPage: 0,
                noiDungGiayPhep: {
                    chuDauTuDTOs: [],
                    //drop
                    chuDauTuDropDTOs: [],
                }
            };
            this.setState(this.state);
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }

    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }

    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }

    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }

    //handle modal
    handleIdDropLoDat = (e, value) => {
        this.setState({
            deleteLodat: value
        })
    }

    handleAdd = (value) => {
        const { data } = this.state
        const newData = [{ ...value }, ...data];
        // this.setDataParam('chuDauTuDTOs', this.state.data);
        this.props.onUpdateDataState('chuDauTus', newData);
        this.setState({
            data: [...newData],
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                idGiayPhep: 0,
                tenLoaiGiayPhep: '',
                nguonChuDauTu: 1,
                gioiTinh: 0,
                duong: '',
                tenLoaiGiayTo: 'Chứng minh nhân dân',
                tenTinhThanh: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
            },
        })
    }

    handleUpdate = (index, value) => {
        const { data } = this.state
        const newData = [...data.slice(0, index), { ...data[index], ...value }, ...data.slice(index + 1)]
        this.props.onUpdateDataState('chuDauTus', newData);
    }

    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
    }

    handleDrop = (index) => {
        if (this.state.data[index]?.id > 0) {
            this.state.dataDrop.push(this.state.data[index]);
        }
        this.setDataParam('chuDauTuDropDTOs', this.state.dataDrop);
        this.props.onUpdateDataState("chuDauTuDrops", this.state.dataDrop);
        this.state.data.splice(index, 1);
        this.props.onUpdateDataState("chuDauTus", this.state.data);
        this.setDataParam('chuDauTuDTOs', this.state.data);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa chủ đầu tư thành công');
        this.handleSearch();
    }

    handleModalToggle = () => {
        const { openModel, chuDauTu } = this.state
        this.setState({ openModel: !openModel });
    }

    handleModalToggleCustom = () => {
        const { openModelCustom } = this.state
        this.setState({ openModelCustom: !openModelCustom });
    }

    openEdit = (index) => {
        const { indexEdit, chuDauTu, data } = this.state;
        this.setState({ ...this.state, indexEdit: index, chuDauTu: { ...chuDauTu, ...data[index] } });
        this.handleModalToggle();
    }

    openAdd = () => {
        this.state.indexEdit = -1;
        this.state.chuDauTu = {
            id: 0,
            ten: '',
            diaChiChiTiet: '',
            idGiayPhep: 0,
            tenLoaiGiayPhep: '',
            nguonChuDauTu: 1,
            gioiTinh: 0,
            duong: '',
            tenLoaiGiayTo: 'Chứng minh nhân dân',
            tenTinhThanh: '',
            tenQuanHuyen: '',
            tenPhuongXa: '',
            ngayCap: '',
            soGiayTo: '',
            noiCap: '',
            diaChiCu: '',
            ngaySinh: '',
            loaiChuDauTu: 1,
            hoatDong: 1,
            nguonChuDauTu: 1,
        };
        this.handleModalToggle();
    }

    loadDataTable = (soCMND) => {
        new HoSoCuaToiMgtService().layThongTinCongDanByCMND(soCMND).then((res) => {
            if (res != null) {
                let datas = res.data.data.data;
                if (datas.maGioiTinh == "2") {
                    datas.tenGioiTinh = 1
                } else {
                    datas.tenGioiTinh = 2
                }
                this.setState({
                    dataValue: datas || []
                })
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    loadDataTables = (maSoDoanhNghiep) => {
        new HoSoCuaToiMgtService().layThongTinDoanhNghiepByMSDN(maSoDoanhNghiep).then((res) => {
            if (res != null) {
                let datas = res.data.data.data;
                this.setState({
                    dataValues: datas || []
                })
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    resetState = () => {
        this.setState({
            checked: false,
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            openModelCustom: false,
            deleteLodat: 0,
            recordsPerPage: 0,
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                idGiayPhep: 0,
                tenLoaiGiayPhep: '',
                nguonChuDauTu: 1,
                gioiTinh: 0,
                duong: '',
                loaiGiayTo: '',
                tenTinhThanh: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
                keySet: 0
            },
            indexEdit: -1,
            dataValue: [],
            dataValues: [],
            check: '',
        })
    }

    handleClickSetData = () => {
        if (this.state.checked) {
            const { data, dataValue } = this.state
            // let result;
            // for (let i = 0; i < this.state.dataPagi.length; i++) {
            //     result = this.state.dataPagi[i].ten;
            // }
            // if (result != dataValue.tenCongDan) {
                const chuDauTu = {
                    id: 0,
                    ten: dataValue.tenCongDan,
                    diaChiChiTiet: dataValue.diaChi,
                    idGiayPhep: 0,
                    tenLoaiGiayPhep: '',
                    nguonChuDauTu: 1,
                    gioiTinh: dataValue.tenGioiTinh,
                    duong: '',
                    tenLoaiGiayTo: 'Chứng minh nhân dân',
                    tenTinhThanh: '',
                    tenQuanHuyen: '',
                    tenPhuongXa: '',
                    ngayCap: '',
                    soGiayTo: dataValue.soCMND,
                    noiCap: '',
                    diaChiCu: '',
                    ngaySinh: dataValue.ngayThangNamSinh,
                    loaiChuDauTu: 1,
                    hoatDong: 1,
                    nguonChuDauTu: 1,
                    keySet: 1
                };
                const newData = [{ ...chuDauTu }, ...data];
                this.props.onUpdateDataState('chuDauTus', newData);
                this.resetState();
                this.handleModalToggleCustom();
            // } else {
            //     toastr.warning('Thông tin đã tồn tại');
            //     this.handleModalToggleCustom();
            // }
        } else {
            this.handleModalToggleCustom();
        }
    }

    handleClickSetDatas = () => {
        if (this.state.checked) {
            const { data, dataValues } = this.state
            // let result;
            // for (let i = 0; i < this.state.dataPagi.length; i++) {
            //     result = this.state.dataPagi[i].ten;
            // }
            // if (result != dataValues.tenDoanhNghiep_VN) {
                const chuDauTu = {
                    id: 0,
                    ten: dataValues.tenDoanhNghiep_VN,
                    diaChiChiTiet: dataValues.fullAddress,
                    idGiayPhep: 0,
                    tenLoaiGiayPhep: '',
                    nguonChuDauTu: 1,
                    gioiTinh: 0,
                    duong: '',
                    tenLoaiGiayTo: 'Chứng minh nhân dân',
                    tenTinhThanh: '',
                    tenQuanHuyen: '',
                    tenPhuongXa: '',
                    ngayCap: '',
                    soGiayTo: '',
                    noiCap: '',
                    diaChiCu: '',
                    ngaySinh: '',
                    loaiChuDauTu: 2,
                    hoatDong: 1,
                    nguonChuDauTu: 1,
                    keySet: 1
                };
                const newData = [{ ...chuDauTu }, ...data];
                this.props.onUpdateDataState('chuDauTus', newData);
                this.resetState();
                this.handleModalToggleCustom();
            } else {
                toastr.warning('Thông tin đã tồn tại');
                this.handleModalToggleCustom();
            }
        // } else {
        //     this.handleModalToggleCustom();
        // }
    }

    handleChangeCheck = (e) => {
        const { checked } = e.target
        this.setState({
            checked: checked
        })
    }

    render() {
        console.log("this.state.dataPagi : ", this.state.dataPagi)
        return (
            <div style={{ marginTop: '10px' }}>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Loại hình</th>
                            <th>Chủ đầu tư</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.loaiChuDauTu == 1 ? "Cá nhân" : "Doanh Nghiệp"}</td>
                                    <td>{item.ten}</td>
                                    <td width="10%">
                                        <div className="row">
                                            <div className="col-md">
                                                {!item.keySet == 1 &&
                                                    <button onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index)}><img src={editIcon} style={{ float: 'left', marginLeft: '20px' }}></img></button>
                                                }
                                                {/* {item.keySet == 1 &&
                                                    <button disabled onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index)}><img src={editIcon} style={{ float: 'left', marginLeft: '20px' }}></img></button>
                                                } */}
                                            </div>
                                            <div className="col-md">
                                                <button data-toggle="modal" data-target="#modalXoaCDT" onClick={(e) => this.handleIdDropLoDat(e, index)}><img src={deleteIcon} style={{ float: 'right', marginRight: '20px' }}></img></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-6" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                    <div className="col-md text-right" style={{ fontSize: '14px' }}>
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.handleModalToggleCustom}>Chọn từ kho</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.openAdd}>Thêm mới</button>
                    </div>
                </div>

                <div class="modal fade" id="modalXoaCDT" tabindex="-1" role="dialog" aria-labelledby="modalXoaCDT" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                                <button data-dismiss="modal" className="btn btn-primary btn-sm" onClick={() => this.handleDrop(this.state.deleteLodat)}> Xóa</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" onClick={() => this.handleIdDropLoDat(0)} className="btn btn-secondary btn-sm"> Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <ModalSearchCDT
                    loadCDT={this.loadCDT}
                    tenLoaiGiayPhep={this.props.tenLoaiGiayPhep}
                    maHoSo={this.props.maHoSo}
                    onModalToggle={this.handleModalToggleCustom}
                    show={this.state.openModelCustom}
                    loadDataTable={this.loadDataTable}
                    dataValue={this.state.dataValue}
                    handleClickSetData={this.handleClickSetData}
                    checked={this.state.checked}
                    handleChangeCheck={this.handleChangeCheck}
                    loadDataTables={this.loadDataTables}
                    dataValues={this.state.dataValues}
                    handleClickSetDatas={this.handleClickSetDatas}
                ></ModalSearchCDT>
                <ModalCreateCDTCN
                    indexEdit={this.state.indexEdit}
                    data={this.state.chuDauTu}
                    chuDauTus={this.state.data}
                    onAddData={this.handleAdd}
                    onUpdateData={this.handleUpdate}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel}
                ></ModalCreateCDTCN>
            </div >
        )
    }
}