import React from 'react';
import moment from 'moment';
import { HandleClickViewLichSu } from './HandleClickViewLichSu';
import { DI_DOI } from '../../../../Constants/constants';

export default class LichSu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataPagi: [],
            openModel: false,
            recordsPerPage: 0,
            idGiayPhep: 0,
        }
    }
    componentDidUpdate() {
        if (this.props.lichSuGiayPhep !== undefined && this.props.lichSuGiayPhep !== this.state.data) {
            this.state.data = this.props.lichSuGiayPhep;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.giayPhep !== undefined && this.props.giayPhep.id !== this.state.idGiayPhep) {
            this.state.idGiayPhep = this.props.giayPhep.id;
            this.setState(this.state);
        }
    }

    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.setState({
            ...this.state,
            recordsPerPage: number
        })
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }
    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>
                <br></br>
                <br></br>
                <div class="row">
                    <div class="col-md-6">
                        <div className="row">

                            <div className="col-md-4">
                                <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '7px' }}>Tình trạng giấy phép</h6>
                            </div>

                            <div className="col-md-8">
                                <p style={{ fontSize: '14px', marginTop: '7px' }}>{this.props.giayPhep.tinhTrang}</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="row">

                            <div className="col-md-4">
                                <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '7px' }}>
                                    Loại giấy phép</h6>
                            </div>

                            <div className="col-md-8">
                                <p style={{ fontSize: '14px', marginTop: '7px' }}>{this.props.giayPhep.tenLoaiGiayPhep}</p>
                            </div>

                        </div>
                    </div>
                </div>

                <br></br>
                <h6 style={{ fontWeight: 'bold' }}>Lịch sử chỉnh sửa</h6>

                <table class="table table-bordered" style={{ marginTop: '5px' }}>
                    <thead>
                        <tr className="table-secondary text-center">
                            <th>STT</th>
                            <th>Nội dung</th>
                            <th>Ngày thực hiện</th>
                            <th>Tệp tin</th>
                            <th className="text-center">Xem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.loaiBienDong === parseInt(DI_DOI.ma) ? 'Cấp mới giấy phép' : item.tenLoaiBienDong + ' giấy phép'}</td>
                                    <td className="cell-number">{moment(new Date(item.ngayThongKe)).format('DD-MM-yyyy')}</td>
                                    {this.props.files != undefined && this.props.files != null && this.props.files.map((item1, index1) => {
                                        return (
                                            <td key={index1}>{item1.name}</td>
                                        )
                                    })}
                                    <td className="text-center">
                                        <HandleClickViewLichSu
                                            itemView={item}
                                            idGiayPhep={this.state.idGiayPhep}
                                        ></HandleClickViewLichSu>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                {/* <div className="">
                    <button type="button" class="btn btn-secondary-custom btn-sm">In ấn</button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary-custom btn-sm">Trở về</button>
                </div> */}
                <br></br>
            </div>
        )
    }
}