import React from 'react';

export default class ThongTinXayDungRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row">
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN XÂY DỰNG</h6>
                </div>

                <div>
                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Tổng diện tích sàn</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.tongDienTichSan} m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Diện tích xây dựng tầng một</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.dienTichXayDungTangMot} m&#178;</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chiều cao tầng một</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.chieuCaoTangMot}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chiều cao công trình</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.chieuCaoCongTrinh}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Số tầng</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.soTang}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Cốt nền</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.cotNen}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Mật độ xây dựng</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.matDoXayDung}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Hệ số sử dụng đất</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.heSoSuDungDat}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chỉ giới đường đỏ</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.chiGioiDuongDo}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chỉ giới xây dựng</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.chiGioiXayDung}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Màu sắc</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.mauSac}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chiều sâu công trình</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXayDung.chieuSauCongTrinh}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}