import React from 'react';
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';
import closeIcon from "../../../../Assets/icons/u1233.png";
import StringUtil from '../../../../utils/stringUtils';

export default class ModalCreateDanhMuc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            item: {},
            errors: {
                ten: ''
            },
        }
    }

    componentDidUpdate() {
        if (this.props.show !== this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
        if (this.props.editItem !== undefined && this.props.editItem !== this.state.item) {
            this.state.item = this.props.editItem;
            this.setState({ ...this.state })
        }
    }

    /*Add thông tin thẩm định*/
    setParamss = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.item[name] = value;
        this.setState({ ...this.state })
    }

    validation = async () => {
        let valid = true;
        let checkValidation = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.item.ten)) {
            this.state.errors.ten = 'Vui lòng nhập nội dung gợi ý';
            valid = false;
        } else {
            checkValidation = await this.checkDuplicate();
            if (checkValidation) {
                this.state.errors.ten = 'Nội dung gợi ý đã tồn tại!';
                valid = false;
            }
        }
        this.setState(this.state);
        return valid;

    }
    async checkDuplicate() {
        let valid;
        await AXIOS_INSTANCE.post(this.props.urlKiemTraDuplicate, this.state.item).then(res => {
            if (res.data.error_code === 'DUPLICATE_CODE') {
                valid = true;
            } else {
                valid = false;
            }
        })
        return valid
    }

    addItem = async (event) => {
        const result = await this.validation();
        if (result) {
            let message = "";
            if (this.state.item.id === 0) {
                message = "Thêm "
            } else {
                message = "Cập nhật "
            }
            await AXIOS_INSTANCE.post(this.props.url, this.state.item).then(res => {
                if (res.data !== null || res.data.error_code === 'SUCCESSFUL') {
                    this.handleCloseModal('');
                    toastr.success(message + this.props.itemName.toLowerCase() + ' thành công');
                    this.props.onPagi();
                }
            }).catch(error => {
                toastr.warning(message + this.props.itemName.toLowerCase() + 'không thành công');
                console.log(error);
            });
        }

    }

    componentDidMount() {
    }
    /*Tìm kiếm*/

    handleCloseModal = (key) => {
        this.setState({
            errors: {}
        })
        this.props.onModalToggle(key);
        this.props.clearDataModal();
    }
    render() {
        return (
            <>
                <Modal show={this.state.show} onHide={() => this.handleCloseModal('')} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                        <h5 class="modal-title" id="modalCreateDVTD" style={{ fontWeight: 'bold', color: "white" }}>{this.state.item.id === 0 ? "THÊM" : "CẬP NHẬT"} {this.props.itemName.toUpperCase()}</h5>
                        <button type="button" onClick={() => this.handleCloseModal('')} aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon}></img></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="ten" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung gợi ý</label>
                                    <input type="text" className="form-control" id="ten" name="ten"
                                        defaultValue={this.state.item.ten}
                                        onChange={this.setParamss} />
                                    <div style={Boolean(this.state.errors?.ten) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ten}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button onClick={this.addItem} type="button" data-dismiss="modal" class="btn btn-primary btn-lg" >Lưu</button>&nbsp;&nbsp;
                        <button onClick={() => this.handleCloseModal('')} type="button" class="btn btn-secondary btn-lg" >Hủy</button>
                    </div>
                </Modal>
            </>
        )
    }
}