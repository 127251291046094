import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../../api';
import { HoSoCuaToiMgtService } from '../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';

export default class FormSearchCDTCN extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                soCMND: '',
                maSoDoanhNghiep: '',
                ten: '',
                diaChiChiTiet: '',
                gioiTinh: 0,
                tenTinhThanh: '',
                loaiGiayTo: '',
                tenQuanHuyen: '',
                soGiayTo: '',
                tenPhuongXa: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5,
                nguonChuDauTu: 2,
            },
            pagination: {},
            selectedOption: '',
            pageIndex: 0,
            pageLength: 0,
            total: 0,
            checkedOptions: new Map(),
            maHoSo: this.props.maHoSo,
            idGiayPhep: [],
            data: []
        }
    }



    componentDidMount() {
    }

    componentWillUnmount() {
    }

    closeModal = (event) => {
        document.getElementById('closeModal').click();
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.search[name] = value;
        this.setState(this.state);
    }

    loadData = () => {
        let soCMND = this.state.search.soCMND
        new HoSoCuaToiMgtService().layThongTinCongDanByCMND(soCMND).then((res) => {
            if (res != null) {
                let datas = res.data.data.data;
                console.log(" datas : ", datas)
                this.setState({
                    data: datas || []
                })
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        console.log("this.state.data : ", this.state.data);
        return (
            <div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10">
                            <div className="form-group">
                                <input type="text" value={this.state.search.soCMND} className='form-control col-sm' id="soCMND" name="soCMND" onChange={this.setParams} />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div className="form-group">
                                <button className="btn btn-primary" onClick={() => this.props.loadDataTable(this.state.search.soCMND)}> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                <div className="col-md-12">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="table-secondary">
                                {/* <th>STT</th> */}
                                <th></th>
                                <th>Họ tên</th>
                                <th>Ngày sinh</th>
                                <th>Giới tính</th>
                                <th>Số giấy tờ</th>
                                <th>Địa chỉ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.dataValue.soCMND != null &&
                                <tr>
                                    <td>
                                        <input
                                            type="radio"
                                            onChange={e => this.props.handleChangeCheck(e)}
                                            defaultChecked={this.props.checked}
                                        ></input>
                                    </td>
                                    <td>{this.props.dataValue.tenCongDan}</td>
                                    <td>{this.props.dataValue.ngayThangNamSinh}</td>
                                    <td>{this.props.dataValue.tenGioiTinh == 1 ? 'Nam' : 'Nữ'}</td>
                                    {/* <td>{item.gioiTinh == 1 ? 'Nam' : 'Nữ'}</td> */}
                                    <td className="cell-number" >{this.props.dataValue.soCMND}</td>
                                    <td >{this.props.dataValue.diaChi}</td>

                                </tr>
                            }
                            {this.props.dataValue.soCMND == null &&
                                <tr>
                                    <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div className="text-right col-md-6">
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={this.props.handleClickSetData} className="btn btn-primary">Chọn</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}