import { Box, Button, createStyles, Grid, InputBase, InputLabel, makeStyles, Theme, TextField, Card, CardContent } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";
import { OverlayLoading } from '../../commons/components/overlayLoading/overlayLoading';
import { PageRequest } from "../../../api/baseService";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { ThongKeService } from "../../../api/service/ThongKeService";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
}

export interface BieuDoThongkeRespone {
    tongConHan : number;
    tongHetHan : number;
    thang : number;
} 

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;"
        },
    }),
);

export const BieuDoRenderComponent = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<BieuDoThongkeRespone[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);

    const dataDangHoatDong = [...rows.map((item) => item.tongDangHoatDong)];
    const dataTongHetHan = [...rows.map((item) => item.tongHetHan)];
    const dataTongThuHoi = [...rows.map((item) => item.tongThuHoi)];
    const dataTongHuy = [...rows.map((item) => item.tongHuy)]

    useEffect(() => {
        formik.setValues({
            request: {
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestBieuDoThongKeDTO,
        });
        namThongKeList();
        execute();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestBieuDoThongKeDTO,
        },
        onSubmit: () => execute(),
    });

    const execute = async () => {
        let request = {
            ...formik.values.request,
        } as RequestBieuDoThongKeDTO;
        dispatchLoading(showLoading());
        await new ThongKeService().bieuDoThongke(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
                setRows(res.data.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Đang hoạt động",
                backgroundColor: "#51A3A3",
                data: dataDangHoatDong,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hết hạn",
                backgroundColor: "#ffb229",
                data: dataTongHetHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Thu hồi",
                backgroundColor: "#8faadc",
                data: dataTongThuHoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hủy",
                backgroundColor: "#c9c9c9",
                data: dataTongHuy,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
           
        ],
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{nam: 0, name: 'Chọn năm'}];
        for(let i = namHienTai; i >= namHienTai - 5; --i){
            var temp = {nam: i, name: 'Năm ' + i};
            temps.push(temp);
        }
        setDataNamThongKe(temps);
    }
    
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Năm </InputLabel>
                        <Autocomplete options={dataNamThongKe}
                            value={{ nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0, name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.namThongKe", newValue?.nam);
                                formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <InputBase fullWidth
                                        {...params.inputProps}

                                    />
                                </div>
                            }}
                        />
                    </Grid>
                     {/* <Grid item xs={6} className="grid-item">
                        <InputLabel >Loại giấy phép</InputLabel>
                        <Autocomplete
                            value={{ ma: formik.values?.request?.maLoaiGiayPhep ? formik.values.request?.maLoaiGiayPhep : "", ten: formik.values?.request?.tenLoaiGiayPhep ? formik.values.request?.tenLoaiGiayPhep : "" }}
                            options={LIST_LOAIGIAYPHEP}
                            getOptionLabel={(option: any) => option.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maLoaiGiayPhep", newValue?.ma);
                                formik.setFieldValue("request.tenLoaiGiayPhep", newValue?.ten);
                            }}
                            renderInput={(params) => (
                                <TextField  {...params} variant="outlined" />
                            )}
                    />     
                   </Grid>              */}
                    <Grid item xs={3} className="grid-item">
                        <Button onClick={execute} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="grid-item">
                        <Card classes={classes}>
                            <CardContent>
                                <Bar data={dataBD} options={options} />
                            </CardContent>
                        </Card>
                        {/* <Paper>
                            <Chart
                            data ={data}
                            >
                                <ArgumentAxis />
                                <ValueAxis />

                                <BarSeries
                                    name="Còn hạn"
                                    valueField="tongConHan"
                                    argumentField="thang"
                                    color="#00c292"
                                />
                                <BarSeries
                                    name="Hết hạn"
                                    valueField="tongHetHan"
                                    argumentField="thang"
                                    color="orange"
                                />
                                <Animation />
                                <Legend position="bottom"
                                    rootComponent={(root: Legend.RootProps) => {
                                        return (
                                            <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                                {root.children}
                                            </Box>
                                        )
                                    }}
                                    itemComponent={(label: Legend.ItemProps) => {
                                        return (
                                            <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                                {label.children}
                                            </Box>
                                        )
                                    }} />
                                <Stack />
                            </Chart>
                        </Paper> */}
                    </Grid>
                </Grid>
            </form>
            <OverlayLoading />
        </div >
    )
}

