import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import closeIcon from "../../../../Assets/icons/u1233.png";
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../utils/stringUtils';
export default class ModalLoDatXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            donViHanhChinh: {
                id: 0,
                tenHuyenThiTP: '',
                tenPhuongXaTT: '',
            },
            show: false,
            errors: {
                tenHuyenThiTP: '',
                tenPhuongXaTT: ''
            }
        }
    }
    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
    }
    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle();
    }
    handleChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.state.donViHanhChinh[name] = value;
        this.state.errors = {
            soTo: '',
            soThua: ''
        }
        this.setState(this.state);
    }
    handleSubmit = () => {
        if (this.validation()) {
            this.props.onUpdateData(this.state.donViHanhChinh);
            this.handleCloseModal();
            toastr.success('Thêm mới đơn vị hành chính thành công');
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.donViHanhChinh.tenHuyenThiTP)) {
            this.state.errors.tenHuyenThiTP = 'Vui lòng nhập Huyện / Thị xã / Thành phố';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.donViHanhChinh.tenPhuongXaTT)) {
            this.state.errors.tenPhuongXaTT = 'Vui lòng nhập Phường / Xã / Thị trấn';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state.donViHanhChinh = {
            id: 0,
            tenHuyenThiTP: '',
            tenPhuongXaTT: '',
        }
        this.state.errors = {
            tenHuyenThiTP: '',
            tenPhuongXaTT: ''
        }
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI ĐƠN VỊ HÀNH CHÍNH CÔNG TRÌNH PHẢI ĐI QUA</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="tenHuyenThiTP" style={{ fontWeight: 'bold', fontSize: ' ' }}>Huyện / Thị xã / Thành phố  <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.donViHanhChinh.tenHuyenThiTP} className="form-control" id="tenHuyenThiTP" name="tenHuyenThiTP" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.tenHuyenThiTP) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenHuyenThiTP}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="tenPhuongXaTT" style={{ fontWeight: 'bold', fontSize: ' ' }}>Phường / Xã / Thị trấn <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.donViHanhChinh.tenPhuongXaTT} className="form-control" id="tenPhuongXaTT" name="tenPhuongXaTT" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.tenPhuongXaTT) ? { display: 'block' } : {}} className="invalid-feedback">
                                        {this.state.errors?.tenPhuongXaTT}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                     <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}