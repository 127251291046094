
import React  from 'react';
import 'toastr/build/toastr.min.css';
import ReactApexChart from'react-apexcharts'
import StringUtil from '../../../utils/stringUtils';
import { LIST_LOAIGIAYPHEP } from '../../Constants/constants';
import AXIOS_INSTANCE from '../../../api';
import { Card, CardContent, Theme } from '@material-ui/core';
import { Bar } from "react-chartjs-2";


export default class BieuDoRender extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = {
        nghiepVu: [],
        namThongTin: [],
        namThongKe: "",
        loaiGiayPhep: "",
        series: [],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12',],
          },
          yaxis: {
            title: {
              text: 'Số giấy phép'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " Giấy phép"
              }
            }
          }
        },
      };
    }
    
    
    componentDidMount() {
        this.nghiepVu();
        this.danhSachNam();
        this.bieuDoData();
    }

    bieuDoData = async() =>{
      const searchRequest = {
        namThongKe: StringUtil.isNullOrEmty(this.state.namThongKe) ? (new Date()).getFullYear() : this.state.namThongKe,
        loaiGiayPhep: this.state.loaiGiayPhep
      }
      await AXIOS_INSTANCE.post('lich-su-giay-phep/bieu-do',searchRequest).then(res => {
          if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
            this.setState({ series : res.data.data.data || [] });
          } else {
            this.setState({ series : [] });
          }
      }).catch(error => {
          this.setState({ series : [] });
      });
    };
    
    setParams = (event) => {
      let name = event.target.name;
      let value = event.target.value;
      this.setState({ [name]: value })
    }

    nghiepVu = () => {
      this.setState({ nghiepVu : LIST_LOAIGIAYPHEP || [] });
    }
    danhSachNam = () =>{
        const minOffset = 0, maxOffset = 20;
        const thisYear = (new Date()).getFullYear();
        const allYears = [];
        for(let x = 0; x <= maxOffset; x ++) {
            const year = thisYear - x;
            const dataYear = { key : year , value: "Năm " + year};
            allYears.push(dataYear);
        }
      this.setState({ namThongTin :allYears || [] , namThongKe : thisYear});
    }
    render() {
      return (
        <React.Fragment>
            <h6 className="mt-4" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG</h6>
                <br></br>
                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Năm thống kê</label>
                            <select className="form-control" id="sel1" name="namThongKe" onChange={this.setParams}>
                                {
                                    this.state.namThongTin.map((e) => {
                                        return <option value={e.key}>{e.value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại giấy phép</label>
                            <select className="form-control" id="sel1" name="loaiGiayPhep" onChange={this.setParams}>
                                <option value="">--- Chọn ---</option>
                                {
                                    this.state.nghiepVu.map((e) => {
                                        return <option value={e.ma}>{e.ten}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>&emsp;&emsp;&emsp;</label>
                            <button className="btn btn-secondary-custom btn-lg" style={{ width: '120px' }} onClick={this.bieuDoData}>Tổng hợp</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div id="chart" className="col-md-12 mt-5">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
                    </div>
                </div>
        </React.Fragment>
      );
    }
  }