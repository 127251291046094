import React from 'react';
import deleteIcon from '../../../../../Assets/icons/u626.png';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Pagination } from '@material-ui/lab';
import ModalLoDatXD from './ModalLoDatXD';
import { Box, NativeSelect } from '@material-ui/core';
import { ToastHeader } from 'react-bootstrap';
import ModalXacNhanXoa from './ModalXacNhanXoa';

export default class TableLoDatXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],

            openModalXacNhan: false,
            openModel: false,
            deleteItemId: '',
            recordsPerPage: 0,
            congTrinhDA: {
                loDatXayDungs: [],
                //drop
                loDatXayDungDropDTOs: [],
            }
        }
    }

    componentDidUpdate() {
        if (this.props.congTrinhDA !== undefined && this.props.congTrinhDA.loDatXayDungs !== this.state.data) {
            this.state.data = this.props.congTrinhDA.loDatXayDungs
            this.setState(this.state)
            this.handleSearch();
        }
        if (this.props.congTrinhDA !== undefined && this.props.congTrinhDA.loDatXayDungDropDTOs != this.state.dataDrop) {
            this.state.dataDrop = this.props.congTrinhDA.loDatXayDungDropDTOs;
            this.setState(this.state);
            this.handleSearch();
        }
    }
    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }
    handleSearch = () => {
        const { pageNumber, pageSize, data } = this.state;
        let offset = 0;
        if (pageNumber === 1) {
            offset = 0;
        } else if (pageNumber > 0 && pageSize > 0) {
            offset = ((pageNumber - 1) * pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = data?.slice(offset, this.state.pageSize + offset);
        this.setState(this.state)
        this.handleRecordsPerPage(this.state.dataPagi)
    }
    //handle modal
    handleIdDropItem = (e, value) => {
        this.setState({
            deleteItemId: value
        })
        this.handleModalToggle('xacNhan');
    }

    handleAddItem = (value) => {
        const { data } = this.state;
        const newData = [{ ...value }, ...data];
        this.props.onUpdateDataState("loDatXayDungs", newData);
        this.setState({
            data: [...data, ...newData],
        })
    }
    setDataParam = (name, value) => {
        this.state.congTrinhDA[name] = value;
        this.setState(this.state);
    }
    handleDropItem = async (index) => {
        const { data, dataDrop } = this.state;
        let newDataDrop;
        let theDataDrop = [];
        if (dataDrop) {
            theDataDrop = [...dataDrop];
        }
        if (data[index]?.id > 0) {
            newDataDrop = [{ ...data[index] }, ...theDataDrop];
        }
        await this.props.onUpdateDataState("loDatXayDungDropDTOs", newDataDrop);
        const newData = data.filter((item, j) => index != j);
        await this.props.onUpdateDataState("loDatXayDungs", newData);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa lô đất xây dựng thành công')
    }
    handleModalToggle = (key) => {
        if (key === "themMoi") {
            this.setState({
                openModel: !this.state.openModel
            });
        }
        if (key === "xacNhan") {
            this.state.openModalXacNhan = !this.state.openModalXacNhan;
            this.setState(this.state)
        }
    }
    render() {
        return (
            <div>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Số Tờ</th>
                            <th>Số thửa</th>
                            <th>Một phần thửa</th>
                            <th className="text-center">Xóa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.soTo}</td>
                                    <td>{item.soThua}</td>
                                    <td className="text-center">
                                        <input type="checkbox" checked={item.motPhanThua} readOnly={true} />
                                    </td>
                                    <td align="center"><button onClick={(e) => this.handleIdDropItem(e, index)}><img src={deleteIcon}></img></button></td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-9" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                    <div className="col-md text-right" style={{ fontSize: '14px' }}>
                        <button type="button" className="btn btn-primary btn-lg" onClick={() => this.handleModalToggle('themMoi')}>Thêm mới</button>
                    </div>
                </div>

                {
                    <ModalXacNhanXoa
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModalXacNhan}
                        onDropItem={this.handleDropItem}
                        itemId={this.state.deleteItemId}
                    ></ModalXacNhanXoa>
                }
                {
                    this.state.openModel &&


                    <ModalLoDatXD
                        onUpdateData={this.handleAddItem}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalLoDatXD>
                }
            </div>
        )
    }
}