import React from 'react';
import { DI_DOI } from '../../../../../Constants/constants';

export default class ViTriXayDungRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row">
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>{this.props.loaiBienDong === parseInt(DI_DOI.ma)? 'VỊ TRÍ XÂY DỰNG HIỆN TẠI':'VỊ TRÍ XÂY DỰNG'}</h6>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-3 text-right">
                        <p style={{ fontWeight: 'bold' }}>Địa chỉ</p>
                    </div>
                    <div className="col-md">
                        <p>{this.props.noiDungGiayPhep.giayPhep.viTriXayDung}</p>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-3 text-right">
                        <p style={{ fontWeight: 'bold' }}>Địa chỉ cũ</p>
                    </div>
                    <div className="col-md">
                        <p>  {this.props.noiDungGiayPhep.viTriXayDung.diaChiCu
                            &&
                            <>
                                {this.props.noiDungGiayPhep.viTriXayDung.diaChiCu}
                            </>
                        }</p>
                    </div>
                </div>
                {
                    (this.props.noiDungGiayPhep.giayPhep.maLoaiGiayPhep !== 2
                        && this.props.noiDungGiayPhep.giayPhep.maLoaiGiayPhep !== 5)
                    &&

                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-3 text-right">
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Lô đất xây dựng</p>
                        </div>
                        <div className="col-md-9">
                            <table class="table table-bordered">
                                <thead>
                                    <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                        <th>STT</th>
                                        <th>Số tờ</th>
                                        <th>Số thửa</th>
                                        <th>Một phần thửa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.noiDungGiayPhep.loDatXayDungs?.length > 0 ? this.props.noiDungGiayPhep.loDatXayDungs?.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.soTo}</td>
                                                    <td>{item.soThua}</td>
                                                    <td>{item.motPhanThua}</td>
                                                </tr>
                                            </>
                                        )
                                    }) :
                                        <tr>
                                            <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    (this.props.noiDungGiayPhep.giayPhep.maLoaiGiayPhep === '2'
                        || this.props.noiDungGiayPhep.giayPhep.maLoaiGiayPhep === '5')
                    &&

                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-3 text-right">
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Các đơn vị hành chính công trình phải đi qua</p>
                        </div>
                        <div className="col-md-9">
                            <table class="table table-bordered">
                                <thead>
                                    <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                        <th>STT</th>
                                        <th>Huyện / Thị xã / Thành phố</th>
                                        <th>Phường / Xã / Thị trấn</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.noiDungGiayPhep.donViHanhChinhs?.length > 0 ? this.props.noiDungGiayPhep.donViHanhChinhs?.map((item, index) => {

                                        return (
                                            <>
                                                <tr>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.tenHuyenThiTP}</td>
                                                    <td>{item.tenPhuongXaTT}</td>
                                                </tr>
                                            </>
                                        )
                                    }) :
                                        <tr>
                                            <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        )
    }
}