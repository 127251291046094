export const SYSTEM_CODE = "H13.16";
export const SYSTEM_NAME = "Sở Xây dựng";
export const CO_QUANS = [
    {
        ten: "Sở Xây dựng",
        ma: "H13.16"
    }
]
// export const CO_QUANS = [
//     {
//         name: "Sở Xây dựng",
//         code: "000.00.16.H13"
//     }
// ]