import React from 'react';

export default class QuyenSuDungDatRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>GIẤY TỜ VỀ QUYỀN SỬ DỤNG ĐẤT</h6>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Diện tích khuôn viên đất</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.quenSuDungDat.dienTichKhuonVienDat}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Diện tích khuôn viên hiện hữu</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.quenSuDungDat.dienTichKhuonVienHienHuu}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Diện tích ban công</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.quenSuDungDat.dienTichBanCong}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Diện tích chênh lệch</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.quenSuDungDat.dienTichChenhLech}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Diện tích giữ lại</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.quenSuDungDat.dienTichGiuLai}</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Mật độ cho phép xây dựng</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.quenSuDungDat.matDoChoPhepXayDung}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Cốt nền</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.quenSuDungDat.cotNen}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Lý do chênh lệch</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.quenSuDungDat.lyDoChenhLech}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
            </>
        )
    }
}