import React from "react";
import TableBaoCaoCPXD from './TableBaoCaoCPXD';
import FormBaoCaoCPXD from './FormBaoCaoCPXD';
import AXIOS_INSTANCE from "../../../../api";
import PaginationCustom from "../../CapPhepDungChung/PaginationGPXD";


export default class BaoCaoCPXDRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      pageIndex: 0,
      pageLength: 0,
      pageNumber: 0,
      pageSize: 10,
      dataInfo: {
        "pageSize": 10,
        "pageNumber": 0,
        "soGiayPhep": "",
        "loaiGiayPhep": "",
        "ngayCapTu": 0,
        "ngayCapDen": 0,
        "tinhTrang": "",
        "chuDauTu": "",
        "tinhTp": "",
        "huyenThi": "",
        "phuongXa": "",
        "duongSoNha": "",
        "donViCapPhep": "",
        "hoatDong": 1
      }
    }
  }

  componentDidMount() {
    this.onPagi();
  }

  fetchSearch = keySearch => {
    AXIOS_INSTANCE.post('giay-phep-xay-dung/bao-cao', keySearch).then(res => {
      if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
        const datas = res.data.data;
        console.log(datas);
        const total = datas.total;
        const pageIndex = datas.pageIndex + 1;
        const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
        this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
      } else {
        this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
      }
    }).catch(error => {
      this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
    });
  }

  onSearch = dataInfo => {
    this.setState({ dataInfo: dataInfo, pageNumber: 0, pageSize: 10 }, () => {
      this.fetchSearch({
        ...dataInfo,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      });
    });
  }

  onPagi = () => {
    const dataInfo = this.state.dataInfo;
    this.fetchSearch(
      {
        ...dataInfo,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      });
  }

  onSelectPage = page => {
    this.setState({ pageNumber: page }, this.onPagi);
  }
  onChangePageSize = pageSize => {
    this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
  }

  render() {
    const {pageNumber, pageIndex, pageLength, pageSize, list, total} = this.state;
    return (
      <div className="dash-content">
      <>
        <div className="row">
          <FormBaoCaoCPXD onSearch={this.onSearch} />
        </div>
        <TableBaoCaoCPXD
         pageIndex={pageNumber==0?pageNumber:(pageNumber-1)*pageSize}
          list={list} 
        ></TableBaoCaoCPXD>
        <PaginationCustom
          pageSize={pageSize}
          pageLength={pageLength}
          pageIndex={pageIndex}
          total={total}
          pageNumber={pageNumber}
          onSelectPage={this.onSelectPage}
          onChangePageSize={this.onChangePageSize}
          />
      </>
      </div>
    );
  }
}
