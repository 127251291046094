import AXIOS_INSTANCE from "..";

export default class GiayPhepService {
    //Add By IN
    getChiTietById = (requestBody) => {
        return AXIOS_INSTANCE.post('giay-phep-xay-dung/chi-tiet-by-id', requestBody);
    }
    getByMaSoGiayPhep = (requestBody) => {
        return AXIOS_INSTANCE.post('giay-phep-xay-dung/get-by-ma-so-giay-phep', requestBody);
    }
    downloadFileDanhSachGiayPhep = (requestBody) => {
        return AXIOS_INSTANCE.get('export/giay-phep-xay-dung', {
            responseType: 'blob',
            params: requestBody
        });
    }
    downloadFileBaoCaoGiayPhep = (requestBody) => {
        return AXIOS_INSTANCE.get('export/bao-cao-giay-phep', {
            responseType: 'blob',
            params: requestBody
        });
    }
    downloadFileThongKeGiayPhep = (requestBody) => {
        return AXIOS_INSTANCE.get('export/thong-ke-giay-phep', {
            responseType: 'blob',
            params: requestBody
        });
    }
}

