import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import closeIcon from "../../../Assets/icons/u1233.png";


export default class ModalClearAll extends React.Component {
    constructor(props) {
        super(props);

    }

    handleCloseModal = (key) => {
        this.props.onModalToggle(key);
    }
    handleClearAll = () => {
        this.props.onClearData();
        toastr.success("Xoá thành công")
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.handleCloseModal('')} on size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                    <button type="button" class="close" onClick={() => this.handleCloseModal('')} aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12 text-center">
                            <h3>Xác nhận xóa tất cả thông tin đã nhập?</h3>
                        </div>
                        <br></br>
                        <div class="col-md-12">
                            <div class="row">
                                <div className="text-right col-md-6">
                                    <button className="btn btn-primary btn-sm" onClick={this.handleClearAll}> Xóa</button>
                                </div>
                                <div className="col-md-6">
                                    <button onClick={() => this.handleCloseModal('')} className="btn btn-secondary btn-sm"> Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}