import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import closeIcon from "../../../../Assets/icons/u1233.png";
import AXIOS_INSTANCE from '../../../../api/index';


export default class ModalXacNhanXoa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show :  false,
        }
    }
    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
    }
    handleCloseModal = (key) => {
        this.props.onModalToggle(key);
    }

    handleDropItem = (event) => {
        const url = this.props.url + this.props.deleteItemId;
        AXIOS_INSTANCE.post(url).then(res => {
            if (res != null) {
                toastr.success('Xóa '+ this.props.itemName.toLowerCase() +' thành công');
                this.props.onPagi();
                this.handleCloseModal('')
            } else {
                toastr.warning('Xóa '+this.props.itemName.toLowerCase()+' không thành công');
            }
        }).catch(error => {
            toastr.warning('Xóa '+this.props.itemName.toLowerCase()+' không thành công');
            console.log(error);
        });
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={()=>this.handleCloseModal('')} on size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                    <button type="button" class="close" onClick={()=>this.handleCloseModal('')} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12 text-center">
                            <h3>Xác nhận xóa thông tin này?</h3>
                        </div>
                        <br></br>
                        <div class="col-md-12">
                            <div class="row">
                                <div className="text-right col-md-6">
                                    <button  className="btn btn-primary btn-sm" onClick={this.handleDropItem}> Xóa</button>
                                </div>
                                <div className="col-md-6">
                                    <button onClick={()=>this.handleCloseModal('')} className="btn btn-secondary btn-sm"> Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}