import { Box, IconButton, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { Cell, CheckIconDeActive, Row } from "../../../../App/commons/components/table/tableCommons";
import DateUtil from "../../../../App/commons/components/utils/dateUtils";
import { CustomInput } from "../../../../App/Components/CapPhepDungChung/ComponentCustom/customInput";
import { User } from "../../../../model/user";

interface IModalProps {
    openEditDialog: (itemSelected: User) => void;
    open: boolean;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface IDataListProps {
    dataList: User[];
    deleteItem: (userId: string) => void;
    unlockUser: (userId: string, userLocked: boolean) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataListUser = (props: IDataListProps) => {
    const [rows, setRows] = useState<User[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Tên đăng nhập
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="25%">
                                Tên đầy đủ
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Đăng nhập gần đây
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Hoạt động
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Khóa tài khoản
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <Row key={row.userId}>
                                <Cell scope="row" align="center">
                                    {i + 1}
                                </Cell>
                                <Cell align="left">{row.userName}</Cell>
                                <Cell align="left">{row.fullName}</Cell>
                                <Cell align="center">{DateUtil.toDDMMYYYY_HH_MM_SS(row.lastSigin)}</Cell>
                                <Cell align="center">{row.isActive && <CheckIconDeActive />}</Cell>
                                <Cell align="center">{row.userLocked && <CheckIconDeActive />}</Cell>
                                <Cell align="center">
                                    <Box display="flex" justifyContent="center">
                                        <Box color="green" visibility={Boolean(row.userLocked) ? "visible" : "hidden"}>
                                            {Boolean(row.userLocked) ? (
                                                <IconButton
                                                    title="Mở khóa tài khoản"
                                                    color="inherit"
                                                    aria-label="edit"
                                                    size="small"
                                                    onClick={() => {
                                                        props.unlockUser(row.userId, row.userLocked);
                                                    }}
                                                >
                                                    <LockOpenIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton aria-label="none" size="small">
                                                    <LockOpenIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                        <IconButton
                                            color="primary"
                                            aria-label="edit"
                                            size="small"
                                            onClick={() => {
                                                props.modalEdit.openEditDialog(row);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            size="small"
                                            onClick={() => {
                                                props.deleteItem(row.userId);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={props.pageProps.onChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {props.pageProps.page * rowsPerPage + 1} - {props.pageProps.page * rowsPerPage + rowsPerPage} / {props.pageProps.count}
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};
