import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../../utils/stringUtils';

export default class ModalLoDatXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loDat: {
                id: 0,
                soTo: '',
                soThua: '',
                motPhanThua: false,
            },
            show: false,
            errors: {
                soTo: '',
                soThua: ''
            }
        }
    }
    componentDidUpdate() {
        // if (this.props.show != this.state.show) {
        //     this.setState({
        //         show: this.props.show
        //     });
        // }
    }
    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle('themMoi');
    }
    handleChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.state.loDat[name] = value;
        this.state.errors = {
            soTo: '',
            soThua: ''
        }
        this.setState(this.state);
    }
    handleSubmit = () => {
        if (this.validation()) {
            this.props.onUpdateData(this.state.loDat);
            this.handleCloseModal();
            toastr.success('Thêm mới lô đất xây dựng thành công');
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.loDat.soTo)) {
            this.state.errors.soTo = 'Vui lòng nhập số tờ';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.loDat.soThua)) {
            this.state.errors.soThua = 'Vui lòng nhập số thửa';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state.loDat = {
            id: 0,
            soTo: '',
            soThua: '',
            motPhanThua: false
        }
        this.state.errors = {
            soTo: '',
            soThua: ''
        }
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI LÔ ĐẤT XÂY DỰNG</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="soTo" style={{ fontWeight: 'bold', fontSize: ' ' }}>Số tờ  <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.loDat.soTo} className="form-control" id="soTo" name="soTo" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.soTo) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.soTo}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="soThua" style={{ fontWeight: 'bold', fontSize: ' ' }}>Số thửa <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.loDat.soThua} className="form-control" id="soThua" name="soThua" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.soThua) ? { display: 'block' } : {}} className="invalid-feedback">
                                        {this.state.errors?.soThua}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-check">
                                    <input type="checkbox" defaultChecked={this.state.loDat.motPhanThua} className='form-check-input' value={this.state.loDat.motPhanThua} id="motPhanThua" name="motPhanThua" onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="motPhanThua">Một phần thửa</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}