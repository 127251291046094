import React from 'react';
import { CreateGiayPhepDTO } from '../../../../../api/Init/CapPhepXayDungFinal';
import ChiTietGPXDWrapper from '../../ChiTietGPXD/SubChiTietGPXD/SubNoiDungCapPhep/ChiTietGPXDWrapper';
import moment from 'moment';
import UploadFilesCommon from '../../../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../../DateInputCustome.css";

export default class NoiDungThuHoi extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            noiDungGiayPhep: {
                ...CreateGiayPhepDTO,
                thongTinThuHoi : {
                    soQDThuHoi: '',
                    ngayThuHoi: 0,
                    lyDoThuHoi: '',
                    nguoiKy: '',
                    ghiChu: '',
                }
            },
            search: {
                keywords: this.props.idGiayPhep,
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5
            },
            errors: {}
        }
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
    }
    setParams = (event, key) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        if (key == 'thongTinThuHoi') {
            if (type === 'date') {
                if (!this.state.noiDungGiayPhep.thongTinThuHoi || this.state.noiDungGiayPhep.thongTinThuHoi === null || this.state.noiDungGiayPhep.thongTinThuHoi === undefined) {
                    this.state.noiDungGiayPhep.thongTinThuHoi = {}
                }
                this.state.noiDungGiayPhep.thongTinThuHoi[name] = new Date(value).getTime();
            } else {
                if (!this.state.noiDungGiayPhep.thongTinThuHoi || this.state.noiDungGiayPhep.thongTinThuHoi === null || this.state.noiDungGiayPhep.thongTinThuHoi === undefined) {
                    this.state.noiDungGiayPhep.thongTinThuHoi = {}
                }
                this.state.noiDungGiayPhep.thongTinThuHoi[name] = value;
            }
        }
        this.props.onUpdateDataState('thongTinThuHoi', this.state.noiDungGiayPhep.thongTinThuHoi);
    }
    handleChangeNgayThuHoi(date) {
        let name = "ngayThuHoi";
        var longFormat = date * 1;
        if (!this.state.noiDungGiayPhep.thongTinThuHoi ||
            this.state.noiDungGiayPhep.thongTinThuHoi === null ||
            this.state.noiDungGiayPhep.thongTinThuHoi === undefined) {
            this.state.noiDungGiayPhep.thongTinThuHoi = {}
            this.state.noiDungGiayPhep.thongTinThuHoi[name] = longFormat;
        } else {
            this.state.noiDungGiayPhep.thongTinThuHoi = {}
            this.state.noiDungGiayPhep.thongTinThuHoi[name] = longFormat;
        }
        this.props.onUpdateDataState('thongTinThuHoi', this.state.noiDungGiayPhep.thongTinThuHoi);
    }
    render() {
        const CustomInputNgayThuHoi = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: '245px' }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div className="col-md-12" style={{ float: 'left' }}>
                {this.state.noiDungGiayPhep !== null &&
                    <ChiTietGPXDWrapper
                        renderViTriDiDoi={true}
                        loaiBienDong={this.state.noiDungGiayPhep.giayPhep.loaiBienDong}
                        noiDungGiayPhep={this.state.noiDungGiayPhep}
                    ></ChiTietGPXDWrapper>
                }
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN THU HỒI</h6>
                </div>
                <br></br>
                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="soQDThuHoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số quyết định thu hồi <span style={{ color: 'red' }}>(*)</span></label>
                            <input type="text" className="form-control" id="soQDThuHoi" name="soQDThuHoi"
                                value={this.state.noiDungGiayPhep?.thongTinThuHoi?.soQDThuHoi} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinThuHoi")} />
                            <div style={Boolean(this.state.errors?.soQDThuHoi) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.soQDThuHoi}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="ngayThuHoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày thu hồi <span style={{ color: 'red' }}>(*)</span></label>
                            {/* <input type="date" className="form-control" id="ngayThuHoi" name="ngayThuHoi"
                                value={this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi ? moment(new Date(this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinThuHoi")} /> */}
                            <DatePicker
                                customInput={<CustomInputNgayThuHoi />}
                                selected={this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi}
                                onChange={this.handleChangeNgayThuHoi.bind(this)}
                                value={this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi === 0 ? moment(this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi).format("DD/MM/YYYY") : null}
                                name="ngayThuHoi"
                                placeholderText={"dd/mm/yyyy"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                locale={vi}
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                    }
                                }}
                            >
                            </DatePicker>
                            <div style={Boolean(this.state.errors?.ngayThuHoi) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayThuHoi}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="nguoiKy" style={{ fontWeight: 'bold', fontSize: '14px' }}>Người ký</label>
                            <input type="text" className="form-control" id="nguoiKy" name="nguoiKy"
                                value={this.state.noiDungGiayPhep?.thongTinThuHoi?.nguoiKy} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinThuHoi")} />
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="lyDoThuHoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lý do thu hồi</label>
                            <textarea type="text-area" className="form-control" id="lyDoThuHoi" name="lyDoThuHoi"
                                rows="3"
                                value={this.state.noiDungGiayPhep?.thongTinThuHoi?.lyDoThuHoi} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinThuHoi")} />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                            <textarea type="text-area" className="form-control" id="ghiChu" name="ghiChu"
                                rows="3"
                                value={this.state.noiDungGiayPhep?.thongTinThuHoi?.ghiChu} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinThuHoi")} />
                        </div>
                    </div>
                </div>
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep?.thongTinThuHoi?.files}
                    fileTrash={this.state.noiDungGiayPhep?.thongTinThuHoi?.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
            </div>
        )
    }
}