import React from 'react';
import 'toastr/build/toastr.min.css';
import CHBMModal from './CHBMModal';
export default class CHBMForm extends React.Component {

    state = {
        trangThai: true,
        tenBieuMau: "",
        idReset: 0,
        cauHinhBieuMau : {
            tenBieuMau: "",
            maLinhVuc: "",
            maNghiepVu: "",
            trangThai: true,
            bieuMauDinhKem: [
                    {
                        tenHienThi: "",
                        tenTapTin: "",
                        loaiTapTin: "",
                        idTepDinhKem: "",
                        trangThai: true
                    }
            ],
        }
    }

    btnRef = React.createRef();

    componentDidMount() {
        
    }
    handleTuKhoaChange = e => {
        this.setState({ tenBieuMau: e.target.value });
    }
    setParamCheckBox = e => {
        let value = e.target.checked;
        this.setState({ trangThai: value });
    }
    onClickReset = () =>{
        this.setState({ idReset: new Date().getTime() });
    }

    onSearch = () => {
        const dataInfo = {
            "pageNumber": 0,
            "pageSize": 10,
            "tenBieuMau": this.state.tenBieuMau,
            "trangThai": this.state.trangThai
        };
        this.props.onSearch(dataInfo);
    }

    render() {
        return (
            <React.Fragment>
                <CHBMModal onSearch={this.onSearch} idReset={this.state.idReset}></CHBMModal>
                <br></br>
                <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Danh Mục Biểu Mẫu</h6>
                <br></br>
                <div class="row">
                    <div class="col-md-12">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Từ khóa</label>
                            <input type="text" className="form-control" id="idTuKhoa" placeholder="Nhập từ khóa tìm kiếm" onChange={this.handleTuKhoaChange} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-left">
                        <div className="form-group">
                            <input type="checkbox" onChange={this.setParamCheckBox} defaultChecked="true"/> Hoạt động
                        </div>
                    </div>
                    <div class="col-md-6 text-right">
                        <div className="form-group">
                            <button className="btn btn-secondary-custom mr-1" style={{ width: '120px' }} onClick={this.onSearch}>Tìm kiếm</button>
                            <button type="button"  data-toggle="modal" data-target="#CHBMModal" className="btn btn-secondary-custom ml-1" style={{ width: '120px' }} onClick={this.onClickReset}> Thêm mới</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}