export const CreateGiayPhepDTO = {
    loDatXayDungs: [],
    loDatXayDungDropDTOs: [],
    thongTinThamDinhs: [],
    thongTinThamDinhDrops: [],
    donViHanhChinhs: [],
    donViHanhChinhDrops: [],
    congTrinhDAs: [],
    congTrinhDADrops: [],
    chuDauTus: [],
    chuDauTuDrops: [],
    giayPhep: {},
    pthongTinCongTrinh: {},
    thongTinXayDung: {},
    viTriXayDung: {},
    thongTinXDCTTheoTuyen: {},
    thongTinSuaChua: {},
    quenSuDungDat: {},
    hangMucs: [],
    donViChuNhiemThietKe: {},
    hongTinDeXuat: {},
    thongTinCongTrinhNgam: {},
    files: [],
    fileTrash: [],
    thongTinThuHoi: {},
    viTriDiDoi:{},
    loDatXayDungDiDoi:[],
    loDatXayDungDiDoiDropDTOs:[]
}