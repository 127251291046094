import isEqual from 'lodash/isEqual';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import closeIcon from '../../../Assets/icons/u1233.png';
export default class CHBMDeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idDelete : this.props.idDelete
        }
    }
    componentDidMount() {
        this.hiddenFileInput = React.createRef();
    }
    componentDidUpdate(prevProps) {
        const { idDelete } = this.props;
        if (!isEqual(prevProps.idDelete, idDelete)) {
            this.setState({
                idDelete: idDelete,
            });
        }
    }
    deleteBieuMau = async () => {
       const url = `danh-muc-bieu-mau/xoa/${this.state.idDelete}`;
       await AXIOS_INSTANCE.get(url).then(res => {
            if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                toastr.success('Xóa biểu mẫu thành công!');
            } else {
                toastr.warning('Xóa biểu mẫu không thành công!');
            }
          }).catch(error => {
            toastr.warning('Xóa biểu mẫu không thành công!');
          });
        this.props.onSearch();
        this.hiddenFileInput.current.click();
    }

    render() {
        return (
            <React.Fragment>
                <div class="modal fade" id="ModalDelete" tabindex="-1" role="dialog" aria-labelledby="ModalDelete" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin biểu mẫu này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                            <button type="button" style={{fontSize: "14px"}} onClick={this.deleteBieuMau} class="btn btn-danger btn-sm">Xóa</button>
                                            <button data-dismiss="modal" className="btn btn-secondary btn-sm" ref={this.hiddenFileInput} style={{display:'none'}}/>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" className="btn btn-secondary btn-sm">Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment>
        )
    }
}