
import { useHistory } from 'react-router';
import 'toastr/build/toastr.min.css';
import InAnDropdown from '../InAnNghiepVu/InAnDropdown';

const ButtonGroupGiayPhep = (props) => {
    const history = useHistory();
    return (
        <div className="">
            <button type="button" onClick={props.handleAddOrUpdate} class="btn btn-primary" style={{ fontSize: '14px', width: '70px' }}>Lưu</button>&nbsp;&nbsp;
            {!props.isNhapDauKy &&
                <InAnDropdown class="btn btn-primary" maHoSo={props.maHoSo} maLoaiGiayPhep={props.maLoaiGiayPhep} isNhapDauKy={props.isNhapDauKy} />
            }
            <button type="button" class="btn btn-primary" onClick={() => props.handleModalToggle("kiem-tra-ngan-chan")} style={{ fontSize: '14px', width: '150px' }}>Kiểm tra ngăn chặn</button>&nbsp;&nbsp;
            {props.idGiayPhep ? (
               <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalXMTD" style={{ fontSize: '14px', width: '150px' }}>Xác minh thực địa </button>
               
               ):(
                <button disabled type="button" class="btn btn-primary" data-toggle="modal" data-target="#" style={{ fontSize: '14px', width: '150px',backgroundColor:"GrayText" }}>Xác minh thực địa </button>
               )
            }&nbsp;&nbsp;
            
            <button type="button" onClick={() => props.handleModalToggle("clear-all")} class="btn btn-danger" style={{ fontSize: '14px', width: '70px' }}>Xóa</button>&nbsp;&nbsp;
            <a class="btn btn-primary" onClick={() => history.go(-1)} style={{ fontSize: '14px', width: '70px' }}>Trở về</a>&nbsp;&nbsp;
        </div>
    );

}
export default ButtonGroupGiayPhep;