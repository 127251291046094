import React from 'react';
import closeIcon from "../../../../Assets/icons/u1233.png";
import deleteIcon from '../../../../Assets/icons/u626.png';
import editIcon from '../../../../Assets/icons/u624.png';
import AXIOS_INSTANCE from '../../../../api/index';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PaginationCustom from '../../CapPhepDungChung/PaginationGPXD';
import StringUtil from '../../../../utils/stringUtils';
import { Modal } from 'react-bootstrap';

export default class DMLBDRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            total: 0,
            pageIndex: 0,
            pageLength: 0,
            pageNumber: 0,
            pageSize: 10,
            dataInfo: {
                keywords: '',
                hoatDong: 0,
                pageSize: 10,
                pageNumber: 0,
            },
            errors: {
                ma: '',
                ten: ''
            },
            show: false,
            submit: false
        }
    }

    componentDidMount() {
        this.onPagi();
    }

    setParamss = (event) => {
        let name = event.target.name;
        let type = event.target.type;
        let value = event.target.value;
        if (type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        this.state[name] = value;
        this.state.submit = false;
        this.setState(this.state)
    }

    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.ten)) {
            this.state.errors.ten = 'Vui lòng nhập tên';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(this.state.ma)) {
            this.state.errors.ma = 'Vui lòng nhập mã';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }

    addOrUpdate = () => {
        this.state.submit = true;
        if (this.validation()) {
            const data = JSON.stringify({
                id: this.state.id,
                ma: this.state.ma,
                ten: this.state.ten,
                trangThai: this.state.trangThai
            });
            AXIOS_INSTANCE.post('danh-muc/loai-ban-do/them-moi', data).then(res => {
                if (res.data != null && res.data.error_code == 'SUCCESSFUL') {
                    this.handleCloseModal();
                    toastr.success('Thêm loại bản đồ thành công');
                    this.onSearch();
                    this.state.submit = false;
                } else {
                    if(res.data != null && res.data.error_code == 'DUPLICATE_CODE'){
                        toastr.warning('Trùng mã loại bản đồ');
                    } else{
                        toastr.warning('Thêm loại bản đồ không thành công');
                    }
                    this.state.submit = false;
                }
            }).catch(error => {
                toastr.warning('Thêm loại bản đồ không thành công');
                console.log(error);
            });
        }

    }

    editById(item) {
        this.state.id = item.id;
        this.state.ma = item.ma;
        this.state.ten = item.ten;
        this.state.trangThai = item.trangThai;
        this.state.show = true;
        this.state.submit = false;
        this.setState(this.state);
    }

    clearDataModal = () => {
        this.setState({
            id: 0,
            ma: '',
            ten: '',
            trangThai: 0
        });
    }

    handleId(value) {
        this.state.deleteId = value;
        this.setState(this.state)
    }

    deleteById = (event) => {
        this.onSearch();
        if (event) event.preventDefault();
        this.state.submit = false;
        this.setState(this.state);
        const url = 'danh-muc/loai-ban-do/xoa/' + this.state.deleteId;
        AXIOS_INSTANCE.post(url).then(res => {
            if (res != null) {
                toastr.success('Xóa loại bản đồ thành công');
                this.onSearch();
            } else {
                toastr.warning('Xóa loại bản đồ không thành công');
            }
        }).catch(error => {
            toastr.warning('Xóa loại bản đồ không thành công');
            console.log(error);
        });
    }


    /*Tìm kiếm*/
    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        if (type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        this.state.dataInfo[name] = value;
        this.setState(this.state);
    }

    clickSearch = (event) => {
        this.state.dataInfo['pageNumber'] = 1;
        this.onPagi(event);
    }

    fetchSearch = (keySearch) => {
        AXIOS_INSTANCE.post('danh-muc/loai-ban-do/tim-kiem', keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    onPagi = () => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearch(
            {
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
    }

    onSearch = dataInfo => {
        this.setState({ dataInfo: dataInfo, pageNumber: 0, pageSize: 10 }, () => {
            this.fetchSearch({
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        });
    }

    onSelectPage = page => {
        this.setState({ pageNumber: page - 1 }, this.onPagi);
    }

    onChangePageSize = pageSize => {
        this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
    }

    handleCloseModal = () => {
        this.state = {
            id: 0,
            ma: '',
            ten: '',
            trangThai: 0
        };
        this.state.show = false;
        this.setState(this.state);
    }

    handleOpenModal = () => {
        this.state = {
            id: 0,
            ma: '',
            ten: '',
            trangThai: 0
        };
        this.state.show = true;
        this.setState(this.state);
    }

    render() {
        return (
            <>
                <br />
                <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                    DANH MỤC loại bản đồ
                </h5>
                <br></br>

                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung gợi ý</label>
                            <input type="text" className="form-control" id="keywords" name="keywords"
                                onChange={this.setParams} />
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox" name="hoatDong"
                                            checked={this.state.hoatDong}
                                            onChange={this.setParams} className="form-check-input" />Hoạt động
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-10 text-right">
                                <div className="form-group">
                                    <button type="button" class="btn btn-primary btn-lg" onClick={this.clickSearch} >Tìm kiếm</button>&nbsp;
                                    <button type="button" class="btn btn-primary btn-lg" onClick={this.handleOpenModal}>Thêm mới</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Tên</th>
                            <th>Mã</th>
                            <th>Hoạt động</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list?.length > 0 ? this.state.list?.map((item, index) => {
                            return (
                                <tr>
                                    <td className="text-center" width="11%">
                                        {this.state.pageSize * this.state.pageNumber + index + 1}
                                    </td>
                                    <td>{item.ten}</td>
                                    <td>{item.ma}</td>
                                    <td className="tex-center" align="center" width="15%">
                                        {item.trangThai > 0 ? <i class="fas fa-check fa-lg" style={{ color: 'green' }}></i> : ''}
                                    </td>
                                    <td width="11%">
                                        <div className="row">
                                            <div className="col-md">
                                                <button data-toggle="modal" data-target="#ModalAdd"
                                                    onClick={() => this.editById(item)}><img src={editIcon} style={{ float: 'left', marginLeft: '20px' }}></img></button>
                                            </div>
                                            <div className="col-md">
                                                <button data-toggle="modal" data-target="#ModalDelete"
                                                    onClick={() => this.handleId(item.id)}><img src={deleteIcon} style={{ float: 'right', marginRight: '20px' }}></img></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <PaginationCustom
                    pageSize={this.state.pageSize}
                    pageLength={this.state.pageLength}
                    pageIndex={this.state.pageIndex}
                    total={this.state.total}
                    pageNumber={this.state.pageNumber}
                    onSelectPage={this.onSelectPage}
                    onChangePageSize={this.onChangePageSize}
                />

                <Modal show={this.state.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                        <h5 class="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI DANH MỤC LOẠI BẢN ĐỒ</h5>
                        <button type="button" class="close" onClick={this.handleCloseModal} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon}></img></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="ma" style={{ fontWeight: 'bold', fontSize: '14px' }}>Mã loại bản đồ<span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" className="form-control" id="ma" name="ma"
                                        value={this.state.ma} onChange={this.setParamss} 
                                        
                                        />
                                    <div style={this.state.submit && Boolean(this.state.errors?.ma) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ma}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="ten" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên loại bản đồ<span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" className="form-control" id="ten" name="ten"
                                        value={this.state.ten} onChange={this.setParamss} />
                                    <div style={this.state.submit && Boolean(this.state.errors?.ten) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ten}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-12">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox" name="trangThai" checked={this.state.trangThai === 1} onChange={this.setParamss} className="form-check-input" />Hoạt động
                                    </label>
                                </div>
                            </div>
                        </div>

                        <br />
                        <button onClick={this.addOrUpdate} type="button" class="btn btn-primary">Lưu</button>&nbsp;&nbsp;
                        <button onClick={this.clearDataModal} type="button" class="btn btn-danger">Xóa</button> &nbsp;&nbsp;
                        <button onClick={this.handleCloseModal} type="button" class="btn btn-primary">Hủy</button>

                    </div>
                </Modal>

                <div class="modal fade" id="ModalDelete" tabindex="-1" role="dialog" aria-labelledby="ModalDelete" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                                <button data-dismiss="modal" className="btn btn-primary btn-sm" onClick={this.deleteById}> Xóa</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" onClick={() => this.handleId(0)} className="btn btn-secondary btn-sm"> Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}