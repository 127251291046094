import { DIEU_CHINH, TINH_TRANG_DIEU_CHINH, DIEU_CHINH_GIAY_PHEP } from '../../Constants/constants';
import TabDCCT from "./TabDCCT";

function DCCTRender({ match, location }) {

  const {
    params: { mahoso }
  } = match;

  const tenLoaiGiayPhep = DIEU_CHINH_GIAY_PHEP.ten;
  const maLoaiGiayPhep = DIEU_CHINH_GIAY_PHEP.ma;
  const tenTrangThai = TINH_TRANG_DIEU_CHINH.ten;
  const maTrangThai = TINH_TRANG_DIEU_CHINH.ma;
  const tenLoaiBienDong = DIEU_CHINH.ten;
  const loaiBienDong = DIEU_CHINH.ma;
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const maHoSo = location.state && location.state?.maHoSo ? location.state?.maHoSo : "";
  const nhapDauKy = location.state && location.state?.nhapDauKy ? location.state?.nhapDauKy : false;
  const readonly = true;
  const disabled = true;
  const condition = true;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TabDCCT
            id={id}
            isNhapDauKy={nhapDauKy}
            maHoSo={maHoSo}
            maLoaiGiayPhep={maLoaiGiayPhep}
            tenLoaiGiayPhep={tenLoaiGiayPhep}
            loaiBienDong={loaiBienDong}
            tenLoaiBienDong={tenLoaiBienDong}
            tenTrangThai={tenTrangThai}
            maTrangThai={maTrangThai}
            readonly={readonly}
            disabled={disabled}
          ></TabDCCT>
        </div>
      </div>
    </div>
  );
}
export default DCCTRender;
