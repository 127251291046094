import React from 'react';
import AXIOS_INSTANCE from'../../../api';
import closeIcon from '../../../Assets/icons/u1233.png';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../utils/stringUtils';
import deleteIcon from '../../../Assets/icons/u626.png';
import editIcon from '../../../Assets/icons/u624.png';
import saveIcon from '../../../Assets/icons/u27634.svg';
import UploadFileService from "../../../api/common/UploadFile";
import { LIST_LOAIGIAYPHEP } from '../../Constants/constants';
import { DanhMucService } from '../../../api/danhMuc/danhMucService';
import isEqual from 'lodash/isEqual';
export default class CHBMModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nghiepVu: [],
            linhVuc: [],
            bieuMauDinhKem : [],
            cauHinhBieuMau : {
                tenBieuMau: "",
                maLinhVuc: "",
                maNghiepVu: "",
                trangThai: true,
                idReset: 0,
                bieuMauDinhKem: [
                        {
                            tenHienThi: "",
                            tenTapTin: "",
                            loaiTapTin: "",
                            tepDinhKem: null,
                            idTepDinhKem: "",
                            trangThai: true
                        }
                ],
            }
        }
        // this.uploadFileService = new UploadFileService();
        this.apiDanhMuc = new DanhMucService();
    }
    componentDidMount() {
        this.nghiepVu();
        this.linhVuc();
        this.closeModel = React.createRef();
        this.hiddenFileInput = React.createRef();
    }
    componentDidUpdate(prevProps) {
        const { idReset } = this.props;
        if (!isEqual(prevProps.idReset, idReset)) {
           this.resetInfo();
        }
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.cauHinhBieuMau[name] = value;
        this.setState(this.state)
    }
    setParamNghiepVu = (event) => {
        let maNghiepVu = event.target.value;
        let tenNghiepVu = event.target.selectedOptions[0].text;
        this.state.cauHinhBieuMau.maNghiepVu = maNghiepVu;
        this.state.cauHinhBieuMau.tenNghiepVu = tenNghiepVu;
        this.setState(this.state)
    }
    nghiepVu = () => {
        this.setState({ nghiepVu : LIST_LOAIGIAYPHEP || [] });
    }

    linhVuc = () => {
        this.apiDanhMuc.getLinhVucByMaCoQuan_HoatDong("000.00.16.H13", true).then(danhMucLinhVuc =>  this.setState({ linhVuc: danhMucLinhVuc || [] }));
    }

    onSubmit = async () => {
        const cauHinhBieuMau = this.state.cauHinhBieuMau;
        const tenBieuMau = cauHinhBieuMau?.tenBieuMau;
        if(StringUtil.isNullOrEmty(tenBieuMau)){
            toastr.warning('Tên biểu mẫu không được để trống!');
            return;
        }
        const maLinhVuc = cauHinhBieuMau?.maLinhVuc;
        if(StringUtil.isNullOrEmty(maLinhVuc)){
            toastr.warning('Lĩnh vực không được để trống!');
            return;
        }
        const maNghiepVu = cauHinhBieuMau?.maNghiepVu;
        if(StringUtil.isNullOrEmty(maNghiepVu)){
            toastr.warning('Nghiệp vụ không được để trống!');
            return;
        }
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        cauHinhBieuMau.bieuMauDinhKem = dinhKemFile;
       await AXIOS_INSTANCE.post('danh-muc-bieu-mau/them-moi', cauHinhBieuMau ).then(res => {
            if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                toastr.success('Thêm mới biểu mẫu thành công!');
                this.props.onSearch();
                this.backInfo();
            } else {
                toastr.warning('Lĩnh Vực và Nghiệp Vụ đã tồn tại trong hệ thống!');
            }
          }).catch(error => {
            toastr.warning('Thêm mới biểu mẫu không thành công!');
          });
    }

    themMoiFile  = () => {
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        const newFile = {
            tenHienThi: "",
            tenTapTin: "",
            loaiTapTin: "",
            tepDinhKem: null,
            idTepDinhKem: "",
            trangThai: true
        };
        dinhKemFile.push(newFile);
        this.setState({ bieuMauDinhKem: dinhKemFile });
    }

    handleClickFile = (event) => {
        this.hiddenFileInput.current.click();
    };

    handleOnChangeTenHienThi = (event) => {
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        const newFile = dinhKemFile[event.target.name];
        const tenHienThi = event.target.value;
        if(StringUtil.isNullOrEmty(tenHienThi)){
            toastr.warning('Tên hiển thị không được để trống!');
            return;
        }else{
            newFile.tenHienThi = tenHienThi;
        }
        dinhKemFile.splice(event.target.name, 1, newFile );
        this.setState({ bieuMauDinhKem : dinhKemFile });
    };

    handleOnChangeFile = (event) => {
        const fileTemp = event.target.files[0];
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        const newFile = dinhKemFile[event.target.name];
        const fileName = fileTemp.name;
        if(StringUtil.isNullOrEmty(fileName)){
            toastr.warning('Tên hiển thị không được để trống!');
            return;
        }else{
            var ext =  fileName.split('.').pop();
            if(ext === "doc" || ext=== "docx"){
                newFile.tenTapTin = fileTemp.name;
                newFile.tepDinhKem = fileTemp;
                newFile.loaiTapTin = ext;
            } else{
                toastr.warning('Tệp biểu mẫu không đúng định dạng!');
            return;
            }
        }
        dinhKemFile.splice(event.target.name, 1, newFile );
        this.setState({ bieuMauDinhKem : dinhKemFile });
    };

    handleRemoveFile = (event) => {
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        dinhKemFile.splice(event.target.name, 1);
        this.setState({ bieuMauDinhKem : dinhKemFile });
    };

    handleSaveFile = (event) => {
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        const newFile = dinhKemFile[event.target.name];
        if(StringUtil.isNullOrEmty(newFile.tenHienThi)){
            toastr.warning('Tên hiển thị không được để trống!');
            return;
        }
        if(StringUtil.isNullOrEmty(newFile.tenHienThi)){
            toastr.warning('Tên hiển thị không được để trống!');
            return;
        }
        if(newFile.tepDinhKem === null){
            toastr.warning('Tệp biểu mẫu không được để trống!');
            return;
        }
        if(StringUtil.isNullOrEmty(newFile.idTepDinhKem)){
            const formData = new FormData();
            formData.append(newFile.tepDinhKem.name, newFile.tepDinhKem);
            UploadFileService.upload(formData).then(res => {
                    if (res.data.error_code == 'SUCCESSFUL') {
                        newFile.idTepDinhKem = res.data.data[0].docId;
                        newFile.trangThai = false;
                        newFile.tepDinhKem = null;
                        dinhKemFile.splice(event.target.name, 1, newFile );
                        this.setState({ bieuMauDinhKem : dinhKemFile });
                    } else {
                        toastr.warning(res.data.error_message);
                        return;
                    }
            }).catch(error => {
                toastr.warning('Lỗi kết nối dịch vụ (456)');
                return;
            }); 
        }else{
            newFile.trangThai = false;
            dinhKemFile.splice(event.target.name, 1, newFile );
            this.setState({ bieuMauDinhKem : dinhKemFile });
            return;
        }
    };
    
    handleEditFile = (event) => {
        const dinhKemFile  = this.state.bieuMauDinhKem || [];
        const newFile = dinhKemFile[event.target.name];
        newFile.trangThai = true;
        dinhKemFile.splice(event.target.name, 1, newFile );
        this.setState({ bieuMauDinhKem : dinhKemFile });
    };

    setParamCheckBox = e => {
        let value = e.target.checked;
        let name = e.target.name;
        this.state.cauHinhBieuMau[name] = value;
        this.setState(this.state)
    }

    resetInfo = () =>{
      const  cauHinhBieuMau = {
            tenBieuMau: "",
            maLinhVuc: "",
            maNghiepVu: "",
            tenNghiepVu:"",
            trangThai: true,
            bieuMauDinhKem: [],
        }
        this.setState({ bieuMauDinhKem : [], cauHinhBieuMau : cauHinhBieuMau });
    }

    backInfo = () =>{
        const  cauHinhBieuMau = {
              tenBieuMau: "",
              maLinhVuc: "",
              maNghiepVu: "",
              tenNghiepVu:"",
              trangThai: true,
              bieuMauDinhKem: [],
          }
          this.setState({ bieuMauDinhKem : [], cauHinhBieuMau : cauHinhBieuMau });
          this.closeModel.current.click();
      } 
    render() {
        return (
            <React.Fragment>
                <form>
                    <div className="modal fade" id="CHBMModal" ref={this.modalRef} role="dialog" aria-labelledby="CHBMModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth :" 826px"}}>
                            <div className="modal-content">
                                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                    <h5 className="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>CẤU HÌNH BIỂU MẪU</h5>
                                    <img type="button" className="close" data-dismiss="modal" aria-label="Close" src={closeIcon}></img>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên biểu mẫu <span style={{ color: 'red' }}> (*)</span></label>
                                            <input type="text" className="form-control" id="soGiayPhepValue" name="tenBieuMau" value={this.state.cauHinhBieuMau.tenBieuMau} onChange={this.setParams} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lĩnh vực <span style={{ color: 'red' }}> (*)</span></label>
                                            <select className="form-control" id="idMaLinhVuc" value={this.state.cauHinhBieuMau.maLinhVuc} name="maLinhVuc" onChange={this.setParams}>
                                                <option defaultChecked={true} value="">--Chọn--</option>
                                                {
                                                    this.state.linhVuc.map((e) => {
                                                        return <option value={e.ma}>{e.ten}</option>
                                                    })
                                                }
                                            </select>
                                            {this.state.soGiayPhepError && <span style={{ color: 'red' }} >{this.state.soGiayPhepError}</span>}
                                        </div>
                                        <div className="col-md-6 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nghiệp vụ <span style={{ color: 'red' }}>(*)</span></label>
                                            <select className="form-control" id="idmaNghiepVu" value={this.state.cauHinhBieuMau.maNghiepVu} name="maNghiepVu" onChange={this.setParamNghiepVu}>
                                                <option value="">--Chọn--</option>
                                                {
                                                    this.state.nghiepVu.map((e) => {
                                                        return <option value={e.ma}>{e.ten}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đính kèm</label>
                                            <div class="row mt-1">
                                                    <div class="col-md-12"> 
                                                        <table className="table table-bordered" style={{ fontSize: '14px' }}>
                                                            <thead>
                                                                <tr className="table-secondary">
                                                                    <th>STT</th>
                                                                    <th>Tên hiển thị</th>
                                                                    <th>Tên tập tin</th>
                                                                    <th className="text-center">Thao tác</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.bieuMauDinhKem?.length > 0 ? this.state.bieuMauDinhKem?.map((item, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="cell-number" style={{width :"35px"}}>{index + 1}</td>
                                                                            <td style={{width :"350px"}}>
                                                                                {
                                                                                    item.trangThai ?
                                                                                    <input className="form-control" onChange={this.handleOnChangeTenHienThi} name={index} defaultValue={item.tenHienThi}></input>
                                                                                    :
                                                                                    item.tenHienThi
                                                                                }
                                                                            </td>
                                                                            <td style={{width :"332px"}}>
                                                                                    {
                                                                                        item.trangThai ?
                                                                                        <div className ="row">
                                                                                        <div className="col-md-6 text-left">{item.tenTapTin}</div> 
                                                                                        <div className="col-md-6 text-right">
                                                                                            <button type="button" style={{fontSize: "14px"}} onClick={this.handleClickFile} class="btn btn-secondary">Chọn tập tin</button>
                                                                                            <input type="file" ref={this.hiddenFileInput} name={index}  onChange={this.handleOnChangeFile} style={{display:'none'}}/>
                                                                                        </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="row">
                                                                                        <div className="col-md-12 text-left">{item.tenTapTin}</div>
                                                                                        </div>
                                                                                    }
                                                                            </td>
                                                                            <td style={{width :"90px"}}>
                                                                                    {
                                                                                        item.trangThai ?
                                                                                        <div className ="row">
                                                                                            <div className="col-md-6 text-right"><img src={saveIcon} onClick={this.handleSaveFile} name={index}></img></div> 
                                                                                            <div className="col-md-6 text-left"><img src={deleteIcon} onClick={this.handleRemoveFile} name={index}></img></div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 text-right"><img src={editIcon} name={index} onClick={this.handleEditFile}></img></div> 
                                                                                            <div className="col-md-6 text-left"><img src={deleteIcon} onClick={this.handleRemoveFile} name={index}></img></div>
                                                                                        </div>
                                                                                    }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr>
                                                                        <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mt-2 text-right"> 
                                                    <button type="button" class="btn btn-primary" onClick={this.themMoiFile}>Thêm mới</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" onChange={this.setParamCheckBox} name="trangThai" defaultChecked="true"/> Hoạt động
                                    </div>
                                    <div className="col-md-12 text-left row">
                                    <button type="reset" class="btn btn-primary btn-sm" onClick={this.onSubmit}> Lưu</button>&nbsp;
                                    <button type="button" ref={this.closeModel} style={{display:'none'}} data-dismiss="modal"></button>
                                    <button type="reset" class="btn btn-danger btn-sm" onClick={this.resetInfo}>Xóa</button>&nbsp;
                                    <button type="reset" class="btn btn-primary btn-sm" onClick={this.backInfo}>Trở về</button>&nbsp;
                                </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </form>
            </React.Fragment>
        )
    }
}