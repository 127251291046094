import React from 'react';
import { Modal } from 'react-bootstrap';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';
import closeIcon from "../../../../Assets/icons/u1233.png";
import editIcon from '../../../../Assets/icons/u624.png';
import deleteIcon from '../../../../Assets/icons/u626.png';
import { KIEM_TRA_NGAN_CHAN } from '../../../Constants/constants';
import ModalXacNhanXoa from '../Modals/ModalXacNhanXoa';
import PaginationCPXD from '../PaginationGPXD';
import ModalCreateKiemTraNganChan from './ModalCreateKiemTraNganChan';
import moment from 'moment';
import { Grid, SvgIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import KiemTraNganChanService from "../../../../api/service/KiemTraNganChanService";
import { createFileType, downLoadFile } from '../../../../api/common/exportUtil';
import toastr from 'toastr';
import ModalXacNhanXoaCommon from '../../CapPhepXDCTDA/SubTabCapPhepXDCTDA/SubModalCongTrinhDA/ModalXacNhanXoa';

export default class PageKiemTraNganChan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInfo: {
                tenChuSuDung: '',
                diaChiChiTiet: '',
                noiDungNganChan: '',
                soTo: '',
                soThua: '',
                soGiayCN: '',
                soVaoSo: '',
                ngayNganChan: '',
                congVanNC: '',
                maTinhThanh: '92',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ghiChu: '',
                exportFileName: '',
                exportFileType: '',
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 10
            },
            list: [],
            total: 0,
            pageIndex: 0,
            pageLength: 0,
            pageNumber: 0,
            pageSize: 10,
            selectedOption: '',
            total: 0,
            show: false,
            openModel: '',
            quanHuyen: [],
            phuongXa: [],
            editIndex: -1,
            deleteIdDonViThamDinh: 0
        }
    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({ ...this.state });
    }
    clearDataModal = () => {
        this.setState({ ...this.state, editIndex: -1 });
    }

    editDonViThamDinh(id) {
        this.state.editIndex = id;
        this.setState({ ...this.state });
        this.handleModalToggle('them-hoac-cap-nhat')

    }

    handleIdDonViThamDinh(value) {
        this.state.deleteIdDonViThamDinh = value;
        this.setState({ ...this.state });
        this.handleModalToggle('xac-nhan-xoa')
    }
    handleDropItem = (event) => {
        const url = KIEM_TRA_NGAN_CHAN.urlDelete + this.state.deleteIdDonViThamDinh;
        AXIOS_INSTANCE.post(url).then(res => {
            if (res != null) {
                toastr.success('Xóa ' + KIEM_TRA_NGAN_CHAN.ten.toLowerCase() + ' thành công');
                this.handleModalToggle('')
                let valuePage
                //Set Pagenumber after delete
                if (this.state.list) {
                    valuePage = Math.ceil(this.state.list.length / this.state.pageSize)
                }
                this.onSelectPage(valuePage)
            } else {
                toastr.warning('Xóa ' + KIEM_TRA_NGAN_CHAN.ten.toLowerCase() + ' không thành công');
            }
        }).catch(error => {
            toastr.warning('Xóa ' + KIEM_TRA_NGAN_CHAN.ten.toLowerCase() + ' không thành công');
            console.log(error);
        });
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.state.list.length === 0) {
                AXIOS_INSTANCE.post(KIEM_TRA_NGAN_CHAN.urlSearch, {
                    hoatDong: 1,
                    keywords: "",
                    pageNumber: this.state.pageNumber - 1,
                    pageSize: 10
                }).then(res => {
                    if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                        const datas = res.data.data;
                        const total = datas.total;
                        const pageIndex = datas.pageIndex + 1;
                        const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                        this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
                    } else {
                        this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
                    }
                }).catch(error => {
                    this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
                });
            }
        }, 200);
        this.danhSachQuanHuyenInfo();
        this.onPagi();
    }

    /*Tìm kiếm*/
    setParams = (e) => {
        let target = e.target;
        let type = target.type;
        let name = target.name;
        let value = target.value;
        let text = '';
        if (type === "select-one") {
            text = target.selectedOptions[0].text;
        }
        if (name === "maQuanHuyen") {
            this.state.dataInfo[name] = value;
            this.state.dataInfo.tenQuanHuyen = text;
            this.danhSachPhuongXa(value);
        }
        if (name === "maPhuongXa") {
            this.state.dataInfo[name] = value;
            this.state.dataInfo.tenPhuongXa = text;
        }
        this.state.dataInfo[name] = value;
        this.setState({ ...this.state });
    }
    clickSearch = (event) => {
        this.state.dataInfo['pageNumber'] = 1;
        this.onSearch(event);
    }
    fetchSearch = keySearch => {
        AXIOS_INSTANCE.post(KIEM_TRA_NGAN_CHAN.urlSearch, keySearch).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                const datas = res.data.data;
                const total = datas.total;
                const pageIndex = datas.pageIndex + 1;
                const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length)
                this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
            } else {
                this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
            }
        }).catch(error => {
            this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        });
    }

    onPagi = () => {
        const dataInfo = this.state.dataInfo;
        this.fetchSearch(
            {
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
    }

    onSearch = dataInfo => {
        this.setState({ dataInfo: dataInfo, pageNumber: 0, pageSize: 10 }, () => {
            this.fetchSearch({
                ...dataInfo,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            });
        });
    }
    onSelectPage = page => {
        this.setState({ pageNumber: page - 1 }, this.onPagi);
    }
    onChangePageSize = pageSize => {
        this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
    }



    danhSachQuanHuyenInfo = maTinhThanh => {
        const queryParam = {
            "maTinhThanh": this.state.dataInfo.maTinhThanh
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }
    danhSachPhuongXa = maQuanHuyen => {
        const queryParam = {
            "maTinhThanh": this.state.dataInfo.maTinhThanh,
            "maQuanHuyen": maQuanHuyen
        }
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }
    //xuat file bao cao
    downloadFile = async (extension) => {
        const { dataInfo } = this.state;
        let request = {
            ...dataInfo,
            hoatDong: 1,
            exportFileName: 'DS_kiem_tra_ngan_chan',
            exportType: extension
        };
        await new KiemTraNganChanService().downloadFileDanhSachKiemTraNganChan(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'DS_kiem_tra_ngan_chan');
        }).finally(() => {
        });
    }
    render() {
        return (
            <div className="dash-content">
            <>
                <React.Fragment>
                    <div className="dash-title">
                        <span>Danh sách ngăn chặn</span>
                    </div>
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chủ sử dụng</label>
                                <input type="text" className="form-control col-md" id="tenChuSuDung" name="tenChuSuDung" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung ngăn chặn</label>
                                <input type="text" className="form-control col-md" id="noiDungNganChan" name="noiDungNganChan" onChange={this.setParams} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tờ</label>
                                <input type="text" className="form-control col-md" id="soTo" name="soTo" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Quận, huyện</label>
                                <select type="text"
                                    value={this.state.dataInfo?.maQuanHuyen}
                                    className='form-control col-sm'
                                    id="maQuanHuyen"
                                    name="maQuanHuyen"
                                    onChange={this.setParams} >
                                    <option value=""> Chọn quận, huyện</option>
                                    {
                                        this.state.quanHuyen.map((e, ind) => {
                                            return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số thửa</label>
                                <input type="text" className="form-control col-md" id="soThua" name="soThua" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phường, xã</label>
                                <select type="text"
                                    value={this.state.dataInfo?.maPhuongXa}
                                    className='form-control col-sm'
                                    id="maPhuongXa"
                                    name="maPhuongXa"
                                    onChange={this.setParams} >
                                    <option value="">Chọn phường, xã</option>
                                    {
                                        this.state.phuongXa.map((e, ind) => {
                                            return <option key={e.maPhuongXa} value={e.maPhuongXa}>{e.tenPhuongXa}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy CN</label>
                                <input type="text" className="form-control col-md" id="soGiayCN" name="soGiayCN" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày ngăn chặn</label>
                                <input type="date"
                                    max={moment(new Date()).format('yyyy-MM-DD')}
                                    value={this.state.dataInfo?.ngayNganChan ? moment(new Date(this.state.dataInfo.ngayNganChan)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                    className='form-control col-sm'
                                    id="ngayNganChan"
                                    name="ngayNganChan"
                                    onChange={this.setParams} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số vào sổ</label>
                                <input type="text" className="form-control col-md" id="soVaoSo" name="soVaoSo" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group row">
                                <label htmlFor="id" className="col-md-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Công văn NC</label>
                                <input type="text" className="form-control col-md" id="congVanNC" name="congVanNC" onChange={this.setParams} />
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-12">
                            <div className="form-group" align="center">
                                <button type="button" class="btn btn-primary btn-lg" onClick={this.onPagi} >Tìm kiếm</button>&nbsp;
                                    <button type="button" onClick={() => this.handleModalToggle('them-hoac-cap-nhat')} class="btn btn-primary btn-lg" >Thêm mới</button>
                            </div>
                        </div>
                    </div>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('docx');
                        }}>
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('pdf');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('xlsx');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                        <thead>
                            <tr className="table-secondary">
                                <th>STT</th>
                                <th>Nội dung ngăn chặn</th>
                                <th>Phường/ Xã</th>
                                <th>Quận/ Huyện</th>
                                <th>Số tờ</th>
                                <th>Số thửa</th>
                                <th>Chủ sử dụng</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list?.length > 0 ? this.state.list?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{this.state.pageSize * this.state.pageNumber + index + 1}</td>
                                        <td>
                                            {item.noiDungNganChan}
                                        </td>
                                        <td>
                                            {item.tenPhuongXa}
                                        </td>
                                        <td>
                                            {item.tenQuanHuyen}
                                        </td>
                                        <td>
                                            {item.soTo}
                                        </td>
                                        <td>
                                            {item.soThua}
                                        </td>
                                        <td>
                                            {item.tenChuSuDung}
                                        </td>
                                        <td width="10%">
                                            <div className="row">
                                                <div className="col-md">
                                                    <button onClick={() => this.editDonViThamDinh(item.id)}><img src={editIcon}></img></button>
                                                </div>
                                                <div className="col-md">
                                                    <button onClick={() => this.handleIdDonViThamDinh(item.id)}><img src={deleteIcon}></img></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <PaginationCPXD
                        pageSize={this.state.pageSize}
                        pageLength={this.state.pageLength}
                        pageIndex={this.state.pageIndex}
                        total={this.state.total}
                        pageNumber={this.state.pageNumber}
                        onSelectPage={this.onSelectPage}
                        onChangePageSize={this.onChangePageSize}
                    ></PaginationCPXD>
                    {this.state.openModel === 'them-hoac-cap-nhat' &&
                        <ModalCreateKiemTraNganChan
                            urlKiemTraDuplicate={KIEM_TRA_NGAN_CHAN.urlKiemTraDuplicate}
                            url={KIEM_TRA_NGAN_CHAN.urlAddorUpdate}
                            itemName={KIEM_TRA_NGAN_CHAN.ten}
                            clearDataModal={this.clearDataModal}
                            editIndex={this.state.editIndex}
                            onPagi={this.onPagi}
                            onModalToggle={this.handleModalToggle}
                            show={this.state.openModel === 'them-hoac-cap-nhat'}
                        ></ModalCreateKiemTraNganChan>
                    }
                    <ModalXacNhanXoaCommon
                        onDropItem={this.handleDropItem}
                        show={this.state.openModel === 'xac-nhan-xoa'}
                        itemId={this.state.deleteIdDonViThamDinh}
                        onModalToggle={this.handleModalToggle}
                    ></ModalXacNhanXoaCommon>
                </React.Fragment>
            </>
            </div>
        )
    }
}