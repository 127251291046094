import React from 'react';
import TableDonViHanhChinh from '../CapPhepDungChung/NDGP/TableDonViHanhChinh';
import 'toastr/build/toastr.min.css';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import ModalKyHieuThietKe from '../CapPhepDungChung/NDGP/ModalKyHieuThietKe';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import InputCapCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputCapCongTrinh';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import moment from 'moment';
import DropZoneCustom from '../../commons/components/CustomDropzone/DropZoneCustom';
import AXIOS_INSTANCE from '../../../api';
import { ACCEPT_FILES } from '../../Constants/constants';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import UploadFileService from "../../../api/common/UploadFile";
import { Box, Grid } from '@material-ui/core';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

export default class FormCPXDCongTrinhNgam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            openModel: false,
            noiDungGiayPhep: {
                thongTinCongTrinhNgam: {},
                quenSuDungDat: {},
                hangMucs: [],
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
        }
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinhNgam != this.state.noiDungGiayPhep.thongTinCongTrinhNgam) {
            this.state.noiDungGiayPhep.thongTinCongTrinhNgam = this.props.noiDungGiayPhep.thongTinCongTrinhNgam;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinhNgam);
        }

        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.donViHanhChinhDrops != this.state.noiDungGiayPhep.donViHanhChinhDrops) {
            this.state.noiDungGiayPhep.donViHanhChinhDrops = this.props.noiDungGiayPhep.donViHanhChinhDrops;
            this.setState(this.state.noiDungGiayPhep.donViHanhChinhDrops);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.donViHanhChinhs != this.state.noiDungGiayPhep.donViHanhChinhs) {
            this.state.noiDungGiayPhep.donViHanhChinhs = this.props.noiDungGiayPhep.donViHanhChinhs;
            this.setState(this.state.noiDungGiayPhep.donViHanhChinhs);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files != this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash != this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep != this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }
    }


    setParams = (event, key) => {
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;
        this.state.noiDungGiayPhep.thongTinCongTrinhNgam[name] = value;
        this.props.onUpdateDataState('thongTinCongTrinhNgam', this.state.noiDungGiayPhep.thongTinCongTrinhNgam);
        if (key == 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
    }

    setParamLoDats = (key, value) => {
        if (key === 'donViHanhChinhs') {
            this.state.noiDungGiayPhep.donViHanhChinhs = value;
            this.props.onUpdateDataState('donViHanhChinhs', this.state.noiDungGiayPhep.donViHanhChinhs);
        }
        if (key === 'donViHanhChinhDrops') {
            this.state.noiDungGiayPhep.donViHanhChinhDrops = value;
            this.props.onUpdateDataState('donViHanhChinhDrops', this.state.noiDungGiayPhep.donViHanhChinhDrops);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState({ ...this.state });
    }

    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }
    handleChangeNhapDauKy(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }
    render() {
        const CustomInputNhapDauKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>

                {this.props.isNhapDauKy &&
                    <div class="col-md-12">
                        <br />
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="maSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    <input type="text" className="form-control" id="maSoGiayPhep" name="maSoGiayPhep"
                                        value={this.state.noiDungGiayPhep?.giayPhep?.maSoGiayPhep} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} />
                                    <div style={Boolean(this.state.errors?.maSoGiayPhep) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.maSoGiayPhep}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap"
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap ? moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                    <DatePicker
                                        customInput={<CustomInputNhapDauKy />}
                                        selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                        onChange={this.handleChangeNhapDauKy.bind(this)}
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                        name="ngayCap"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                    <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="tenCongTrinh" name="tenCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.tenCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={this.setParams} />
                                <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diemDauCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Điểm đầu công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="diemDauCongTrinh" name="diemDauCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.diemDauCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={this.setParams} />
                                <div style={Boolean(this.state.errors?.diemDauCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.diemDauCongTrinh}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Cấp công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="capCongTrinh" name="capCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.capCongTrinh}
                                    onChange={this.setParams} style={{ fontSize: '14px' }}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.capCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.capCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diemCuoiCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Điểm cuối công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="diemCuoiCongTrinh" name="diemCuoiCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.diemCuoiCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={this.setParams} />
                                <div style={Boolean(this.state.errors?.diemCuoiCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.diemCuoiCongTrinh}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <label htmlFor="kyHieuThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ký hiệu thiết kế</label>
                                    <p style={{ display: 'flex' }}>
                                        <input type="text"
                                            className="form-control"
                                            id="kyHieuThietKe" name="kyHieuThietKe"
                                            style={{ fontSize: '14px' }}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.kyHieuThietKe}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinhNgam")}
                                            readOnly={this.props.readonly ? true : false} />
                                        <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                            onClick={() => this.handleModalToggle('ky-hieu')}></i></p>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="quyMoCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Quy mô công trình</label>
                                        <textarea type="text" className="form-control" id="quyMoCongTrinh" name="quyMoCongTrinh" rows="10"
                                            readOnly={this.props.readonly ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.quyMoCongTrinh}
                                            onChange={this.setParams} style={{ fontSize: '14px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="tongChieuDai" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Tổng chiều dài <span style={{ color: 'red' }}>(*)</span></label>
                                        <input type="text" className="form-control" id="tongChieuDai" name="tongChieuDai"
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.tongChieuDai} style={{ fontSize: '14px' }}
                                            onChange={this.setParams} />
                                        <div style={Boolean(this.state.errors?.tongChieuDai) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tongChieuDai}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="TableDonViHanhChinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Các đơn vị hình chính mà công trình phải đi qua</label>
                                <TableDonViHanhChinh
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.setParamLoDats}
                                    disabled={this.props.disabled}
                                ></TableDonViHanhChinh>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều rộng công trình</label>
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="input-group">
                                                    <p style={{ marginRight: '5px', marginTop: '7px' }}>Từ</p>
                                                    <input type="text" className="form-control" id="chieuRongCongTrinhFrom" name="chieuRongCongTrinhFrom"
                                                        value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhFrom}
                                                        onChange={this.setParams} style={{ fontSize: '14px', borderRadius: '5px' }} />
                                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="input-group">
                                                    <p style={{ marginRight: '5px', marginTop: '7px' }}>Đến</p>
                                                    <input type="text" className="form-control" id="chieuRongCongTrinhTo" name="chieuRongCongTrinhTo"
                                                        value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhTo}
                                                        onChange={this.setParams} style={{ fontSize: '14px', borderRadius: '5px' }} />
                                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều sâu công trình</label>
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="input-group">
                                                    <p style={{ marginRight: '5px', marginTop: '7px' }}>Từ</p>
                                                    <input type="text" className="form-control" id="chieuRongCongTrinhFrom" name="chieuSauCongTrinhFrom"
                                                        value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhFrom}
                                                        onChange={this.setParams} style={{ fontSize: '14px', borderRadius: '5px' }} />
                                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="input-group">
                                                    <p style={{ marginRight: '5px', marginTop: '7px' }}>Đến</p>
                                                    <input type="text" className="form-control" id="chieuRongCongTrinhTo" name="chieuSauCongTrinhTo"
                                                        value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhTo}
                                                        onChange={this.setParams} style={{ fontSize: '14px', borderRadius: '5px' }} />
                                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="khoangCachNN" style={{ fontWeight: 'bold', fontSize: '14px' }}>Khoảng cách nhỏ nhất đến nhà dân</label>
                                        <input type="text" className="form-control" id="khoangCachNN" name="khoangCachNN"
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.khoangCachNN} style={{ fontSize: '14px' }}
                                            onChange={this.setParams} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="ghiChuVTXD" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú về vị trí xây dựng công trình</label>
                                        <textarea type="text" className="form-control" id="ghiChuVTXD" name="ghiChuVTXD"
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChuVTXD}
                                            onChange={this.setParams} style={{ fontSize: '14px' }} rows="4 " />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                                        <textarea type="text" className="form-control" id="ghiChu" name="ghiChu"
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChu}
                                            onChange={this.setParams} style={{ fontSize: '14px' }} rows="3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep.files}
                    fileTrash={this.state.noiDungGiayPhep.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
                <br></br>
                <br></br>
                {this.state.openModel === 'kiem-tra-ngan-chan'
                    &&
                    <ModalKiemTraNganChan
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel === 'kiem-tra-ngan-chan'}
                    ></ModalKiemTraNganChan>
                }
                <ButtonGroupGiayPhep
                    isNhapDauKy={this.props.isNhapDauKy}
                    handleModalToggle={this.handleModalToggle}
                    handleAddOrUpdate={this.props.addOrUpdate}
                    maHoSo={this.state.maHoSo}
                    maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                    idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                ></ButtonGroupGiayPhep>
                <ModalKyHieuThietKe
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'ky-hieu'}
                ></ModalKyHieuThietKe>
                <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                <ModalClearAll onClearData={this.handleClearAll} onModalToggle={this.handleModalToggle} show={this.state.openModel === 'clear-all'}></ModalClearAll>
                <br />
            </div>
        )
    }
}