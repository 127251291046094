import React from 'react';

export default class ThongTinDeXuat extends React.Component {

    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br></br>
                <br></br>

                <div className="row">
                    <div className="col-md-3 text-right">
                        <h6 style={{ fontWeight: 'bold' }}>Kết luận</h6>
                    </div>
                    <div className="col-md-9">
                        {this.props.thongTinDeXuat
                            && <p>{this.props.thongTinDeXuat.ketluan}</p>
                        }
                    </div>
                </div>

                <div className="row" style={{ marginTop: '15px' }}>
                    <div className="col-md-3 text-right">
                        <h6 style={{ fontWeight: 'bold' }}>Đề xuất</h6>
                    </div>
                    <div className="col-md-9">
                        {this.props.thongTinDeXuat
                            && <p>{this.props.thongTinDeXuat.dexuat}</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}