import React from 'react';
import 'toastr/build/toastr.min.css';
import PoolFileItem from "./PoolFileItem";

export default class PoolFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: this.props.files

        };
    }

    render() {
        if (this.props.files == null) return "";
        return (
            <>
                {
                    this.getListItem()
                }
            </>
        );
    }

    getListItem = () => {
        let items = [];
        for (let i = 0; i < this.props.files.length; i++) {
            let item = this.props.files[i];
            items.push(
                <PoolFileItem item={item} idx={i} delHandler={this.onDel} downHandler={this.donwload}/>
            );
        }
        return items;

    }

    onDel = (item, idx) => {
        if (this.props.onDelete != null)
            this.props.onDelete(item, idx);
    }

    donwload = (item) =>{
        if (this.props.downloadFile != null)
            this.props.downloadFile(item);
    }
}
