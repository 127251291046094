import { CAP_MOI, CONG_TRINH_TON_GIAO, TINH_TRANG_DANG_HOAT_DONG } from '../../Constants/constants';
import TabCPXDCTTonGiao from './TabCPXDCTTonGiao';

function CapPhepXDCTKTTGDRender({ location }) {
  const tenLoaiGiayPhep = CONG_TRINH_TON_GIAO.ten;
  const maLoaiGiayPhep = CONG_TRINH_TON_GIAO.ma;
  const tenTrangThai = TINH_TRANG_DANG_HOAT_DONG.ten;
  const maTrangThai = TINH_TRANG_DANG_HOAT_DONG.ma;
  const tenLoaiBienDong = CAP_MOI.ten;
  const loaiBienDong = CAP_MOI.ma;
  
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const maHoSo = location.state && location.state?.maHoSo  ? location.state?.maHoSo : "";
  const nhapDauKy = location.state && location.state?.nhapDauKy  ? location.state?.nhapDauKy : false;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TabCPXDCTTonGiao
             id={id}
             isNhapDauKy={nhapDauKy}
             maHoSo={maHoSo}
             maLoaiGiayPhep={maLoaiGiayPhep}
             tenLoaiGiayPhep={tenLoaiGiayPhep}
             loaiBienDong={loaiBienDong}
             tenLoaiBienDong={tenLoaiBienDong}
             tenTrangThai={tenTrangThai}
             maTrangThai={maTrangThai}
          ></TabCPXDCTTonGiao>
        </div>
      </div>
    </div>
  );
}
export default CapPhepXDCTKTTGDRender;
