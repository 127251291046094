import { Box, Grid, InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import 'toastr/build/toastr.min.css';
import GiayPhepService from '../../../api/service/CapPhepXDCTHService';
import DropZoneCustom from '../../commons/components/CustomDropzone/DropZoneCustom';
import { bootstrapTextFieldHook } from '../../commons/components/input/bootstrap';
import { ACCEPT_FILES } from '../../Constants/constants';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import InputCapCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputCapCongTrinh';
import InputLoaiCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputLoaiCongTrinh';
import InputPhanLoaiXayDung from '../CapPhepDungChung/DanhMucDungRieng/InputPhanLoaiXayDung';
import SelectPhuongXa from '../CapPhepDungChung/InputGroup/SelectPhuongXa';
import SelectQuanHuyen from '../CapPhepDungChung/InputGroup/SelectQuanHuyen';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import ModalDonViThamDinh from '../CapPhepDungChung/NDGP/ModalDonViThamDinh';
import ModalDonViTuVan from '../CapPhepDungChung/NDGP/ModalDonViTuVan';
import ModalKyHieuThietKe from '../CapPhepDungChung/NDGP/ModalKyHieuThietKe';
import TableLoDatXD from '../CapPhepDungChung/NDGP/TableLoDatXD';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import UploadFileService from "../../../api/common/UploadFile";
import AXIOS_INSTANCE from '../../../api';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

export default class FormCapPhepSuaChua extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            submit: true,
            noiDungGiayPhep: {
                thongTinCongTrinh: {},
                thongTinXayDung: {},
                viTriXayDung: {},
                loDatXayDungs: [],
                loDatXayDungDropDTOs: [],
                giayToVeDatDai: '',
                thongTinSuaChua: {},
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
            openModel: '',
            errors: {},
            soGiayPhepAuto: '',
            danhSachGiayPhepDaCap: []
        }
        this.giayPhepService = new GiayPhepService();
    }


    componentDidUpdate(prevProps) {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinh !== this.state.noiDungGiayPhep.thongTinCongTrinh) {
            this.state.noiDungGiayPhep.thongTinCongTrinh = this.props.noiDungGiayPhep.thongTinCongTrinh;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung !== this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriXayDung !== this.state.noiDungGiayPhep.viTriXayDung) {
            this.state.noiDungGiayPhep.viTriXayDung = this.props.noiDungGiayPhep.viTriXayDung;
            this.setState(this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung !== this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinSuaChua !== this.state.noiDungGiayPhep.thongTinSuaChua) {
            this.state.noiDungGiayPhep.thongTinSuaChua = this.props.noiDungGiayPhep.thongTinSuaChua;
            this.setState(this.state.noiDungGiayPhep.thongTinSuaChua);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungs !== this.state.noiDungGiayPhep.loDatXayDungs) {
            this.state.noiDungGiayPhep.loDatXayDungs = this.props.noiDungGiayPhep.loDatXayDungs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDropDTOs !== this.state.noiDungGiayPhep.loDatXayDungDropDTOs) {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = this.props.noiDungGiayPhep.loDatXayDungDropDTOs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (this.props.errorState !== undefined && this.props.errorState !== this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files !== this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash !== this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep !== this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }
        if (this.props.noiDungGiayPhep.thongTinSuaChua.soGiayPhepCu !== prevProps.noiDungGiayPhep.thongTinSuaChua.soGiayPhepCu) {
            this.state.soGiayPhepAuto = this.props.noiDungGiayPhep.thongTinSuaChua.soGiayPhepCu;
            this.setState(this.state)
        }
    }


    setParams = (event, key) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key == 'thongTinCongTrinh') {
            this.state.noiDungGiayPhep.thongTinCongTrinh[name] = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (key == 'thongTinXayDung') {
            if (this.state.noiDungGiayPhep.thongTinXayDung === null) {
                this.state.noiDungGiayPhep.thongTinXayDung = {};
            }
            this.state.noiDungGiayPhep.thongTinXayDung[name] = value;
            this.props.onUpdateDataState('thongTinXayDung', this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (key == 'thongTinSuaChua') {
            if (!this.state.noiDungGiayPhep.thongTinSuaChua) {
                this.state.noiDungGiayPhep.thongTinSuaChua = {}
            }
            this.state.noiDungGiayPhep.thongTinSuaChua[name] = value;
            this.props.onUpdateDataState('thongTinSuaChua', this.state.noiDungGiayPhep.thongTinSuaChua);
        }
        if (key == 'viTriXayDung') {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (key == 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key == 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
        if (key === "HuyenThiThanhPho") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.huyenThiThanhPho = text;
            this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (name === "maPhuongXaThiTran") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.phuongXaThiTran = text;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
    }
    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key === 'loDatXayDungDropDTOs') {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
        if (key === "donViThamDinh") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "donViTuVan") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "kyHieuThietKe") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
    }
    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }
    //handle autocomplete
    loadThongTinTheoSoGiayPhepCu = (newValue) => {
        this.state.soGiayPhepAuto = newValue.giayPhep.maSoGiayPhep;
        this.setState(this.state);
        this.props.onUpdateDataState("state.noiDungGiayPhep", {
            ...newValue,
            giayPhep: {
                ...newValue.giayPhep,
                soGiayPhepCu: newValue.giayPhep.maSoGiayPhep,
                maSoGiayPhep: '',
                id: this.state.noiDungGiayPhep.giayPhep.id,
            },
            thongTinSuaChua: {
                soGiayPhepCu: newValue.giayPhep.maSoGiayPhep,
                ngayGiayPhepCu: newValue.giayPhep.ngayCap
            }
        });
        this.setState(this.state);
    }
    onChangAutoComplete = (event) => {
        let thongTinSuaChua = { ...this.state.noiDungGiayPhep.thongTinSuaChua };
        thongTinSuaChua.ngayGiayPhepCu = 0;
        thongTinSuaChua.soGiayPhepCu = event.target.value;
        this.props.onUpdateDataState('thongTinSuaChua', thongTinSuaChua)
        let giayPhep = { ...this.state.noiDungGiayPhep.giayPhep }
        giayPhep.soGiayPhepCu = event.target.value;
        this.props.onUpdateDataState('giayPhep', giayPhep)
        let soGiayPhep = event.target.value;
        this.state.soGiayPhepAuto = soGiayPhep;
        this.setState(this.state);
        this.giayPhepService.getByMaSoGiayPhep({ maSoGiayPhep: this.state.soGiayPhepAuto }).then(res => {
            if (res != null && res.data.data && res.data.data?.length >= 0) {
                const danhSachGiayPhepDaCapNew = [...res.data.data];
                this.state.danhSachGiayPhepDaCap = [...danhSachGiayPhepDaCapNew];
                this.setState(this.state);
            }
        });
    }
    handleChangeNhapDauKy(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }
    render() {
        const CustomInputNhapDauKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>

                {this.props.isNhapDauKy &&
                    <div class="col-md-12">
                        <br />
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="maSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    <input type="text" className="form-control" id="maSoGiayPhep" name="maSoGiayPhep"
                                        value={this.state.noiDungGiayPhep?.giayPhep?.maSoGiayPhep} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} />
                                    <div style={Boolean(this.state.errors?.maSoGiayPhep) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.maSoGiayPhep}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap"
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap ? moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                    <DatePicker
                                        customInput={<CustomInputNhapDauKy />}
                                        selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                        onChange={this.handleChangeNhapDauKy.bind(this)}
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                        name="ngayCap"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                    <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="col-md-12">
                    <br />
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="tenCongTrinh" name="tenCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep?.thongTinCongTrinh.tenCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")} />
                                <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tinhThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tỉnh/Thành phố <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="tinhThanhPho" style={{ fontSize: '14px' }} name="tinhThanhPho" disabled
                                    value={this.state.noiDungGiayPhep.viTriXayDung.tinhThanhPho}>
                                    <option selected>Thành phố Cần Thơ</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <SelectQuanHuyen
                                quanHuyenValue={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectQuanHuyen>
                        </div>
                        <div class="col-md-3">
                            <SelectPhuongXa
                                maQuanHuyen={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                phuongXaValue={this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectPhuongXa>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Cấp công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="capCongTrinh" name="capCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.capCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.capCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.capCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                {/* <select className="form-control" id="duong" name="duong"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}>
                                    <option value="">--Chọn đường--</option>
                                    <option value="Đường 1">Đường 1</option>
                                    <option value="Đường 2">Đường 2</option>
                                    <option value="Đường 3">Đường 3</option>
                                </select> */}
                                <input type="text" className="form-control" id="duong" name="duong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div className="form-group">
                                <label htmlFor="soNhaDuong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số nhà, đường</label>
                                <input type="text" className="form-control" id="soNhaDuong" name="soNhaDuong"
                                    value={this.state.noiDungGiayPhep.viTriXayDung.soNhaDuong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}
                                    readOnly={this.props.readonly ? true : false} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="loaiCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="loaiCongTrinh" name="loaiCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.loaiCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn Loại công trình--</option>
                                    <InputLoaiCongTrinh></InputLoaiCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.loaiCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.loaiCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diaChiCu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                <input type="text" className="form-control" id="diaChiCu" name="diaChiCu"
                                    value={this.state.noiDungGiayPhep.viTriXayDung.diaChiCu} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}
                                    readOnly={this.props.readonly ? true : false} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="phanLoaiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Phân loại xây dựng <span style={{ color: 'red' }}>(*)</span></label>
                                        <select className="form-control" id="phanLoaiXayDung" name="phanLoaiXayDung"
                                            disabled={this.props.disabled ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.phanLoaiXayDung} style={{ fontSize: '14px' }}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                            <option value="">--Chọn Loại xây dựng--</option>
                                            <InputPhanLoaiXayDung></InputPhanLoaiXayDung>
                                        </select>
                                        <div style={Boolean(this.state.errors?.phanLoaiXayDung) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.phanLoaiXayDung}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViTuVan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị tư vấn</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViTuVan" name="donViTuVan"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tu-van')}></i></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <label htmlFor="kyHieuThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ký hiệu thiết kế</label>
                                    <p style={{ display: 'flex' }}>
                                        <input type="text"
                                            className="form-control"
                                            id="kyHieuThietKe" name="kyHieuThietKe"
                                            style={{ fontSize: '14px' }}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                            readOnly={this.props.readonly ? true : false} />
                                        <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                            onClick={() => this.handleModalToggle('ky-hieu')}></i></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViThamDinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị thẩm tra</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViThamDinh" name="donViThamDinh"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tham-dinh')}></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="soNha" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lô đất xây dựng</label>
                                <TableLoDatXD
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.setParamLoDats}
                                    disabled={this.props.disabled}
                                ></TableLoDatXD>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div className="form-group">
                        <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                        <input type="text" className="form-control" id="ghiChu" name="ghiChu"
                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.ghiChu}
                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")} style={{ fontSize: '14px' }}
                            readOnly={this.props.readonly ? true : false} />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng diện tích sàn</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="tongDienTichSan" name="tongDienTichSan"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.tongDienTichSan}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichXayDungTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích xây dựng tầng một</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichXayDungTangMot" name="dienTichXayDungTangMot"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.dienTichXayDungTangMot}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao tầng một</label>
                                <input type="number" className="form-control" id="chieuCaoangmot" name="chieuCaoTangMot"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoTangMot}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao công trình</label>
                                <input type="number" className="form-control" id="chieuCaoCongTrinh" name="chieuCaoCongTrinh"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoCongTrinh}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-*" style={{ marginLeft: '30px' }}>
                        <div className="form-group">
                            <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Được phép sửa chữa, cải tạo với nội dung sau</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinhMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cấp công trình </label>
                                <select className="form-control" id="capCongTrinhMoi" name="capCongTrinhMoi"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinSuaChua?.capCongTrinhMoi}
                                    onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="loaiCongTrinhMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại công trình </label>
                                <select className="form-control" id="loaiCongTrinhMoi" name="loaiCongTrinhMoi"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinSuaChua?.loaiCongTrinhMoi}
                                    onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }}>
                                    <option value="">--Chọn Loại công trình--</option>
                                    <InputLoaiCongTrinh></InputLoaiCongTrinh>
                                </select>

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích cải tạo</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="dienTichMoi" name="dienTichMoi"
                                        value={this.state.noiDungGiayPhep.thongTinSuaChua?.dienTichMoi}
                                        onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tongDienTichSanMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng diện tích sàn</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="tongDienTichSanMoi" name="tongDienTichSanMoi"
                                        value={this.state.noiDungGiayPhep.thongTinSuaChua?.tongDienTichSanMoi}
                                        onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao</label>
                                <input type="text" className="form-control" id="chieuCaoMoi" name="chieuCaoMoi"
                                    value={this.state.noiDungGiayPhep.thongTinSuaChua?.chieuCaoMoi}
                                    onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="soTangMoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tầng</label>
                                <input type="text" className="form-control" id="soTangMoi" name="soTangMoi"
                                    value={this.state.noiDungGiayPhep.thongTinSuaChua?.soTangMoi}
                                    onChange={(event) => this.setParams(event, "thongTinSuaChua")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="sogiayphepcu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép cũ (nếu có)</label>
                                <Autocomplete style={{ width: '390px' }}
                                    id="soGiayPhepCu"
                                    name="soGiayPhepCu"
                                    options={this.state.danhSachGiayPhepDaCap}
                                    // value={this.state.noiDungGiayPhep}
                                    getOptionLabel={(option) => option.giayPhep.maSoGiayPhep}
                                    onChange={(event, newValue) => this.loadThongTinTheoSoGiayPhepCu(newValue)}
                                    renderInput={(params) => {
                                        return <div className='' ref={params.InputProps.ref} >
                                            <InputBase style={{ width: '352px' }}
                                                {...params.inputProps}
                                                value={this.state.soGiayPhepAuto}
                                                id="soGiayPhepCu"
                                                name="soGiayPhepCu"
                                                //onChange={(event) => this.setParams(event, "autoComplete")}
                                                onChange={this.onChangAutoComplete}
                                                classes={bootstrapTextFieldHook.useInput()}
                                            />
                                            <i class="fas fa-search" style={{ marginLeft: '2px' }}></i>
                                        </div>
                                    }}
                                />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="ngaygiayphepcu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày giấy phép cũ</label>
                                <input type="date" className="form-control" id="ngayCap" name="ngayCap" disabled
                                    value={this.state.noiDungGiayPhep.thongTinSuaChua?.ngayGiayPhepCu ?
                                        moment(new Date(this.state.noiDungGiayPhep.thongTinSuaChua?.ngayGiayPhepCu)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep.files}
                    fileTrash={this.state.noiDungGiayPhep.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
                <br></br>
                <br></br>
                {this.state.openModel === 'kiem-tra-ngan-chan'
                    &&
                    <ModalKiemTraNganChan
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel === 'kiem-tra-ngan-chan'}
                    ></ModalKiemTraNganChan>
                }
                <ButtonGroupGiayPhep
                    idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                    isNhapDauKy={this.props.isNhapDauKy}
                    handleModalToggle={this.handleModalToggle}
                    handleAddOrUpdate={this.props.addOrUpdate}
                    maHoSo={this.state.maHoSo}
                    maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                ></ButtonGroupGiayPhep>
                <ModalDonViTuVan
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tu-van'}
                ></ModalDonViTuVan>
                <ModalDonViThamDinh
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tham-dinh'}
                ></ModalDonViThamDinh>
                <ModalKyHieuThietKe
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'ky-hieu'}
                ></ModalKyHieuThietKe>
                <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                <ModalClearAll onClearData={this.handleClearAll} onModalToggle={this.handleModalToggle} show={this.state.openModel === 'clear-all'}></ModalClearAll>

                <br />
            </div>
        )
    }
}