import { Box, ClickAwayListener, createStyles, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTE_CN_NHAPDAUKY } from '../../../routes';
import './style.css';
const useStyles = makeStyles((theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0.5),
        },
        menuItem: {
            color: "#0821af",
            textDecoration: "none",
            padding: "10px 15px",
            '&:hover': {
                color: "#0821af",
                cursor: "pointer",
                backgroundColor: "#bfc1cc",
                fontWeight: "bold",
                textDecoration: "none"
            },
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }),
);
export const Popover = (props) => {
    const classes = useStyles();
    const [showNghiepVu, setShowNghiepVu] = useState(false);

    useEffect(() => {
        setShowNghiepVu(props.showNghiepVu)
    }, [props.showNghiepVu]);

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setShowNghiepVu(false);
            props.setOpenNhapDauKy(false);
        }
    }

    const handleCloseProper = (event) => {
        if (props.btnRef.current && props.btnRef.current.contains(event.target)) {
            return;
        }
        setShowNghiepVu(false);
        props.setOpenNhapDauKy(false);
    };

    return (
        <Popper open={showNghiepVu} anchorEl={props.btnRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseProper}>
                            <MenuList autoFocusItem={showNghiepVu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {
                                    ROUTE_CN_NHAPDAUKY.map((item, k) =>
                                        <MenuItem key={k}>
                                            <NavLink exact to={{
                                                pathname: item.duongDan,
                                                state: { nhapDauKy: true }
                                            }}
                                                className={classes.menuItem} >
                                                <Box display="flex" alignItems="center">
                                                    <Box component="span" fontSize="15px"> {item.ten}</Box>
                                                </Box>
                                            </NavLink >
                                        </MenuItem>)
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default Popover;