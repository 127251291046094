
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { createFileType, downLoadFile } from "../../../api/common/exportUtil";
import AXIOS_INSTANCE from '../../../api/index';
export default class InAnDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            tepDinhKem: []
        }
    }
    componentDidMount() {
     this.getDanhSachTepDinhKem();
    }
    
    getDanhSachTepDinhKem = async () => {
        const url =`/danh-muc-bieu-mau/danh-sach/tep-dinh-kem?maNghiepVu=${this.state.maLoaiGiayPhep}&maHoSo=${this.state.maHoSo}`
        await AXIOS_INSTANCE.get(url, "").then(res => {
                if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                    this.setState({ tepDinhKem: res.data.data || [] });
                } else {
                    this.setState({ tepDinhKem: [] });
                }
            }).catch(error => {
                this.setState({ tepDinhKem: [] });
            });
    }

    exportFile = async (loaiTapTin, tenTapTin, event) => {
        const url =`/danh-muc-bieu-mau/export/giay-phep?maNghiepVu=${this.state.maLoaiGiayPhep}&idTapTin=${event.target.name}&maHoSo=${this.state.maHoSo}`;
        await AXIOS_INSTANCE.get(url, {
            responseType: 'blob',
                }).then(res => {
                if (res!=null && res.status == 200) {
                    downLoadFile(res.data, createFileType(loaiTapTin), tenTapTin);
                    toastr.success("In giấy phép xây dựng thành công!");
                } else {
                    toastr.warning('In giấy phép xây dựng thất bại!');
                }
            }).catch(error => {
                toastr.warning('In giấy phép xây dựng thất bại!');
            });
    }

    render() {

        return (
            <React.Fragment>
                <button type="button" class="btn btn-primary" style={{ fontSize: '14px', width: '70px' }} onClick={this.getDanhSachNghiepVuByMaThuTuc} href="javascript:void(0)" data-toggle="dropdown">In ấn</button> &nbsp;&nbsp;
                <div className="dropdown-menu" style={{ fontSize: '14px', width: '250px' }}>
                    <ul>
                        {this.state.tepDinhKem?.length > 0 ? this.state.tepDinhKem.map((item) => {
                            return (
                                <li className="border-bottom" style={{ marginTop: '5px' }}>
                                    <a href="#" onClick={(event ) => this.exportFile(item.loaiTapTin, item.tenTapTin,event)} name ={item.idTaiTaptin} style={{ marginLeft: '20px', marginTop: '15px', textDecoration: 'none' }}>{item.tenHienThi}</a>
                                </li>
                            );
                        })
                        :
                        <p className="text-center">Chưa cấu hình</p>
                        }
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}