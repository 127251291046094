import { Pagination } from '@material-ui/lab';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import closeIcon from "../../../../Assets/icons/u1233.png";
import deleteIcon from '../../../../Assets/icons/u626.png';
import ModalLoDatXDDCCT from './ModalLoDatXDDCCT';

export default class TableLoDatXDDCCTCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],

            openModel: false,
            deleteLodat: '',
            recordsPerPage: 0,
            noiDungGiayPhep: {
                loDatXayDungDiDoi: [],
                //drop
                loDatXayDungDiDoiDropDTOs: [],
            }
        }
    }

    componentDidUpdate() {
        console.log('loDatXayDungDiDoi : ', this.props.noiDungGiayPhep.loDatXayDungDiDoi);
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDiDoi !== this.state.data) {
            this.state.data = this.props.noiDungGiayPhep.loDatXayDungDiDoi;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs !== this.state.dataDrop) {
            this.state.dataDrop = this.props.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }

    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }

    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }

    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }

    //handle modal
    handleIdDropLoDat = (e, value) => {
        this.setState({
            deleteLodat: value
        })
    }

    handleAddLoDat = (loDat) => {
        if(!this.state.data){
            this.state.data = []
        }
        this.state.data.unshift(loDat);
        //this.setDataParam('loDatXayDungDiDoi', this.state.data);
        this.props.onUpdateDataState("loDatXayDungDiDoi", this.state.data);
        this.handleSearch();
    }

    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
        this.setState(this.state);
    }

    handleDropLoDat = (index) => {
        console.log('handleDropLoDat');
        if (this.state.data[index]?.id > 0) {
            if (!this.state.dataDrop) {
                this.state.dataDrop = []
            }
            this.state.dataDrop.push(this.state.data[index]);
        }
        this.props.onUpdateDataState("loDatXayDungDiDoiDropDTOs", this.state.dataDrop);
        console.log(' this.state.data : ', this.state.data);
        this.state.data.splice(index, 1);

        this.props.onUpdateDataState("loDatXayDungDiDoi", this.state.data);
        let valuePage;
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa lô đất xây dựng thành công')
        //this.handleSearch();
    }

    handleModalToggle = () => {
        this.state.openModel = !this.state.openModel;
        this.setState(this.state);
    }

    render() {

        return (
            <div>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Số Tờ</th>
                            <th>Số thửa</th>
                            <th>Một phần thửa</th>
                            <th className="text-center">Xóa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.soTo}</td>
                                    <td>{item.soThua}</td>
                                    <td className="text-center">
                                        <input type="checkbox" checked={item.motPhanThua} readOnly={true} />
                                    </td>
                                    <td align="center"><button data-toggle="modal" data-target="#modalXoaLoDatCreate" onClick={(e) => this.handleIdDropLoDat(e, index)}><img src={deleteIcon}></img></button></td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                    <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.handleModalToggle}>Thêm mới</button>
                    </div>
                </div>
                <div class="modal fade" id="modalXoaLoDatCreate" tabindex="-1" role="dialog" aria-labelledby="modalXoaLoDat" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat1" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                                <button data-dismiss="modal" className="btn btn-primary btn-sm" onClick={() => this.handleDropLoDat(this.state.deleteLodat)}> Xóa</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" className="btn btn-secondary btn-sm"> Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    <ModalLoDatXDDCCT
                        onUpdateData={this.handleAddLoDat}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalLoDatXDDCCT>
                }
            </div>
        )
    }
}