import React from 'react';
import closeIcon from "../../../Assets/icons/u1233.png";
import deleteIcon from '../../../Assets/icons/u626.png';
import editIcon from '../../../Assets/icons/u624.png';
import { Pagination } from '@material-ui/lab';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ModalThongTinThamDinh from './ModalThongTinThamDinh'

export default class TableTTTD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            deleteLodat: 0,
            recordsPerPage: 0,
            noiDungGiayPhep: {
                thongTinThamDinhDTOs: [],
                thongTinThamDinhDropDTOs: []
            },
            thongTinThamDinh: {
                id: 0,
                noiDung: '',
                files: [],
                fileTrash: []
            },
            indexEdit: -1
        }
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep.thongTinThamDinhs !== this.state.data && this.props.noiDungGiayPhep.thongTinThamDinhs !== undefined) {
            this.state.data = this.props.noiDungGiayPhep.thongTinThamDinhs;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    initData = () => {
        if (this.props.noiDungGiayPhep !== undefined) {
            this.setState({
                pageNumber: 1,
                pageSize: 5,
                data: [],
                dataDrop: [],
                dataPagi: [],
                openModel: false,
                deleteLodat: '',
                recordsPerPage: 0,
                noiDungGiayPhep: {
                    thongTinThamDinhDTOs: this.props.noiDungGiayPhep.thongTinThamDinhs || [],
                    //drop
                    thongTinThamDinhDropDTOs: [],
                }
            })
        } else {
            this.state.noiDungGiayPhep = {
                thongTinThamDinhDTOs: [],
                thongTinThamDinhDropDTOs: []
            };
            this.setState(this.state);
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }

    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }

    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }

    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }

    handleIdDropLoDat = (e, value) => {
        this.setState({
            deleteLodat: value
        })
    }

    openAdd = () => {
        this.state.indexEdit = -1;
        this.state.thongTinThamDinh = {};
        this.handleModalToggle();
    }

    openEdit = (index) => {
        const { indexEdit, thongTinThamDinh, data } = this.state;
        this.setState({ ...this.state, indexEdit: index, thongTinThamDinh: { ...thongTinThamDinh, ...data[index] } });
        this.handleModalToggle();
    }

    handleAdd = (value) => {
        const { data } = this.state
        const newData = [{ ...value }, ...data];
        // this.setDataParam('chuDauTuDTOs', this.state.data);
        this.props.onUpdateDataState('thongTinThamDinhs', newData);
        this.setState({
            data: [...newData],
            thongTinThamDinh: {
                id: 0,
                noiDung: '',
                files: [],
                fileTrash: []
            }
        })
    }

    handleUpdate = (index, value) => {
        const { data } = this.state
        const newData = [...data.slice(0, index), { ...data[index], ...value }, ...data.slice(index + 1)]
        this.props.onUpdateDataState('thongTinThamDinhs', newData);
    }

    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
    }

    handleDropLoDat = (index) => {
        if (this.state.data[index]?.id > 0) {
            this.state.dataDrop.push(this.state.data[index]);
        }
        this.props.onUpdateDataState('thongTinThamDinhDrops', this.state.dataDrop);
        this.state.data.splice(index, 1);
        this.setDataParam('thongTinThamDinhDTOs', this.state.data);
        this.setDataParam('thongTinThamDinhDropDTOs', this.state.dataDrop);
        this.props.onUpdateDataState('thongTinThamDinhs', this.state.data);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa thông tin thẩm định thành công');
        this.handleSearch();
    }

    handleModalToggle = () => {
        const { openModel } = this.state
        this.setState({ openModel: !openModel });
    }
    renderFileName = (files) => {
        let fileName = '';
        if (files && files.length > 0) {
            files.forEach((item, index) => {
                if (index == 0) {
                    fileName += item.name
                } else {
                    fileName += ', ' + item.name
                }
            });
        }
        return fileName;
    }
    render() {
        return (
            <>
                <div style={{ marginTop: '10px' }}>
                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                        <thead>
                            <tr className="table-secondary">
                                <th>STT</th>
                                <th>Nội dung</th>
                                <th>Tập tin</th>
                                <th className="text-center">Hoạt động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                        <td>{item.noiDung}</td>
                                        <td>{this.renderFileName(item.files)}</td>
                                        <td width="10%">
                                            <div className="row">
                                                <div className="col-md">
                                                    <button onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index)}><img src={editIcon} style={{ float: 'left', marginLeft: '20px' }}></img></button>
                                                </div>
                                                <div className="col-md">
                                                    <button data-toggle="modal" data-target="#modalXoaTTTD" onClick={(e) => this.handleIdDropLoDat(e, index)}><img src={deleteIcon} style={{ float: 'right', marginRight: '20px' }}></img></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className="row">
                        {/* pagination*/}
                        <div className="col-md-6" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                            <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                            <div>
                                <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                            </div>
                        </div>
                        <div className="col-md text-right" style={{ fontSize: '14px' }}>
                            <button type="button" className="btn btn-primary btn-lg" onClick={this.openAdd}>Thêm mới</button>
                        </div>
                    </div>

                </div>

                { this.state.openModel &&
                    <ModalThongTinThamDinh
                        indexEdit={this.state.indexEdit}
                        data={this.state.thongTinThamDinh}
                        onAddData={this.handleAdd}
                        onUpdateData={this.handleUpdate}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalThongTinThamDinh>
                }

                <div class="modal fade" id="modalXoaTTTD" tabindex="-1" role="dialog" aria-labelledby="modalXoaTTTD" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                                <button data-dismiss="modal" className="btn btn-primary btn-sm" onClick={() => this.handleDropLoDat(this.state.deleteLodat)}> Xóa</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" className="btn btn-secondary btn-sm"> Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </>
        )
    }
}