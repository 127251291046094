import { Box, Grid } from "@material-ui/core";
import UploadFileService from "../../../api/common/UploadFile";
import DropZoneCustom from "../../commons/components/CustomDropzone/DropZoneCustom";
import { ACCEPT_FILES } from "../../Constants/constants";
import { createFileType, downLoadFile } from "../../../api/common/exportUtil";
import AXIOS_INSTANCE from "../../../api";
import { getObject } from "../../../helpers/localStorage";
import StringUtil from "../../commons/components/utils/stringUtils";

const UploadFilesCommon = (props) => {
  //handle file
  function handleChangeStatus(file) {
    if (
      file.xhr?.readyState === 4 &&
      file.xhr.status === 200 &&
      file.meta.status === "done"
    ) {
      let res = JSON.parse(file.xhr.response);
      let C_USER_DATA_DE = getObject("C_USER_DATA");
      res.data.map((item) => {
        item.userId = C_USER_DATA_DE.userId;
      });
      setDataFiles([...res.data]);
      file.remove();
    }
  }
  function setDataFiles(memData) {
    let mems = [];
    if (props.files) {
      mems = [...props.files];
    }
    mems = mems.concat(memData);
    props.onUpdateDataState("files", mems);
  }
  function handleDeleteFileServer(item, index) {
    onDeleteFiles(index);
  }
  function onDeleteFiles(index) {
    let mems = [...props.files];
    if (mems) {
      let item = mems[index];
      mems.forEach((file) => {
        if (item === item) {
          let tempFileTrash = [];
          if (props.fileTrash) {
            tempFileTrash = [...props.fileTrash];
            tempFileTrash.push(file);
          } else {
            tempFileTrash = [file];
          }

          props.onUpdateDataState("fileTrash", tempFileTrash);
        }
      });
      if (index > -1) {
        mems.splice(index, 1);
      }
      props.onUpdateDataState("files", mems);
    }
  }
  function handleDownloadFile(file) {
    if (file.motCua && !StringUtil.isNullOrEmty(file.url)) {
      let link = document.createElement("a");
      link.href = file?.url ? file?.url : "";
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(file?.url ? file?.url : "");
    } else {
      UploadFileService.downloadFile(file.docId).then((res) => {
        downLoadFile(res.data, createFileType(file.extension), file.name);
      });
    }
  }

  return (
    <>
      <Box>
        <Grid container>
          <DropZoneCustom
            acceptFiles={ACCEPT_FILES}
            attachFileServer={props.files}
            url={
              AXIOS_INSTANCE.defaults.baseURL + "/uploadcontroller/upload-file"
            }
            handleChangeStatus={handleChangeStatus}
            handleDeleteFileServer={handleDeleteFileServer}
            handleDownloadFile={handleDownloadFile}
          />
        </Grid>
      </Box>
    </>
  );
};

export default UploadFilesCommon;
