import React from 'react';
import moment from "moment";
import ThongTinCongTrinhRender from './ThongTinCongTrinhRender';
import ThongTinXayDungRender from './ThongTinXayDungRender';
import ThongTinCongTrinhNgamRender from './ThongTinCongTrinhNgamRender';
import ThongTinXDCTTheoTuyenRender from './ThongTinXDCTTheoTuyenRender';
import QuyenSuDungDatRender from './QuyenSuDungDatRender'
import { DI_DOI } from '../../../../../Constants/constants';
import ViTriXayDungRender from './ViTriXayDungRender';
import ViTriDiDoiRender from './ViTriDiDoiRender';

export default class ChiTietGPXDWrapper extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>{this.props.loaiBienDong !== DI_DOI.ma &&
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Số giấy phép</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.noiDungGiayPhep.giayPhep?.maSoGiayPhep}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Ngày cấp</p>
                            </div>
                            <div className="col-md-6">
                                <p>{moment(new Date(this.props.noiDungGiayPhep.giayPhep?.ngayCap).getTime()).format('DD/MM/yyyy')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Loại giấy phép</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.noiDungGiayPhep.giayPhep?.tenLoaiGiayPhep}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>

                {this.props.noiDungGiayPhep.thongTinCongTrinh &&
                    <ThongTinCongTrinhRender
                        thongTinCongTrinh={this.props.noiDungGiayPhep.thongTinCongTrinh}
                    ></ThongTinCongTrinhRender>
                }

                {
                    this.props.noiDungGiayPhep.viTriXayDung &&
                    <ViTriXayDungRender
                        loaiBienDong={this.props.loaiBienDong}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                    ></ViTriXayDungRender>
                }
                {this.props.renderViTriDiDoi &&
                    this.props.noiDungGiayPhep.viTriDiDoi &&
                    <ViTriDiDoiRender
                        loaiBienDong={this.props.loaiBienDong}
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                    />
                }
                {this.props.noiDungGiayPhep.thongTinXayDung &&
                    <ThongTinXayDungRender
                        thongTinXayDung={this.props.noiDungGiayPhep.thongTinXayDung}
                    ></ThongTinXayDungRender>
                }
                {
                    this.props.noiDungGiayPhep.thongTinCongTrinhNgam &&
                    <ThongTinCongTrinhNgamRender
                        noiDungGiayPhep={this.props.noiDungGiayPhep}
                    ></ThongTinCongTrinhNgamRender>
                }
                {
                    this.props.noiDungGiayPhep.thongTinXDCTTheoTuyen &&
                    <ThongTinXDCTTheoTuyenRender
                        thongTinXDCTTheoTuyen={this.props.noiDungGiayPhep.thongTinXDCTTheoTuyen}
                    ></ThongTinXDCTTheoTuyenRender>
                }
                {
                    this.props.noiDungGiayPhep.quenSuDungDat &&
                    <QuyenSuDungDatRender
                        quenSuDungDat={this.props.noiDungGiayPhep.quenSuDungDat}
                    ></QuyenSuDungDatRender>
                }
            </>
        )
    }
}