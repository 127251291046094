import React from 'react';
import api from "../../../../../api/index";
import { CreateGiayPhepDTO } from '../../../../../api/Init/CapPhepXayDungFinal';
import ChiTietGPXDWrapper from '../SubChiTietGPXD/SubNoiDungCapPhep/ChiTietGPXDWrapper';

export default class NoiDungCapPhep extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            noiDungGiayPhep: { ...CreateGiayPhepDTO },
            search: {
                keywords: this.props.idGiayPhep,
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5
            },
        }
    }


    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
    }

    goBack = () => {
        window.history.back();
    }

    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br />
                {this.state.noiDungGiayPhep !== null &&
                    <ChiTietGPXDWrapper
                        renderViTriDiDoi = {true}
                        loaiBienDong = {this.state.noiDungGiayPhep.giayPhep.loaiBienDong}
                        noiDungGiayPhep={this.state.noiDungGiayPhep}
                    ></ChiTietGPXDWrapper>
                }
                <>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-md-3">
                            <button type="button" class="btn btn-primary" style={{ fontSize: '14px', width: '75px' }}>In ấn</button>&nbsp;&nbsp;
                        <button type="button" onClick={this.goBack} class="btn btn-primary" style={{ fontSize: '14px', width: '75px' }}>Trở về</button>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                </>
            </div>
        )
    }
}