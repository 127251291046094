import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import removeIcon from '../../../Assets/icons/u27633.png';
import resetIcon from '../../../Assets/icons/u27634.png';
import menuIcon from '../../../Assets/icons/u27725.png';
import editIcon from '../../../Assets/icons/u624.png';
import { ROUTE_CHITIET, ROUTE_CN_DDCT, ROUTE_CN_SuaChua, ROUTE_CN_XDCTCTH, ROUTE_CN_XDCTDA, ROUTE_CN_XDCTGDKTT, ROUTE_CN_XDCTKTT, ROUTE_CN_XDCTNgam, ROUTE_CN_XDCTQC, ROUTE_CN_XDCTT, ROUTE_CN_XDCTTG, ROUTE_CN_XDCTTinNguong, ROUTE_CN_XDCTTuongDai, ROUTE_HUYGIAYPHEP, ROUTE_THUHOIGIAYPHEP } from '../../../routes';
import StringUtil from '../../../utils/stringUtils';
import { CONG_TRINH_CO_THOI_HAN, CONG_TRINH_DI_DOI, CONG_TRINH_DU_AN, CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN, CONG_TRINH_GIAI_DOAN_THEO_TUYEN, CONG_TRINH_KHONG_THEO_TUYEN, CONG_TRINH_NGAM, CONG_TRINH_QUANG_CAO, CONG_TRINH_SUA_CHUA, CONG_TRINH_THEO_TUYEN, CONG_TRINH_TIN_NGUONG, CONG_TRINH_TON_GIAO, CONG_TRINH_TUONG_DAI } from '../../Constants/constants';

export const TableGPXD = (props) => {

    let history = useHistory();
    const [pageIndex, setPageIndex] = useState(0);
    const [list, setList] = useState([]);

    useEffect(() => {
        setPageIndex(props.pageIndex);
        setList(props.list);
    }, [props.pageIndex, props.list]);

    const handleClickEdit = (item) => {
        let pathLink = "";
        if (item.maLoaiGiayPhep === CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN.ma) {
            pathLink = ROUTE_CN_XDCTGDKTT.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_GIAI_DOAN_THEO_TUYEN.ma) {
            pathLink = ROUTE_CN_XDCTTG.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_DU_AN.ma) {
            pathLink = ROUTE_CN_XDCTDA.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_SUA_CHUA.ma) {
            pathLink = ROUTE_CN_SuaChua.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_KHONG_THEO_TUYEN.ma) {
            pathLink = ROUTE_CN_XDCTKTT.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_THEO_TUYEN.ma) {
            pathLink = ROUTE_CN_XDCTT.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_NGAM.ma) {
            pathLink = ROUTE_CN_XDCTNgam.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_DI_DOI.ma) {
            pathLink = ROUTE_CN_DDCT.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_TON_GIAO.ma) {
            pathLink = ROUTE_CN_XDCTTG.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_TIN_NGUONG.ma) {
            pathLink = ROUTE_CN_XDCTTinNguong.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_QUANG_CAO.ma) {
            pathLink = ROUTE_CN_XDCTQC.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_TUONG_DAI.ma) {
            pathLink = ROUTE_CN_XDCTTuongDai.duongDan;
        }
        if (item.maLoaiGiayPhep === CONG_TRINH_CO_THOI_HAN.ma) {
            pathLink = ROUTE_CN_XDCTCTH.duongDan;
        }
        history.push(pathLink, { id: item.id, nhapDauKy: true });
    }

    const handleClickView = (id) => {
        const pathName = ROUTE_CHITIET.duongDan;
        history.push(pathName, { id: id });
    }
    const handleClickThuHoiView = (id) => {
        const pathName = ROUTE_THUHOIGIAYPHEP.duongDan;
        history.push(pathName, { id: id });
    }
    const handleClickHuyView = (id) => {
        const pathName = ROUTE_HUYGIAYPHEP.duongDan;
        history.push(pathName, { id: id });
    }
    return (
        <table className="table table-bordered text-center">
            <thead>
                <tr className="table-secondary" style={{ fontSize: '13px' }}>
                    <th>STT</th>
                    <th>Loại giấy phép</th>
                    <th>Số giấy phép</th>
                    <th>Ngày cấp</th>
                    <th>Chủ đầu tư</th>
                    <th>Vị trí xây dựng</th>
                    <th>Số lần điều chỉnh</th>
                    <th>Số lần gia hạn</th>
                    <th>Tình trạng</th>
                    <th>Thao tác</th>
                </tr>
            </thead>
            <tbody>
                {
                    list && list.length ? list.map((item, index) => {
                        return (
                            <tr>
                                <td style={{ width: '8px' }}><small>{pageIndex + index + 1}</small></td>
                                <td className="text-left" style={{ width: '186px' }}><small>{item.tenLoaiGiayPhep}</small>
                                </td>
                                <td style={{ width: '115px' }}>
                                    <small>
                                        <a href="javascript:void(0)" onClick={(event) => handleClickView(item.id)}>{item.tenSoGiayPhep}</a>
                                    </small>
                                </td>
                                <td style={{ width: '115px' }}><small>{StringUtil.timeToDDMMYYYY(item.ngayCap)}</small></td>
                                <td className="text-left" style={{ width: '115px' }}><small>{item.tenChuDauTu}</small></td>
                                <td className="text-left" style={{ width: '205px' }}><small>{item.tenLoaiGiayPhep == "Cấp phép di dời công trình" ? item.viTriXayDungDiDoi : item.viTriXayDung}</small></td>
                                <td style={{ width: '8px', fontSize: '14px' }}>{item.soLanDieuChinh > 0 ? item.soLanDieuChinh : 0}</td>
                                <td style={{ width: '8px', fontSize: '14px' }}>0</td>
                                <td style={{ width: '137px' }}><small>{item.tenTrangThai}</small></td>
                                <td style={{ width: '71px' }}>
                                    <div className="dropdown dropleft center">
                                        <button type="button" className="btn" data-toggle="dropdown">
                                            <img src={menuIcon}></img>
                                        </button>
                                        <div className="dropdown-menu" style={{ width: '189px' }}>
                                            <ul className="wrap-list">
                                                <li className="from-group" style={{ margin: '5px' }}>
                                                    <img style={{ display: 'unset', margin: '5px' }} src={editIcon}></img>
                                                    <a href="javascript:void(0)" onClick={(event) => handleClickEdit(item)} style={{ marginLeft: '0px', textDecoration: 'none' }}>Chỉnh sửa</a>
                                                </li>
                                                {
                                                    (item.maTrangThai === '3' || item.maTrangThai === '1') &&
                                                    <li className="from-group" style={{ margin: '5px' }}>
                                                        <img style={{ display: 'unset', margin: '5px' }} src={resetIcon}></img>
                                                        <a href="javascript:void(0)" onClick={(event) => handleClickThuHoiView(item.id)} style={{ marginLeft: '0px', textDecoration: 'none' }}>{item.maTrangThai === '3' ? 'Chỉnh sửa thu hồi' : 'Thu hồi giấy phép'} </a>
                                                    </li>
                                                }
                                                {
                                                    (item.maTrangThai === '4' || item.maTrangThai === '1') &&
                                                    <li style={{ margin: '5px' }}>
                                                        <img style={{ display: 'unset', margin: '5px' }} src={removeIcon}></img>
                                                        <a href="javascript:void(0)" onClick={(event) => handleClickHuyView(item.id)} style={{ marginLeft: '0px', textDecoration: 'none' }}>{item.maTrangThai === '4' ? 'Chỉnh sửa hủy' : 'Hủy giấy phép'} </a>
                                                    </li>
                                                }


                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                        :
                        <tr>
                            <td colSpan={10}> Không có dữ liệu</td>
                        </tr>}
            </tbody>
        </table>
    )

}