import { Grid, SvgIcon } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SelectLoaiGiayPhep from '../../CapPhepDungChung/InputGroup/SelectLoaiGiayPhep';
import SelectQuanHuyen from '../../CapPhepDungChung/InputGroup/SelectQuanHuyen';
import SelectTinhTrang from '../../CapPhepDungChung/InputGroup/SelectTinhTrang';
import GiayPhepService from "../../../../api/service/CapPhepXDCTHService";
import { createFileType, downLoadFile } from '../../../../api/common/exportUtil';

export default class FormBaoCaoCPXD extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            danhMucLoaiGiayPhep: [],
            danhMucTinhTrang: [],
            kyThongKe: 'year',
            nam: new Date().getFullYear().toString(),
            tongDienTichSan: '',
            tuNgay: '',
            denNgay: '',
            maHuyenThiThanhPho: '',
            loaiGiayPhep: '',
            tinhTrang: '',
            isYear: true,
            required: false
        }
    }

    componentDidMount() {

    }

    validation = () => {
        let valid = true;
        this.state.errors = {};
        // if (this.state.isYear) {
        //     if (StringUtil.isNullOrEmty(this.state.nam)) {
        //         this.state.errors.nam = 'Vui lòng chọn năm';
        //         valid = false;
        //     }
        // } else {
        //     if (StringUtil.isNullOrEmty(this.state.tuNgay)) {
        //         this.state.errors.tuNgay = 'Vui lòng chọn từ ngày';
        //         valid = false;
        //     }
        //     if (StringUtil.isNullOrEmty(this.state.denNgay)) {
        //         this.state.errors.denNgay = 'Vui lòng chọn đến ngày';
        //         valid = false;
        //     }
        // }
        this.setState(this.state);
        return valid;
    }

    onSearch = () => {
        if (this.validation()) {
            let tuNgayValue = '', denNgayValue = '';
            if (this.state.isYear) {
                tuNgayValue = this.state.nam + '-01-01';
                denNgayValue = this.state.nam + '-12-31';
            } else {
                tuNgayValue = this.state.tuNgay;
                denNgayValue = this.state.denNgay;
            }
            const data = {
                "kyThongKe": this.state.kyThongKe,
                "nam": this.state.nam,
                "ngayCapTu": new Date(tuNgayValue).getTime(),
                "ngayCapDen": new Date(denNgayValue).getTime(),
                "maHuyenThiThanhPho": this.state.maHuyenThiThanhPho,
                "loaiGiayPhep": this.state.loaiGiayPhep,
                "tongDienTichSan": this.state.tongDienTichSan,
                "tinhTrang": this.state.tinhTrang,
                'hoatDong': 1
            };
            this.props.onSearch(data);
        }
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (name === "kyThongKe") {
            let isYear;
            if (value !== "year") {
                isYear = false;
            } else {
                isYear = true;
            }
            this.state.isYear = isYear;
        }
        this.state[name] = value;
        this.setState(this.state)
    }

    submitSearch = (event) => {
    }

    clearData = (event) => {
        this.setState({
            kyThongKe: 'year',
            nam: new Date().getFullYear().toString(),
            tuNgay: '',
            denNgay: '',
            maHuyenThiThanhPho: '',
            loaiGiayPhep: '',
            tinhTrang: '',
            isYear: true
        })
    }

    renderYear = () => {
        let curYear = new Date().getFullYear();
        let listYear = [];
        for (var i = 0; i < 20; i++) {
            listYear.push((curYear--).toString())
        }
        return listYear
    }

    downloadFile = async (extension) => {
        let tuNgayValue = '', denNgayValue = '';
        if (this.state.isYear) {
            tuNgayValue = this.state.nam + '-01-01';
            denNgayValue = this.state.nam + '-12-31';
        } else {
            tuNgayValue = this.state.tuNgay;
            denNgayValue = this.state.denNgay;
        }
        let request = {
            kyThongKe: this.state.kyThongKe,
            nam: this.state.nam,
            soGiayPhep: this.state.soGiayPhepValue,
            loaiGiayPhep: this.state.loaiGiayPhepValue,
            ngayCapTu: new Date(tuNgayValue).getTime(),
            ngayCapDen: new Date(denNgayValue).getTime(),
            tinhTrang: this.state.tinhTrangValue,
            chuDauTu: this.state.chuDauTuValue,
            tinhTp: this.state.tinhTpValue,
            maHuyenThiThanhPho: this.state.huyenThiValue,
            phuongXa: this.state.phuongXaValue,
            tongDienTichSan: this.state.tongDienTichSan,
            hoatDong: 1,
            exportFileName: 'Bao_cao_giay_phep',
            exportType: extension
        };
        await new GiayPhepService().downloadFileBaoCaoGiayPhep(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Bao_cao_giay_phep');
        }).finally(() => {

        });
    }

    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>
                <br></br>
                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}>BÁO CÁO TÌNH HÌNH CẤP GIẤY PHÉP XÂY DỰNG</h6>
                <br></br>
                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="kyThongKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Kỳ báo cáo</label>
                            <select
                                className="form-control"
                                id="kyThongKe" name="kyThongKe"
                                value={this.state.kyThongKe}
                                style={{ fontSize: '14px' }}
                                onChange={this.setParams}>
                                <option value="year">Năm</option>
                                <option value="period">Khoảng thời gian</option>
                            </select>
                        </div>
                    </div>
                    {this.state.isYear &&
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="nam" style={{ fontWeight: 'bold', fontSize: '14px' }}>Năm</label>
                                <select
                                    className="form-control"
                                    id="nam" name="nam"
                                    value={this.state.nam}
                                    style={{ fontSize: '14px' }}
                                    onChange={this.setParams}>
                                    {this.renderYear().map((item, index) => {
                                        return (
                                            <option value={item}>Năm {item}</option>
                                        )
                                    })
                                    }
                                </select>
                                <div style={Boolean(this.state.errors?.nam) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.nam}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {!this.state.isYear &&
                        <>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="tuNgay" style={{ fontWeight: 'bold', fontSize: '14px' }}>Từ ngày</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="tuNgay" name="tuNgay"
                                        value={this.state.tuNgay}
                                        onChange={this.setParams}
                                    ></input>
                                    <div style={Boolean(this.state.errors?.tuNgay) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.tuNgay}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="denNgay" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đến ngày</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="denNgay" name="denNgay"
                                        onChange={this.setParams}
                                    ></input>
                                    <div style={Boolean(this.state.errors?.denNgay) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.denNgay}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng diện tích sàn</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="tongDienTichSan" name="tongDienTichSan"
                                        value={this.state.tongDienTichSan}
                                        onChange={this.setParams} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <SelectQuanHuyen
                                    quanHuyenValue={this.state.maHuyenThiThanhPho}
                                    setParams={this.setParams}
                                    isRequired={this.state.required}
                                ></SelectQuanHuyen>
                            </div>
                            <div class="col-md-6">
                                <SelectLoaiGiayPhep
                                    loaiGiayPhep={this.state.loaiGiayPhep}
                                    setParams={this.setParams}
                                ></SelectLoaiGiayPhep>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <SelectTinhTrang
                                    tinhTrangValue={this.state.tinhTrang}
                                    setParams={this.setParams}
                                ></SelectTinhTrang>
                            </div>
                            <div class="col-md-6" style={{ marginTop: '30px' }}>
                                <button className='btn btn-danger btn-lg' onClick={this.clearData}>Làm mới</button>
                                &nbsp;&nbsp;
                                <button className='btn btn-primary btn-lg' onClick={this.onSearch}>Tổng hợp</button>
                            </div>
                        </div>
                    </div>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('docx');
                        }}>
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('pdf');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            this.downloadFile('xlsx');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                            </SvgIcon>
                        </Link>
                    </Grid>
                </div>
            </div>
        )
    }
}