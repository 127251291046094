import React from 'react';
import TableLoDatXD from '../CapPhepDungChung/NDGP/TableLoDatXD';
import ModalDonViTuVan from '../CapPhepDungChung/NDGP/ModalDonViTuVan';
import ModalDonViThamDinh from '../CapPhepDungChung/NDGP/ModalDonViThamDinh';
import ModalKyHieuThietKe from '../CapPhepDungChung/NDGP/ModalKyHieuThietKe';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import UploadFiles from '../CapPhepDungChung/UploadFiles';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import InputCapCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputCapCongTrinh';
import InputLoaiCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputLoaiCongTrinh';
import InputPhanLoaiXayDung from '../CapPhepDungChung/DanhMucDungRieng/InputPhanLoaiXayDung';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import SelectQuanHuyen from '../CapPhepDungChung/InputGroup/SelectQuanHuyen';
import SelectPhuongXa from '../CapPhepDungChung/InputGroup/SelectPhuongXa';
import moment from 'moment';
import DropZoneCustom from '../../commons/components/CustomDropzone/DropZoneCustom';
import AXIOS_INSTANCE from '../../../api';
import { ACCEPT_FILES } from '../../Constants/constants';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import UploadFileService from "../../../api/common/UploadFile";
import { Box, Grid } from '@material-ui/core';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

export default class FormCPXDCTTonGiao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            openModel: false,
            noiDungGiayPhep: {
                thongTinCongTrinh: {},
                thongTinXayDung: {},
                viTriXayDung: {},
                loDatXayDungs: [],
                loDatXayDungDropDTOs: [],
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
            errors: {}
        }
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinh != this.state.noiDungGiayPhep.thongTinCongTrinh) {
            this.state.noiDungGiayPhep.thongTinCongTrinh = this.props.noiDungGiayPhep.thongTinCongTrinh;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung != this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriXayDung != this.state.noiDungGiayPhep.viTriXayDung) {
            this.state.noiDungGiayPhep.viTriXayDung = this.props.noiDungGiayPhep.viTriXayDung;
            this.setState(this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungs != this.state.noiDungGiayPhep.loDatXayDungs) {
            this.state.noiDungGiayPhep.loDatXayDungs = this.props.noiDungGiayPhep.loDatXayDungs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files != this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash != this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep != this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }
    }

    setParams = (event, key) => {
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;
        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key == 'thongTinCongTrinh') {
            this.state.noiDungGiayPhep.thongTinCongTrinh[name] = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (key == 'thongTinXayDung') {
            if (this.state.noiDungGiayPhep.thongTinXayDung === null) {
                this.state.noiDungGiayPhep.thongTinXayDung = {};
            }
            this.state.noiDungGiayPhep.thongTinXayDung[name] = value;
            this.props.onUpdateDataState('thongTinXayDung', this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (key == 'viTriXayDung') {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (key == 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
        if (key === "HuyenThiThanhPho") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.huyenThiThanhPho = text;
            this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (name === "maPhuongXaThiTran") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.phuongXaThiTran = text;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
    }

    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key === 'loDatXayDungDropDTOs') {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
        if (key === "donViThamDinh") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViThamDinh = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "donViTuVan") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "kyHieuThietKe") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
    }
    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }
    handleChangeNhapDauKy(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }
    render() {
        const CustomInputNhapDauKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>

                {this.props.isNhapDauKy &&
                    <div class="col-md-12">
                        <br />
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="maSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    <input type="text" className="form-control" id="maSoGiayPhep" name="maSoGiayPhep"
                                        value={this.state.noiDungGiayPhep?.giayPhep?.maSoGiayPhep} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} />
                                    <div style={Boolean(this.state.errors?.maSoGiayPhep) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.maSoGiayPhep}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap"
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap ? moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                    <DatePicker
                                        customInput={<CustomInputNhapDauKy />}
                                        selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                        onChange={this.handleChangeNhapDauKy.bind(this)}
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                        name="ngayCap"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                    <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="tenCongTrinh" name="tenCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep?.thongTinCongTrinh?.tenCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")} />
                            </div>
                            <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tinhThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tỉnh/Thành phố <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="tinhThanhPho"
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.tinhThanhPho} style={{ fontSize: '14px' }} name="tinhThanhPho" disabled>
                                    <option selected>Thành phố Cần Thơ</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <SelectQuanHuyen
                                quanHuyenValue={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectQuanHuyen>
                        </div>
                        <div class="col-md-3">
                            <SelectPhuongXa
                                maQuanHuyen={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                phuongXaValue={this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectPhuongXa>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cấp công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="capCongTrinh" name="capCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh?.capCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.capCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.capCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                {/* <select className="form-control" id="duong" name="duong"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}>
                                    <option value="">--Chọn đường--</option>
                                    <option value="Đường 1">Đường 1</option>
                                    <option value="Đường 2">Đường 2</option>
                                    <option value="Đường 3">Đường 3</option>
                                </select> */}
                                <input type="text" className="form-control" id="duong" name="duong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div className="form-group">
                                <label htmlFor="soNhaDuong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số nhà, đường</label>
                                <input type="text" className="form-control" id="soNhaDuong" name="soNhaDuong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.soNhaDuong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="loaiCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="loaiCongTrinh" name="loaiCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh?.loaiCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn Loại công trình--</option>
                                    <InputLoaiCongTrinh></InputLoaiCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.loaiCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.loaiCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diaChiCu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                <input type="text" className="form-control" id="diaChiCu" name="diaChiCu"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.diaChiCu} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="phanLoaiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phân loại xây dựng <span style={{ color: 'red' }}>(*)</span></label>
                                        <select className="form-control" id="phanLoaiXayDung" name="phanLoaiXayDung"
                                            disabled={this.props.disabled ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh?.phanLoaiXayDung} style={{ fontSize: '14px' }}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                            <option value="">--Chọn loại xây dựng--</option>
                                            <InputPhanLoaiXayDung></InputPhanLoaiXayDung>
                                        </select>
                                        <div style={Boolean(this.state.errors?.phanLoaiXayDung) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.phanLoaiXayDung}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViTuVan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị tư vấn</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViTuVan" name="donViTuVan"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh?.donViTuVan}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tu-van')}></i></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <label htmlFor="kyHieuThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ký hiệu thiết kế</label>
                                    <p style={{ display: 'flex' }}>
                                        <input type="text"
                                            className="form-control"
                                            id="kyHieuThietKe" name="kyHieuThietKe"
                                            style={{ fontSize: '14px' }}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh?.kyHieuThietKe}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                            readOnly={this.props.readonly ? true : false} />
                                        <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                            onClick={() => this.handleModalToggle('ky-hieu')}></i></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViThamDinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị thẩm tra</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViThamDinh" name="donViThamDinh"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh?.donViThamDinh}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tham-dinh')}></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="soNha" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lô đất xây dựng</label>
                                <TableLoDatXD
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.setParamLoDats}
                                    disabled={this.props.disabled}
                                ></TableLoDatXD>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div className="form-group">
                        <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                        <input type="text" className="form-control" id="ghiChu" name="ghiChu"
                            readOnly={this.props.readonly ? true : false}
                            value={this.state.noiDungGiayPhep.thongTinCongTrinh?.ghiChu}
                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")} style={{ fontSize: '14px' }} />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tongDienTichSan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tổng diện tích sàn</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="tongDienTichSan" name="tongDienTichSan"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.tongDienTichSan}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichXayDungTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích xây dựng tầng một</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichXayDungTangMot" name="dienTichXayDungTangMot"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.dienTichXayDungTangMot}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoTangMot" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao tầng một</label>
                                <input type="number" className="form-control" id="chieuCaoTangMot" name="chieuCaoTangMot"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoTangMot}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div >
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chieuCaoCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều cao công trình</label>
                                <input type="number" className="form-control" id="chieuCaoCongTrinh" name="chieuCaoCongTrinh"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuCaoCongTrinh}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div >
                    </div >
                </div >
                <div class="col-md-12">
                    <div className="form-group">
                        <label htmlFor="soTang" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tầng</label>
                        <input type="text" className="form-control" id="soTang" name="soTang"
                            value={this.state.noiDungGiayPhep.thongTinXayDung?.soTang}
                            onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                    </div>
                </div >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="cotNen" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cốt nền</label>
                                <div className="input-group">
                                <input type="number" className="form-control" id="cotNen" name="cotNen"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.cotNen}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                <p style={{ marginLeft: '5px', marginTop: '7px' }}>m</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="matDoXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Mật độ xây dựng</label>
                                <input type="number" className="form-control" id="matDoXayDung" name="matDoXayDung"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.matDoXayDung}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="heSoSuDungDat" style={{ fontWeight: 'bold', fontSize: '14px' }}>Hệ số sử dụng đất</label>
                                <input type="text" className="form-control" id="heSoSuDungDat" name="heSoSuDungDat"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.heSoSuDungDat}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div >
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chiGioiDuongDo" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chỉ giới đường đỏ</label>
                                <input type="text" className="form-control" id="chiGioiDuongDo" name="chiGioiDuongDo"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chiGioiDuongDo}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div >
                    </div >
                </div >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chiGioiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chỉ giới xây dựng</label>
                                <input type="text" className="form-control" id="chiGioiXayDung" name="chiGioiXayDung"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chiGioiXayDung}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="mauSac" style={{ fontWeight: 'bold', fontSize: '14px' }}>Màu sắc</label>
                                <input type="text" className="form-control" id="mauSac" name="mauSac"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.mauSac}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div className="form-group">
                                <label htmlFor="chieuSauCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chiều sâu công trình</label>
                                <input type="number" className="form-control" id="chieuSauCongTrinh" name="chieuSauCongTrinh"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chieuSauCongTrinh}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div >
                    </div >
                </div >
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep.files}
                    fileTrash={this.state.noiDungGiayPhep.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
                <br></br>
                <br></br>
                {this.state.openModel === 'kiem-tra-ngan-chan'
                    &&
                    <ModalKiemTraNganChan
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel === 'kiem-tra-ngan-chan'}
                    ></ModalKiemTraNganChan>
                }
                <ButtonGroupGiayPhep
                    idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                    isNhapDauKy={this.props.isNhapDauKy}
                    handleModalToggle={this.handleModalToggle}
                    handleAddOrUpdate={this.props.addOrUpdate}
                    maHoSo={this.state.maHoSo}
                    maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                ></ButtonGroupGiayPhep>
                <ModalDonViTuVan
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tu-van'}
                ></ModalDonViTuVan>
                <ModalDonViThamDinh
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tham-dinh'}
                ></ModalDonViThamDinh>
                <ModalKyHieuThietKe
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'ky-hieu'}
                ></ModalKyHieuThietKe>
                <ModalClearAll
                    onClearData={this.handleClearAll}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'clear-all'}
                ></ModalClearAll>
                <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                <br />
            </div >
        )
    }
}