import React from 'react';

export default class ThongTinXDCTTheoTuyenRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row">
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN XÂY DỰNG</h6>
                </div>
                <div>
                    {this.props.thongTinXDCTTheoTuyen.diaChi &&
                        <div className="row" style={{ marginTop: '12px' }}>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 text-right">
                                        <p style={{ fontWeight: 'bold' }}>Giai đoạn 1: </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 text-right">
                                        <p style={{ fontWeight: 'bold' }}>Địa chỉ</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>{this.props.thongTinXDCTTheoTuyen.diaChi} m&#178;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Hướng tuyến công trình</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXDCTTheoTuyen.huongTuyenCongTrinh}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Cốt nền xây dựng công trình</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXDCTTheoTuyen.cotNen}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Khoảng cách từ cốt 0,00 và khoảng cách đến các công trình đặc thù</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXDCTTheoTuyen.khoangCach}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 text-right">
                                    <p style={{ fontWeight: 'bold' }}>Chiều sâu công trình (đối với công trình ngầm theo tuyến)</p>
                                </div>
                                <div className="col-md-6">
                                    <p>{this.props.thongTinXDCTTheoTuyen.chieuSauCongTrinh}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.props.thongTinXDCTTheoTuyen.diaChiGD2
                        || this.props.thongTinXDCTTheoTuyen.huongTuyenCongTrinhGD2
                        || this.props.thongTinXDCTTheoTuyen.cotNenGD2
                        || this.props.thongTinXDCTTheoTuyen.khoangCachGD2
                        || this.props.thongTinXDCTTheoTuyen.chieuSauCongTrinhGD2)
                        &&
                        <>
                            <div className="row" style={{ marginTop: '12px' }}>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Giai đoạn 2: </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Địa chỉ</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{this.props.thongTinXDCTTheoTuyen.diaChiGD2} m&#178;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '12px' }}>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Hướng tuyến công trình</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{this.props.thongTinXDCTTheoTuyen.huongTuyenCongTrinhGD2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Cốt nền xây dựng công trình</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{this.props.thongTinXDCTTheoTuyen.cotNenGD2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '12px' }}>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Khoảng cách từ cốt 0,00 và khoảng cách đến các công trình đặc thù</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{this.props.thongTinXDCTTheoTuyen.khoangCachGD2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right">
                                            <p style={{ fontWeight: 'bold' }}>Chiều sâu công trình (đối với công trình ngầm theo tuyến)</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{this.props.thongTinXDCTTheoTuyen.chieuSauCongTrinhGD2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
}