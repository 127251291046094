import React from "react";
import ChuDauTu from "./SubChiTietGPXD/ChuDauTu";
import GiayToVeDatDai from "./SubChiTietGPXD/GiayToVeDatDai";
import NoiDungCapPhep from "./SubChiTietGPXD/NoiDungCapPhep";
import ThongTinThamDinh from "./SubChiTietGPXD/ThongTinThamDinh";
import ThongTinDeXuat from "./SubChiTietGPXD/ThongTinDeXuat";
import TepDinhKem from "./SubChiTietGPXD/TepDinhKem";
import LichSu from "./SubChiTietGPXD/LichSu";
import { CreateGiayPhepDTO } from "../../../../api/Init/CapPhepXayDungFinal";

export default class NavbarGPXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noiDungGiayPhep: { ...CreateGiayPhepDTO }
        }
    }
    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
    }

    render() {
        return (

            <div className="container-fluid" style={{ fontSize: '14px' }}>
                <nav style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="py-3" style={{ height: '55px' }}>
                        <ul class="nav nav-tabs" role="tablist" style={{ fontWeight: 'bold' }}>
                            <li class="ml-2">&nbsp;</li>
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#noidungcapphep" role="tab">Nội dung cấp phép</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#chudautu" role="tab">Chủ đầu tư</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#giaytovedatdai" role="tab">Giấy tờ về đất đai</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#thongtinthamdinh" role="tab">Thông tin thẩm định</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#thongtindexuat" role="tab">Thông tin đề xuất</a>
                            </li>
                            {/* <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tepdinhkem" role="tab">Tệp đính kèm</a>
                            </li> */}
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#lichsu" role="tab">Lịch sử</a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane p-2 active" id="noidungcapphep" role="tabpanel">
                                <NoiDungCapPhep
                                    idGiayPhep={this.state.idGiayPhep}
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                ></NoiDungCapPhep>
                            </div>
                            <div class="tab-pane p-2" id="chudautu" role="tabpanel">
                                <ChuDauTu
                                    chuDauTus={this.state.noiDungGiayPhep.chuDauTus}
                                ></ChuDauTu>
                            </div>
                            <div class="tab-pane p-2" id="giaytovedatdai" role="tabpanel">
                                <GiayToVeDatDai
                                    giayToVeDatDai={this.state.noiDungGiayPhep.giayPhep.giayToVeDatDai}
                                ></GiayToVeDatDai>
                            </div>
                            <div class="tab-pane p-2" id="thongtinthamdinh" role="tabpanel">
                                <ThongTinThamDinh
                                    thongTinThamDinhs={this.state.noiDungGiayPhep.thongTinThamDinhs}
                                ></ThongTinThamDinh>
                            </div>
                            <div class="tab-pane p-2" id="thongtindexuat" role="tabpanel">
                                <ThongTinDeXuat
                                    thongTinDeXuat={this.state.noiDungGiayPhep.thongTinDeXuat}
                                ></ThongTinDeXuat>
                            </div>
                            {/* <div class="tab-pane p-2" id="tepdinhkem" role="tabpanel">
                                <TepDinhKem
                                    files={this.state.noiDungGiayPhep.files}
                                ></TepDinhKem>
                            </div> */}
                            <div class="tab-pane p-2" id="lichsu" role="tabpanel">
                                <LichSu
                                    files={this.state.noiDungGiayPhep.files}
                                    giayPhep={this.state.noiDungGiayPhep.giayPhep}
                                    lichSuGiayPhep={this.state.noiDungGiayPhep.lichSuGiayPhep}
                                ></LichSu>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

        )
    }
}

