import { Box, Button, IconButton, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Pagination } from "@material-ui/lab";
import React, { useEffect } from "react";
import CustomCell from "./customCellTable";
import ViewListIcon from "@material-ui/icons/ViewList";
import { Cell, Row } from "../../../App/commons/components/table/tableCommons";

const StyledTablePagination = withStyles({
    root: {
        borderBottom: 0,
    },
    toolbar: {
        padding: "0",
        "& .MuiPagination-root": {
            order: "-1",
            marginRight: "10px",
        },
        "& .MuiTablePagination-caption:nth-last-child(2)": {
            flexGrow: 1,
            textAlign: "right",
        },
    },
    spacer: {
        flex: 0,
    },
})(TablePagination);

export enum TypeCell {
    BOOLEAN = "0",
    NUMBERIC = "1",
    DATE = "2",
    DATE_TIME = "3",
    CURRENCY = "4",
    FILE = "5",
    ACTION = "6",
    ACTION_LINE = "7",
}

export interface ColumnTable<T> {
    width?: number | string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    field: String;
    title: string;
    type?: TypeCell;
}

interface CommonTablePropsI<T> {
    onDelete?: (data: T, index: number) => void;
    onEdit?: (data: T) => void;
    onAdd?: () => void;
    columns: ColumnTable<T>[];
    rows: T[];
    mode?: boolean;
    turnOffAction?: boolean;
}

export const CommonTableChuyenNganh = <T extends object>(props: CommonTablePropsI<T>) => {
    const { onAdd, onEdit, onDelete, columns, rows, mode, turnOffAction } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
        return o[propertyName]; // o[propertyName] is of type T[K]
    }

    useEffect(() => {
        if (page !== 0 && rowsPerPage === rows.length) {
            setPage(page - 1);
        }
    }, [rows.length]);

    return (
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        {columns.map((col, i) => (
                            <Cell key={i} component="th" scope="row" align={col.align ? col.align : "center"} width={col.width}>
                                {col.title}
                            </Cell>
                        ))}
                        {!turnOffAction && (
                            <Cell component="th" scope="row" align="center" width="5%">
                                Xử lý
                            </Cell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((item: any, i) => (
                        <Row key={i}>
                            <Cell scope="row" align="center">
                                {i + 1 + page * rowsPerPage}
                            </Cell>
                            {columns.map(({ field, type }, j) => {
                                return <CustomCell item={item} key={j} field={`${field}`} type={type} />;
                            })}
                            <Cell align="center">
                                {!mode && (
                                    <Box display="flex" justifyContent="center">
                                        {onEdit && (
                                            <IconButton color="primary" aria-label="edit" size="small" onClick={onEdit ? () => onEdit(item) : () => {}}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                        {onDelete && (
                                            <IconButton color="secondary" aria-label="edit" size="small" onClick={onDelete ? () => onDelete(item, i) : () => {}}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                )}
                                {mode && (
                                    <Box display="flex" justifyContent="center">
                                        <IconButton color="default" aria-label="edit" size="small">
                                            <ViewListIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </Cell>
                        </Row>
                    ))}
                    {rows.length === 0 && (
                        <TableRow>
                            <Cell colSpan={columns.length + 2}>
                                <Box textAlign="center" padding={1}>
                                    Không có dữ liệu{" "}
                                </Box>
                            </Cell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {/* <StyledTablePagination
                            labelRowsPerPage={"Số mẫu tin trên trang"}
                            labelDisplayedRows={({ from, to, count }) =>
                                onAdd ? (
                                    <Button type="button" variant="contained" color="primary" disableElevation onClick={onAdd}>
                                        Thêm mới
                                    </Button>
                                ) : (
                                    `Mẫu tin ${from} - ${to}/${count}`
                                )
                            }
                            rowsPerPageOptions={[5, 10, 25]}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                input: <CustomInput fullWidth={false} />,
                                inputProps: { "aria-label": "Số mẫu tin trên trang" },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        /> */}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

function TablePaginationActions(props: TablePaginationActionsProps) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        onChangePage(null, value - 1);
    };

    return (
        <Pagination
            count={Math.ceil(count / rowsPerPage)}
            defaultPage={1}
            siblingCount={1}
            disabled={Math.ceil(count / rowsPerPage) === 1}
            variant="outlined"
            size="medium"
            color="primary"
            shape="rounded"
            showFirstButton
            showLastButton
            onChange={handleOnChangePage}
        />
    );
}
