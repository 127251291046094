import { Modal } from 'react-bootstrap';
import React from 'react';
import closeIcon from "../../../../Assets/icons/u1233.png";
import FormSearchCDT from './FormSearchCDT';
export default class ModalSearchCDT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "CN",
            maHoSo: this.props.maHoSo,
            tenLoaiGiayPhep: this.props.tenLoaiGiayPhep
        }

        this.radioChange = this.radioChange.bind(this);
    }
    radioChange(e) {
        this.setState({
            selectedOption: e.currentTarget.value
        });
    }
    loadCDT = () => {
        this.props.loadCDT();
    }
    handleCloseModal = () => {
        // this.clearData();
        this.props.onModalToggle();
    }
    render() {
        const selectedOption = this.state.selectedOption;
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>CHỌN CHỦ ĐẦU TƯ TỪ KHO</h5>
                    <button type="button" class="close" data-dismiss="modal" onClick={this.handleCloseModal} aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div className="form-group row">
                            <label htmlFor="id" className="col-sm-3 custom-control-inline" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại đối tượng</label>
                            <div className="col-sm">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="customRadioInline1" checked={selectedOption === 'CN'} value="CN" onChange={this.radioChange} class="custom-control-input" />
                                    <label class="custom-control-label" for="customRadioInline1">Cá nhân</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="customRadioInline1" checked={selectedOption === 'DN'} value="DN" onChange={this.radioChange} class="custom-control-input" />
                                    <label class="custom-control-label" for="customRadioInline2">Doanh nghiệp</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 style={{ fontWeight: 'bold' }}>THÔNG TIN TÌM KIẾM</h6>
                    <br></br>
                    <FormSearchCDT
                        isCN={selectedOption}
                        tenLoaiGiayPhep={this.state.tenLoaiGiayPhep}
                        maHoSo={this.state.maHoSo}
                        loadCDT={this.loadCDT}
                        loadDataTable={this.props.loadDataTable}
                        dataValue={this.props.dataValue}
                        handleClickSetData={this.props.handleClickSetData}
                        checked={this.props.checked}
                        handleChangeCheck={this.props.handleChangeCheck}
                        loadDataTables={this.props.loadDataTables}
                        dataValues={this.props.dataValues}
                        handleClickSetDatas={this.props.handleClickSetDatas}
                    ></FormSearchCDT>
                </div>

            </Modal>
        )
    }

}