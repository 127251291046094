import React from 'react';
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';
import closeIcon from "../../../../Assets/icons/u1233.png";
import StringUtil from '../../../../utils/stringUtils';
import moment from 'moment';
import { KIEM_TRA_NGAN_CHAN } from '../../../Constants/constants'
import KiemTraNganChanService from '../../../../api/service/KiemTraNganChanService';

export default class ModalCreateKiemTraNganChan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            item: {
                id: 0,
                tenChuSuDung: '',
                diaChiChiTiet: '',
                noiDungNganChan: '',
                soTo: '',
                soThua: '',
                soGiayCN: '',
                soVaoSo: '',
                ngayNganChan: '',
                congVanNC: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ghiChu: '',
            },
            errors: {
                tenChuSuDung: '',
                soTo: '',
                soThua: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ngayNganChan: '',
                noiDungNganChan: '',
            },
            maTinhThanh: '92',
            quanHuyen: [],
            phuongXa: [],
        }
        this.kiemTraNganChanService = new KiemTraNganChanService();
    }

    componentWillUnmount() {
        this.props.clearDataModal();
    }

    /*Add thông tin thẩm định*/
    setParams = (e) => {
        let target = e.target;
        let type = target.type;
        let name = target.name;
        let value = target.value;
        let text = '';
        if (type === "select-one") {
            text = target.selectedOptions[0].text;
        }
        if (name === "maQuanHuyen") {
            this.state.item[name] = value;
            this.state.item.tenQuanHuyen = text;
            this.danhSachPhuongXa(value);
        }
        if (name === "maPhuongXa") {
            this.state.item[name] = value;
            this.state.item.tenPhuongXa = text;
        }
        this.state.item[name] = value;
        this.setState({ ...this.state })
    }

    validation = async () => {
        const { item } = this.state;
        let valid = true;
        let checkValidation = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(item.tenChuSuDung)) {
            this.state.errors.tenChuSuDung = 'Vui lòng nhập tên chủ sử dụng';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(item.soTo)) {
            this.state.errors.soTo = 'Vui lòng nhập số tờ';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(item.soThua)) {
            this.state.errors.soThua = 'Vui lòng nhập số thửa';
            valid = false;
        }
        if (!StringUtil.isNullOrEmty(item.soTo) && !StringUtil.isNullOrEmty(item.soThua)) {
            checkValidation = await this.checkDuplicate();
            if (checkValidation) {
                this.state.errors.soTo = 'Số tờ/ số thửa đã tồn tại';
                this.state.errors.soThua = 'Số tờ/ số thửa đã tồn tại';
                valid = false;
            }
        }
        if (StringUtil.isNullOrEmty(item.maQuanHuyen)) {
            this.state.errors.maQuanHuyen = 'Vui lòng chọn quận/ huyện';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(item.maPhuongXa)) {
            this.state.errors.maPhuongXa = 'Vui lòng chọn phường/ xã';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(item.noiDungNganChan)) {
            this.state.errors.noiDungNganChan = 'Vui lòng nhập nội dung ngăn chặn';
            valid = false;
        }
        if (!item.ngayNganChan || item.ngayNganChan === undefined || item.ngayNganChan === 0) {
            this.state.errors.ngayNganChan = 'Vui lòng nhập ngày ngăn chặn';
            valid = false;
        }
        this.setState(this.state);
        return valid;

    }
    async checkDuplicate() {
        let valid;
        await this.kiemTraNganChanService.checkDuplicateSoToAndSoThua(this.state.item).then(res => {
            if (res.data.error_code === 'DUPLICATE_CODE') {
                valid = true;
            } else {
                valid = false;
            }
        })
        return valid
    }

    addItem = async (event) => {
        const result = await this.validation();
        if (result) {
            let message = "";
            if (this.state.item.id === 0) {
                message = "Thêm "
            } else {
                message = "Cập nhật "
            }
            await this.kiemTraNganChanService.addOrUpdate(this.state.item).then(res => {
                if (res.data !== null || res.data.error_code === 'SUCCESSFUL') {
                    this.handleCloseModal('');
                    toastr.success(message + this.props.itemName.toLowerCase() + ' thành công');
                    this.props.onPagi();
                }
            }).catch(error => {
                toastr.warning(message + this.props.itemName.toLowerCase() + 'không thành công');
                console.log(error);
            });
        }

    }

    componentDidMount() {
        const { editIndex } = this.props;
        if (editIndex !== -1) {
            this.getItemById(editIndex);
        }
        this.danhSachQuanHuyenInfo('92');
    }
    /*Tìm kiếm*/

    handleCloseModal = (key) => {
        this.setState({
            errors: {}
        })
        this.props.onModalToggle(key);
        this.props.clearDataModal();
    }

    getItemById = (id) => {
        if (this.props.editIndex !== -1) {
            this.kiemTraNganChanService.getChiTietById(id).then(res => {
                if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                    this.setState({ item: res.data.data || [] });
                    this.danhSachPhuongXa(this.state.item.maQuanHuyen);
                }
            })
        }
    }

    danhSachQuanHuyenInfo = maTinhThanh => {
        const queryParam = {
            "maTinhThanh": maTinhThanh
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }
    danhSachPhuongXa = maQuanHuyen => {
        const queryParam = {
            "maTinhThanh": this.state.maTinhThanh,
            "maQuanHuyen": maQuanHuyen
        }
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }
    render() {
        const { item } = this.state;
        console.log("Item", item);
        console.log("state", this.state);
        return (
            <>
                <Modal show={this.props.show} onHide={() => this.handleCloseModal('')} on size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                        <h5 class="modal-title" id="modalCreateDVTD" style={{ fontWeight: 'bold', color: "white" }}>{this.state.item.id === 0 ? "THÊM" : "CẬP NHẬT"} {this.props.itemName.toUpperCase()}</h5>
                        <button type="button" onClick={() => this.handleCloseModal('')} aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon}></img></span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div className="modal-body">
                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chủ sử dụng<span style={{ color: 'red' }}>(*)</span></label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="tenChuSuDung" name="tenChuSuDung"
                                            value={item.tenChuSuDung}
                                            onChange={this.setParams} />
                                        <div style={Boolean(this.state.errors?.tenChuSuDung) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenChuSuDung}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ chi tiết</label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="diaChiChiTiet" name="diaChiChiTiet"
                                            value={item.diaChiChiTiet}
                                            onChange={this.setParams} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số tờ <span style={{ color: 'red' }}>(*)</span></label>
                                        <input type="text"
                                            className="form-control col-md"
                                            id="soTo" name="soTo"
                                            value={item.soTo}
                                            onChange={this.setParams} />
                                        <div style={Boolean(this.state.errors?.soTo) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.soTo}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Quận, huyện <span style={{ color: 'red' }}>(*)</span></label>
                                        <select type="text"
                                            value={item?.maQuanHuyen}
                                            className='form-control col-md'
                                            id="maQuanHuyen"
                                            name="maQuanHuyen"
                                            onChange={this.setParams} >
                                            <option value=""> Chọn quận, huyện</option>
                                            {
                                                this.state.quanHuyen.map((e, ind) => {
                                                    return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                                                })
                                            }
                                        </select>
                                        <div style={Boolean(this.state.errors?.maQuanHuyen) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.maQuanHuyen}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số thửa <span style={{ color: 'red' }}>(*)</span></label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="soThua" name="soThua"
                                            value={item.soThua}
                                            onChange={this.setParams} />
                                        <div style={Boolean(this.state.errors?.soThua) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.soThua}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phường, xã <span style={{ color: 'red' }}>(*)</span></label>
                                        <select type="text"
                                            value={item?.maPhuongXa}
                                            className='form-control col-md'
                                            id="maPhuongXa"
                                            name="maPhuongXa"
                                            onChange={this.setParams} >
                                            <option value="">Chọn phường, xã</option>
                                            {
                                                this.state.phuongXa.map((e, ind) => {
                                                    return <option key={e.maPhuongXa} value={e.maPhuongXa}>{e.tenPhuongXa}</option>
                                                })
                                            }
                                        </select>
                                        <div style={Boolean(this.state.errors?.maPhuongXa) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.maPhuongXa}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy CN</label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="soGiayCN" name="soGiayCN"
                                            value={item.soGiayCN}
                                            onChange={this.setParams} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày ngăn chặn <span style={{ color: 'red' }}>(*)</span></label>
                                        <input type="date"
                                            max={moment(new Date()).format('yyyy-MM-DD')}
                                            value={item?.ngayNganChan ? moment(new Date(item.ngayNganChan)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                            className='form-control col-sm'
                                            id="ngayNganChan"
                                            name="ngayNganChan"
                                            onChange={this.setParams} />
                                        <div style={Boolean(this.state.errors?.ngayNganChan) ? { display: 'block' } : {}} className="invalid-feedback offset-md-5" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayNganChan}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số vào sổ</label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="soVaoSo" name="soVaoSo"
                                            value={item.soVaoSo}
                                            onChange={this.setParams} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Công văn NC</label>
                                        <input
                                            type="text"
                                            className="form-control col-md"
                                            id="congVanNC" name="congVanNC"
                                            value={item.congVanNC}
                                            onChange={this.setParams} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-2half col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung ngăn chặn <span style={{ color: 'red' }}>(*)</span></label>
                                        <textarea
                                            type="text"
                                            className="form-control col-md"
                                            id="noiDungNganChan" name="noiDungNganChan"
                                            value={item.noiDungNganChan}
                                            onChange={this.setParams} rows="2" />
                                        <div style={Boolean(this.state.errors?.noiDungNganChan) ? { display: 'block' } : {}} className="invalid-feedback offset-md-2half" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.noiDungNganChan}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-md-2half col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                                        <textarea
                                            type="text"
                                            className="form-control col-md"
                                            id="ghiChu" name="ghiChu"
                                            value={item.ghiChu}
                                            onChange={this.setParams} rows="2" />
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <div className="form-group" align="center">
                                        <button onClick={this.addItem} type="button" data-dismiss="modal" class="btn btn-primary btn-lg" >Lưu</button>&nbsp;&nbsp;
                                        <button onClick={() => this.handleCloseModal('')} type="button" class="btn btn-secondary btn-lg" >Hủy</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
            </>
        )
    }
}