import { CAP_MOI, CONG_TRINH_NGAM, TINH_TRANG_DANG_HOAT_DONG } from '../../Constants/constants';
import TabCPXDCongTrinhNgam from './TabCPXDCongTrinhNgam';

function CapPhepXDCTNgam({ location }) {
  const tenLoaiGiayPhep = CONG_TRINH_NGAM.ten;
  const maLoaiGiayPhep = CONG_TRINH_NGAM.ma;
  const tenTrangThai = TINH_TRANG_DANG_HOAT_DONG.ten;
  const maTrangThai = TINH_TRANG_DANG_HOAT_DONG.ma;
  const tenLoaiBienDong = CAP_MOI.ten;
  const loaiBienDong = CAP_MOI.ma;
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const maHoSo = location.state && location.state?.maHoSo ? location.state?.maHoSo : "";
  const nhapDauKy = location.state && location.state?.nhapDauKy ? location.state?.nhapDauKy : false;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TabCPXDCongTrinhNgam
            id={id}
            isNhapDauKy={nhapDauKy}
            maHoSo={maHoSo}
            maLoaiGiayPhep={maLoaiGiayPhep}
            tenLoaiGiayPhep={tenLoaiGiayPhep}
            loaiBienDong={loaiBienDong}
            tenLoaiBienDong={tenLoaiBienDong}
            tenTrangThai={tenTrangThai}
            maTrangThai={maTrangThai}
          ></TabCPXDCongTrinhNgam>
        </div>
      </div>
    </div>
  );
}
export default CapPhepXDCTNgam;
