
import { useHistory } from 'react-router';
import 'toastr/build/toastr.min.css';

const ButtonGroupThuHoiHuy = (props) => {
    const history = useHistory();
    return (
        <div className="">
            <button type="button" onClick={props.addOrUpdate} class="btn btn-primary" style={{ fontSize: '14px', width: '70px' }}>Lưu</button>&nbsp;&nbsp;
            <button type="button" onClick={() => props.onModalToggle('')} class="btn btn-danger" style={{ fontSize: '14px', width: '70px' }}>Xóa</button>&nbsp;&nbsp;
            <a class="btn btn-primary" onClick={() => history.go(-1)} style={{ fontSize: '14px', width: '70px' }}>Trở về</a>&nbsp;&nbsp;
        </div>
    );

}
export default ButtonGroupThuHoiHuy;