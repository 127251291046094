export enum ThongTinChuyenNganhEnum {
  NGAY_THANG_HIEN_TAI = "ngaythanghientai",
  CMND = "cmnd",
  NGAY_CAP_CMND = "ngaycapcmnd",
  NOI_CAP_CMND = "noicapcmnd",
  HO_KHAU_THUONG_TRU = "hokhauthuongtru",
  NOI_O_HIEN_NAY = "noiohiennay",
  HO_VA_TEN = "hovaten",
  HO_VA_TEN_VIET_HOA = "hovatenviethoa",
  NGAY_THANG_NAM_SINH = "ngaythangsinh",
  NGAY_HIEN_TAI = "ngayhientai",
  THANG_HIEN_TAI = "thanghientai",
  NAM_HIEN_TAI = "namhientai",
  QUOC_TICH = "quoctich",
  TINH_THANH = "tinhthanh",
  QUAN_HUYEN = "quanhuyen",
  PHUONG_XA = "xaphuong",
  SO_DIEN_THOAI ="sdt",
  FAX ="fax",
  EMAIL ="email",
  GIOI_TINH ="gioitinh",
  DIA_CHI ="diachi",
  KINH_GUI ="kinhgui",
  TEN_DOANH_NGHIEP ="tendoanhnghiep",
  DIA_CHI_DOANH_NGHIEP ="diachidoanhnghiep",
  WEBSITE = "website",
  GIAY_DKDN = "giaydkkd",
  CO_QUAN_CAP_DKDN = "coquancapdkkd",
  NGAY_CAP_DKDN = "ngaycapdkkd",
  NOI_CAP_DKDN = "noicapdkkd",
  HO_VA_TEN_NGUOI_DAI_DIEN = "hotennguoidaidien",
  CHUC_VU_NGUOI_DAI_DIEN = "chucvunguoidaidien",
  DIA_DIEM = "diadiem",
}

export enum TypeField{
  TYPE_STRING = '0',
  TYPE_NUMBER = '1',
  TYPE_DATE = '2',
}