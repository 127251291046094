import React from 'react';
import TableLoDatXD from '../CapPhepDungChung/NDGP/TableLoDatXD';
import ModalDonViTuVan from '../CapPhepDungChung/NDGP/ModalDonViTuVan';
import ModalDonViThamDinh from '../CapPhepDungChung/NDGP/ModalDonViThamDinh';
import ModalKyHieuThietKe from '../CapPhepDungChung/NDGP/ModalKyHieuThietKe';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import TableDSHM from './TableDSHM';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import InputCapCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputCapCongTrinh';
import InputLoaiCongTrinh from '../CapPhepDungChung/DanhMucDungRieng/InputLoaiCongTrinh';
import InputPhanLoaiXayDung from '../CapPhepDungChung/DanhMucDungRieng/InputPhanLoaiXayDung';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import moment from 'moment';
import SelectQuanHuyen from '../CapPhepDungChung/InputGroup/SelectQuanHuyen'
import SelectPhuongXa from '../CapPhepDungChung/InputGroup/SelectPhuongXa'
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
import StringUtil from '../../../utils/stringUtils';
// import 'bootstrap/dist/css/bootstrap.min.css';

export default class FormCapPhepCoThoiHan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maHoSo: this.props.maHoSo,
            maLoaiGiayPhep: this.props.maLoaiGiayPhep,
            openModel: false,
            noiDungGiayPhep: {
                thongTinCongTrinh: {},
                thongTinXayDung: {},
                viTriXayDung: {},
                loDatXayDungs: [],
                loDatXayDungDropDTOs: [],
                quenSuDungDat: {},
                hangMucs: [],
                hangMucDrops: [],
                files: [],
                fileTrash: [],
                giayPhep: this.props.noiDungGiayPhep.giayPhep
            },
            errors: {}
        }
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinCongTrinh != this.state.noiDungGiayPhep.thongTinCongTrinh) {
            this.state.noiDungGiayPhep.thongTinCongTrinh = this.props.noiDungGiayPhep.thongTinCongTrinh;
            this.setState(this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.thongTinXayDung != this.state.noiDungGiayPhep.thongTinXayDung) {
            this.state.noiDungGiayPhep.thongTinXayDung = this.props.noiDungGiayPhep.thongTinXayDung;
            this.setState(this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriXayDung != this.state.noiDungGiayPhep.viTriXayDung) {
            this.state.noiDungGiayPhep.viTriXayDung = this.props.noiDungGiayPhep.viTriXayDung;
            this.setState(this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungs != this.state.noiDungGiayPhep.loDatXayDungs) {
            this.state.noiDungGiayPhep.loDatXayDungs = this.props.noiDungGiayPhep.loDatXayDungs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDropDTOs != this.state.noiDungGiayPhep.loDatXayDungDropDTOs) {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = this.props.noiDungGiayPhep.loDatXayDungDropDTOs;
            this.setState(this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.quenSuDungDat != this.state.noiDungGiayPhep.quenSuDungDat) {
            this.state.noiDungGiayPhep.quenSuDungDat = this.props.noiDungGiayPhep.quenSuDungDat;
            this.setState(this.state.noiDungGiayPhep.quenSuDungDat);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.hangMucs != this.state.noiDungGiayPhep.hangMucs) {
            this.state.noiDungGiayPhep.hangMucs = this.props.noiDungGiayPhep.hangMucs;
            this.setState(this.state.noiDungGiayPhep.hangMucs);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.hangMucDrops != this.state.noiDungGiayPhep.hangMucDrops) {
            this.state.noiDungGiayPhep.hangMucDrops = this.props.noiDungGiayPhep.hangMucDrops;
            this.setState(this.state.noiDungGiayPhep.hangMucDrops);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.files != this.state.noiDungGiayPhep.files) {
            this.state.noiDungGiayPhep.files = this.props.noiDungGiayPhep.files;
            this.setState(this.state.noiDungGiayPhep.files);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.fileTrash != this.state.noiDungGiayPhep.fileTrash) {
            this.state.noiDungGiayPhep.fileTrash = this.props.noiDungGiayPhep.fileTrash;
            this.setState(this.state.noiDungGiayPhep.fileTrash);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep != this.state.noiDungGiayPhep.giayPhep) {
            this.state.noiDungGiayPhep.giayPhep = this.props.noiDungGiayPhep.giayPhep;
            this.setState(this.state.noiDungGiayPhep.giayPhep);
        }
    }

    setParams = (event, key) => {
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;
        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key == 'thongTinCongTrinh') {
            this.state.noiDungGiayPhep.thongTinCongTrinh[name] = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh);
        }
        if (key == 'thongTinXayDung') {
            if (this.state.noiDungGiayPhep.thongTinXayDung === null) {
                this.state.noiDungGiayPhep.thongTinXayDung = {};
            }
            this.state.noiDungGiayPhep.thongTinXayDung[name] = value;
            this.props.onUpdateDataState('thongTinXayDung', this.state.noiDungGiayPhep.thongTinXayDung);
        }
        if (key == 'viTriXayDung') {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (key == 'quenSuDungDat') {
            this.state.noiDungGiayPhep.quenSuDungDat[name] = value;
            this.props.onUpdateDataState('quenSuDungDat', this.state.noiDungGiayPhep.quenSuDungDat);
            if ((name === 'dienTichKhuonVienDat') &&
            (this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat && this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat >= 0 && 
             Number(value) >= 0 &&
                this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat >= Number(value))) {

                this.state.noiDungGiayPhep.quenSuDungDat.dienTichGiuLai = 
                (Number(value) - Number(this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienHienHuu))  > 0 ? (Number(value) - Number(this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienHienHuu)) : 0;
                this.props.onUpdateDataState('quenSuDungDat', this.state.noiDungGiayPhep.quenSuDungDat);
            } else if ((name === 'dienTichKhuonVienHienHuu') 
            && (this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat >= 0 && Number(value) >= 0) &&
            this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat >= Number(value)) {
                if (Number(value) < this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat) {
                    this.state.noiDungGiayPhep.quenSuDungDat.dienTichGiuLai = 
                    Number(this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat) - Number(value) ? Number(this.state.noiDungGiayPhep.quenSuDungDat.dienTichKhuonVienDat) - Number(value) : 0;
                    this.props.onUpdateDataState('quenSuDungDat', this.state.noiDungGiayPhep.quenSuDungDat);
                }
            }
        }
        if (key == 'giayPhep') {
            if (type === 'date') {
                this.state.noiDungGiayPhep.giayPhep[name] = new Date(value).getTime();
            } else {
                this.state.noiDungGiayPhep.giayPhep[name] = value;
            }
            this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
        }
        if (key === "HuyenThiThanhPho") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.huyenThiThanhPho = text;
            this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
        if (name === "maPhuongXaThiTran") {
            this.state.noiDungGiayPhep.viTriXayDung[name] = value;
            this.state.noiDungGiayPhep.viTriXayDung.phuongXaThiTran = text;
            this.props.onUpdateDataState('viTriXayDung', this.state.noiDungGiayPhep.viTriXayDung);
        }
    }

    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungs') {
            this.state.noiDungGiayPhep.loDatXayDungs = value;
            this.props.onUpdateDataState('loDatXayDungs', this.state.noiDungGiayPhep.loDatXayDungs);
        }
        if (key === 'hangMucDrops') {
            this.state.noiDungGiayPhep.hangMucDrops = value;
            // call props and send data
            this.props.onUpdateDataState('hangMucDrops', this.state.noiDungGiayPhep.hangMucDrops);
        }
        if (key === 'hangMucs') {
            this.state.noiDungGiayPhep.hangMucs = value;
            // call props and send data
            this.props.onUpdateDataState('hangMucs', this.state.noiDungGiayPhep.hangMucs);
        }
        if (key === 'loDatXayDungDropDTOs') {
            this.state.noiDungGiayPhep.loDatXayDungDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDropDTOs);
        }
        if (key === "files") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            this.state.noiDungGiayPhep.files = value;
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }
        if (key === "donViTuVan") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
        if (key === "kyHieuThietKe") {
            this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe = value;
            this.props.onUpdateDataState('thongTinCongTrinh', this.state.noiDungGiayPhep.thongTinCongTrinh)
        }
    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }
    handleChangeNhapDauKy(date) {
        let name = "ngayCap";
        var longFormat = date * 1;
        this.state.noiDungGiayPhep.giayPhep[name] = longFormat;
        this.props.onUpdateDataState('giayPhep', this.state.noiDungGiayPhep.giayPhep);
    }
    render() {
        const CustomInputNhapDauKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div>

                {this.props.isNhapDauKy &&
                    <div class="col-md-12">
                        <br />
                        <div class="row">
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="maSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    <input type="text" className="form-control" id="maSoGiayPhep" name="maSoGiayPhep"
                                        value={this.state.noiDungGiayPhep?.giayPhep?.maSoGiayPhep} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} />
                                    <div style={Boolean(this.state.errors?.maSoGiayPhep) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.maSoGiayPhep}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp
                                        <span style={{ color: 'red' }}>(*)</span>
                                    </label>
                                    {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap"
                                        value={this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi ? moment(new Date(this.state.noiDungGiayPhep.thongTinThuHoi?.ngayThuHoi)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "giayPhep")} /> */}
                                    <DatePicker
                                        customInput={<CustomInputNhapDauKy />}
                                        selected={this.state.noiDungGiayPhep.giayPhep?.ngayCap}
                                        onChange={this.handleChangeNhapDauKy.bind(this)}
                                        value={this.state.noiDungGiayPhep.giayPhep?.ngayCap === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCap).format("DD/MM/YYYY") : null}
                                        name="ngayCap"
                                        placeholderText={"dd/mm/yyyy"}
                                        dateFormat={"dd/MM/yyyy"}
                                        className="form-control"
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        locale={vi}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: "5px, 10px"
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: "viewport",
                                            }
                                        }}
                                    >
                                    </DatePicker>
                                    <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tên công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" className="form-control" id="tenCongTrinh" name="tenCongTrinh"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep?.thongTinCongTrinh?.tenCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")} />
                                <div style={Boolean(this.state.errors?.tenCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenCongTrinh}</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tinhThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Tỉnh/Thành phố <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="tinhThanhPho" style={{ fontSize: '14px' }} name="tinhThanhPho"
                                    value={this.state.noiDungGiayPhep.viTriXayDung.tinhThanhPho} disabled>
                                    <option selected>Thành phố Cần Thơ</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <SelectQuanHuyen
                                quanHuyenValue={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectQuanHuyen>
                        </div>
                        <div class="col-md-3">
                            <SelectPhuongXa
                                maQuanHuyen={this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho}
                                phuongXaValue={this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran}
                                errors={this.state.errors}
                                setParams={this.setParams}
                                disabled={this.props.disabled}
                            ></SelectPhuongXa>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="capCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cấp công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="capCongTrinh" name="capCongTrinh"
                                    disabled={this.props.disabled}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.capCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn cấp công trình--</option>
                                    <InputCapCongTrinh></InputCapCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.capCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.capCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                {/* <select className="form-control" id="duong" name="duong"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")}>
                                    <option value="">--Chọn đường--</option>
                                    <option value="Đường 1">Đường 1</option>
                                    <option value="Đường 2">Đường 2</option>
                                    <option value="Đường 3">Đường 3</option>
                                </select> */}
                                <input type="text" className="form-control" id="duong" name="duong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung?.duong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div className="form-group">
                                <label htmlFor="soNhaDuong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số nhà, đường</label>
                                <input type="text" className="form-control" id="soNhaDuong" name="soNhaDuong"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.soNhaDuong} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="loaiCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại công trình <span style={{ color: 'red' }}>(*)</span></label>
                                <select className="form-control" id="loaiCongTrinh" name="loaiCongTrinh"
                                    disabled={this.props.disabled ? true : false}
                                    value={this.state.noiDungGiayPhep.thongTinCongTrinh.loaiCongTrinh} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                    <option value="">--Chọn Loại công trình--</option>
                                    <InputLoaiCongTrinh></InputLoaiCongTrinh>
                                </select>
                                <div style={Boolean(this.state.errors?.loaiCongTrinh) ? { display: 'block' } : {}} className="invalid-feedback" >
                                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.loaiCongTrinh}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="diaChiCu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                <input type="text" className="form-control" id="diaChiCu" name="diaChiCu"
                                    readOnly={this.props.readonly ? true : false}
                                    value={this.state.noiDungGiayPhep.viTriXayDung.diaChiCu} style={{ fontSize: '14px' }}
                                    onChange={(event) => this.setParams(event, "viTriXayDung")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="phanLoaiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phân loại xây dựng <span style={{ color: 'red' }}>(*)</span></label>
                                        <select className="form-control" id="phanLoaiXayDung" name="phanLoaiXayDung"
                                            disabled={this.props.disabled ? true : false}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.phanLoaiXayDung} style={{ fontSize: '14px' }}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}>
                                            <option value="">--Chọn loại xây dựng--</option>
                                            <InputPhanLoaiXayDung></InputPhanLoaiXayDung>
                                        </select>
                                        <div style={Boolean(this.state.errors?.phanLoaiXayDung) ? { display: 'block' } : {}} className="invalid-feedback" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.phanLoaiXayDung}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <div className="form-group">
                                        <label htmlFor="donViTuVan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đơn vị tư vấn</label>
                                        <p style={{ display: 'flex' }}>
                                            <input type="text"
                                                className="form-control"
                                                id="donViTuVan" name="donViTuVan"
                                                style={{ fontSize: '14px' }}
                                                value={this.state.noiDungGiayPhep.thongTinCongTrinh.donViTuVan}
                                                onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                                readOnly={this.props.readonly ? true : false} />
                                            <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                                onClick={() => this.handleModalToggle('tu-van')}></i></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="" style={{ marginLeft: '15px', width: '370px' }}>
                                    <label htmlFor="kyHieuThietKe" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ký hiệu thiết kế</label>
                                    <p style={{ display: 'flex' }}>
                                        <input type="text"
                                            className="form-control"
                                            id="kyHieuThietKe" name="kyHieuThietKe"
                                            style={{ fontSize: '14px' }}
                                            value={this.state.noiDungGiayPhep.thongTinCongTrinh.kyHieuThietKe}
                                            onChange={(event) => this.setParams(event, "thongTinCongTrinh")}
                                            readOnly={this.props.readonly ? true : false} />
                                        <i class="fas fa-search" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }}
                                            onClick={() => this.handleModalToggle('ky-hieu')}></i></p>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-9">
                            <div className="form-group">
                                <label htmlFor="soNha" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lô đất xây dựng</label>
                                <TableLoDatXD
                                    noiDungGiayPhep={this.state.noiDungGiayPhep}
                                    onUpdateDataState={this.setParamLoDats}
                                    disabled={this.props.disabled}
                                ></TableLoDatXD>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="duKienHoanThanh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Dự kiến hoàn thành</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="duKienHoanThanh" name="duKienHoanThanh"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.duKienHoanThanh}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    {/* <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chiGioiDuongDo" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chỉ giới đường đỏ</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="chiGioiDuongDo" name="chiGioiDuongDo"
                                        value={this.state.noiDungGiayPhep.thongTinXayDung?.chiGioiDuongDo}
                                        onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                                    {/* <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="chiGioiXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chỉ giới xây dựng</label>
                                <input type="text" className="form-control" id="chiGioiXayDung" name="chiGioiXayDung"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.chiGioiXayDung}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="thoiHanCongTrinh" style={{ fontWeight: 'bold', fontSize: '14px' }}>Thời hạn công trình</label>
                                <input type="text" className="form-control" id="thoiHanCongTrinh" name="thoiHanCongTrinh"
                                    value={this.state.noiDungGiayPhep.thongTinXayDung?.thoiHanCongTrinh}
                                    onChange={(event) => this.setParams(event, "thongTinXayDung")} style={{ fontSize: '14px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-*" style={{ marginLeft: '30px' }}>
                        <div className="form-group">
                            <label htmlFor="ghichu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Giấy tờ về QSD đất</label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichKhuonVienDat" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Diện tích khuôn viên đất</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichKhuonVienDat" name="dienTichKhuonVienDat"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.dienTichKhuonVienDat}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichKhuonVienHienHuu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích khuôn viên hiện hữu</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichKhuonVienHienHuu" name="dienTichKhuonVienHienHuu"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.dienTichKhuonVienHienHuu}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichBanCong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích ban công</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichBanCong" name="dienTichBanCong"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.dienTichBanCong}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichChenhLech" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích chênh lệch</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichChenhLech" name="dienTichChenhLech"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.dienTichChenhLech}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="dienTichGiuLai" style={{ fontWeight: 'bold', fontSize: '14px' }}>Diện tích giữ lại</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="dienTichGiuLai" name="dienTichGiuLai"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.dienTichGiuLai}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="matDoChoPhepXayDung" style={{ fontWeight: 'bold', fontSize: '14px' }}>Mật độ cho phép xây dựng</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="matDoChoPhepXayDung" name="matDoChoPhepXayDung"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.matDoChoPhepXayDung}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>%</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="cotNen" style={{ fontWeight: 'bold', fontSize: '14px' }}>Cốt nền</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="cotNen" name="cotNen"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.cotNen}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="lyDoChenhLech" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lý do chênh lệch</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="lyDoChenhLech" name="lyDoChenhLech"
                                        value={this.state.noiDungGiayPhep.quenSuDungDat?.lyDoChenhLech}
                                        onChange={(event) => this.setParams(event, "quenSuDungDat")} style={{ fontSize: '14px' }} />
                                    {/* <p style={{ marginLeft: '5px', marginTop: '7px' }}>m&#178;</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="soNha" style={{ fontWeight: 'bold', fontSize: '14px' }}>Danh sách hạng mục</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" style={{ fontSize: '14px' }}>
                    <TableDSHM
                        noiDungGiayPhep={this.state.noiDungGiayPhep}
                        onUpdateDataState={this.setParamLoDats}
                        disabled={this.props.disabled}
                    ></TableDSHM>
                </div>

                <br />
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep.files}
                    fileTrash={this.state.noiDungGiayPhep.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
                <br />
                {this.state.openModel === 'kiem-tra-ngan-chan'
                    &&
                    <ModalKiemTraNganChan
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel === 'kiem-tra-ngan-chan'}
                    ></ModalKiemTraNganChan>
                }
                <ButtonGroupGiayPhep
                    idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                    isNhapDauKy={this.props.isNhapDauKy}
                    handleModalToggle={this.handleModalToggle}
                    handleAddOrUpdate={this.props.addOrUpdate}
                    maHoSo={this.state.maHoSo}
                    maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                ></ButtonGroupGiayPhep>
                <ModalDonViTuVan
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tu-van'}
                ></ModalDonViTuVan>
                <ModalDonViThamDinh
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'tham-dinh'}
                ></ModalDonViThamDinh>
                <ModalKyHieuThietKe
                    onUpdateParam={this.setParamLoDats}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'ky-hieu'}
                ></ModalKyHieuThietKe>
                <ModalClearAll
                    onClearData={this.handleClearAll}
                    onModalToggle={this.handleModalToggle}
                    show={this.state.openModel === 'clear-all'}
                ></ModalClearAll>
                <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                {/* <ModalClearAll onClearData={this.handleClearAll} onModalToggle={this.handleModalToggle} show={this.state.openModel}></ModalClearAll> */}
                <br />
            </div >
        )
    }
}