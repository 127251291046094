import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LIST_ICONS } from '../../Constants/constants';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { MenuRoleItem } from '../../../api/QuanTri/MenuRoleManagementService';
const useStyles = makeStyles((theme) => ({
    root: {
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    menuItem: {
        color: "#fff",
        textDecoration: "none",
        padding: "10px 15px",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "#333333",
            color: "white !important",
            fontWeight: "bold",
        },
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    itemIcon: {
        color: 'white',
        minWidth: '30px'
    },
    paddingLeft5: {
        paddingLeft: '5px'
    },
    activeLink: {
        color: '#2fabf3;'
    },
    nested: {
        paddingLeft: theme.spacing(1.5),
    },
    
}));

function getIcon(icon?: string): JSX.Element | undefined {
    let iconImg = LIST_ICONS.filter(p => p.ten === icon)[0];
    if (iconImg && iconImg.icon !== undefined) {
        return iconImg.icon;
     } else {
         return undefined;
     }
}

export const SubMenuTree = (props: { menu: MenuRoleItem }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [menu, setMenu] = useState({} as MenuRoleItem);
    const [active, setActive] = React.useState(false);
    useEffect(() => {
        setMenu(props.menu);
    }, [props.menu]);

    const triggerNalinkClick = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget.getElementsByTagName('a')[0].click();
    };
    return (
        <>
            {
                (menu.childrens && menu.childrens.length > 0) ?
                    <div>
                        <ListItem key={menu.id + menu.name}  onClick={() => setOpen(!open)} className={classes.menuItem}>
                            <ListItemIcon className={classes.itemIcon}>
                                {getIcon(menu.icon)}
                            </ListItemIcon>
                            <ListItemText primary={menu.name} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.paddingLeft5}>
                                {
                                    menu.childrens && menu.childrens.map((p, i) => {
                                        return <SubMenuTree key={i} menu={p} />
                                    })
                                }
                            </List>
                        </Collapse>
                    </div> :
                    <ListItem key={menu.id + menu.name} className={classes.menuItem} onClick={triggerNalinkClick}>
                        <ListItemIcon className={classes.itemIcon}>
                        {getIcon(menu.icon)}
                        </ListItemIcon>
                        <NavLink  style={{ textDecoration: 'none' }} exact to={String(menu.path_)}
                            className={classes.menuItem} activeClassName={classes.activeLink} >
                            <Box display="flex" alignItems="center">
                                <Box component="span" fontSize="15px">{menu.name}</Box>
                            </Box>
                        </NavLink >
                    </ListItem>
            }
        </>
    )
}

