import React from 'react';

export default class FormGTDD extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            giayToVeDatDai:'',
        }
    }
    
    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.giayPhep.giayToVeDatDai != this.state.giayToVeDatDai) {
            this.state.giayToVeDatDai = this.props.noiDungGiayPhep.giayPhep.giayToVeDatDai;
            this.setState(this.state);
        }
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state[name] = value;
        this.setState(this.state)
        this.props.onChangeStateGTDD(this.state.giayToVeDatDai);
    }

    render() {
        return (
            <div class="col-md-12" style={{ marginTop: '10px' }}>
                <div class="row">
                    <div class="col-md-12">
                        <div className="form-group form-group-lg">
                            <label htmlFor="giayToVeDatDai" style={{ fontWeight: 'bold', fontSize: '14px' }}>Giấy tờ về đất đai</label>
                            <textarea type="text" value={this.state.giayToVeDatDai} className="form-control" name="giayToVeDatDai" id="giayToVeDatDai" rows="8" onChange={this.setParams} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}