import React from 'react';
import { render } from "react-dom";
import { Provider } from 'react-redux';
import { App } from './App';
import './Assets/css/style.css';
import './Assets/css/tailwind.output.css';
import './index.css';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
