import { CAP_MOI, CONG_TRINH_THEO_TUYEN, TINH_TRANG_DANG_HOAT_DONG } from '../../Constants/constants';
import TabCPXDCTTheoTuyen from './TabCPXDCTTheoTuyen';

function CapPhepXDCTT({ location }) {
  const tenLoaiGiayPhep = CONG_TRINH_THEO_TUYEN.ten;
  const maLoaiGiayPhep = CONG_TRINH_THEO_TUYEN.ma;
  const tenTrangThai = TINH_TRANG_DANG_HOAT_DONG.ten;
  const maTrangThai = TINH_TRANG_DANG_HOAT_DONG.ma;
  const tenLoaiBienDong = CAP_MOI.ten;
  const loaiBienDong = CAP_MOI.ma;
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const maHoSo = location.state && location.state?.maHoSo  ? location.state?.maHoSo : "";
  const nhapDauKy = location.state && location.state?.nhapDauKy  ? location.state?.nhapDauKy : false;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TabCPXDCTTheoTuyen
                  id={id}
                  isNhapDauKy={nhapDauKy}
                  maHoSo={maHoSo}
                  maLoaiGiayPhep={maLoaiGiayPhep}
                  tenLoaiGiayPhep={tenLoaiGiayPhep}
                  loaiBienDong={loaiBienDong}
                  tenLoaiBienDong={tenLoaiBienDong}
                  tenTrangThai={tenTrangThai}
                  maTrangThai={maTrangThai}
          ></TabCPXDCTTheoTuyen>
        </div>
      </div>
    </div>
  );
}
export default CapPhepXDCTT;
