import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AUTHORIZE_REQUEST, CONFIG } from "../Login/sso/config";

interface PrivateRouteProps extends RouteProps {
    component: any;
    isSignedIn: boolean;
    redirectToSSO?: boolean;
}
export const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, isSignedIn, redirectToSSO, ...rest } = props;
    return (
        redirectToSSO && !!!isSignedIn ?
            <Route {...rest} render={() => {
                window.location.href = AUTHORIZE_REQUEST;
                return null;
            }} />
            : <Route
                {...rest}
                render={(routeProps) =>
                    isSignedIn ? (
                        <Component {...routeProps} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/admin/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
                }
            />
    );
}