import React from 'react';
import AXIOS_INSTANCE from '../../../api';
import editIcon from '../../../Assets/icons/u624.png';
import CNSGPModal from './CNSGPModal';


export default class CNSGPTable extends React.Component {
    state = {
        idRandom: 0,
        capSoGiayPhep: {
            id:"",
            maHoSo: "",
            soGiayPhep: "",
            ngayCapPhep: 0,
            ngayHetHan: 0,
            ghiChuCapSo: "",
            nguoiKy: "",
            mienPhiCapGiayPhep: false,
            files: [],
            fileTrash: [],
            loaiKetQua: "CHUA_LAY_KET_QUA",
        }
    }
    handleMaHoSoChange = async (e) => {
      const idReset = new Date().getTime();
      await  AXIOS_INSTANCE.get(`giay-phep-xay-dung/chi-tiet?idGiayPhep=${e.target.name}`).then((response) => {
            const data = {
                "id": e.target.name,
                "soGiayPhep": "",
                "ngayCapPhep": 0,
                "ngayHetHan":0,
                "ghiChu": "",
                "nguoiKy": "",
                "mienPhiCapGiayPhep": true,
            };
            if (response!=null && response.status == 200 && response.data.error_code === "SUCCESSFUL") {
                this.setState({
                    capSoGiayPhep: response.data.data || data , idRandom: idReset
                });
            } else {
                this.setState({ capSoGiayPhep: [] , idRandom: idReset});
            }
        }).catch(function (error) {
            this.setState({ capSoGiayPhep: [] , idRandom: idReset});
        });
    }

    handleUpdateDataState = (name, value) => {
        this.state.capSoGiayPhep[name] = value;
        this.setState(this.state);
      }

    render() {
        return (
            <React.Fragment>
                <CNSGPModal onUpdateDataState={this.handleUpdateDataState} capSoGiayPhep={this.state.capSoGiayPhep} idReset={this.state.idRandom}></CNSGPModal>
                <table className="table table-bordered text-center">
                    <thead>
                        <tr className="table-secondary"  style={{fontSize: '14px'}}>
                            <th>STT</th>
                            <th>Số biên nhận</th>
                            <th>Chủ đầu tư</th>
                            <th>Tên công trình</th>
                            <th>Địa chỉ công trình</th>
                            <th>Loại giấy phép</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.list && this.props.list.length ? this.props.list.map((item, index) => {
                                return (
                                    <tr>
                                        <td style={{ width: '40px' }}><small>{this.props.pageIndex + index}</small></td>
                                        <td style={{ width: '184px' }}><small>{item.maHoSo}</small> </td>
                                        <td className="text-left" style={{ width: '266px' }}><small>{item.tenChuDauTu}</small></td>
                                        <td className="text-left" style={{ width: '270px' }}><small>{item.tenCongTrinh}</small></td>
                                        <td className="text-left" style={{ width: '270px' }}><small>{item.viTriXayDung}</small></td>
                                        <td className="text-left" style={{ width: '270px' }}><small>{item.tenLoaiGiayPhep}</small></td>
                                        <td style={{ width: '60px' }}>
                                            <img type="button" data-toggle="modal" data-target="#CNSGPModal" style={{ display: 'unset', margin: '5px' }} name={item.id} src={editIcon} onClick={this.handleMaHoSoChange}></img>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                    <td colSpan={7}> Không có dữ liệu</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}