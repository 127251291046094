import React from "react";
import AXIOS_INSTANCE from '../../../../api/index';
import toastr from 'toastr';
import TableThongKeCPXD from "./TableThongKeCPXD";
import FormThongKeCPXD from "./FormThongKeCPXD";


export default class ThongKeCPXDRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaiThongKe: 1,
    }
  }

  loadData = (request) => {
    AXIOS_INSTANCE.post('lich-su-giay-phep/thong-ke', request).then(res => {
      if (res != null) {
        this.state.data = res.data.data || [];
        this.setState(this.state);
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      console.log(error);
    });
  }

  setLoaiThongKe = (value) => {
    this.state.loaiThongKe = value;
    this.setState(this.state);
  }

  render() {
    return (
      <div className="dash-content">
        <>
          <div className="row">
            <FormThongKeCPXD
              setLoaiThongKe={this.setLoaiThongKe}
              loadData={this.loadData}
            />
          </div>
          <div className="row">
            <TableThongKeCPXD
              data={this.state.data}
              loaiThongKe={this.state.loaiThongKe}
            />
          </div>
          <div className="row">

          </div>
        </>
      </div>
    )
  }
}
