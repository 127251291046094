import React from 'react';
import deleteIcon from '../../../../../Assets/icons/u626.png';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import editIcon from '../../../../../Assets/icons/u624.png'
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { Pagination } from '@material-ui/lab';
import ModalCongTrinhDA from './ModalCongTrinhDA';
import { Box, NativeSelect } from '@material-ui/core';
import { ToastHeader } from 'react-bootstrap';
import ModalXacNhanXoa from '../SubModalCongTrinhDA/ModalXacNhanXoa';

export default class TableCongTrinhDA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            openModalXacNhan: false,
            deleteItemId: '',
            recordsPerPage: 0,
            noiDungGiayPhep: {
                congTrinhDAs: [],
                //drop
                congTrinhDADrops: [],
            },
            congTrinhDA: {
                noiDungCongTrinhDA: {
                    id: 0,
                    tenCongTrinh: '',
                    donViTuVan: '',
                    kyHieuThietKe: '',
                    ghiChuDiaChi: '',
                    thongTinKhoiCong: '',
                    tenHangMuc: '',
                    congTrinhChinh: false,
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoTangMot: '',
                    chieuCaoCongTrinh: '',
                    soTang: '',
                    cotNen: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    mauSac: '',
                    ketCau: '',
                    noiDungKhac: '',
                },

                loDatXayDungs: [],
                hangMucs: [],
                //drop
                loDatXayDungDropDTOs: [],
                HangMucDrops: [],

            },
            indexEdit: -1

        }
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.congTrinhDAs != this.state.data) {
            this.state.data = this.props.noiDungGiayPhep.congTrinhDAs;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.congTrinhDADrops != this.state.dataDrop) {
            this.state.dataDrop = this.props.noiDungGiayPhep.congTrinhDADrops;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length === 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }
    //handle modal
    handleIdDropItem = (e, value) => {
        this.setState({
            deleteItemId: value
        })
        this.handleModalToggle('xacNhan')
    }

    handleAddItem = (value) => {
        let { data } = this.state;
        if (!data) {
            data = []
        }
        const newData = [{ ...value }, ...data];
        this.props.onUpdateDataState('congTrinhDAs', newData);
        this.setState({
            data: [...newData],
            congTrinhDA: {
                noiDungCongTrinhDA: {
                    id: 0,
                    tenCongTrinh: '',
                    donViTuVan: '',
                    kyHieuThietKe: '',
                    ghiChuDiaChi: '',
                    thongTinKhoiCong: '',

                    tenHangMuc: '',
                    congTrinhChinh: false,
                    dienTichXayDungTangMot: '',
                    tongDienTichSan: '',
                    chieuCaoTangMot: '',
                    chieuCaoCongTrinh: '',
                    soTang: '',
                    cotNen: '',
                    matDoXayDung: '',
                    heSoSuDungDat: '',
                    mauSac: '',
                    ketCau: '',
                    noiDungKhac: '',
                },

                loDatXayDungs: [],
                hangMucs: [],
                //drop
                loDatXayDungDropDTOs: [],
                HangMucDrops: [],
            }
        })
    }
    handleUpdate = (index, value) => {
        const { data } = this.state
        const newData = [...data.slice(0, index), { ...data[index], ...value }, ...data.slice(index + 1)]
        this.props.onUpdateDataState('congTrinhDAs', newData);
    }
    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
        this.setState(this.state);
    }
    handleDropItem = (index) => {
        if (this.state.data[index]?.noiDungCongTrinhDA.id > 0) {
            this.state.dataDrop.push(this.state.data[index]);
        }
        // this.setDataParam('congTrinhDADrops', this.state.dataDrop);
        this.props.onUpdateDataState("congTrinhDADrops", this.state.dataDrop);
        this.state.data.splice(index, 1);
        // this.setDataParam('congTrinhDAs', this.state.data);
        this.props.onUpdateDataState("congTrinhDAs", this.state.data);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa công trình dự án thành công')
        this.handleModalToggle('');
        this.handleSearch();
    }
    openEdit = (index) => {
        const { indexEdit, congTrinhDA, data } = this.state;
        this.setState({ indexEdit: index, congTrinhDA: { ...data[index] } })
        this.handleModalToggle('themMoi');
    }

    openAdd = () => {
        this.state.indexEdit = -1;
        this.state.congTrinhDA = {
            noiDungCongTrinhDA: {
                id: 0,
                tenCongTrinh: '',
                donViTuVan: '',
                kyHieuThietKe: '',
                ghiChuDiaChi: '',
                thongTinKhoiCong: '',

                tenHangMuc: '',
                congTrinhChinh: false,
                dienTichXayDungTangMot: '',
                tongDienTichSan: '',
                chieuCaoTangMot: '',
                chieuCaoCongTrinh: '',
                soTang: '',
                cotNen: '',
                matDoXayDung: '',
                heSoSuDungDat: '',
                mauSac: '',
                ketCau: '',
                noiDungKhac: '',
            },
            loDatXayDungs: [],
            hangMucs: [],
            //drop
            loDatXayDungDropDTOs: [],
            HangMucDrops: [],
        };
        this.handleModalToggle('themMoi');
    }
    handleModalToggle = (key) => {
        const { openModel, openModalXacNhan } = this.state
        if (key === "themMoi") {
            this.setState({ openModel: !openModel })
        }
        if (key === "xacNhan") {
            this.setState({ openModalXacNhan: !openModalXacNhan })
        }
    }

    render() {
        console.log(this.state)
        return (
            <div>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Công trình</th>
                            <th>Số tầng</th>
                            <th>Màu sắc</th>
                            <th>DTXD tầng một (m&sup2;)</th>
                            <th>Tổng diện tích sàn (m&sup2;)</th>
                            <th>Chiều cao công trình</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.noiDungCongTrinhDA.tenCongTrinh}</td>
                                    <td className="cell-number">{item.noiDungCongTrinhDA.soTang}</td>
                                    <td>{item.noiDungCongTrinhDA.mauSac}</td>
                                    <td className="cell-number">{item.noiDungCongTrinhDA.dienTichXayDungTangMot}</td>
                                    <td className="cell-number">{item.noiDungCongTrinhDA.tongDienTichSan}</td>
                                    <td className="cell-number">{item.noiDungCongTrinhDA.chieuCaoCongTrinh}</td>
                                    <td width="10%">
                                        <div className="row">
                                            <div className="col-md">
                                                <button onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index )}
                                                disabled={this.props.disabled ? true : false}>
                                                    <img src={editIcon} style={{ float: 'left', marginLeft: '20px' }}></img>
                                                </button>
                                            </div>
                                            <div className="col-md">
                                                <button onClick={(e) => this.handleIdDropItem(e, index)}
                                                disabled={this.props.disabled ? true : false}>
                                                    <img src={deleteIcon} style={{ float: 'right', marginRight: '20px' }}></img>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={8}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                    <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.openAdd}
                        disabled={this.props.disabled ? true : false}>Thêm mới</button>
                    </div>
                </div>
                {
                    <ModalXacNhanXoa
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModalXacNhan}
                        onDropItem={this.handleDropItem}
                        itemId={this.state.deleteItemId}
                    ></ModalXacNhanXoa>
                }
                { this.state.openModel &&
                    <ModalCongTrinhDA
                        indexEdit={this.state.indexEdit}
                        data={this.state.congTrinhDA}
                        onAddData={this.handleAddItem}
                        onUpdateData={this.handleUpdate}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalCongTrinhDA>
                }
            </div>
        )
    }
}