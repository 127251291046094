import CLOSE_ICON from "../../Assets/icons/u1233.png";
import DELETE_ICON from '../../Assets/icons/u626.png';
import EDIT_ICON from '../../Assets/icons/u624.png';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import GradeIcon from '@material-ui/icons/Grade';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AppsIcon from '@material-ui/icons/Apps';

export const ICONS = {
    close: CLOSE_ICON,
    delete: DELETE_ICON,
    edit: EDIT_ICON,
}

export const LIST_ICONS = [
    {
        ten: 'App',
        icon: <AppsIcon fontSize="small" />
    },
    {
        ten: 'Cài đặt',
        icon: <SettingsIcon fontSize="small" />
    },
    {
        ten: 'Tệp tin',
        icon: <DescriptionIcon fontSize="small" />
    },
    {
        ten: 'Căn hộ, chung cư',
        icon: <ApartmentIcon fontSize="small" />
    },
    {
        ten: 'Thuốc lá',
        icon: <SmokingRoomsIcon fontSize="small" />
    },
    {
        ten: 'Rượu',
        icon: <LocalBarIcon fontSize="small" />
    },
    {
        ten: 'Ăn uống',
        icon: <FastfoodIcon fontSize="small" />
    },
    {
        ten: 'Chứng nhận',
        icon: <CardMembershipIcon fontSize="small" />
    },
    {
        ten: 'Ngôi sao',
        icon: <GradeIcon fontSize="small" />
    },
    {
        ten: 'Chai',
        icon: <BatteryStdIcon fontSize="small" />
    },
    {
        ten: 'Cúp',
        icon: <EmojiEventsIcon fontSize="small" />
    }

];
export const ROUTE_APP = {
    LOGIN: '/login',
    HOME_PAGE: '/',
}
export const THANH_PHO_CAN_THO = {
    ten: "Thành phố Cần Thơ",
    ma: "92",
}

export const TINH_TRANG_DANG_HOAT_DONG = { ten: "Đang hoạt động", ma: "1" };
export const TINH_TRANG_HET_HAN = { ten: "Hết hạn", ma: "2" };
export const TINH_TRANG_THU_HOI = { ten: "Thu hồi", ma: "3" };
export const TINH_TRANG_HUY = { ten: "Hủy", ma: "4" };
export const TINH_TRANG_DIEU_CHINH = { ten: "Điều chỉnh", ma: "5" };


export const CAP_MOI = { ten: "Cấp mới", ma: "1" };
export const CAP_LAI = { ten: "Cấp lại", ma: "2" };
export const DIEU_CHINH = { ten: "Điều chỉnh", ma: "3" };
export const GIA_HAN = { ten: "Gia hạn", ma: "4" };
export const THU_HOI = { ten: "Thu hồi", ma: "5" };
export const HUY = { ten: "Hủy", ma: "6" };
export const DI_DOI = { ten: "Di dời", ma: "7" };
export const SUA_CHUA = { ten: "Sữa chữa", ma: "8" };

export const CONG_TRINH_KHONG_THEO_TUYEN = { ten: "Cấp phép xây dựng công trình không theo tuyến", ma: '1' };
export const CONG_TRINH_THEO_TUYEN = { ten: "Cấp phép xây dựng công trình theo tuyến", ma: '2' };
export const CONG_TRINH_NGAM = { ten: "Cấp phép xây dựng công trình ngầm", ma: '3' };
export const CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN = { ten: "Cấp phép xây dựng giai đoạn công trình không theo tuyến", ma: '4' };
export const CONG_TRINH_GIAI_DOAN_THEO_TUYEN = { ten: "Cấp phép xây dựng giai đoạn công trình theo tuyến", ma: '5' };
export const CONG_TRINH_DU_AN = { ten: "Cấp phép xây dựng công trình dự án", ma: '6' };
export const CONG_TRINH_DI_DOI = { ten: "Cấp phép di dời công trình", ma: '7' };
export const CONG_TRINH_SUA_CHUA = { ten: "Cấp phép sửa chữa cải tạo", ma: '8' };
export const CONG_TRINH_TON_GIAO = { ten: "Cấp phép xây dựng công trình tôn giáo", ma: '9' };
export const CONG_TRINH_TIN_NGUONG = { ten: "Cấp phép xây dựng công trình tín ngưỡng", ma: '10' };
export const CONG_TRINH_QUANG_CAO = { ten: "Cấp phép xây dựng công trình quảng cáo", ma: '11' };
export const CONG_TRINH_TUONG_DAI = { ten: "Cấp phép xây dựng công trình tượng đài, tranh hoành tráng", ma: '12' };
export const CONG_TRINH_CO_THOI_HAN = { ten: "Cấp phép xây dựng công trình có thời hạn", ma: '13' };
export const DIEU_CHINH_GIAY_PHEP = { ten: "Điều chỉnh giấy phép xây dựng", ma: '14' };

export const LIST_LOAIGIAYPHEP = [
    CONG_TRINH_KHONG_THEO_TUYEN,
    CONG_TRINH_THEO_TUYEN,
    CONG_TRINH_NGAM,
    CONG_TRINH_GIAI_DOAN_KHONG_THEO_TUYEN,
    CONG_TRINH_GIAI_DOAN_THEO_TUYEN,
    CONG_TRINH_DU_AN,
    CONG_TRINH_DI_DOI,
    CONG_TRINH_SUA_CHUA,
    CONG_TRINH_TON_GIAO,
    CONG_TRINH_TIN_NGUONG,
    CONG_TRINH_QUANG_CAO,
    CONG_TRINH_TUONG_DAI,
    CONG_TRINH_CO_THOI_HAN
]


export const LOAI_THONG_KE = [
    {
        ten: "Thống kê theo loại giấy phép",
        value: 1
    },
    {
        ten: "Thống kê theo loại tình trạng",
        value: 2
    },
    {
        ten: "Thống kê theo địa chỉ cấp phép",
        value: 3
    }
]

export const DON_VI_THAM_DINH = {
    ten: "Đơn vị thẩm định",
    urlDelete: 'don-vi-tham-dinh/xoa/',
    urlAddorUpdate: 'don-vi-tham-dinh/them-moi',
    urlSearch: 'don-vi-tham-dinh/tim-kiem',
    urlKiemTraDuplicate: 'don-vi-tham-dinh/kiem-tra-duplicate',
};

export const DON_VI_TU_VAN = {
    ten: "Đơn vị tư vấn",
    urlDelete: 'don-vi-tu-van/xoa/',
    urlAddorUpdate: 'don-vi-tu-van/them-moi',
    urlSearch: 'don-vi-tu-van/tim-kiem',
    urlKiemTraDuplicate: 'don-vi-tu-van/kiem-tra-duplicate',
};
export const KY_HIEU_THIET_KE = {
    ten: "Ký hiệu thiết kế",
    urlDelete: 'ky-hieu-thiet-ke/xoa/',
    urlAddorUpdate: 'ky-hieu-thiet-ke/them-moi',
    urlSearch: 'ky-hieu-thiet-ke/tim-kiem',
    urlKiemTraDuplicate: 'ky-hieu-thiet-ke/kiem-tra-duplicate',

};
export const KIEM_TRA_NGAN_CHAN = {
    ten: "Kiểm tra ngăn chặn",
    urlDelete: 'kiem-tra-ngan-chan/delete-by-id/',
    urlAddorUpdate: 'kiem-tra-ngan-chan/them-moi',
    urlSearch: 'kiem-tra-ngan-chan/tim-kiem',
    urlGetById: 'kiem-tra-ngan-chan/get-by-id/',
    urlKiemTraDuplicate: 'kiem-tra-ngan-chan/kiem-tra-duplicate',
    urlDownLoadDS: 'kiem-tra-ngan-chan/export-danh-sach',
};
export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";;
