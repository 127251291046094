import React from 'react';
import StringUtil from '../../../../../../utils/stringUtils';
import { DI_DOI } from '../../../../../Constants/constants';

export default class ViTriDiDoiRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            diaChiDiDoi:'',
            data:{}
        }
    }
    componentDidUpdate(){
        if(this.props.noiDungGiayPhep!==undefined && this.state.data !== this.props.noiDungGiayPhep.viTriDiDoi){
            this.state.data = this.props.noiDungGiayPhep.viTriDiDoi;
            console.log(this.props.noiDungGiayPhep.viTriDiDoi.soNhaDuong);
            this.setState(this.state);
            this.renderDiaChiDiDoi();
        }
    }
    renderDiaChiDiDoi(){
        const {diaChiDiDoi} = this.state;
        let newData = diaChiDiDoi;
        let count = 0;
        if(!StringUtil.isNullOrEmty(this.props.noiDungGiayPhep.viTriDiDoi.soNhaDuong)){
            if(count>0){
                newData += ', '
            }
            newData += this.props.noiDungGiayPhep.viTriDiDoi.soNhaDuong
            count++;
        }
        if(!StringUtil.isNullOrEmty(this.props.noiDungGiayPhep.viTriDiDoi.duong)){
            if(count>0){
                newData += ', '
            }
            newData += this.props.noiDungGiayPhep.viTriDiDoi.duong
            count++;
        }
        if(!StringUtil.isNullOrEmty(this.props.noiDungGiayPhep.viTriDiDoi.phuongXaThiTran)){
            if(count>0){
                newData += ', '
            }
            newData += this.props.noiDungGiayPhep.viTriDiDoi.phuongXaThiTran
            count++;
        }
        if(!StringUtil.isNullOrEmty(this.props.noiDungGiayPhep.viTriDiDoi.huyenThiThanhPho)){
            if(count>0){
                newData += ', '
            }
            newData += this.props.noiDungGiayPhep.viTriDiDoi.huyenThiThanhPho
            count++;
        }
        if(!StringUtil.isNullOrEmty(this.props.noiDungGiayPhep.viTriDiDoi.tinhThanhPho)){
            if(count>0){
                newData += ', '
            }
            newData += this.props.noiDungGiayPhep.viTriDiDoi.tinhThanhPho
            count++;
        }
        this.setState({
            ...this.state,
            diaChiDiDoi: newData
        })
    }
    render() {
        return (
            <>
                <div className="row">
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>VỊ TRÍ XÂY DỰNG DI DỜI</h6>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-3 text-right">
                        <p style={{ fontWeight: 'bold' }}>Địa chỉ</p>
                    </div>
                    <div className="col-md">
                        <p>{this.state.diaChiDiDoi}</p>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-3 text-right">
                        <p style={{ fontWeight: 'bold' }}>Địa chỉ cũ</p>
                    </div>
                    <div className="col-md">
                        <p>  {this.props.noiDungGiayPhep.viTriDiDoi.diaChiCu
                            &&
                            <>
                                {this.props.noiDungGiayPhep.viTriDiDoi.diaChiCu}
                            </>
                        }</p>
                    </div>
                </div>
                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-3 text-right">
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Lô đất xây dựng</p>
                        </div>
                        <div className="col-md-9">
                            <table class="table table-bordered">
                                <thead>
                                    <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                        <th>STT</th>
                                        <th>Số tờ</th>
                                        <th>Số thửa</th>
                                        <th>Một phần thửa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.noiDungGiayPhep.loDatXayDungDiDoi?.length > 0 ? this.props.noiDungGiayPhep.loDatXayDungDiDoi?.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.soTo}</td>
                                                    <td>{item.soThua}</td>
                                                    <td>{item.motPhanThua}</td>
                                                </tr>
                                            </>
                                        )
                                    }) :
                                        <tr>
                                            <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            </>
        )
    }
}