import StringUtil from "../utils/stringUtils";


export function getString(key: string): string {
    let result: string = "";
    let valueAny: any = localStorage.getItem(key);
    if (!StringUtil.isNullOrEmty(valueAny)) {
        result = valueAny as string;
    }
    return result;
}
export function getObject(key: string): any {
    let valueAny: any = localStorage.getItem(key);
    if (valueAny != null) {
        return JSON.parse(valueAny);
    } else {
        return {};
    }
}
