import NavbarGPXD from "./NavbarGPXD";
import GiayPhepService from "../../../../api/service/CapPhepXDCTHService";
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import { CreateGiayPhepDTO } from "../../../../api/Init/CapPhepXayDungFinal";

function ChiTietGPXDRender({ location }) {
  const [noiDungGiayPhep, setNoiDungGiayPhep] = useState( {...CreateGiayPhepDTO});
  const id = location.state && location.state?.id > 0 ? location.state?.id : 0;
  const giayPhepService = new GiayPhepService();
  useEffect(() => {
    loadThongTinGiayPhepXayDung(id);
  },[id]);
  const loadThongTinGiayPhepXayDung = (id) => {
    giayPhepService.getChiTietById({  id: id } ).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          setNoiDungGiayPhep(datas);
        } else {
          toastr.warning('Lỗi kết nối dịch vụ');
        }
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
    });
  }

  return (
    <>
      <br></br>
      <div className="row">
        <br />
        <h6 style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline' }}>
          {noiDungGiayPhep.giayPhep.tenLoaiGiayPhep}</h6>
        <br></br>
        <br></br>
        <NavbarGPXD idGiayPhep={id} noiDungGiayPhep={noiDungGiayPhep}></NavbarGPXD>
      </div >
    </>
  );
}

export default ChiTietGPXDRender;
