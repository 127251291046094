import { Box, Checkbox, createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";
import { DialogContent, DialogTitle } from "../../../../App/commons/components/dialog/dialogCommons";
import { bootstrapTextFieldHook } from "../../../../App/commons/components/input/bootstrap";
import StringUtil from "../../../../App/commons/components/utils/stringUtils";
import { User } from "../../../../model/user";
import { BaseResponse } from "../../../baseService";
import { UserManagementService } from "../../../userMgt/userManagementService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

interface IModalProps {
    onSubmitData: (resp: BaseResponse) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: User;
}

type ErrorValidation = {
    userName: string;
    password: string | null | undefined;
    passwordReset: string | null | undefined;
};

export const UserEditDialog = (props: IModalProps) => {
    const classes = useStyles();

    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<User>({} as User);
    const [errors, setErrors] = useState<ErrorValidation>({} as ErrorValidation);

    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setItemEditDTO((itemEditDTO) => ({
            ...itemEditDTO,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleEnableResetPassword = (e: any) => {
        setItemEditDTO((itemEditDTO) => ({
            ...itemEditDTO,
            passwordReset: "",
        }));
        handleChange(e);
    };

    /* eslint-disable no-useless-escape */
    function validatePassword(str: string): { isFailed: boolean; message: string } {
        let message = "";
        if (StringUtil.isNullOrEmty(str)) {
            message = "Mật khẩu bắt buộc nhập";
        } else if (str.length < 8) {
            message = "Mật khẩu yêu cầu tối thiểu 8 kí tự";
        } else {
            let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
            if (!strongRegex.test(str)) {
                message = "Mật khẩu yêu cầu bao gồm cả chữ và số, ký tự đặc biệt: *,$,#...";
            }
        }
        return { isFailed: message.length !== 0, message: message };
    }

    function validateFrom(): boolean {
        let errorValidation = {} as ErrorValidation;
        if (StringUtil.isNullOrEmty(itemEditDTO.userName)) {
            errorValidation.userName = "Tên đăng nhập bắt buộc nhập";
        } else if (itemEditDTO.userName.trim().length > 30) {
            errorValidation.userName = "Tên đăng nhập không vượt quá 30 ký tự";
        }
        if (StringUtil.isNullOrEmty(itemEditDTO.userId)) {
            let rs = validatePassword(itemEditDTO.password);
            if (rs.isFailed) {
                errorValidation.password = rs.message;
            }
        }
        if (!StringUtil.isNullOrEmty(itemEditDTO.userId) && itemEditDTO.isResetPassword) {
            let rs = validatePassword(itemEditDTO.passwordReset);
            if (rs.isFailed) {
                errorValidation.passwordReset = rs.message;
            }
        }
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0;
    }

    const submitData = async (callback: (resp: BaseResponse) => void) => {
        if (validateFrom()) {
            await new UserManagementService().insertOrUpdateUser(itemEditDTO).then((res) => {
                callback(res);
            });
        }
    };

    const onResetData = () => {
        setItemEditDTO((itemEditDTO) => ({
            ...itemEditDTO,
            userName: StringUtil.isNullOrEmty(itemEditDTO.userId) ? "" : itemEditDTO.userName,
            password: "",
            fullName: "",
            isActive: false,
            isResetPassword: false,
        }));
    };

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {StringUtil.isNullOrEmty(itemEditDTO.userId) ? "Thêm mới người dùng" : "Cập nhật người dùng"}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    disabled={!StringUtil.isNullOrEmty(itemEditDTO.userId)}
                                    label={"Tên đăng nhập"}
                                    required
                                    name="userName"
                                    value={itemEditDTO.userName}
                                    onChange={handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    error={Boolean(errors.userName)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(errors.userName) && errors.userName}
                                />
                            </Grid>
                            {StringUtil.isNullOrEmty(itemEditDTO.userId) && (
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label={"Mật khẩu"}
                                        required
                                        name="password"
                                        value={itemEditDTO.password}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        error={Boolean(errors.password)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(errors.password) && errors.password}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Tên đầy đủ"}
                                    name="fullName"
                                    value={itemEditDTO.fullName}
                                    onChange={handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item" style={{ paddingLeft: 5 }}>
                                <Checkbox
                                    name="isActive"
                                    checked={itemEditDTO.isActive}
                                    color="default"
                                    size="small"
                                    value={itemEditDTO.isActive}
                                    onChange={handleChange}
                                />{" "}
                                Hoạt động
                                {!StringUtil.isNullOrEmty(itemEditDTO.userId) && (
                                    <>
                                        <Checkbox
                                            name="isResetPassword"
                                            checked={itemEditDTO.isResetPassword}
                                            color="default"
                                            size="small"
                                            value={itemEditDTO.isResetPassword}
                                            onChange={handleEnableResetPassword}
                                        />{" "}
                                        Đặt lại mật khẩu
                                    </>
                                )}
                            </Grid>
                            {itemEditDTO.isResetPassword && (
                                <Grid item xs={12} className="grid-item">
                                    <TextField
                                        name="passwordReset"
                                        fullWidth
                                        label={"Mật khẩu mới"}
                                        required
                                        value={itemEditDTO.passwordReset}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        error={Boolean(errors.passwordReset)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                        helperText={Boolean(errors.passwordReset) && errors.passwordReset}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} className="grid-item">
                                <Box marginTop="5px">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        className={classes.button}
                                        onClick={() => {
                                            submitData(props.onSubmitData);
                                        }}
                                    >
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="default" onClick={onResetData} disableElevation className={classes.button}>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation className={classes.button} onClick={props.onDialogClose}>
                                        Hủy
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
