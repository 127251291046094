import moment from "moment";
import StringUtil from "./stringUtils";
export default class DateUtil {
    static toDDMMYYYY_HH_MM_SS(date: Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return '';
        }
    }

    static toDDMMYYYY(date: null | Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }
    static timeToDDMMYYYY(dateTime: null | number): string {
        if (dateTime && dateTime != 0) {
            return moment(new Date(dateTime)).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }

    static  stringDDMMYYYtoDate(value:string): Date|null{
        let result= null;
        if(!StringUtil.isNullOrEmty(value)){
            result = moment(value, 'DD/MM/YYYY').toDate();
        }
        return result;
    }
}