import { combineReducers } from "redux"
import { applicationReducer } from "./applicationReducer"
import { authenticateReducer } from "./authenticateReducer"
import { menuReducer } from "./menuReducer"

export const rootReducer = combineReducers({
    authenticate: authenticateReducer,
    application: applicationReducer,
    menuReducer: menuReducer,
})

export type RootState = ReturnType<typeof rootReducer>