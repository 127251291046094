import React from 'react';
import closeIcon from "../../../Assets/icons/u1233.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class ModalXMTD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                id: 0,
                hoVaTen: '',
                ngayYeuCau: '',
                diaChiThuongTru: '',
                diaChiDangKy: '',
                veViec: '',
                noiDungYeuCau: '',
                banVeThietKe: '',
                cacVanDeKhac: '',
                nguoiKy: '',
                ngayKy: '',
                maHoSo: this.props.maHoSo,
                soBienNhan: this.props.soBienNhan,
                ngayNop: this.props.ngayNop,
                idGiayPhep: this.props.idGiayPhep,
            },
            submit: false
        }
    }

    closeModal = (event) => {
        document.getElementById('closeModal').click();
    }
    setParamsModal = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.modal[name] = value;
        this.setState(this.state);
    }
    clearModal = (event) => {
        this.state.modal['hoVaTen'] = '';
        this.state.modal['ngayYeuCau'] = '';
        this.state.modal['diaChiThuongTru'] = '';
        this.state.modal['diaChiDangKy'] = '';
        this.state.modal['veViec'] = '';
        this.state.modal['noiDungYeuCau'] = '';
        this.state.modal['banVeThietKe'] = '';
        this.state.modal['cacVanDeKhac'] = '';
        this.state.modal['nguoiKy'] = '';
        this.state.modal['ngayKy'] = '';
        this.state.submit = false;
        this.setState(this.state);
    }

    componentDidMount() {
        this.initData();
    }

    initData = idGiayPhep => {
        if (this.props.idGiayPhep > 0){
            AXIOS_INSTANCE.get(`/xac-minh-thuc-dia/get-by-id-giay-phep/${this.props.idGiayPhep}`).then(response => {
                if (response != null && response.status == 200 && response.data.error_code === "SUCCESSFUL") {
                    if (response.data.data){
                        if (response.data.data.ngayYeuCau !==""){
                            this.state.modal.ngayYeuCau = Number(response.data.data.ngayYeuCau);
                        }
                        if (response.data.data.ngayKy !==""){
                            this.state.modal.ngayKy = Number(response.data.data.ngayKy);
                        }
                        this.state.modal.id = response.data.data.id;
                        this.state.modal.hoVaTen = response.data.data.hoVaTen;
                        this.state.modal.diaChiThuongTru = response.data.data.diaChiThuongTru;
                        this.state.modal.diaChiDangKy = response.data.data.diaChiDangKy;
                        this.state.modal.veViec = response.data.data.veViec;
                        this.state.modal.noiDungYeuCau = response.data.data.noiDungYeuCau;
                        this.state.modal.banVeThietKe = response.data.data.banVeThietKe;
                        this.state.modal.cacVanDeKhac = response.data.data.cacVanDeKhac;
                        this.state.modal.nguoiKy = response.data.data.nguoiKy;
                        this.setState(this.state);
                    }
                   
                
                } else {
                    toastr.warning(response.data.error_message);
                }
            }).catch(error => {
                toastr.warning('Có lỗi xảy ra thực hiện lại');
            });
        }
    }


    /*Thao tác */
    addOrUpdate = (event) => {
        event.preventDefault();
        this.state.submit = true;
        this.setState(this.state);
        if (this.state.modal.hoVaTen == '') {
            return
        }
        if (this.state.modal.idGiayPhep < 0){
            toastr.warning("Vui lòng tạo giấy phép trước");
            return
        }
        this.state.modal.idGiayPhep = this.props.idGiayPhep;
        const data = JSON.stringify(this.state.modal);
        AXIOS_INSTANCE.post('xac-minh-thuc-dia/them-moi-hoac-cap-nhat', data).then(response => {
            if (response != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    toastr.success('Thực hiện thao tác thành công');
                    // this.clearModal();
                    this.closeModal();
                } else {
                    toastr.warning(response.data.error_message);
                }
            } else {
                toastr.warning('Thực hiện thao tác không thành công');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    handleChangeNgayYeuCau(date) {
        var longFormat = date * 1;
        this.state.modal.ngayYeuCau = longFormat;
        this.setState(this.state)
        // this.setState({ tuNgay: longFormat });
    }
    handleChangeNgayKy(date) {
        var longFormat = date * 1;
        this.state.modal.ngayKy = longFormat;
        this.setState(this.state)
        // this.setState({ denNgay: longFormat });
    }

    render() {
        const CustomInputNgayYeuCau = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "252px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputNgayKy = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "252px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div class="modal fade" id="modalXMTD" tabIndex="-1" role="dialog" aria-labelledby="modalXMTD" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                            <h5 class="modal-title" id="titlemodalXMTD" style={{ fontWeight: 'bold', color: "white" }}>XÁC MINH THỰC ĐỊA</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                                <span aria-hidden="true"><img src={closeIcon}></img></span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số biên nhận</label>
                                                <span type="text" style={{ color: 'red', fontWeight: 'bold' }} className="col-md col-form-label" id="id" >{this.props.maHoSo}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày nộp</label>
                                                <span type="text" style={{ color: 'red', fontWeight: 'bold' }} className="col-md-8 col-form-label" id="id" >{this.props.ngayNop}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="id" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Họ và tên <span style={{ color: 'red' }}>(*)</span></label>
                                                <input type="text" value={this.state.modal.hoVaTen} id="hoVaTen" name="hoVaTen" onChange={this.setParamsModal} className="form-control col-md"  />
                                            </div>
                                            <div style={this.state.submit && this.state.modal.hoVaTen == '' ? { display: 'block' } : {}} className="invalid-feedback">
                                                Vui lòng nhập họ và tên
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày yêu cầu</label>
                                                {/* <input type="date" value={this.state.modal.ngayYeuCau} id="ngayYeuCau" name="ngayYeuCau" onChange={this.setParamsModal} className="col-md-8 form-control" /> */}
                                                <DatePicker
                                                    customInput={<CustomInputNgayYeuCau />}
                                                    selected={this.state.modal.ngayYeuCau}
                                                    onChange={this.handleChangeNgayYeuCau.bind(this)}
                                                    value={this.state.modal.ngayYeuCau > 0 ? moment(this.state.modal.ngayYeuCau).format("DD/MM/YYYY") : null}
                                                    name="ngayYeuCau"
                                                    placeholderText={"dd/mm/yyyy"}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    popperClassName="some-custom-class"
                                                    popperPlacement="top-end"
                                                    locale={vi}
                                                    popperModifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport",
                                                        }
                                                    }}
                                                >
                                                </DatePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="diaChiThuongTru" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ thường trú</label>
                                        <input type="text" value={this.state.modal.diaChiThuongTru} id="diaChiThuongTru" name="diaChiThuongTru" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="diaChiDangKy" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ đăng ký</label>
                                        <input type="text" value={this.state.modal.diaChiDangKy} id="diaChiDangKy" name="diaChiDangKy" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="veViec" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Về việc</label>
                                        <input type="text" value={this.state.modal.veViec} id="veViec" name="veViec" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="noiDungYeuCau" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung yêu cầu</label>
                                        <input type="text" value={this.state.modal.noiDungYeuCau} id="noiDungYeuCau" name="noiDungYeuCau" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="banVeThietKe" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Bản vẽ thiết kế</label>
                                        <input type="text" value={this.state.modal.banVeThietKe} id="banVeThietKe" name="banVeThietKe" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="cacVanDeKhac" className="col-md-2half  col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Các vấn đề khác</label>
                                        <input type="text" value={this.state.modal.cacVanDeKhac} id="cacVanDeKhac" name="cacVanDeKhac" onChange={this.setParamsModal} className="form-control col-md"  />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="nguoiKy" className="col-md-5 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Người ký</label>
                                                <input type="text" value={this.state.modal.nguoiKy} id="nguoiKy" name="nguoiKy" onChange={this.setParamsModal} className="form-control col-md"  />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-group row">
                                                <label htmlFor="ngayKy" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày ký</label>
                                                {/* <input type="date" value={this.state.modal.ngayKy} id="ngayKy" name="ngayKy" onChange={this.setParamsModal} className="col-md-8 form-control" /> */}
                                                <DatePicker
                                                    customInput={<CustomInputNgayKy />}
                                                    selected={this.state.modal.ngayKy}
                                                    onChange={this.handleChangeNgayKy.bind(this)}
                                                    value={this.state.modal.ngayKy === 0 ? moment(this.state.modal.ngayKy).format("DD/MM/YYYY") : null}
                                                    name="ngayKy"
                                                    placeholderText={"dd/mm/yyyy"}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    popperClassName="some-custom-class"
                                                    popperPlacement="top-end"
                                                    locale={vi}
                                                    popperModifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport",
                                                        }
                                                    }}
                                                >
                                                </DatePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div className="text-center">
                                        <button className="btn btn-primary btn-sm" onClick={this.addOrUpdate}> Lưu</button>&nbsp;&nbsp;
                                        <button className="btn btn-danger btn-sm" onClick={this.clearModal}>Xóa</button> &nbsp;&nbsp;
                                        <button className="btn btn-primary btn-sm">In ấn</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}