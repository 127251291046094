import React from 'react';
import AXIOS_INSTANCE from '../../../api';
import closeIcon from '../../../Assets/icons/u1233.png';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import saveIcon from '../../../Assets/icons/checked.png';
import isEqual from 'lodash/isEqual';
import { createFileType, downLoadFile } from "../../../api/common/exportUtil";
import { LIST_LOAIGIAYPHEP } from '../../Constants/constants';
import { DanhMucService } from '../../../api/danhMuc/danhMucService';
import { SYSTEM_CODE } from '../../../configs/systemConfig';
export default class CHBMViewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nghiepVu: [],
            linhVuc: [],
            bieuMauDinhKem: this.props.bieuMauDinhKem,
            cauHinhBieuMau: this.props.cauHinhBieuMau
        }
        this.apiDanhMuc = new DanhMucService();
    }
    componentDidMount() {
        this.nghiepVu();
        this.linhVuc();
    }
    nghiepVu = () => {
        this.setState({ nghiepVu: LIST_LOAIGIAYPHEP || [] });
    }

    linhVuc = () => {
        this.apiDanhMuc.getLinhVucByMaCoQuan_HoatDong(SYSTEM_CODE, true).then(danhMucLinhVuc => this.setState({ linhVuc: danhMucLinhVuc || [] }));
    }
    componentDidUpdate(prevProps) {
        const { cauHinhBieuMau, bieuMauDinhKem } = this.props;
        if (!isEqual(prevProps.cauHinhBieuMau, cauHinhBieuMau)) {
            this.setState({
                cauHinhBieuMau: cauHinhBieuMau,
                bieuMauDinhKem: bieuMauDinhKem
            });
        }
    }

    dowloadFileInfo = (loaiTapTin, tenTapTin, event) => {
        const url = `/danh-muc-bieu-mau/dowload/tep-dinh-kem?maNghiepVu=${this.state.cauHinhBieuMau.maNghiepVu}&idTapTin=${event.target.name}&maLinhVuc=${this.state.cauHinhBieuMau.maLinhVuc}`;
        AXIOS_INSTANCE.get(url, {
            responseType: 'blob',
        }).then(res => {
            if (res != null && res.status == 200) {
                downLoadFile(res.data, createFileType(loaiTapTin), tenTapTin);
                toastr.success("Tải biểu mẫu thành công!");
            } else {
                toastr.warning('Tải biểu mẫu không thành công');
            }
        }).catch(error => {
            toastr.warning('Tải biểu mẫu không thành công');
        });
    }

    render() {
        return (
            <React.Fragment>
                <form>
                    <div className="modal fade" id="CHBMViewModal" ref={this.modalRef} role="dialog" aria-labelledby="CHBMViewModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: " 826px" }}>
                            <div className="modal-content">
                                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                    <h5 className="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>CHỈNH SỬA CẤU HÌNH BIỂU MẪU</h5>
                                    <img type="button" className="close" data-dismiss="modal" aria-label="Close" src={closeIcon}></img>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên biểu mẫu <span style={{ color: 'red' }}> (*)</span></label>
                                            <input type="text" className="form-control" id="soGiayPhepValue" name="tenBieuMau" value={this.state.cauHinhBieuMau.tenBieuMau} disabled />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lĩnh vực <span style={{ color: 'red' }}> (*)</span></label>
                                            <select className="form-control" id="sel1" name="maLinhVuc" value={this.state.cauHinhBieuMau.maLinhVuc} disabled>
                                                <option value="">--Chọn--</option>
                                                {
                                                    this.state.linhVuc.map((e) => {
                                                        return <option value={e.ma}>{e.ten}</option>
                                                    })
                                                }
                                            </select>
                                            {this.state.soGiayPhepError && <span style={{ color: 'red' }} >{this.state.soGiayPhepError}</span>}
                                        </div>
                                        <div className="col-md-6 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nghiệp vụ <span style={{ color: 'red' }}>(*)</span></label>
                                            <select className="form-control" id="sel1" name="maNghiepVu" value={this.state.cauHinhBieuMau.maNghiepVu} disabled>
                                                <option value="">--Chọn--</option>
                                                {
                                                    this.state.nghiepVu.map((e) => {
                                                        return <option value={e.ma}>{e.ten}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-left">
                                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đính kèm</label>
                                            <div class="row mt-1">
                                                <div class="col-md-12">
                                                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                                                        <thead>
                                                            <tr className="table-secondary">
                                                                <th>STT</th>
                                                                <th>Tên hiển thị</th>
                                                                <th>Tên tập tin</th>
                                                                <th className="text-center">Thao tác</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.bieuMauDinhKem?.length > 0 ? this.state.bieuMauDinhKem?.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td className="cell-number" style={{ width: "35px" }}>{index + 1}</td>
                                                                        <td style={{ width: "350px" }}>
                                                                            {item.tenHienThi}
                                                                        </td>
                                                                        <td style={{ width: "332px" }}>
                                                                            <div className="row">
                                                                                <div className="col-md-12 text-left">{item.tenTapTin}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: "90px" }}>
                                                                            <div className="row">
                                                                                <div className="col-md-6 text-center"><img src={saveIcon} name={item.idTepDinhKem} onClick={(event) => this.dowloadFileInfo(item.loaiTapTin, item.tenTapTin, event)}></img></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" onChange={this.setParamCheckBox} name="trangThai" checked={this.state.cauHinhBieuMau.trangThai} disabled /> Hoạt động
                                    </div>
                                    <div className="col-md-12 text-left row">
                                        <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Trở về</button>&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </form>
            </React.Fragment>
        )
    }
}