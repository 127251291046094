import React from 'react';

export default class ThongTinCongTrinhNgamRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN CÔNG TRÌNH NGẦM</h6>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Tên công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.tenCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Cấp công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.capCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Điển đầu công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.diemDauCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Điển cuối công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.diemCuoiCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Quy mô công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.quyMoCongTrinh}</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Tổng chiều dài</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.noiDungGiayPhep.thongTinCongTrinhNgam.tongChieuDai}</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>CÁC ĐƠN VỊ HÀNH CHÍNH CÔNG TRÌNH PHẢI ĐI QUA</h6>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <table class="table table-bordered">
                        <thead>
                            <tr className="table-secondary text-center" style={{ fontSize: '14px' }}>
                                <th>STT</th>
                                <th>Huyện / Thị xã / Thành phố</th>
                                <th>Phường / Xã / Thị trấn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.noiDungGiayPhep.donViHanhChinhs?.length > 0 ? this.props.noiDungGiayPhep.donViHanhChinhs.map((item, index) => {

                                return (
                                    <>
                                        <tr>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{item.tenHuyenThiTP}</td>
                                            <td>{item.tenPhuongXaTT}</td>
                                        </tr>
                                    </>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={3}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN XÂY DỰNG</h6>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Chiều rộng công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >Từ:
                                {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhFrom
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhFrom}
                                        </>
                                    }
                                    m
                                    - Đến:
                                    {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhTo
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuRongCongTrinhTo}
                                        </>
                                    }
                                     m</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Chiều Sâu công trình</p>
                            </div>
                            <div className="col-md-6">
                            <p >Từ:
                                {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhFrom
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhFrom}
                                        </>
                                    }
                                    m
                                    - Đến:
                                    {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhTo
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.chieuSauCongTrinhTo}
                                        </>
                                    }
                                     m</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Khoảng cách nhỏ nhất đến nhà dân</p>
                            </div>
                            <div className="col-md-6">
                                <p >    {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.khoangCachNN
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.khoangCachNN}
                                        </>
                                    }</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Ghi chú về vị trí xây dựng công trình</p>
                            </div>
                            <div className="col-md-6">
                                 <p >    {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChuVTXD
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChuVTXD}
                                        </>
                                    }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Ghi Chú</p>
                            </div>
                            <div className="col-md-6">
                                    <p >    {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChu
                                        && <>
                                            {this.props.noiDungGiayPhep.thongTinCongTrinhNgam.ghiChu}
                                        </>
                                    }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}