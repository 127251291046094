import React from 'react';
import editIcon from '../../../Assets/icons/u624.png';
import removeIcon from '../../../Assets/icons/u626.png';
import checked from '../../../Assets/icons/checked.png';
import CHBMUpdateModal from './CHBMUpdateModal';
import CHBMDeleteModal from './CHBMDeleteModal';
import CHBMViewModal from './CHBMViewModal';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
export default class CHBMTable extends React.Component {
    state = {
        bieuMauDinhKem :  [],
        idDelete: 0,
        cauHinhBieuMau : {
            id: 0,
            tenBieuMau: "",
            maLinhVuc: "",
            maNghiepVu: "",
            trangThai: true,
            bieuMauDinhKem: [
                    {
                        id: 0,
                        tenHienThi: "",
                        tenTapTin: "",
                        loaiTapTin: "",
                        idTepDinhKem: "",
                        tepDinhKem: null,
                        trangThai: true
                    }
            ],
        }
    }
    deleteBieuMau = (event) => {
        const idDelete = event.target.name;
        this.setState({idDelete: idDelete || 0 });
    }

    chiTietBieuMau = (event) => {
        const idBM =   event.target.name;
        const url = `danh-muc-bieu-mau/chi-tiet/${idBM}`;
        AXIOS_INSTANCE.get(url).then(res => {
            if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
               this.setState({cauHinhBieuMau: res.data.data || {} , bieuMauDinhKem : res.data.data.bieuMauDinhKem || [] });
            } else {
                this.setState({cauHinhBieuMau: {} , bieuMauDinhKem : [] });
            }
          }).catch(error => {
            this.setState({cauHinhBieuMau: {} , bieuMauDinhKem : [] });
          });
    }
    onSearch = () => {
        const dataInfo = {
            "pageNumber": 0,
            "pageSize": 10,
            "tenBieuMau": "",
            "trangThai": true
        };
        this.props.onSearch(dataInfo);
    }
    render() {
        return (
            <React.Fragment> 
            <CHBMUpdateModal onSearch={this.onSearch} cauHinhBieuMau = {this.state.cauHinhBieuMau} bieuMauDinhKem={this.state.bieuMauDinhKem} checkTime={new Date().getTime()}></CHBMUpdateModal>
            <CHBMViewModal  cauHinhBieuMau = {this.state.cauHinhBieuMau} bieuMauDinhKem={this.state.bieuMauDinhKem}></CHBMViewModal>
            <CHBMDeleteModal onSearch={this.onSearch} idDelete={this.state.idDelete}></CHBMDeleteModal>
            <table className="table table-bordered text-center">
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Tên biểu mẫu</th>
                        <th>Lĩnh vực</th>
                        <th>Nghiệp vụ</th>
                        <th>Hoạt động</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.list && this.props.list.length ? this.props.list.map((item, index) => {
                            return (
                                <tr>
                                    <td  style={{ width: '44px' }}><small>{this.props.pageIndex + index}</small></td>
                                    <td style={{ width: '450px' }}>
                                        <small>
                                            <a type="button" data-toggle="modal" data-target="#CHBMViewModal" className="ml-1 mr-2"  name={item.id} onClick={this.chiTietBieuMau} style={{display: 'unset', margin: '5px'}} name={item.id} >{item.tenBieuMau}</a>
                                        </small>
                                    </td>
                                    <td style={{ width: '250px' }}><small>{item.tenLinhVuc}</small></td>
                                    <td style={{ width: '300px' }}><small>{item.tenNghiepVu}</small></td>
                                    <td style={{ width: '150px' }}> 
                                    {item.trangThai ? <img style={{display: 'unset', margin: '5px'}} src={checked}></img> : 'Không hoạt động'}
                                    </td>
                                    <td style={{ width: '100px' }}>
                                        <img type="button" data-toggle="modal" data-target="#CHBMUpdateModal" className="ml-1 mr-2"  name={item.id} onClick={this.chiTietBieuMau} style={{display: 'unset', margin: '5px'}} name={item.id} src={editIcon}></img>
                                        <img type="button" className="ml-2 mr-1" style={{display: 'unset', margin: '5px'}} data-toggle="modal" data-target="#ModalDelete" name={item.id} onClick={this.deleteBieuMau}  src={removeIcon}></img>
                                    </td>
                                </tr>
                            )
                        })
                        : <tr>
                            <td colSpan={6}> Không có dữ liệu</td>
                        </tr>}
                </tbody>
            </table>
        </React.Fragment>
        )
    }
}