import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../../api/index';

export default class FormSearchCDTDN extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                maSoDoanhNghiep: '',
                ten: '',
                tenTinhThanh: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                tenLoaiToChuc: '',
                soQuyetDinh: '',
                loaiChuDauTu: 2,
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5,
                nguonChuDauTu: 2,
            },
            pagination: {},
            selectedOption: '',
            pageIndex: 0,
            pageLength: 0,
            total: 0,
            checkedOptions: new Map(),
            maHoSo: this.props.maHoSo,
            idGiayPhep: []
        }
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.search[name] = value;
        this.setState(this.state);
    }

    render() {
        console.log("this.props.dataValues : ", this.props.dataValues)
        return (
            <div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10">
                            <div className="form-group">
                                <input type="text" value={this.state.maSoDoanhNghiep} className='form-control col-sm' id="maSoDoanhNghiep" name="maSoDoanhNghiep" onChange={this.setParams} />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div className="form-group">
                                <button className="btn btn-primary" onClick={() => this.props.loadDataTables(this.state.search.maSoDoanhNghiep)}> Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                <div className="col-md-12">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="table-secondary">
                                {/* <th>STT</th> */}
                                <th></th>
                                <th>Tên tổ chức</th>
                                <th>Loại tổ chức</th>
                                <th>Người đại diện</th>
                                <th>Địa chỉ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.dataValues.tenDoanhNghiep_VN != null &&
                                <tr>
                                    <td>
                                        <input
                                            type="radio"
                                            onChange={e => this.props.handleChangeCheck(e)}
                                            defaultChecked={this.props.checked}
                                        ></input>
                                    </td>
                                    <td>{this.props.dataValues.tenDoanhNghiep_VN}</td>
                                    <td>{this.props.dataValues.doanhNghiepID > 0 ? "Doanh Nghiệp" : ""}</td>
                                    <td>{this.props.dataValues.doanhNghiepID > 0 ? "Người đại diện" : ""}</td>
                                    <td>{this.props.dataValues.fullAddress}</td>
                                </tr>
                            }
                            {this.props.dataValues.tenDoanhNghiep_VN == null &&
                                <tr>
                                    <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div className="text-right col-md-6">
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={this.props.handleClickSetDatas} className="btn btn-primary">Chọn</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}