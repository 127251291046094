import { TypeField } from '../../../commons/components/eform/thongTinChuyenNganheForm';
import DateUtil from '../../../commons/components/utils/dateUtils';
import StringUtils from '../../../commons/components/utils/stringUtils';

export default class ThongTinChuyenNganhUtil {
    static getValueByKey(key: string, thongTinChuyenNganh: any[], typeField: TypeField): any {
        let result;
        if (typeField === TypeField.TYPE_STRING) {
            if (!StringUtils.isNullOrEmty(key)) {
                for (let i = 0; i < thongTinChuyenNganh.length; i++) {
                    if (thongTinChuyenNganh[i].tenTruong === key) { result = thongTinChuyenNganh[i].giaTri; break }
                }
            }
        }
        if (typeField === TypeField.TYPE_DATE) {
            if (!StringUtils.isNullOrEmty(key)) {
                for (let i = 0; i < thongTinChuyenNganh.length; i++) {
                    if (thongTinChuyenNganh[i].tenTruong === key) { result = DateUtil.stringDDMMYYYtoDate(thongTinChuyenNganh[i].giaTri); break }
                }
            }
        }
        return result;
    }

}