import moment from 'moment';
import React from 'react';
export default class TableGPXD extends React.Component {

    render() {
        return (
            <table className="table table-bordered text-center" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Loại giấy phép</th>
                        <th>Số giấy phép</th>
                        <th>Ngày cấp</th>
                        <th>Chủ đầu tư</th>
                        <th>Tổng diện tích sàn</th>
                        <th>Vị trí xây dựng</th>
                        <th>Tình trạng</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.list && this.props.list.length
                        ? this.props.list.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{ width: '15px' }}>{this.props.pageIndex + index + 1}</td>
                                    <td style={{ width: '186px' }}>
                                        {item.tenLoaiGiayPhep}
                                    </td>
                                    <td style={{ width: '88px' }}>{item.tenSoGiayPhep}</td>
                                    <td style={{ width: '115px' }}>{moment(item.ngayCap).format("DD/MM/YYYY")}</td>
                                    <td style={{ width: '115px' }}>{item.tenChuDauTu}</td>
                                    <td style={{ width: '115px' }}>{item.tongDienTichSan}</td>
                                    <td style={{ width: '205px' }}>{item.viTriXayDung}</td>
                                    <td style={{ width: '137px' }}>{item.tenTrangThai}</td>
                                </tr>
                            )
                        })
                        : <tr>
                            <td colSpan={8}> Không có dữ liệu</td>
                        </tr>}
                </tbody>
            </table>
        )
    }
}