import React from 'react';
import closeIcon from "../../../../Assets/icons/u1233.png";
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../utils/stringUtils';
import moment from 'moment';
import AXIOS_INSTANCE from '../../../../api/index';
import { HoSoCuaToiMgtService } from '../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import { TypeField } from '../../../commons/components/eform/thongTinChuyenNganheForm';

export default class ModalCreateCDTCN extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                idGiayPhep: 0,
                tenLoaiGiayPhep: '',
                nguonChuDauTu: 1,
                gioiTinh: 0,
                duong: '',
                tenLoaiGiayTo: 'Chứng minh nhân dân',
                maTinhThanh: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
                tenDoanhNghiep: '',
            },
            show: false,
            indexEdit: -1,
            errors: {
                ten: '',
                soGiayTo: '',
                // tinhThanh: '',
                // phuongXa: '',
                // quanHuyen: '',
                tenDoanhNghiep: '',
            },
            quanHuyen: [],
            phuongXa: [],
            data: [],
            dataCMT: {}
        }
    }


    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
        if (this.props.data != null && this.props.data != this.state.chuDauTu) {
            this.state.chuDauTu = this.props.data;
            this.setState(this.state)
        }
        if (this.props.indexEdit != null && this.props.indexEdit != this.state.indexEdit) {
            this.state.indexEdit = this.props.indexEdit;
            this.setState(this.state)
        }
        if (this.props.chuDauTus !== null && this.props.chuDauTus !== this.state.data) {
            this.state.data = this.props.chuDauTus;
            this.setState(this.state)
        }
    }

    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle();
    }
    danhSachQuanHuyenInfo = maTinhThanh => {
        const queryParam = {
            "maTinhThanh": maTinhThanh
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }
    handleChange = (e) => {
        let target = e.target;
        let type = target.type;
        let name = target.name;
        let value = target.value;
        let text = '';
        if (type === "select-one") {
            text = target.selectedOptions[0].text;
        }
        if (name === "maQuanHuyen") {
            this.state.chuDauTu[name] = value;
            this.state.chuDauTu.tenQuanHuyen = text;
            this.danhSachPhuongXa(value);
        }
        if (name === "maTinhThanh") {
            this.state.chuDauTu[name] = value;
            this.state.chuDauTu.tenTinhThanh = text;
            this.danhSachQuanHuyenInfo(value);
        }
        if (name === "maPhuongXa") {
            this.state.chuDauTu[name] = value;
            this.state.chuDauTu.tenPhuongXa = text;
        }
        if (type === "date") {
            this.state.chuDauTu[name] = new Date(value).getTime();
        } else {
            this.state.chuDauTu[name] = value;
        }
        this.state.errors = {
            ten: '',
            soGiayTo: ''
        }
        if (name == "loaiChuDauTu") {
            this.state.chuDauTu.loaiChuDauTu = value;
        }
        this.setState(this.state);
    }

    handleSubmit = () => {
        if (this.validation()) {
            if (this.state.indexEdit >= 0) {
                this.props.onUpdateData(this.state.indexEdit, this.state.chuDauTu);
                toastr.success('Cập nhật chủ đầu tư thành công')
                this.handleCloseModal();
            } else {
                this.props.onAddData(this.state.chuDauTu);
                toastr.success('Thêm mới chủ đầu tư thành công')
                this.handleCloseModal();
            }
        }
    }

    danhSachPhuongXa = maQuanHuyen => {
        const queryParam = {
            "maTinhThanh": this.state.chuDauTu.maTinhThanh,
            "maQuanHuyen": maQuanHuyen
        }
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }

    initData = () => {
        if (this.state.eForm.length > 0) {
            this.state = {
                chuDauTu: {
                    id: 0,
                    ten: '',
                    diaChiChiTiet: '',
                    idGiayPhep: 0,
                    tenLoaiGiayPhep: '',
                    nguonChuDauTu: 1,
                    gioiTinh: 0,
                    duong: '',
                    tenLoaiGiayTo: 'Chứng minh nhân dân',
                    maTinhThanh: '',
                    maQuanHuyen: '',
                    maPhuongXa: '',
                    ngayCap: this.state.dataCMT.cmt_NgayCap,
                    soGiayTo: '',
                    noiCap: '',
                    diaChiCu: '',
                    ngaySinh: '',
                    loaiChuDauTu: 1,
                    hoatDong: 1,
                    nguonChuDauTu: 1,
                },
                show: false,
                indexEdit: -1,
                errors: {
                    ten: '',
                    soGiayTo: '',
                    // tinhThanh: '',
                    // phuongXa: '',
                    // quanHuyen: '',
                    tenDoanhNghiep: '',
                },
                quanHuyen: [],
                phuongXa: [],
                data: [],
                dataCMT: {}
            };
        } else {
            this.state = {
                chuDauTu: {
                    id: 0,
                    ten: '',
                    diaChiChiTiet: '',
                    idGiayPhep: 0,
                    tenLoaiGiayPhep: '',
                    nguonChuDauTu: 1,
                    gioiTinh: 0,
                    duong: '',
                    tenLoaiGiayTo: 'Chứng minh nhân dân',
                    maTinhThanh: '',
                    maQuanHuyen: '',
                    maPhuongXa: '',
                    ngayCap: '',
                    soGiayTo: '',
                    noiCap: '',
                    diaChiCu: '',
                    ngaySinh: '',
                    loaiChuDauTu: 1,
                    hoatDong: 1,
                    nguonChuDauTu: 1,
                },
                show: false,
                indexEdit: -1,
                errors: {
                    ten: '',
                    soGiayTo: '',
                    // tinhThanh: '',
                    // phuongXa: '',
                    // quanHuyen: '',
                    tenDoanhNghiep: '',
                },
                quanHuyen: [],
                phuongXa: [],
                data: [],
                dataCMT: {}
            };
        }
        this.setState(this.state);
    }

    loadData = () => {
        let soCMND = this.state.chuDauTu.soGiayTo
        new HoSoCuaToiMgtService().layThongTinCongDanByCMND(soCMND).then((res) => {
            if (res != null) {
                let datas = res.data.data.data;
                var ngayCap = new Date(datas.cmt_NgayCap);
                var longFormatNgayCap = ngayCap * 1;
                var ngaySinh = new Date(datas.ngayThangNamSinh);
                var longFormatngaySinh = ngaySinh * 1;
                if (datas != null) {
                    this.state.chuDauTu.ngayCap = longFormatNgayCap;
                    this.state.chuDauTu.noiCap = datas.cmt_NoiCap
                    this.state.chuDauTu.ngaySinh = longFormatngaySinh;
                    this.setState(this.state)
                } else {
                    this.initData();
                }
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    validation = () => {
        let valid = true;
        const { errors, chuDauTu, data } = this.state;
        let new_errors = {};
        // if (StringUtil.isNullOrEmty(chuDauTu.maTinhThanh)) {
        //     new_errors.tinhThanh = 'Vui lòng chọn tỉnh thành';
        //     valid = false;
        // }
        // if (StringUtil.isNullOrEmty(chuDauTu.maQuanHuyen)) {
        //     new_errors.quanHuyen = 'Vui lòng chọn quận huyện';
        //     valid = false;
        // }
        // if (StringUtil.isNullOrEmty(chuDauTu.maPhuongXa)) {
        //     new_errors.phuongXa = 'Vui lòng chọn phường xã';
        //     valid = false;
        // }
        if (StringUtil.isNullOrEmty(chuDauTu.ten)) {
            new_errors.ten = 'Vui lòng nhập họ và tên';
            valid = false;
        }
        if (StringUtil.isNullOrEmty(chuDauTu.soGiayTo)) {
            new_errors.soGiayTo = 'Vui lòng nhập số giấy tờ';
            valid = false;
        } else {
            if (chuDauTu.tenLoaiGiayTo === "Chứng minh nhân dân") {
                if (!chuDauTu.soGiayTo.match(/^[0-9]{9}$/)) {
                    new_errors.soGiayTo = 'Vui lòng nhập đủ 9 ký số';
                    valid = false;
                } 
                // else {
                //     data.map((item) => {
                //         if (item.soGiayTo == chuDauTu.soGiayTo) {
                //             new_errors.soGiayTo = 'Số giấy tờ đã tồn tại';
                //             valid = false;
                //         }
                //     })
                // }
            }
            if (chuDauTu.tenLoaiGiayTo === "Căn cước công dân") {
                if (!chuDauTu.soGiayTo.match(/^[0-9]{12}$/)) {
                    new_errors.soGiayTo = 'Vui lòng nhập đủ 12 ký số';
                    valid = false;
                } else {
                    data.map((item) => {
                        if (item.soGiayTo == chuDauTu.soGiayTo) {
                            new_errors.soGiayTo = 'Số giấy tờ đã tồn tại';
                            valid = false;
                        }
                    })
                }
            }
            if (chuDauTu.tenLoaiGiayTo === "Hộ chiếu") {
                if (!chuDauTu.soGiayTo.match(/^[A-Z][0-9]{7}$/)) {
                    new_errors.soGiayTo = 'Vui lòng nhập bắt đầu là ký tự viết Hoa sau đó là 7 ký số';
                    valid = false;
                } else {
                    data.map((item) => {
                        if (item.soGiayTo == chuDauTu.soGiayTo) {
                            new_errors.soGiayTo = 'Số giấy tờ đã tồn tại';
                            valid = false;
                        }
                    })
                }
            }

        }

        if (chuDauTu.ngayCap && chuDauTu.ngaySinh) {
            if (chuDauTu.ngayCap <= chuDauTu.ngaySinh) {
                new_errors.ngayCap = "Ngày cấp phải lớn hơn ngày sinh"
                new_errors.ngaySinh = "Ngày cấp phải lớn hơn ngày sinh"
                valid = false;
            }
        }
        let date = new Date();
        if (chuDauTu.ngayCap > date) {
            new_errors.ngayCap = "Ngày cấp phải nhỏ hơn ngày hiện tại"
            valid = false;
        }
        if (chuDauTu.ngaySinh > date) {
            new_errors.ngaySinh = "Ngày sinh phải nhỏ hơn ngày hiện tại"
            valid = false;
        }
        if (chuDauTu.loaiChuDauTu == 2 &&  StringUtil.isEmty(chuDauTu.tenDoanhNghiep)) {
            new_errors.tenDoanhNghiep = "Tên doanh nghiệp không được bỏ trống"
            valid = false;
        }

        this.setState({ errors: new_errors });
        return valid;
    }

    clearData = () => {
        this.setState({
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                gioiTinh: 0,
                duong: '',
                tenLoaiGiayTo: 'Chứng minh nhân dân',
                tenTinhThanh: '',
                maQuanHuyen: '',
                maPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
            },
            errors: {
                ten: '',
                soGiayTo: ''
            }
        });
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI CHỦ ĐẦU TƯ</h5>
                    <button type="button" class="close" onClick={this.handleCloseModal} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        {/* <label htmlFor="id" className="col-md-2half col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại đối tượng:</label>
                                        <span type="text" className="col-form-label" id="id" >Cá nhân</span> */}
                                         <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại chủ đầu tư</label>
                                        <select type="text"
                                            value={this.state.chuDauTu.loaiChuDauTu}
                                            className='form-control col-sm'
                                            id="loaiChuDauTu" name="loaiChuDauTu"
                                            onChange={this.handleChange} >
                                            <option value="1">Cá nhân</option>
                                            <option value="2">Doanh nghiệp</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.chuDauTu.loaiChuDauTu == 2 && (
                                <div class="col-md-12">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-2 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tên doanh nghiệp <span style={{ color: 'red' }}>(*)</span></label>
                                        <input
                                            type="text"
                                            value={this.state.chuDauTu?.tenDoanhNghiep}
                                            className='form-control col-md'
                                            id="tenDoanhNghiep"
                                            name="tenDoanhNghiep"
                                            onChange={this.handleChange} />
                                        <div style={Boolean(this.state.errors?.tenDoanhNghiep) ? { display: 'block' } : {}} className="invalid-feedback  offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tenDoanhNghiep}</span>
                                        </div>
                                        </div>
                                        </div>
                            )
                        }
                 
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Họ và tên người đại diện <span style={{ color: 'red' }}>(*)</span></label>
                                        <input
                                            type="text"
                                            value={this.state.chuDauTu?.ten}
                                            className='form-control col-sm'
                                            id="ten"
                                            name="ten"
                                            onChange={this.handleChange} />
                                        <div style={Boolean(this.state.errors?.ten) ? { display: 'block' } : {}} className="invalid-feedback  offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.ten}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ chi tiết</label>
                                        <input type="text"
                                            value={this.state.chuDauTu?.diaChiChiTiet}
                                            className='form-control col-sm'
                                            id="diaChiChiTiet"
                                            name="diaChiChiTiet"
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Giới tính</label>
                                        <select type="text"
                                            value={this.state.chuDauTu.gioiTinh}
                                            className='form-control col-sm'
                                            id="gioiTinh" name="gioiTinh"
                                            onChange={this.handleChange} >
                                            <option value="0">--Chọn giới tính--</option>
                                            <option value="1">Nam</option>
                                            <option value="2">Nữ</option>
                                            <option value="3">Khác</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                        <input type="text"
                                            value={this.state.chuDauTu.duong}
                                            className='form-control col-sm'
                                            id="duong"
                                            name="duong"
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại giấy tờ</label>
                                        <select type="text"
                                            value={this.state.chuDauTu?.tenLoaiGiayTo}
                                            className='form-control col-sm'
                                            id="tenLoaiGiayTo"
                                            name="tenLoaiGiayTo"
                                            onChange={this.handleChange} >
                                            <option value="Chứng minh nhân dân">Chứng minh nhân dân</option>
                                            <option value="Căn cước công dân">Căn cước công dân</option>
                                            <option value="Hộ chiếu">Hộ chiếu</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tỉnh, thành </label>
                                        <select type="text"
                                            value={this.state.chuDauTu.maTinhThanh}
                                            className='form-control col-sm'
                                            id="maTinhThanh"
                                            name="maTinhThanh"
                                            onChange={this.handleChange} >
                                            <option value="">Chọn tỉnh, thành</option>
                                            <option value="92">Thành phố Cần Thơ</option>
                                        </select>
                                        {/* <div style={Boolean(this.state.errors?.tinhThanh) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.tinhThanh}</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>CMND/CCCD<span style={{ color: 'red' }}>(*)</span></label>
                                        <input type="text"
                                            value={this.state.chuDauTu?.soGiayTo}
                                            className='form-control col-sm'
                                            id="soGiayTo"
                                            name="soGiayTo"
                                            onChange={this.handleChange} />
                                        <i class="fas fa-search" onClick={this.loadData} style={{ marginTop: '12px', marginLeft: '8px' }}></i>
                                        <div style={Boolean(this.state.errors?.soGiayTo) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.soGiayTo}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Quận, huyện </label>
                                        <select type="text"
                                            value={this.state.chuDauTu?.maQuanHuyen}
                                            className='form-control col-sm'
                                            id="maQuanHuyen"
                                            name="maQuanHuyen"
                                            onChange={this.handleChange} >
                                            <option value=""> Chọn quận, huyện</option>
                                            {
                                                this.state.quanHuyen.map((e, ind) => {
                                                    return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                                                })
                                            }
                                        </select>
                                        {/* <div style={Boolean(this.state.errors?.quanHuyen) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.quanHuyen}</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                        <input type="date"
                                            max={moment(new Date()).format('yyyy-MM-DD')}
                                            value={this.state.chuDauTu?.ngayCap ? moment(new Date(this.state.chuDauTu.ngayCap)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                            className='form-control col-sm'
                                            id="ngayCap"
                                            name="ngayCap"
                                            onChange={this.handleChange} />
                                        <div style={Boolean(this.state.errors?.ngayCap) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayCap}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phường, xã </label>
                                        <select type="text"
                                            value={this.state.chuDauTu.maPhuongXa}
                                            className='form-control col-sm'
                                            id="maPhuongXa"
                                            name="maPhuongXa"
                                            onChange={this.handleChange} >
                                            <option value="">Chọn phường, xã</option>
                                            {
                                                this.state.phuongXa.map((e, ind) => {
                                                    return <option key={e.maPhuongXa} value={e.maPhuongXa}>{e.tenPhuongXa}</option>
                                                })
                                            }
                                        </select>
                                        {/* <div style={Boolean(this.state.errors?.phuongXa) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.phuongXa}</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nơi cấp</label>
                                        <select type="text"
                                            value={this.state.chuDauTu.noiCap}
                                            className='form-control col-sm'
                                            id="noiCap"
                                            name="noiCap"
                                            onChange={this.handleChange} >
                                            <option value="">Chọn nơi cấp</option>
                                            <option value="Thành phố Hồ Chí Minh">Thành phố Hồ Chí Minh</option>
                                            <option value="Thành phố Cần Thơ">Thành phố Cần Thơ</option>
                                            <option value="Công an Tỉnh Hậu Giang">Công an Tỉnh Hậu Giang</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                        <input type="text"
                                            value={this.state.chuDauTu.diaChiCu}
                                            className='form-control col-sm'
                                            id="diaChiCu"
                                            name="diaChiCu"
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <label htmlFor="id" className="col-sm-4 col-form-label" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày sinh</label>
                                        <input type="date"
                                            max={moment(new Date()).format('yyyy-MM-DD')}
                                            value={this.state.chuDauTu?.ngaySinh ? moment(new Date(this.state.chuDauTu.ngaySinh)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                            className='form-control col-sm'
                                            id="ngaySinh"
                                            name="ngaySinh"
                                            onChange={this.handleChange} />
                                        <div style={Boolean(this.state.errors?.ngaySinh) ? { display: 'block' } : {}} className="invalid-feedback offset-sm-4" >
                                            <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngaySinh}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div className="text-right col-md-6">
                                    <button className="btn btn-primary btn-sm" onClick={this.handleSubmit}> Lưu</button>
                                </div>
                                <div className="col-md-6">
                                    <button onClick={this.handleCloseModal} className="btn btn-secondary btn-sm"> Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        )
    }

}