import { Pagination } from '@material-ui/lab';
import React from 'react';
import ModalChiTietChuDauTu from './ModalChiTietChuDauTu';

export default class ChuDauTu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chuDauTus: [],
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataPagi: [],
            openModel: false,
            recordsPerPage: 0,
            chuDauTu: {
                id: 0,
                ten: '',
                diaChiChiTiet: '',
                idGiayPhep: 0,
                tenLoaiGiayPhep: '',
                nguonChuDauTu: 1,
                gioiTinh: 0,
                duong: '',
                tenLoaiGiayTo: '',
                tenTinhThanh: '',
                tenQuanHuyen: '',
                tenPhuongXa: '',
                ngayCap: '',
                soGiayTo: '',
                noiCap: '',
                diaChiCu: '',
                ngaySinh: '',
                loaiChuDauTu: 1,
                hoatDong: 1,
                nguonChuDauTu: 1,
            }
        }
    }
    componentDidUpdate() {
        if (this.props.chuDauTus !== undefined && this.props.chuDauTus !== this.state.chuDauTus) {
            this.state.chuDauTus = this.props.chuDauTus;
            this.setState(this.state);
            this.handleSearch();
        }
    }
    openEdit = (index) => {
        const { chuDauTu, chuDauTus } = this.state;
        this.setState({ ...this.state, chuDauTu: { ...chuDauTu, ...chuDauTus[index] } });
        this.handleModalToggle();
    }
    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.chuDauTus?.slice(offset, this.state.pageSize + offset);
        this.handleRecordsPerPage(this.state.dataPagi)
        this.setState(this.state);
    }
    handleModalToggle = () => {
        const { openModel, chuDauTu } = this.state
        this.setState({ openModel: !openModel });
    }
    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br></br>
                <div className="row">
                    <h6 style={{ fontWeight: 'bold' }}>Danh sách chủ đầu tư</h6>
                </div>
                <div className="row">
                    <table class="table table-bordered" style={{ marginTop: '5px' }}>
                        <thead>
                            <tr className="table-secondary text-center">
                                <th>STT</th>
                                <th>Loại hình</th>
                                <th>Chủ đầu tư</th>
                                <th className="text-center">Chi tiết</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                        <td>{item.loaiChuDauTu == 1 ? "Cá nhân" : "Doanh Nghiệp"}</td>
                                        <td>{item.ten}</td>
                                        <td width="10%">
                                            <div className="row cell-number">
                                                <div className="col-md">
                                                    <button onClick={(e) => this.openEdit(((this.state.pageNumber - 1) * this.state.pageSize) + index)}>  <i class="far fa-eye fa-lg" style={{ color: '#367fa9' }}></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                </div>
                <br></br>
                {this.state.openModel &&
                    <ModalChiTietChuDauTu
                        chuDauTu={this.state.chuDauTu}
                        onModalToggle={this.handleModalToggle}
                        show={this.state.openModel}
                    ></ModalChiTietChuDauTu>
                }
            </div>
        )
    }
}