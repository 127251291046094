import React from 'react';
import { useHistory } from 'react-router-dom';
import { DIEU_CHINH, HUY, THU_HOI } from '../../../../Constants/constants';
import { ROUTE_CN_DIEUCHINH, ROUTE_HUYGIAYPHEP, ROUTE_THUHOIGIAYPHEP } from '../../../../../routes';
import { useEffect, useState } from 'react';

export const HandleClickViewLichSu = (props) => {
    let history = useHistory();
    const [itemView, setItemView] = useState({});
    const [idGiayPhep, setIdGiayPhep] = useState(0);

    useEffect(() => {
        setItemView(props.itemView);
        setIdGiayPhep(props.idGiayPhep)
    }, [props.itemView, props.idGiayPhep]);

    const handeCLickView = (item) => {
        let pathLink = "";
        if (item.loaiBienDong === parseInt(DIEU_CHINH.ma)) {
            pathLink = ROUTE_CN_DIEUCHINH.duongDan;
            history.push(pathLink, { maHoSo: item.maHoSo })
        }
        if (item.loaiBienDong === parseInt(THU_HOI.ma)) {
            pathLink = ROUTE_THUHOIGIAYPHEP.duongDan;
            history.push(pathLink, { id: idGiayPhep })
        }
        if (item.loaiBienDong === parseInt(HUY.ma)) {
            pathLink = ROUTE_HUYGIAYPHEP.duongDan;
            history.push(pathLink, { id: idGiayPhep })
        }
    }

    return (
        <a href="javascript:void(0)" onClick={(event) => handeCLickView(itemView)} style={{ marginLeft: '0px', textDecoration: 'none' }}> <i class="far fa-eye fa-lg" style={{ color: '#367fa9' }}></i></a >
    )

}