import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../utils/stringUtils';
import closeIcon from "../../../Assets/icons/u1233.png";
import UploadFileService from "../../../api/common/UploadFile";
import { createFileType, downLoadFile } from "../../../api/common/exportUtil";
import PoolFile from './PoolFile/index';
import UploadFilesCommon from './UploadFilesCommon';

export default class ModalThongTinThamDinh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thongTinThamDinh: {
                id: 0,
                noiDung: '',
                files: [],
                fileTrash: []
            },
            show: false,
            errors: {
                noiDung: '',
            },
            newFileTemps: [],
            newFileTrash: [],
            indexEdit: -1,
        }
        this.uploadFileService = new UploadFileService();
    }

    componentDidMount() {
        const { thongTinThamDinh, newFileTemps } = this.state;
        if (this.props.indexEdit !== -1) {
            this.setState({
                thongTinThamDinh: { ...thongTinThamDinh, ...this.props.data },
                newFileTemps: [...newFileTemps, ...this.props.data.files]
            });
        }
    }
    componentWillUnmount() {
        this.clearData()
    }
    handleCloseModal = () => {
        this.props.onModalToggle();
    }
    handleChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.state.thongTinThamDinh[name] = value;
        this.state.errors = {
            noiDung: '',
        }
        this.setState(this.state);
    }

    handleSubmit = () => {
        if (this.validation()) {
            if (this.props.indexEdit >= 0) {
                this.state.thongTinThamDinh.files = this.state.newFileTemps;
                this.state.thongTinThamDinh.fileTrash = this.state.newFileTrash;
                this.props.onUpdateData(this.props.indexEdit, this.state.thongTinThamDinh);
                toastr.success('Cập nhật thông tin thẩm định thành công');
                this.handleCloseModal();
            } else {
                this.state.thongTinThamDinh.files = this.state.newFileTemps;
                this.state.thongTinThamDinh.fileTrash = this.state.newFileTrash;
                this.props.onAddData(this.state.thongTinThamDinh);
                toastr.success('Thêm mới thông tin thẩm định thành công');
                this.handleCloseModal();
            }
        }
    }

    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.thongTinThamDinh.noiDung)) {
            this.state.errors.noiDung = 'Vui lòng nhập nội dung';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }

    clearData = async () => {
        this.state.thongTinThamDinh = {
            id: 0,
            noiDung: '',
            fileTrash: this.state.files,
            files: []
        }
        this.state.errors = {
            noiDung: '',
        }
        await this.setState({ ...this.state });
    }
    uploadFileHandler = (key, items) => {
        if(key==="files"){
            this.state.newFileTemps = [...items];
            this.setState(this.state)
        }
        if(key==="fileTrash"){
            this.state.newFileTrash = [...items];
            this.setState(this.state)
        }
    }
   

    render() {
        const { newFileTemps, newFileTrash } = this.state;
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI THÔNG TIN THẨM ĐỊNH</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="noiDung" style={{ fontWeight: 'bold', fontSize: ' ' }}>Nội dung <span style={{ color: 'red' }}>(*)</span></label>
                                    <input type="text" value={this.state.thongTinThamDinh.noiDung} className="form-control" id="noiDung" name="noiDung" onChange={this.handleChange} />
                                    <div style={Boolean(this.state.errors?.noiDung) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{ marginBottom: "5px" }}>{this.state.errors?.noiDung}</span>
                                    </div>
                                </div>
                            </div>
                            <UploadFilesCommon
                                files={newFileTemps}
                                fileTrash={newFileTrash}
                                onUpdateDataState={this.uploadFileHandler}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-right col-md-6">
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Lưu</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={this.handleCloseModal} className="btn btn-secondary btn-lg">Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}