// import { ThongTinDiaChi } from "model/hoatDongNganhIn/hoatDongNganhInDTO/hoatDongNangInDTO";

export default class StringUtil {
    static isNullOrEmty(str: any): boolean {
        if (
            typeof str == "undefined" ||
            !str ||
            str.length === 0 ||
            str === "" ||
            !/[^\s]/.test(str) ||
            /^\s*$/.test(str) ||
            str.toString().replace(/\s/g, "") === ""
        )
            return true;
        else return false;
    }
    static formatMoney(str: string): string {
        if (str.match(/,/g)) {
            const newString = `${str.toString().replace(/,/g, "")}`;
            return `${newString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    static checkInputNumber(str: string) {
        if (/^[0-9\b]+$/.test(str)) {
            return str;
        }
    }

    // static diaChiToString(values: ThongTinDiaChi): string {
    //     return Object.entries(values)
    //         .map(([key, value], index) => {
    //             if (key.match("soNhaDuong")) {
    //                 return value;
    //             }
    //             if (key.match("duong")) {
    //                 return value;
    //             }
    //             if (key.match("diaChiXaName")) {
    //                 return value;
    //             }
    //             if (key.match("diaChiHuyenName")) {
    //                 return value;
    //             }
    //             if (key.match("diaChiTinhName")) {
    //                 return value;
    //             }
    //         })
    //         .filter((item) => item !== null && item)
    //         .join(", ");
    // }
}
