import { Grid, SvgIcon } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import GiayPhepService from "../../../api/service/CapPhepXDCTHService";
import { LIST_LOAIGIAYPHEP } from '../../Constants/constants';
import Popover from './index';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

export default class FormGPXD extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nghiepVus: [],
            loaiGiayPhep: [],
            donViCapPhep: [],
            tinhTrang: [],
            tinhThanh: [],
            quanHuyen: [],
            phuongXa: [],
            soGiayPhepValue: "",
            loaiGiayPhepValue: "",
            // ngayCapTuValue: 0,
            // ngayCapDenValue: 0,
            tinhTrangValue: "",
            chuDauTuValue: "",
            tinhTpValue: "92",
            huyenThiValue: "",
            phuongXaValue: "",
            isOpenNhapDauKy: false
        }
        this.btnRef = React.createRef();
    }


    componentDidMount() {
        this.danhSachLoaiGiayPhep();
        this.danhSachTinhTrang();
        this.danhSachQuanHuyenInfo();
        this.danhSachnghiepVu();
    }

    danhSachLoaiGiayPhep = () => {
        this.setState({ loaiGiayPhep: LIST_LOAIGIAYPHEP || [] });
    }


    danhSachnghiepVu = () => {
        this.setState({ nghiepVus: LIST_LOAIGIAYPHEP || [] });
    }

    danhSachTinhTrang = () => {
        AXIOS_INSTANCE.get('/tinh-trang').then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ tinhTrang: res.data.data || [] });
            } else {
                this.setState({ tinhTrang: [] });
            }
        }).catch(error => {
            this.setState({ tinhTrang: [] });
        });
    }
    danhSachQuanHuyenInfo = () => {
        const queryParam = {
            "maTinhThanh": "92"
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }

    danhSachPhuongXa = maQuanHuyen => {
        const queryParam = {
            "maTinhThanh": this.state.tinhTpValue,
            "maQuanHuyen": maQuanHuyen
        }
        AXIOS_INSTANCE.get(`/danh-muc-phuong-xa?tenQuanHuyen=&maTinhThanh=${queryParam.maTinhThanh}&maQuanHuyen=${queryParam.maQuanHuyen}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ phuongXa: res.data.data || [] });
            } else {
                this.setState({ phuongXa: [] });
            }
        }).catch(error => {
            this.setState({ phuongXa: [] });
        });
    }

    handleSoGiayPhepChange = e => {
        this.setState({ soGiayPhepValue: e.target.value });
    }

    handleLoaiGiayPhepChange = e => {
        this.setState({ loaiGiayPhepValue: e.target.value });
    }

    handleNgayCapTuChange = e => {
        const ngayCapTu = new Date(e.target.value);
        this.setState({ ngayCapTuValue: ngayCapTu.getTime() });
    }

    handleNgayCapDenChange = e => {
        const ngayCapDen = new Date(e.target.value);
        this.setState({ ngayCapDenValue: ngayCapDen.getTime() });
    }

    handleTinhTrangChange = e => {
        this.setState({ tinhTrangValue: e.target.value });
    }

    handleChuDauTuChange = e => {
        this.setState({ chuDauTuValue: e.target.value });
    }

    handleHuyenThiChange = e => {
        this.setState({ huyenThiValue: e.target.value }, () => {
            this.danhSachPhuongXa(e.target.value);
        });
    }

    handlePhuongXaChange = e => {
        this.setState({ phuongXaValue: e.target.value });
    }

    onSearch = () => {
        const dataInfo = {
            "pageNumber": 0,
            "pageSize": 10,
            "soGiayPhep": this.state.soGiayPhepValue,
            "loaiGiayPhep": this.state.loaiGiayPhepValue,
            "ngayCapTu": this.state.ngayCapTuValue,
            "ngayCapDen": this.state.ngayCapDenValue,
            "tinhTrang": this.state.tinhTrangValue,
            "chuDauTu": this.state.chuDauTuValue,
            "maHuyenThiThanhPho": this.state.huyenThiValue,
            "phuongXa": this.state.phuongXaValue,
            "hoatDong": 1
        };
        this.props.onSearch(dataInfo);
    }

    onChangeItem = item => {
        console.log('onChangeItem', item);
    }
    handlePopoverNghiepVu = () => {
        this.state.isOpenNhapDauKy = !this.state.isOpenNhapDauKy;
        this.setState(this.state);
    }
    setOpenNhapDauKy = (value) => {
        this.state.isOpenNhapDauKy = value;
        this.setState(this.state);
    }

    downloadFile = async (extension) => {
        let request = {
            soGiayPhep: this.state.soGiayPhepValue,
            loaiGiayPhep: this.state.loaiGiayPhepValue,
            ngayCapTu: this.state.ngayCapTuValue,
            ngayCapDen: this.state.ngayCapDenValue,
            tinhTrang: this.state.tinhTrangValue,
            chuDauTu: this.state.chuDauTuValue,
            tinhTp: this.state.tinhTpValue,
            maHuyenThiThanhPho: this.state.huyenThiValue,
            phuongXa: this.state.phuongXaValue,
            hoatDong: 1,
            exportFileName: 'Danh_sach_giay_phep',
            exportType: extension
        };
        await new GiayPhepService().downloadFileDanhSachGiayPhep(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Danh_sach_giay_phep');
        }).finally(() => {

        });
    }

    handleReset = () => {
        window.location.reload();
    }
    handleChangeNgayCapTu(date) {
        var longFormat = date * 1;
        // this.state.ngayCapTuValue = longFormat;
        this.setState({ ngayCapTuValue: longFormat });
    }
    handleChangeNgayCapDen(date) {
        var longFormat = date * 1;
        // this.state.ngayCapDenValue = longFormat;
        this.setState({ ngayCapDenValue: longFormat });
    }
    
    render() {
        const CustomInputNgayCapTu = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputNgayCapDen = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <React.Fragment>
                <br></br>
                <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>DANH SÁCH GIẤY PHÉP XÂY DỰNG</h6>
                <br></br>
                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép</label>
                            <input type="text" className="form-control" id="idSoGiayPhep" placeholder="Nhập số giấy phép" onChange={this.handleSoGiayPhepChange} />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Loại giấy phép</label>
                            <select className="form-control" id="idLoaiGiayPhep" onChange={this.handleLoaiGiayPhepChange}>
                                <option value="">--Loại giấy phép--</option>
                                {
                                    this.state.loaiGiayPhep.map((e) => {
                                        return <option value={e.ma}>{e.ten}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp từ</label>
                            {/* <input type="date" className="form-control" onChange={this.handleNgayCapTuChange} /> */}
                            <DatePicker
                                customInput={<CustomInputNgayCapTu />}
                                selected={this.state.ngayCapTuValue}
                                onChange={this.handleChangeNgayCapTu.bind(this)}
                                value={this.state.ngayCapTuValue === 0 ? moment(this.state.ngayCapTuValue).format("DD/MM/YYYY") : null}
                                name="ngayCapTuValue"
                                placeholderText={"dd/mm/yyyy"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                locale={vi}
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                    }
                                }}
                            >
                            </DatePicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đến ngày</label>
                            {/* <input type="date" className="form-control" onChange={this.handleNgayCapDenChange} /> */}
                            <DatePicker
                                customInput={<CustomInputNgayCapDen />}
                                selected={this.state.ngayCapDenValue}
                                onChange={this.handleChangeNgayCapDen.bind(this)}
                                value={this.state.ngayCapDenValue === 0 ? moment(this.state.ngayCapDenValue).format("DD/MM/YYYY") : null}
                                name="ngayCapDenValue"
                                placeholderText={"dd/mm/yyyy"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                locale={vi}
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                    }
                                }}
                            >
                            </DatePicker>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Chủ đầu tư</label>
                            <input type="text" className="form-control" id="idChuDauTu" placeholder="Nhập chủ đầu tư" onChange={this.handleChuDauTuChange} />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Huyện/Thị/TP</label>
                            <select className="form-control" id="sel1" onChange={this.handleHuyenThiChange}>
                                <option value="">--Chọn Quận/Huyện--</option>
                                {
                                    this.state.quanHuyen.map((e, ind) => {
                                        return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Phường/Xã/TT</label>
                            <select className="form-control" id="sel1" onChange={this.handlePhuongXaChange}>
                                <option value="">--Chọn phường/xã/thị trấn--</option>
                                {
                                    this.state.phuongXa.map((e, ind) => {
                                        return <option key={e.maPhuongXa} value={e.maPhuongXa}>{e.tenPhuongXa}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="sel1" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tình trạng</label>
                            <select className="form-control" id="sel1" onChange={this.handleTinhTrangChange}>
                                <option value="">--Chọn tình trạng--</option>
                                {
                                    this.state.tinhTrang.map((e, ind) => {
                                        return <option key={e.maTrangThai} value={e.maTrangThai}>{e.tenTrangThai}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-right">
                        <div className="form-group">
                            <button className="btn btn-primary btn-lg" style={{ width: '120px' }} onClick={this.onSearch}>Tìm kiếm</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="form-group mb-0">
                            <button onClick={this.handlePopoverNghiepVu} ref={this.btnRef} className="btn btn-primary btn-lg" style={{ width: '120px' }}>Nhập đầu kì</button>
                            <Popover setOpenNhapDauKy={this.setOpenNhapDauKy} btnRef={this.btnRef} showNghiepVu={this.state.isOpenNhapDauKy} />
                            <button className="btn btn-primary btn-lg" style={{ width: '120px', marginLeft: '30px' }} onClick={this.handleReset}>Làm mới</button>
                        </div>
                        <Grid item xs={12} className="grid-item" container justify="flex-end">
                            <Link title="Tải xuống" href="#" onClick={() => {
                                this.downloadFile('docx');
                            }}>
                                <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                                </SvgIcon>
                            </Link>
                            <Link title="Tải xuống" href="#" onClick={() => {
                                this.downloadFile('pdf');
                            }}>
                                <SvgIcon fontSize="large">
                                    <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                                </SvgIcon>
                            </Link>
                            <Link title="Tải xuống" href="#" onClick={() => {
                                this.downloadFile('xlsx');
                            }}>
                                <SvgIcon fontSize="large">
                                    <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                                </SvgIcon>
                            </Link>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}