import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../utils/stringUtils';
import { THANH_PHO_CAN_THO } from '../../Constants/constants';
import FormNDGPDC from './FormNDGPDC';
import TableCDT from '../CapPhepDungChung/CDT/TableCDT';
import FormTTDX from '../CapPhepDungChung/FormTTDX';
import FormGTDD from '../CapPhepDungChung/FormGTDD';
import TableTTTD from '../CapPhepDungChung/TableTTTD';
import AXIOS_INSTANCE from '../../../api';

export default class TabDCCT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noiDungGiayPhep: {
        thongTinCongTrinh: {},
        thongTinXayDung: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          // tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          // maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          soGiayPhepCu: ''
        },
        chuDauTus: [],
        chuDauTuDrops: [],
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],
        loDatXayDungDropDTOs: [],
        loDatXayDungs: [],
        errors: {
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          huyenThiThanhPho: '',
          phuongXaThiTran: '',
          ngayCap: '',
          maSoGiayPhep: ''
        },
        donViChuNhiemThietKe: {},
      }

    }
  }

  componentDidMount() {
    if (this.props.maHoSo !== undefined && this.props.maHoSo !== null && this.props.maHoSo !== '') {
      //hosocuatoi
      this.loadByMaHoSo();
    } else if (this.props.id > 0) {
      //chinh sua
      this.loadGiayPhepById();
    } else {
      //nhap dau ky
    }
  }

  componentDidUpdate() {
    //to do
  }


  initData = () => {
    this.state.noiDungGiayPhep = {
      thongTinCongTrinh: {},
      thongTinXayDung: {},
      viTriXayDung: {
        tinhThanhPho: THANH_PHO_CAN_THO.ten,
        maTinhThanhPho: THANH_PHO_CAN_THO.ma,
      },
      thongTinDeXuat: {},
      giayPhep: {
        id: this.props.id,
        maHoSo: this.props.maHoSo,
        // tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
        // maLoaiGiayPhep: this.props.maLoaiGiayPhep,
        loaiBienDong: this.props.loaiBienDong,
        tenLoaiBienDong: this.props.tenLoaiBienDong,
        maTrangThai: this.props.maTrangThai,
        tenTrangThai: this.props.tenTrangThai,
        giayToVeDatDai: '',
        soGiayPhepCu: ''
      },
      chuDauTus: [],
      chuDauTuDrops: [],
      thongTinThamDinhs: [],
      thongTinThamDinhDrops: [],
      loDatXayDungs: [],
      loDatXayDungDropDTOs: [],
      donViChuNhiemThietKe: {}
    };
    this.setState(this.state);
  }

  validation = () => {
    let valid = true;
    this.state.errors = {};
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.tenCongTrinh)) {
      this.state.errors.tenCongTrinh = 'Vui lòng nhập tên công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.loaiCongTrinh)) {
      this.state.errors.loaiCongTrinh = 'Vui lòng chọn loại công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.capCongTrinh)) {
      this.state.errors.capCongTrinh = 'Vui lòng chọn cấp công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.phanLoaiXayDung)) {
      this.state.errors.phanLoaiXayDung = 'Vui lòng chọn phân loại xây dựng';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho)) {
      this.state.errors.huyenThiThanhPho = 'Vui lòng chọn Huyện/Thị/TP';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran)) {
      this.state.errors.phuongXaThiTran = 'Vui lòng chọn Phường/Xã/TT';
      valid = false;
    }
    if (this.props.isNhapDauKy) {
      if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.maSoGiayPhep)) {
        this.state.errors.maSoGiayPhep = 'Vui lòng nhập số giấy phép';
        valid = false;
      }
      if (!this.state.noiDungGiayPhep.giayPhep.ngayCap || this.state.noiDungGiayPhep.giayPhep.ngayCap === undefined || this.state.noiDungGiayPhep.giayPhep.ngayCap == 0) {
        this.state.errors.ngayCap = 'Vui lòng nhập ngày cấp';
        valid = false;
      }
    }
    this.setState(this.state);
    return valid;
  }

  loadByMaHoSo = () => {
    const url = 'giay-phep-xay-dung/thong-tin';
    AXIOS_INSTANCE.post(url, { maHoSo: this.props.maHoSo, 'maLoaiGiayPhep': this.props.maLoaiGiayPhep }).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.state.noiDungGiayPhep = datas;
          this.setState(this.state);
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  loadGiayPhepById = (event) => {
    const url = 'giay-phep-xay-dung/chi-tiet-by-id';
    AXIOS_INSTANCE.post(url, { id: this.props.id }).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.setState({
            noiDungGiayPhep: {
              thongTinCongTrinh: datas.thongTinCongTrinh || {},
              thongTinXayDung: datas.thongTinXayDung || {},
              viTriXayDung: datas.viTriXayDung || {},
              thongTinDeXuat: datas.thongTinDeXuat || {},
              chuDauTuDTOs: datas.chuDauTuDTOs || [],
              giayPhep: datas.giayPhep,
              chuDauTus: datas.chuDauTus || [],
              thongTinThamDinhs: datas.thongTinThamDinhs || [],
              loDatXayDungs: datas.loDatXayDungs || [],
              loDatXayDungDropDTOs: [],
              files: datas.files || [],
              fileTrash: []
            }
          })
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  handleUpdateDataState = (name, value) => {
    if (name === 'state.noiDungGiayPhep') {
      this.state.noiDungGiayPhep = value;
    } else {
      this.state.noiDungGiayPhep[name] = value;
    }
    this.setState(this.state);
  }

  onChangeStateGTDD = (value) => {
    this.state.noiDungGiayPhep.giayPhep.giayToVeDatDai = value;
    this.setState(this.state);
  }

  setVarialble = () => {
    this.state.noiDungGiayPhep.giayPhep.maHoSo = this.props.maHoSo;
    this.state.noiDungGiayPhep.giayPhep.tenLoaiBienDong = this.props.tenLoaiBienDong;
    this.state.noiDungGiayPhep.giayPhep.loaiBienDong = this.props.loaiBienDong;
    this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep = this.props.maLoaiGiayPhep;
    this.state.noiDungGiayPhep.giayPhep.tenLoaiGiayPhep = this.props.tenLoaiGiayPhep;
    this.state.noiDungGiayPhep.giayPhep.tenTrangThai = this.props.tenTrangThai;
    this.state.noiDungGiayPhep.giayPhep.maTrangThai = this.props.maTrangThai;
    if (!StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep)) {
      this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep;
    }
    if (!StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep)) {
      this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep;
    }
    this.state.noiDungGiayPhep.giayPhep.tenSoGiayPhep = "";
    this.setState(this.state);
  }


  addOrUpdate = async () => {
    if (this.validation()) {
      await this.setVarialble();
      await AXIOS_INSTANCE.post('giay-phep-xay-dung/dieu-chinh', this.state.noiDungGiayPhep).then(res => {
        if (res != null || res.data.error_code == 'SUCCESSFUL') {
          toastr.success('Thêm nội dung giấy phép thành công');
this.initData();
        } else {
          toastr.warning('Thêm nội dung giấy phép không thành công');
        }
      }).catch(error => {
        toastr.warning('Thêm nội dung giấy phép không thành công');
        console.log(error);
      });
    }
  }

  clearData = () => {
    this.setState({
      noiDungGiayPhep: {
        thongTinCongTrinh: {
          id: 0,
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          donViTuVan: '',
          kyHieuThietKe: '',
          donViThamDinh: '',
          ghiChu: '',
          thoiGianCongTrinh: '',
          noiDungQuangCao: ''
        },
        thongTinXayDung: {
          id: 0,
          tongDienTichSan: '',
          dienTichXayDungTangMot: '',
          chieuCaoTangMot: '',
          chieuCaoCongTrinh: '',
          chieuCaoBienQuangCao: '',
          soTang: '',
          cotNen: '',
          matDoXayDung: '',
          heSoSuDungDat: '',
          chiGioiDuongDo: '',
          chiGioiXayDung: '',
          mauSac: '',
          chieuSauCongTrinh: '',
          giaiDoan: 0,
          dukienhoanthanh: '',
          thoihancongtrinh: '',
        },
        viTriXayDung: {
          id: 0,
          soNhaDuong: '',
          duong: '',
          maPhuongXaThiTran: '',
          maHuyenThiThanhPho: '',
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          diaChiCu: ''
        },
        thongTinDeXuat: {
          ketluan: '',
          dexuat: ''
        },
        giayPhep: {
          id: this.state.noiDungGiayPhep.giayPhep.id,
          maHoSo: this.state.noiDungGiayPhep.giayPhep.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          maSoGiayPhep: '',
          ngayCap: null
        },
        chuDauTuDrops: this.state.noiDungGiayPhep.chuDauTus,
        chuDauTus: [],
        thongTinThamDinhDrops: this.state.noiDungGiayPhep.thongTinThamDinhs,
        thongTinThamDinhs: [],
        loDatXayDungDropDTOs: this.state.noiDungGiayPhep.loDatXayDungs,
        loDatXayDungs: [],
      }
    })
  }


  render() {
    return (
      <div className="dash-content">

        <br />
        <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
          ĐIỀU CHỈNH GIẤY PHÉP XÂY DỰNG
        </h6>
        <br></br>

        <ul class="nav nav-tabs" role="tablist">
          <li class="ml-2">&nbsp;</li>
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#ndgp" role="tab">Nội dung giấy phép</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#cdt" role="tab">Chủ đầu tư</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#gtdd" role="tab">Giấy tờ về đất đai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tttd" role="tab">Thông tin thẩm định</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#ttdx" role="tab">Thông tin đề xuất</a>
          </li>
        </ul>

        <div class="tab-content">

          <div class="tab-pane p-2 active" id="ndgp" role="tabpanel">
            <FormNDGPDC
              isNhapDauKy={this.props.isNhapDauKy}
              onClearData={this.clearData}
              onUpdateDataState={this.handleUpdateDataState}
              addOrUpdate={this.addOrUpdate}
              errorState={this.state.errors}
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              maHoSo={this.state.noiDungGiayPhep.giayPhep.maHoSo}
              maLoaiGiayPhep={this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep}
              readonly={this.props.readonly}
              disabled={this.props.disabled}
            ></FormNDGPDC>
          </div>

          <div class="tab-pane p-2" id="cdt" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? <p>Không có dữ liệu...!</p> :
              <TableCDT
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></TableCDT>
            }
          </div>

          <div class="tab-pane p-2" id="gtdd" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <FormGTDD
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onChangeStateGTDD={this.onChangeStateGTDD}
              ></FormGTDD>
            }
          </div>

          <div class="tab-pane p-2" id="tttd" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <TableTTTD
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></TableTTTD>
            }
          </div>

          <div class="tab-pane p-2" id="ttdx" role="tabpanel">
            {this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep == null ? 'Không có dữ liệu ..!' :
              <FormTTDX
                noiDungGiayPhep={this.state.noiDungGiayPhep}
                onUpdateDataState={this.handleUpdateDataState}
              ></FormTTDX>
            }
          </div>
        </div>
      </div>
    );
  }
}
