import React from 'react';
import { CreateGiayPhepDTO } from '../../../../../api/Init/CapPhepXayDungFinal';
import ChiTietGPXDWrapper from '../../ChiTietGPXD/SubChiTietGPXD/SubNoiDungCapPhep/ChiTietGPXDWrapper';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import AXIOS_INSTANCE from '../../../../../api';
import { createFileType, downLoadFile } from '../../../../../api/common/exportUtil';
import UploadFileService from '../../../../../api/common/UploadFile';
import UploadFilesCommon from '../../../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../../DateInputCustome.css";

export default class NoiDungCapPhep extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            noiDungGiayPhep: {
                ...CreateGiayPhepDTO,
                thongTinHuyGiayPhep: {
                    soQDHuy: '',
                    ngayQD: 0,
                    lyDoHuy: '',
                    nguoiKy: '',
                    ghiChu: ''
                }
            },
            errors: {}
        }
    }

    componentDidMount() {
    }
    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined
            && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
    }
    setParams = (event, key) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        if (key === 'thongTinHuyGiayPhep') {
            if (type === 'date') {
                if (!this.state.noiDungGiayPhep.thongTinHuyGiayPhep || this.state.noiDungGiayPhep.thongTinHuyGiayPhep === null || this.state.noiDungGiayPhep.thongTinHuyGiayPhep === undefined) {
                    this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
                }
                this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = new Date(value).getTime();
            } else {
                if (!this.state.noiDungGiayPhep.thongTinHuyGiayPhep || this.state.noiDungGiayPhep.thongTinHuyGiayPhep === null || this.state.noiDungGiayPhep.thongTinHuyGiayPhep === undefined) {
                    this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
                }
                this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = value;
            }
        }
        this.props.onUpdateDataState('thongTinHuyGiayPhep', this.state.noiDungGiayPhep.thongTinHuyGiayPhep);
    }
    handleChangeNgayQD(date) {
        let name = "ngayQD";
        var longFormat = date * 1;
        if (!this.state.noiDungGiayPhep.thongTinHuyGiayPhep ||
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep === null ||
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep === undefined) {
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = longFormat;
        } else {
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
            this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = longFormat;
        }
        this.props.onUpdateDataState('thongTinHuyGiayPhep', this.state.noiDungGiayPhep.thongTinHuyGiayPhep);
    }
    render() {
        const CustomInputNgayQD = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br />
                {this.state.noiDungGiayPhep !== null &&
                    <ChiTietGPXDWrapper
                        renderViTriDiDoi={true}
                        loaiBienDong={this.state.noiDungGiayPhep.giayPhep.loaiBienDong}
                        noiDungGiayPhep={this.state.noiDungGiayPhep}
                    ></ChiTietGPXDWrapper>
                }
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN HỦY</h6>
                </div>
                <br></br>
                <div class="row">
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="soQDHuy" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số quyết định <span style={{ color: 'red' }}>(*)</span></label>
                            <input type="text" className="form-control" id="soQDHuy" name="soQDHuy"
                                value={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.soQDHuy} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinHuyGiayPhep")} />
                            <div style={Boolean(this.state.errors?.soQDHuy) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.soQDHuy}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="form-group">
                            <label htmlFor="ngayQD" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày quyết định <span style={{ color: 'red' }}>(*)</span></label>
                            {/* <input type="date" className="form-control" id="ngayQD" name="ngayQD"
                                value={this.state.noiDungGiayPhep.thongTinHuyGiayPhep?.ngayQD ? moment(new Date(this.state.noiDungGiayPhep.thongTinHuyGiayPhep?.ngayQD)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinHuyGiayPhep")} /> */}
                            <DatePicker
                                customInput={<CustomInputNgayQD />}
                                selected={this.state.noiDungGiayPhep.thongTinHuyGiayPhep?.ngayQD}
                                onChange={this.handleChangeNgayQD.bind(this)}
                                value={this.state.noiDungGiayPhep.thongTinHuyGiayPhep?.ngayQD === 0 ? moment(this.state.noiDungGiayPhep.thongTinHuyGiayPhep?.ngayQD).format("DD/MM/YYYY") : null}
                                name="ngayQD"
                                placeholderText={"dd/mm/yyyy"}
                                dateFormat={"dd/MM/yyyy"}
                                className="form-control"
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                locale={vi}
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: "viewport",
                                    }
                                }}
                            >
                            </DatePicker>
                            <div style={Boolean(this.state.errors?.ngayQD) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.ngayQD}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="nguoiKy" style={{ fontWeight: 'bold', fontSize: '14px' }}>Người ký</label>
                            <input type="text" className="form-control" id="nguoiKy" name="nguoiKy"
                                value={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.nguoiKy} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinHuyGiayPhep")} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="lyDoHuy" style={{ fontWeight: 'bold', fontSize: '14px' }}>Lý do hủy</label>
                            <textarea className="form-control" id="lyDoHuy" name="lyDoHuy"
                                rows="3"
                                value={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.lyDoHuy} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinHuyGiayPhep")} />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="form-group">
                            <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ghi chú</label>
                            <textarea type="text-area" className="form-control" id="ghiChu" name="ghiChu"
                                rows="3"
                                value={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.ghiChu} style={{ fontSize: '14px' }}
                                onChange={(event) => this.setParams(event, "thongTinHuyGiayPhep")} />
                        </div>
                    </div>
                </div>
                <UploadFilesCommon
                    files={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.files}
                    fileTrash={this.state.noiDungGiayPhep?.thongTinHuyGiayPhep?.fileTrash}
                    onUpdateDataState={this.props.onUpdateDataState}
                />
            </div>
        )
    }
}