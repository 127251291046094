import React from 'react';
import closeIcon from "../../../../../Assets/icons/u1233.png";
import { Modal } from 'react-bootstrap';
import moment from 'moment';

export default class ModalChiTietChuDauTu extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate() {
        console.log("on Modal")
    }
    componentDidMount() {
    }
    handleCloseModal = (key) => {
        this.props.onModalToggle();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa" }}>
                    <h6 class="modal-title" id="exampleModalLongTitle" style={{ fontSize: '14px', fontWeight: 'bold', color: "white" }}>
                        THÔNG TIN CHI TIẾT ĐỐI TƯỢNG CÁ NHÂN</h6>
                    <button type="button" class="close" onClick={this.handleCloseModal} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Họ và tên</div>
                                <div className="col-md-6">{this.props.chuDauTu.ten} </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Địa chỉ chi tiết</div>
                                <div className="col-md-6">{this.props.chuDauTu.diaChiChiTiet} </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Giới tính</div>
                                <div className="col-md-6">{this.props.chuDauTu.gioiTinh === 0 ? this.props.chuDauTu.gioiTinh === 1 ? 'Nam' : 'Nữ' : ''} </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Đường</div>
                                <div className="col-md-6">{this.props.chuDauTu.duong}</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Loại giấy tờ</div>
                                <div className="col-md-6">{this.props.chuDauTu.tenLoaiGiayTo}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Tỉnh, thành</div>
                                <div className="col-md-6">Thành phố Cần Thơ</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Số giấy tờ</div>
                                <div className="col-md-6">{this.props.chuDauTu.soGiayTo}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Quận, huyện</div>
                                <div className="col-md-6">{this.props.chuDauTu.tenQuanHuyen}</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Ngày cấp</div>
                                <div className="col-md-6">{this.props.chuDauTu.ngayCap ? moment(new Date(this.props.chuDauTu.ngayCap)).format('DD-MM-yyyy') : ''}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Phường, xã</div>
                                <div className="col-md-6">{this.props.chuDauTu.tenPhuongXa}</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Nơi cấp</div>
                                <div className="col-md-6">{this.props.chuDauTu.noiCap}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Địa chỉ cũ</div>
                                <div className="col-md-6">{this.props.chuDauTu.diaChiCu}</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6" style={{ fontWeight: 'bold' }}>Ngày sinh</div>
                                <div className="col-md-6">{this.props.chuDauTu.ngaySinh ? moment(new Date(this.props.chuDauTu.ngaySinh)).format('DD-MM-yyyy') : ''}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6"></div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <button type="button" class="btn btn-secondary" onClick={this.handleCloseModal} style={{ fontSize: '14px', width: '75px' }}>Đóng</button>

                </div>
            </Modal>
        )
    }
}