import React from 'react';

export default class ThongTinCongTrinhRender extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="row" style={{ marginTop: '12px' }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>THÔNG TIN CÔNG TRÌNH</h6>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Tên công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.thongTinCongTrinh.tenCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Cấp công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.thongTinCongTrinh.capCongTrinh}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Đơn vị tư vấn</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.thongTinCongTrinh.donViTuVan}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Ký hiệu thiết kế</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.thongTinCongTrinh.kyHieuThietKe}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Loại công trình</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.thongTinCongTrinh.loaiCongTrinh}</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Phân loại xây dựng</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.thongTinCongTrinh.phanLoaiXayDung}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontWeight: 'bold' }}>Đơn vị thẩm tra</p>
                            </div>
                            <div className="col-md-6">
                                <p >{this.props.thongTinCongTrinh.donViThamTra}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>

                <div className="row" style={{ marginTop: '12px' }}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 text-right">
                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Ghi chú</p>
                            </div>
                            <div className="col-md-6">
                                <p>{this.props.thongTinCongTrinh.ghiChu}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
            </>
        )
    }
}