import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import closeIcon from "../../../../Assets/icons/u1233.png";
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../utils/stringUtils';
export default class ModalLoDatXD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            deleteItemId: 0
        }
    }
    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.setState({
                show: this.props.show
            });
        }
        if (this.props.itemId != this.state.deleteItemId) {
            this.setState({
                deleteItemId: this.props.itemId
            });
        }
    }
    handleCloseModal = () => {
        this.props.onModalToggle('xacNhan');
    }
    handleDropItem = (value) => {
        this.props.onDropItem(value);
        this.handleCloseModal();
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleCloseModal} on size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 class="modal-title" id="titleModalXoa" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                    <button type="button" class="close" onClick={this.handleCloseModal} aria-label="Close">
                        <span aria-hidden="true"><img src={closeIcon}></img></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12 text-center">
                            <h3>Xác nhận xóa thông tin này?</h3>
                        </div>
                        <br></br>
                        <div class="col-md-12">
                            <div class="row">
                                <div className="text-right col-md-6">
                                    <button className="btn btn-primary btn-sm" onClick={() => this.handleDropItem(this.state.deleteItemId)}> Xóa</button>
                                </div>
                                <div className="col-md-6">
                                    <button onClick={this.handleCloseModal} className="btn btn-secondary btn-sm"> Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}