import React from 'react';
import FormSearchCDTCN from './FormSearchCDT/FormSearchCDTCN';
import FormSearchCDTDN from './FormSearchCDT/FormSearchCDTDN';

export default class FormSearchCDT extends React.Component {
    constructor(props) {
        super(props)
    }
    loadCDT = () => {
        this.props.loadCDT();
    }
    render() {
        const isCN = this.props.isCN;
        if (isCN === 'CN') {
            return <FormSearchCDTCN
                maHoSo={this.props.maHoSo}
                tenLoaiGiayPhep={this.props.tenLoaiGiayPhep}
                loadCDT={this.loadCDT}
                loadDataTable={this.props.loadDataTable}
                dataValue={this.props.dataValue}
                handleClickSetData={this.props.handleClickSetData}
                checked={this.props.checked}
                handleChangeCheck={this.props.handleChangeCheck}
            ></FormSearchCDTCN>
        } else {
            return <FormSearchCDTDN
                maHoSo={this.props.maHoSo}
                tenLoaiGiayPhep={this.props.tenLoaiGiayPhep}
                loadCDT={this.loadCDT}
                loadDataTables={this.props.loadDataTables}
                dataValues={this.props.dataValues}
                handleClickSetDatas={this.props.handleClickSetDatas}
                checked={this.props.checked}
                handleChangeCheck={this.props.handleChangeCheck}
            ></FormSearchCDTDN>
        }

    }
}