import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DanhSachKhenThuongService } from "../../api/importDauKy/danhSachKhenThuongService";
import { AlertMessage, CustomAlert } from "../../App/commons/components/alert";
import { bootstrapTextFieldHook } from "../../App/commons/components/input/bootstrap";
import { OverlayLoading } from "../../App/commons/components/overlayLoading/overlayLoading";
import { LoadingAction } from "../../redux/actions/applicationAction";
import { DANH_SACH_TEN_NGHIEP_VU } from "./constantNghiepVu/constant";
import { ImportDauKyCuaRequest } from "./model/ImportDauKyModel";
import { KhenThuongTapTheDTO } from "./model/khenThuongDTO";
import { TypeCell } from "./table/commonTableChuyenNganh";
import { Column, CommonTableChuyenNganhLazy, Pageable } from "./table/commonTableChuyenNganhLazy";
import { LoaiDoiTuong } from "./thongTinChuyenNganh";

export const ImportDauKyComponent = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ImportDauKyCuaRequest>({ ten: ""});
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    const [rows, setRows] = useState<KhenThuongTapTheDTO[]>([]);
    const [itemSelected, setItemSelected] = useState<KhenThuongTapTheDTO | null>(null);
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5 } as Pageable);
    const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState(false);
    useEffect(() => {}, []);

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const columns: Column<KhenThuongTapTheDTO>[] = [
        { title: "Số chứng nhận", field: "loaiKhenThuongString", width: "15%" },
        { title: "Số QĐ/ GCNĐKDN/ GCNĐKKD", field: "tenTapThe", width: "15%" },
        { title: "Nơi cấp", field: "diaChi", width: "20%" },
        { title: "Tên tổ chức", field: "hinhThucKhenThuongString", width: "10%" },
        { title: "Điện thoại", field: "danhHieuThiDuaString", width: "10%" },
        { title: "Fax", field: "soTien", width: "10%", type: TypeCell.CURRENCY },
        // { title: "Thành tích khen thưởng", field: "thanhTichKhenThuong", width: "10%" },
        // { title: "Tóm tắt thành tích", field: "tomTatThanhTich", width: "10%" },
    ];
    
    const loadDataTable = async () => {
        // let request: DanhSachKhenThuongTapTheRequest = {
        //     pageNumber: pageParams.page,
        //     pageSize: pageParams.rowsPerPage,
        //     type: LoaiHinhKhenThuong.TAP_THE,
        //     soBienNhan: soBienNhan,
        //     thuTucId: DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_1.ID,
        //     ...formik.values.request,
        // };
        // await new DanhSachKhenThuongService().getTapTheKhenThuong(request).then((p) => {
        //     setRows(p.rows ? p.rows : []);
        //     setPageParams((pageParams) => ({ ...pageParams, total: p.total }));
        // });
    };

    const handOnChangeRowsPerPage = (value: number) => {
        setPageParams((pageParams) => ({ ...pageParams, page: 0, rowsPerPage: value }));
    };
    const handleOnChangePage = (value: number) => {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    };
    function handleDeleteData(data: KhenThuongTapTheDTO) {
        if (data.id) {
            setItemSelected(data);
            setOpenDeleteConfirmDlg(true);
        }
    }
    const handleCloseDeleteConfirmDlg = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSelected && itemSelected.id) {
                // await new DanhSachKhenThuongService().deleteById(itemSelected.id).then((resp) => {
                //     if (resp.isSuccess) {
                //         loadDataTable();
                //         showMessageAfterDelete(resp.message, true);
                //     } else {
                //         showMessageAfterDelete(resp.message, false);
                //     }
                // });
            }
        }
        setOpenDeleteConfirmDlg(false);
    };

    function handleDownloadTemplateFile(loai: string) {
        if (loai == "0") {
            showMessageAlert("Chưa chọn loại đối tượng", false);
        } else {
        new DanhSachKhenThuongService()
            .downloadFileTemplateTapThe(loai)
            .then((response) => {
                let blob = new Blob([response.data], { type: response.data.type });
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Template_import.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                showMessageAlert("Có lỗi khi thực hiện tải file template", false);
            });
        }
    }

    // const handleDongBo = async (isOk?: boolean) => {
    //     if (Boolean(isOk)) {
    //         dispatch(showLoading());
    //         motCuaService
    //             .dongBoMotCua(data)
    //             .then((p) => {
    //                 if (p.data && p.data.error_code === "SUCCESSFUL" && p.data.data) {
    //                     showMessageAlert("Đồng bộ thành công", true);
    //                 } else {
    //                     showMessageAlert("Đồng bộ thất bại", false);
    //                 }
    //             })
    //             .catch((e) => {
    //                 showMessageAlert(e, false);
    //             })
    //             .finally(() => {
    //                 dispatch(hideLoading());
    //             });
    //     }
    //     setOpenConfirm(false);
    // };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>IMPORT ĐẦU KỲ</span>
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel classes={labelStyles}>Loại đối tượng</InputLabel>
                    <NativeSelect
                        input={<InputBase fullWidth classes={inputStyles} />}
                        value={data.ten}
                        onChange={(e) => setData({ ...data, ten: e.target.value })}
                    >
                        <option value={LoaiDoiTuong.NONE}>Chọn loại đối tượng</option>
                            <option value={LoaiDoiTuong.CPXD}>Cấp phép xây dựng</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation onClick={(e)=>handleDownloadTemplateFile(data.ten)}>
                        Tải mẫu
                    </Button>
                    <Button variant="contained" color="primary" disableElevation>
                        Import
                    </Button>
                </Grid>
                <Grid container>
                <Grid item xs={12} className="grid-item ">
                    <CommonTableChuyenNganhLazy
                        columns={columns}
                        rows={rows}
                        // onEdit={onEditData}
                        onDelete={handleDeleteData}
                        pageParams={pageParams}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={handOnChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            </Grid>
            {/* <ConfirmationDialogRaw
                id="confirm-dialog-dong-bo"
                keepMounted
                open={openConfirm}
                title="Đồng bộ"
                content="Bạn có đồng ý thực hiện thao tác này?"
                onClose={handleDongBo}
            /> */}
            <Snackbar
                open={alertMessage.open}
                onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </div>
    );
};
