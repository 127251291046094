import { Pagination } from '@material-ui/lab';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import closeIcon from "../../../../Assets/icons/u1233.png";
import deleteIcon from '../../../../Assets/icons/u626.png';
import ModalDonViHanhChinh from './ModalDonViHanhChinh';

export default class TableDonViHanhChinh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataDrop: [],
            dataPagi: [],
            openModel: false,
            deleteItemId: '',
            recordsPerPage: 0,
            noiDungGiayPhep: {
                donViHanhChinhs: [],
                //drop
                donViHanhChinhDrops: [],
            }
        }
    }

    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.donViHanhChinhs != this.state.data) {
            this.state.data = this.props.noiDungGiayPhep.donViHanhChinhs;
            this.setState(this.state);
            this.handleSearch();
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.donViHanhChinhDrops != this.state.dataDrop) {
            this.state.dataDrop = this.props.noiDungGiayPhep.donViHanhChinhDrops;
            this.setState(this.state);
            this.handleSearch();
        }
    }

    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState({ ...this.state });
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleRecordsPerPage = (dataPagi) => {
        let number;
        if (dataPagi?.length == 0) {
            number = 0
        } else if (Number.isInteger(dataPagi?.length / this.state.pageSize)) {
            number = this.state.pageSize
        } else {
            number = dataPagi?.length % this.state.pageSize
        }
        this.state.recordsPerPage = number;
    }
    handleSearch = () => {
        const { pageNumber, pageSize, data } = this.state;
        let offset = 0;
        if (pageNumber === 1) {
            offset = 0;
        } else if (pageNumber > 0 && pageSize > 0) {
            offset = ((pageNumber - 1) * pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = data?.slice(offset, this.state.pageSize + offset);
        this.setState(this.state)
        this.handleRecordsPerPage(this.state.dataPagi)
    }
    //handle modal
    handleIdDropItem = (e, value) => {
        this.setState({
            deleteItemId: value
        })
    }

    handleAddItem = (value) => {
        const { data } = this.state;
        let newData = [];
        if (data) {
            newData = [{ ...value }, ...data];
        } else {
            newData = [{ ...value }]
        }

        this.props.onUpdateDataState("donViHanhChinhs", newData);
        this.setState({
            data: newData
        })
    }
    setDataParam = (name, value) => {
        this.state.noiDungGiayPhep[name] = value;
        this.setState(this.state);
    }
    handleDropItem = async (index) => {
        const { data, dataDrop } = this.state;
        let newDataDrop;
        let theDataDrop = [];
        if (dataDrop) {
            theDataDrop = [...dataDrop];
        }
        if (data[index]?.id > 0) {
            newDataDrop = [{ ...data[index] }, ...theDataDrop];
        }
        await this.props.onUpdateDataState("donViHanhChinhDrops", newDataDrop);
        const newData = data.filter((item, j) => index != j);
        await this.props.onUpdateDataState("donViHanhChinhs", newData);
        let valuePage
        //Set Pagenumber after delete
        if (this.state.data) {
            valuePage = Math.ceil(this.state.data.length / this.state.pageSize)
        }
        this.handleChangePage(null, valuePage)
        toastr.success('Xóa đơn vị hình chính thành công')
    }
    handleModalToggle = () => {
        this.state.openModel = !this.state.openModel;
        this.setState(this.state);
    }
    render() {
        return (
            <div>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Huyện / Thị xã / Thành phố</th>
                            <th>Phường / Xã / Thị trấn</th>
                            <th>Xóa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.tenHuyenThiTP}</td>
                                    <td>{item.tenPhuongXaTT}</td>
                                    <td align="center">
                                        <button data-toggle="modal" data-target="#modalXoaItem" onClick={(e) => this.handleIdDropItem(e, index)}
                                            disabled={this.props.disabled ? true : false}>
                                            <img src={deleteIcon}></img>
                                        </button>
                                    </td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={4}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang: {this.state.recordsPerPage}/{this.state.pageSize} </span>
                        </div>
                    </div>
                    <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                        <button type="button" className="btn btn-primary btn-lg" onClick={this.handleModalToggle}
                        disabled={this.props.disabled ? true : false}>Thêm mới</button>
                    </div>
                </div>
                <div class="modal fade" id="modalXoaItem" tabindex="-1" role="dialog" aria-labelledby="modalXoaItem" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                                <h5 class="modal-title" id="titleModalDeleteLoDat" style={{ fontWeight: 'bold', color: "white" }}>THÔNG BÁO</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon}></img></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <div class="col-md-12 text-center">
                                        <h3>Xác nhận xóa thông tin này?</h3>
                                    </div>
                                    <br></br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div className="text-right col-md-6">
                                                <button data-dismiss="modal" className="btn btn-primary btn-sm" onClick={() => this.handleDropItem(this.state.deleteItemId)}> Xóa</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button data-dismiss="modal" className="btn btn-secondary btn-sm"> Hủy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    <ModalDonViHanhChinh onUpdateData={this.handleAddItem} onModalToggle={this.handleModalToggle} show={this.state.openModel} ></ModalDonViHanhChinh>
                }
            </div>
        )
    }
}