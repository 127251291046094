import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { HoSoCuaToiMgtService } from '../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import AXIOS_INSTANCE from '../../../api/index';
import StringUtil from '../../../utils/stringUtils';
import { TypeField } from '../../commons/components/eform/thongTinChuyenNganheForm';
import ThongTinChuyenNganhUtil from '../../commons/components/eform/thongTinChuyenNganhUtils';
import { THANH_PHO_CAN_THO } from '../../Constants/constants';
import TableCDT from '../CapPhepDungChung/CDT/TableCDT';
import FormGTDD from '../CapPhepDungChung/FormGTDD';
import FormTTDX from '../CapPhepDungChung/FormTTDX';
import TableTTTD from '../CapPhepDungChung/TableTTTD';
import FormCPXDCTTheoTuyen from './FormCPXDCTTheoTuyen';

export default class TabCPXDCTTheoTuyen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noiDungGiayPhep: {
        thongTinCongTrinh: {},
        thongTinXDCTTheoTuyen: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
        },
        //khoi tao chuDauTu
        chuDauTus: [],
        chuDauTuDrops: [],

        //khoi tao ThongTinThamDinh
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],

        //khoi tao donViHanhChinh
        donViHanhChinhs: [],
        donViHanhChinhDrops: [],

        //khoi tao danh sách file
        files: [],
        fileTrash: [],

        errors: {
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          huyenThiThanhPho: '',
          phuongXaThiTran: '',
        }
      },
      eForm: []
    }
  }

  componentDidMount() {
    if (this.props.maHoSo !== undefined && this.props.maHoSo !== null && this.props.maHoSo !== '') {
      //hosocuatoi
      this.loadByMaHoSo();
    } else if (this.props.id > 0) {
      //chinh sua
      this.loadGiayPhepById(null, this.props.id);
    } else {
      //nhap dau ky
    }
  }

  componentDidUpdate() {
    //to do
  }

  initData = async () => {

    if (this.state.eForm.length > 0) {
      let donViHanhChinhQH = {};
      let donViHanhChinhPX = {};
      let maHuyen, tenHuyen, maXa, tenXa;
      if (!StringUtil.isNullOrEmty(ThongTinChuyenNganhUtil.getValueByKey("quanhuyen", this.state.eForm, TypeField.TYPE_STRING))) {
        let param = ThongTinChuyenNganhUtil.getValueByKey("quanhuyen", this.state.eForm, TypeField.TYPE_STRING);
        await AXIOS_INSTANCE.get(`/danh-muc-quan-huyen/code?maTinhThanh=${param}`).then(res => {
          if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
            donViHanhChinhQH = res.data.data;
            maHuyen = donViHanhChinhQH.maQuanHuyen;
            tenHuyen = donViHanhChinhQH.tenQuanHuyen;
          }
        }).catch(error => {
          console.log(error)
        });
      }
      // console.log('maHuyen : ', maHuyen)
      // console.log('tenHuyen : ', tenHuyen)
      if (!StringUtil.isNullOrEmty(ThongTinChuyenNganhUtil.getValueByKey("xaphuong", this.state.eForm, TypeField.TYPE_STRING))) {
        let param = ThongTinChuyenNganhUtil.getValueByKey("xaphuong", this.state.eForm, TypeField.TYPE_STRING);
        await AXIOS_INSTANCE.get(`/danh-muc-phuong-xa/code?maQuanHuyen=${param}`).then(res => {
          if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
            donViHanhChinhPX = res.data.data;
            maXa = donViHanhChinhPX.maPhuongXa;
            tenXa = donViHanhChinhPX.tenPhuongXa;
          }
        }).catch(error => {
          console.log(error)
        });
      }
      // console.log('maXa : ', maXa)
      // console.log('tenXa : ', tenXa)
      this.state.noiDungGiayPhep = {
        thongTinCongTrinh: {
          // tenCongTrinh: ThongTinChuyenNganhUtil.getValueByKey("phone", this.state.eForm, TypeField.TYPE_STRING), 
        },
        thongTinXDCTTheoTuyen: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          maHuyenThiThanhPho: maHuyen,
          tenHuyenThiThanhPho: tenHuyen,
          maPhuongXaThiTran: maXa,
          phuongXaThiTran: tenXa,
          duong: ThongTinChuyenNganhUtil.getValueByKey("diadiem", this.state.eForm, TypeField.TYPE_STRING) 
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
        },
        //khoi tao chuDauTu
        chuDauTus: [],
        chuDauTuDrops: [],

        //khoi tao ThongTinThamDinh
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],

        //khoi tao donViHanhChinh
        donViHanhChinhs: [],
        donViHanhChinhDrops: [],

        //khoi tao danh sách file
        files: [],
        fileTrash: [],
      };
    } else {
      this.state.noiDungGiayPhep = {
        thongTinCongTrinh: {},
        thongTinXDCTTheoTuyen: {},
        viTriXayDung: {
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
        },
        thongTinDeXuat: {},
        giayPhep: {
          id: this.props.id,
          maHoSo: this.props.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
        },
        //khoi tao chuDauTu
        chuDauTus: [],
        chuDauTuDrops: [],

        //khoi tao ThongTinThamDinh
        thongTinThamDinhs: [],
        thongTinThamDinhDrops: [],

        //khoi tao donViHanhChinh
        donViHanhChinhs: [],
        donViHanhChinhDrops: [],

        //khoi tao danh sách file
        files: [],
        fileTrash: [],
      };
    }
    this.setState(this.state);
  }

  validation = async () => {
    let valid = true;
    let checkValidation = true;
    this.state.errors = {};
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.tenCongTrinh)) {
      this.state.errors.tenCongTrinh = 'Vui lòng nhập tên công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.loaiCongTrinh)) {
      this.state.errors.loaiCongTrinh = 'Vui lòng chọn loại công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.capCongTrinh)) {
      this.state.errors.capCongTrinh = 'Vui lòng chọn cấp công trình';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinCongTrinh.phanLoaiXayDung)) {
      this.state.errors.phanLoaiXayDung = 'Vui lòng chọn phân loại xây dựng';
      valid = false;
    }

    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriXayDung.maHuyenThiThanhPho)) {
      this.state.errors.huyenThiThanhPho = 'Vui lòng chọn Huyện/Thị/TP';
      valid = false;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.viTriXayDung.maPhuongXaThiTran)) {
      this.state.errors.phuongXaThiTran = 'Vui lòng chọn Phường/Xã/TT';
      valid = false;
    }
    if (this.props.isNhapDauKy) {
      if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.giayPhep.maSoGiayPhep)) {
        this.state.errors.maSoGiayPhep = 'Vui lòng nhập số giấy phép';
        valid = false;
      } else {
        checkValidation = await this.checkDuplicate();
        if (checkValidation) {
          this.state.errors.maSoGiayPhep = 'Nội dung gợi ý đã tồn tại!';
          valid = false;
        }
      }
      if (!this.state.noiDungGiayPhep.giayPhep.ngayCap || this.state.noiDungGiayPhep.giayPhep.ngayCap === undefined || this.state.noiDungGiayPhep.giayPhep.ngayCap == 0) {
        this.state.errors.ngayCap = 'Vui lòng nhập ngày cấp';
        valid = false;
      }
      let date = new Date();
      if (this.state.noiDungGiayPhep.giayPhep.ngayCap > date) {
        this.state.errors.ngayCap = "Ngày cấp phải nhỏ hơn ngày hiện tại"
        valid = false;
      }
    }
    this.setState(this.state);
    return valid;
  }

  checkGiaiDoan = () => {
    let count = 0;
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinXDCTTheoTuyen.huongTuyenCongTrinh)) {
      count++;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinXDCTTheoTuyen.khoangCach)) {
      count++;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinXDCTTheoTuyen.cotNen)) {
      count++;
    }
    if (StringUtil.isNullOrEmty(this.state.noiDungGiayPhep.thongTinXDCTTheoTuyen.chieuSauCongTrinh)) {
      count++;
    }
    if (count < 4) {
      this.state.noiDungGiayPhep.thongTinXDCTTheoTuyen.giaiDoan = 0;
      this.setState(this.state)
    }
  }

  loadData = async (maHoSo) => {
    await new HoSoCuaToiMgtService().chiTiet(maHoSo).then((p) => {
      if (p.data.data.thongTinChuyenNganh && p.data.data.thongTinChuyenNganh.length > 0) {
        let result = [];
        let lastResult = [];
        for (let i = 0; i < p.data.data.thongTinChuyenNganh.length; i++) {
          result = [p.data.data.thongTinChuyenNganh[i].data]
        }
        for (let index = 0; index < result.length; index++) {
          lastResult = result[index];
        }
        this.setState({
          eForm: lastResult
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  loadByMaHoSo = async (event) => {
    const url = 'giay-phep-xay-dung/thong-tin';
    const data = { 'maHoSo': this.props.maHoSo, 'maLoaiGiayPhep': this.props.maLoaiGiayPhep }
    await this.loadData(this.props.maHoSo);
    AXIOS_INSTANCE.post(url, data).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.setState({
            noiDungGiayPhep: {
              thongTinCongTrinh: datas.thongTinCongTrinh || {},
              thongTinXDCTTheoTuyen: datas.thongTinXDCTTheoTuyen || {},
              viTriXayDung: datas.viTriXayDung || {},
              thongTinSuaChua: datas.thongTinSuaChua || {},
              thongTinDeXuat: datas.thongTinDeXuat || {},
              giayPhep: datas.giayPhep,
              chuDauTus: datas.chuDauTus || [],
              thongTinThamDinhs: datas.thongTinThamDinhs || [],
              donViHanhChinhs: datas.donViHanhChinhs || [],
              files: datas.files || [],
            }
          })
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  loadGiayPhepById = (event, idGiayPhep) => {
    const url = 'giay-phep-xay-dung/chi-tiet-by-id';
    AXIOS_INSTANCE.post(url, { id: idGiayPhep }).then(res => {
      if (res != null) {
        var datas = res.data.data;
        if (datas != null) {
          this.setState({
            noiDungGiayPhep: {
              thongTinCongTrinh: datas.thongTinCongTrinh || {},
              thongTinXDCTTheoTuyen: datas.thongTinXDCTTheoTuyen || {},
              viTriXayDung: datas.viTriXayDung || {},
              thongTinDeXuat: datas.thongTinDeXuat || {},
              giayPhep: datas.giayPhep,
              chuDauTus: datas.chuDauTus || [],
              thongTinThamDinhs: datas.thongTinThamDinhs || [],
              donViHanhChinhs: datas.donViHanhChinhs || [],
              files: datas.files || [],
              fileTrash: [],
            }
          })
        } else {
          this.initData();
        }
      } else {
        toastr.warning('Lỗi kết nối dịch vụ');
      }
    }).catch(error => {
      toastr.warning('Lỗi kết nối dịch vụ');
      console.log(error);
    });
  }

  handleUpdateDataState = (name, value) => {
    this.state.noiDungGiayPhep[name] = value;
    this.setState(this.state);
  }

  onChangeStateGTDD = (value) => {
    this.state.noiDungGiayPhep.giayPhep.giayToVeDatDai = value;
    this.setState(this.state);
  }

  async checkDuplicate() {
    let valid;
    await AXIOS_INSTANCE.post('giay-phep-xay-dung/kiem-tra-duplicate',
      {
        "id": this.state.noiDungGiayPhep.giayPhep.id,
        "maSoGiayPhep": this.state.noiDungGiayPhep.giayPhep.maSoGiayPhep
      }
    ).then(res => {
      if (res.data.error_code === 'DUPLICATE_CODE') {
        valid = true;
      } else {
        valid = false;
      }
    })
    return valid
  }

  addOrUpdate = async (event) => {
    const result = await this.validation();
    if (result) {
      this.checkGiaiDoan();
      let dataInfo = this.state.noiDungGiayPhep;
      dataInfo.nhapDauKy = this.props.isNhapDauKy;
      AXIOS_INSTANCE.post('giay-phep-xay-dung/them-moi', dataInfo).then(res => {
        if (res != null) {
          if (res.data.error_code == 'SUCCESSFUL') {
            if (this.state.noiDungGiayPhep.giayPhep.id == 0) {
              toastr.success('Thêm nội dung giấy phép thành công');
this.initData();
            } else {
              toastr.success('Cập nhật nội dung giấy phép thành công');
            }
            this.loadGiayPhepById(null, res.data.data)
          } else {
            toastr.warning(res.data.error_message);
          }
        } else {
          toastr.warning('Thêm nội dung giấy phép không thành công');
        }
      }).catch(error => {
        toastr.warning('Thêm nội dung giấy phép không thành công');
        console.log(error);
      });
    }
  }

  clearData = () => {
    this.setState({
      noiDungGiayPhep: {
        thongTinCongTrinh: {
          id: 0,
          tenCongTrinh: '',
          capCongTrinh: '',
          loaiCongTrinh: '',
          phanLoaiXayDung: '',
          donViTuVan: '',
          kyHieuThietKe: '',
          donViThamDinh: '',
          ghiChu: '',
          thoiGianCongTrinh: '',
          noiDungQuangCao: ''
        },
        thongTinXDCTTheoTuyen: {
          id: 0,
          huongTuyenCongTrinh: '',
          cotNen: '',
          khoangCach: '',
          chieuSauCongTrinh: '',
        },
        viTriXayDung: {
          id: 0,
          soNhaDuong: '',
          duong: '',
          maPhuongXaThiTran: '',
          maHuyenThiThanhPho: '',
          tinhThanhPho: THANH_PHO_CAN_THO.ten,
          maTinhThanhPho: THANH_PHO_CAN_THO.ma,
          diaChiCu: ''
        },
        thongTinDeXuat: {
          id: 0,
          ketluan: '',
          dexuat: ''
        },
        giayPhep: {
          id: this.state.noiDungGiayPhep.giayPhep.id,
          maHoSo: this.state.noiDungGiayPhep.giayPhep.maHoSo,
          tenLoaiGiayPhep: this.props.tenLoaiGiayPhep,
          maLoaiGiayPhep: this.props.maLoaiGiayPhep,
          loaiBienDong: this.props.loaiBienDong,
          tenLoaiBienDong: this.props.tenLoaiBienDong,
          maTrangThai: this.props.maTrangThai,
          tenTrangThai: this.props.tenTrangThai,
          giayToVeDatDai: '',
          maSoGiayPhep: '',
          ngayCap: null
        },
        chuDauTuDrops: this.state.noiDungGiayPhep.chuDauTus,
        chuDauTus: [],
        thongTinThamDinhDrops: this.state.noiDungGiayPhep.thongTinThamDinhs,
        thongTinThamDinhs: [],
        donViHanhChinhDrops: this.state.noiDungGiayPhep.donViHanhChinhs,
        donViHanhChinhs: [],
        fileTrash: this.state.files,
        files: [],
      }
    })
  }
  render() {
    return (
      <div className="dash-content">
        <br />
        <h6 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
          CẤP GIẤY PHÉP XÂY DỰNG CÔNG TRÌNH THEO TUYẾN</h6>
        <br></br>
        <ul class="nav nav-tabs" role="tablist">
          <li class="ml-2">&nbsp;</li>
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#ndgp" role="tab">Nội dung giấy phép</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#cdt" role="tab">Chủ đầu tư</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#gtdd" role="tab">Giấy tờ về đất đai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tttd" role="tab">Thông tin thẩm định</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#ttdx" role="tab">Thông tin đề xuất</a>
          </li>
        </ul>


        <div class="tab-content">
          <div class="tab-pane p-2 active" id="ndgp" role="tabpanel">
            <FormCPXDCTTheoTuyen
              isNhapDauKy={this.props.isNhapDauKy}
              errorState={this.state.errors}
              onUpdateDataState={this.handleUpdateDataState}
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              addOrUpdate={this.addOrUpdate}
              onClearData={this.clearData}
              maHoSo={this.state.noiDungGiayPhep.giayPhep.maHoSo}
              maLoaiGiayPhep={this.state.noiDungGiayPhep.giayPhep.maLoaiGiayPhep}
            ></FormCPXDCTTheoTuyen>
          </div>

          <div class="tab-pane p-2" id="cdt" role="tabpanel">
            <TableCDT
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              onUpdateDataState={this.handleUpdateDataState}
            ></TableCDT>
          </div>

          <div class="tab-pane p-2" id="gtdd" role="tabpanel">
            <FormGTDD
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              onChangeStateGTDD={this.onChangeStateGTDD}
            ></FormGTDD>
          </div>

          <div class="tab-pane p-2" id="tttd" role="tabpanel">
            <TableTTTD
              noiDungGiayPhep={this.state.noiDungGiayPhep}
              onUpdateDataState={this.handleUpdateDataState}
            ></TableTTTD>
          </div>

          <div class="tab-pane p-2" id="ttdx" role="tabpanel">
            <FormTTDX noiDungGiayPhep={this.state.noiDungGiayPhep}
              onUpdateDataState={this.handleUpdateDataState}></FormTTDX>
          </div>
        </div>
      </div>
    );
  }
}
