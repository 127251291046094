import moment from 'moment';
export default class StringUtil {
    static isNullOrEmty(str: any): boolean {
        if (typeof str == 'undefined' || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) )
            return true;
        else
            return false;
    }

    static isNotEmty(str : any) : boolean{
        if((typeof str != "undefined"  && str != null  && str.length != null  && str.length > 0)){
             return true;   
        }else{
            return false;
        }
    }

    static isEmty(str : any): boolean{
        if(!str || 0 === str.length){
            return true;   
       }else{
           return false;
       }
    }
    
    static getcurrentDate() : string{
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());
        let  dates =new String();
        dates = currentDate.toISOString().substr(0,10);
        return dates?.toString();
    }
    
    static convertDatToSecond(date : String) : number{
        if(date){
            const myDate = new Date(date.toString());
            var seconds = myDate.getTime();
            return seconds;
        }
        return 0;
    }

    static objectIsNotNull(data : any) : Boolean{
        if(Object.keys(data).length > 0){
           return true;
        }
        return false;
    }

    static isEmtyArray(data : any) : Boolean{
        if (data === undefined || data.length == 0) {
           return true;
        }
        return false;        
    }
    static fomatDate (data : any) : String{
        if(data && data > 0){
              let myDate = new Date(data);
              return moment(myDate.toLocaleDateString()).format("YYYY-MM-DD")?.toString();
          } 
          return ''; 
    }
    static timeToDDMMYYYY (time : any) : String{
        if(time && time > 0){
              let myDate = new Date(time);
              return moment(myDate).format("DD/MM/YYYY")?.toString();
          } 
          return ''; 
    }
    static formatMoney(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, "");
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return "";
    }
    static formatForDot(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, "");
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
        return "";
    }

    static viewNumberDot(value: string): string {
        if(!StringUtil.isNullOrEmty(value)&& value.includes('.')){
            return value.replace('.',',');
        }
        return value;
    }
    static getNumberDot(value: string): string {
        if(!StringUtil.isNullOrEmty(value) && value.includes(',')){
            return value.replace(',','.');
        }
        return  value;
     }

     static allowNumberOrDot(str: string): boolean {
        const rgx = /^[0-9]*\,?[0-9]*$/;
        return rgx.test(str);
     }

     static tinhDienTich(dienTichKhuonVien : Number , dienTichKhuonVienHienHuu : Number) : Number{
        const dienTich = (Number(dienTichKhuonVien) -  Number(dienTichKhuonVienHienHuu));
        return Number(dienTich);
    }

}