import React from 'react';
import AXIOS_INSTANCE from '../../../../api/index';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export default class InputPhanLoaiXayDung extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            danhMucPhanLoaiXayDung: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = (event) => {
        AXIOS_INSTANCE.get('danh-muc/phan-loai-xay-dung/thong-tin', "").then(res => {
            if (res != null) {
                var datas = res.data.data;
                this.state.danhMucPhanLoaiXayDung = datas;
                let newArray = [];
                this.state.danhMucPhanLoaiXayDung.forEach((item) => {
                    if (item.trangThai > 0) {
                        newArray.push(item)
                    }
                })
                this.state.danhMucPhanLoaiXayDung = newArray;
                this.setState(this.state)
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch(error => {
            toastr.warning('Lỗi kết nối dịch vụ');
            console.log(error);
        });
    }

    render() {
        return (
            <>
                {this.state.danhMucPhanLoaiXayDung?.length > 0 ? this.state.danhMucPhanLoaiXayDung?.map((item) => {
                    return (
                        <option value={item.ten}>{item.ten}</option>
                    )
                }) :
                    <option value="">Không có dữ liệu.</option>
                }
            </>
        )
    }
}