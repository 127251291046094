import AXIOS_INSTANCE from "..";

class UploadFileService {
    static upload = (request) => {
        return AXIOS_INSTANCE.post('uploadcontroller/upload-file', request);
    }
    static downloadFile =(id) => {
        return AXIOS_INSTANCE.get('uploadcontroller/download-file/' + id, {
            responseType: 'blob',
        });
    }
}
export default UploadFileService;

