import React from 'react';

export default class GiayToVeDatDai extends React.Component {

    render() {
        return (
            <div className="col-md-12" style={{ float: 'left' }}>

                <br></br>
                <br></br>

                <div className="row">
                    <div className="col-md-3 text-right">
                        <h6 style={{ fontWeight: 'bold' }}>Giấy tờ về đất đai</h6>
                    </div>
                    <div className="col-md-9">
                        {this.props.giayToVeDatDai &&
                            <p>{this.props.giayToVeDatDai}</p>
                        }
                    </div>
                </div>
                <br></br>
            </div>
        )
    }
}