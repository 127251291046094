import React from 'react';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../api';
import CHBMForm from '../CauHinhBieuMau/CHBMForm';
import CHBMPagination from '../CauHinhBieuMau/CHBMPagination';
import CHBMTable from '../CauHinhBieuMau/CHBMTable';

class CHBMPRender extends React.Component {

  state = {
    list: [],
    total: 0,
    pageIndex: 0,
    pageLength: 0,
    pageNumber: 0,
    pageSize: 10,
    dataInfo: {
      "pageNumber": 0,
      "pageSize": 10,
      "tenBieuMau": "",
      "trangThai": true
    }
  }
  componentDidMount() {
    this.onPagi();
  }

  fetchSearch = keySearch => {
    AXIOS_INSTANCE.post('danh-muc-bieu-mau/danh-sach', keySearch ).then(res => {
        if (res!=null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
          const datas = res.data.data;
          const total = datas.total;
          const pageIndex = datas.pageIndex + 1;
          const pageLength = (datas.pageNumber * datas.pageSize + datas.data.length);
          this.setState({ list: datas.data || [], total: total, pageIndex: pageIndex, pageLength: pageLength });
        } else {
          this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
        }
      }).catch(error => {
        this.setState({ list: [], total: 0, pageIndex: 0, pageLength: 0 });
      });
  }
  onPagi = () => {
    const dataInfo = this.state.dataInfo;
    this.fetchSearch( 
      {...dataInfo,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize
      });
  }

  onSearch = dataInfo => {
    this.setState({ dataInfo: dataInfo , pageNumber:0, pageSize : 10 }, () => {
      this.fetchSearch( {...dataInfo,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
        });
    });
  }
  onSelectPage = page => {
    this.setState({ pageNumber: page -1 }, this.onPagi);
  }
  
  onChangePageSize = pageSize => {
    this.setState({ pageSize: pageSize, pageNumber: 0 }, this.onPagi);
  }

  render() {
    return (
      <div className="dash-content">
      <React.Fragment>
          <CHBMForm onSearch={this.onSearch} />
          <CHBMTable pageIndex={this.state.pageIndex} list={this.state.list} onSearch={this.onSearch} />
        <CHBMPagination pageSize={this.state.pageSize} pageLength={this.state.pageLength} pageIndex={this.state.pageIndex} total={this.state.total} pageNumber={this.state.pageNumber} onSelectPage={this.onSelectPage}  onChangePageSize={this.onChangePageSize} />
      </React.Fragment>
      </div>
    );
  }
}

export default CHBMPRender;
