import React from 'react';

export default class FormTTDX extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            thongTinDeXuat: {}
        }
    }
    componentDidUpdate() {
        if (this.props.noiDungGiayPhep.thongTinDeXuat !== this.state.thongTinDeXuat && this.props.noiDungGiayPhep.thongTinDeXuat !== undefined) {
            this.state.thongTinDeXuat = this.props.noiDungGiayPhep.thongTinDeXuat;
            this.setState(this.state);
        }
        if (this.props.dataDrop !== this.state.dataDrop) {
            this.state.dataDrop = this.props.dataDrop;
            this.setState(this.state);
        }
    }
    initData = () => {
        if (this.props.noiDungGiayPhep !== undefined) {
            this.setState({
                noiDungGiayPhep: this.props.noiDungGiayPhep
            })
        } else {
            this.state.thongTinDeXuat = {
                id:0,
                ketluan:'',
                dexuat:''
             };
            this.setState(this.state);
        }
    }
    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.thongTinDeXuat[name] = value;
        this.setState(this.state)
        this.props.onUpdateDataState('thongTinDeXuat', this.state.thongTinDeXuat);
    }

    render() {
        return (
            <div class="col-md-12" style={{ marginTop: '10px' }}>
                <div class="row">
                    <div class="col-md-12">
                        <div className="form-group form-group-lg">
                            <label htmlFor="ketluan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Kết luận</label>
                            <textarea type="text" value={this.state.thongTinDeXuat?.ketluan} className="form-control" name="ketluan" id="ketluan" rows="3" onChange={this.setParams} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div className="form-group form-group-lg">
                            <label htmlFor="dexuat" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đề xuất</label>
                            <textarea type="text"  value={this.state.thongTinDeXuat?.dexuat} className="form-control" name="dexuat" id="dexuat" rows="3" onChange={this.setParams} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}