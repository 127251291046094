import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { isValidSession } from "./actions/session";
import { sendTokenRequest } from "./actions/signIn";
import { PrivateRoute } from "./App/Components/Routers/privateRoute";
import './App.css';
import { Layout } from "./App/Containers/App/Layout";
import { Login } from "./App/Components/Login/internal/login";
import StringUtil from "./utils/stringUtils";
import { getString } from "./helpers/localStorage";
export const App = () => {
    const [checkingSession, setCheckingSession] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory();
    useEffect(() => {
        validateSessionStore();
    }, []);
    async function validateSessionStore() {
        const maHoSo = new URL(window.location.href).searchParams.get("maHoSo");
        if (!StringUtil.isNullOrEmty(maHoSo)) {
            localStorage.setItem("MA_HO_SO", maHoSo != null ? maHoSo : "");
        }
        const code = new URL(window.location.href).searchParams.get("code");
        if (code) {
            await sendTokenRequest(code)
                .then(response => {
                    setIsLoggedIn(true);
                    window.location.href = '/ho-so-cua-toi';
                })
                .catch((error => {
                    setIsLoggedIn(false);
                }));
        } else {
            if(StringUtil.isNotEmty(maHoSo)) {
                window.location.href = '/ho-so-cua-toi';
            }
        }
        if (isValidSession()) {
            setIsLoggedIn(true);
            setCheckingSession(false);
            return;
        }
        if (!StringUtil.isNullOrEmty(getString("C_USER"))) {
            setIsLoggedIn(true);
            setCheckingSession(false);
            return;
        }
        setCheckingSession(false);
    }

    return (
        <>
            {
                !checkingSession &&
                < Router >
                    <Switch>
                        <Route path="/login" component={Login} />
                        <PrivateRoute redirectToSSO={true} path="/" isSignedIn={isLoggedIn} component={Layout} />
                    </Switch>
                </Router>
            }
        </>
    )
}