import { lazy } from 'react';
import BieuDoRender from '../App/Components/BieuDoThongKe/BieuDoRender';
import CNSGPRender from '../App/Components/CapNhatSoGiayPhep/CNSGPRender';
import CapPhepDDCTRender from '../App/Components/CapPhepDiDoiCongTrinh/CapPhepDDCTRender';
import CapPhepXDCTCTHRender from '../App/Components/CapPhepXDCTCoThoiHan/CapPhepXDCTCTHRender';
import CapPhepXDCTKTT from '../App/Components/CapPhepXDCTKTT/CapPhepXDCTKTTRender';
import CapPhepXDCTNgam from '../App/Components/CapPhepXDCTNgam/CapPhepXDCTNgamRender';
import CapPhepXDCTQCRender from '../App/Components/CapPhepXDCTQuangCao/CapPhepXDCTQCRender';
import CapPhepXDCTT from '../App/Components/CapPhepXDCTT/CapPhepXDCTTRender';
import CapPhepXDCTTGDRender from '../App/Components/CapPhepXDCTTGD/CapPhepXDCTTGDRender';
import CapPhepXDCTTinNguong from '../App/Components/CapPhepXDCTTinNguong/CapPhepXDCTTinNguongRender';
import CapPhepXDCTTGRender from '../App/Components/CapPhepXDCTTonGiao/CapPhepXDCTTGRender';
import CapPhepXDCTKTTGDRender from '../App/Components/CapPhepXDCTKTTGD/CapPhepXDCTKTTGDRender';
import CapPhepXDCTTuongDai from '../App/Components/CapPhepXDCTTuongDai/CapPhepXDCTTuongDaiRender';
import CapPhepXDCTDARender from '../App/Components/CapPhepXDCTDA/CapPhepXDCTDARender';
import CapPhepSuaChuaRender from '../App/Components/CapPhepSuaChua/CapPhepSuaChuaRender'
import CHBMPRender from '../App/Components/CauHinhBieuMau/CHBMRender';
import BaoCaoCPXDRender from '../App/Components/ComponentQuanTriHeThong/BaoCaoCPXD/BaoCaoCPXDRender';
import ChiTietGPXDRender from '../App/Components/ComponentQuanTriHeThong/ChiTietGPXD/ChiTietGPXDRender';
import HuyGPXDRender from '../App/Components/ComponentQuanTriHeThong/HuyGPXD/HuyGPXDRender';
import ThongKeCPXDRender from '../App/Components/ComponentQuanTriHeThong/ThongKeCPXD/ThongKeCPXDRender';
import ThuHoiGPXDRender from '../App/Components/ComponentQuanTriHeThong/ThuHoiGPXD/ThuHoiGPXDRender';
import DMCCTRender from '../App/Components/DanhMucDungRieng/DanhMucCapCongTrinh/DMCCTRender';
import DMLBDRender from '../App/Components/DanhMucDungRieng/DanhMucLoaiBanDo/DMLBDRender';
import DMLCTRender from '../App/Components/DanhMucDungRieng/DanhMucLoaiCongTrinh/DMLCTRender';
import DMLCTHMRender from '../App/Components/DanhMucDungRieng/DanhMucLoaiCTHM/DMLCTHMRender';
import DMPLXDRender from '../App/Components/DanhMucDungRieng/DanhMucPhanLoaiXD/DMPLXDRender';
import DMNKRender from '../App/Components/DanhMucDungRieng/DanhMucNguoiKy/DMNKRender';
import GPXDRender from '../App/Components/DanhSachGPXD/GPXDRender';
import DCCTRender from '../App/Components/DieuChinhChiTiet/DCCTRender';
import PageKiemTraNganChan from '../App/Components/CapPhepDungChung/KiemTraNganChan/PageKiemTraNganChan';
import { DongBoMotCuaComponent } from '../pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent';
import { DongBoDuLieuComponent } from '../pages/quanTriHeThong/DongBoDuLieu/DongBoDuLieuComponent';
import { BieuDoRenderComponent } from '../App/Components/BieuDoThongKe/BieuDoRenderComponent';
import { ImportDauKyComponent } from '../pages/importDauKy/ImportDauKyComponent';
import { DongBoCanBoCongChucComponent } from '../api/QuanTri/DongBoCanBo/DongBoCanBoCongChucComponent';
import { UserManagement } from '../api/QuanTri/user/mgt/userManagement';
import { ProfileUpdate } from '../api/QuanTri/user/update/profileUpdate';
import { PasswordManagement } from '../api/QuanTri/user/passwordMgt/passwordManagement';


const HoSoCuaToiMgt = lazy(() => import('../pages/hoSoCuaToi/hoSoCuaToiMgt').then(({ HoSoCuaToiMgt }) => ({ default: HoSoCuaToiMgt })));
const CauHinhNghiepVuMgt = lazy(() => import('../pages/quanTriHeThong/cauHinhNghiepVu/cauHinhNghiepVuMgt').then(({ CauHinhNghiepVuMgt }) => ({ default: CauHinhNghiepVuMgt })));
const MenuRoleManagement = lazy(() => import('../pages/quanTriHeThong/Menu/MenuRoleManagement').then(({ MenuRoleManagement }) => ({ default: MenuRoleManagement })));
const GroupRoleManagementComponent = lazy(() => import('../pages/quanTriHeThong/NhomVaiTro/GroupRoleManagementComponent').then(({ GroupRoleManagementComponent }) => ({ default: GroupRoleManagementComponent })));
const PhanQuyenNguoiDungComponentV2 = lazy(() => import('../pages/quanTriHeThong/PhanQuyenNguoiDung/PhanQuyenNguoiDungComponentV2').then(({ PhanQuyenNguoiDungComponentV2 }) => ({ default: PhanQuyenNguoiDungComponentV2 })));
const RoleManagementComponent = lazy(() => import('../pages/quanTriHeThong/VaiTro/RoleManagementComponent').then(({ RoleManagementComponent }) => ({ default: RoleManagementComponent })));
const HuongDanSuDungComponent = lazy(() => import('../pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));

export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    loaiGiayPhep?: string;
    routes?: RoutePropsI[];
}
export const ROUTE_CN_XDCTKTT = {
    ten: 'Cấp phép xây dựng công trình không theo tuyến',
    giaoDien: CapPhepXDCTKTT,
    duongDan: '/cap-phep-xay-dung-cong-trinh-khong-theo-tuyen'
}
export const ROUTE_CN_XDCTGDKTT = {
    ten: 'Cấp phép xây dựng công trình giai đoạn không theo tuyến',
    giaoDien: CapPhepXDCTKTTGDRender,
    duongDan: '/cap-phep-xay-dung-cong-trinh-giai-doan-khong-theo-tuyen'
}
export const ROUTE_CN_DDCT = {
    ten: 'Cấp phép di dời công trình',
    giaoDien: CapPhepDDCTRender,
    duongDan: '/cap-phep-di-doi-cong-trinh'
}
export const ROUTE_CN_XDGDCTT = {
    ten: 'Cáp phép xây dựng giai đoạn của công trình theo tuyến',
    giaoDien: CapPhepXDCTTGDRender,
    duongDan: '/cap-phep-xay-dung-giai-doan-theo-tuyen'
}
export const ROUTE_CN_XDCTQC = {
    ten: 'Cấp phép xây dựng công trình quảng cáo',
    giaoDien: CapPhepXDCTQCRender,
    duongDan: '/cap-phep-xay-dung-cong-trinh-quang-cao'
}

export const ROUTE_CN_XDCTTG = {
    ten: 'Cấp phép xây dựng công trình tôn giáo',
    giaoDien: CapPhepXDCTTGRender,
    duongDan: '/cap-phep-xay-dung-cong-trinh-ton-giao'
}
export const ROUTE_CN_XDCTCTH = {
    ten: 'Cấp phép xây dựng công trình có thời hạn',
    giaoDien: CapPhepXDCTCTHRender,
    duongDan: '/cap-phep-xay-dung-cong-trinh-co-thoi-han'
}
export const ROUTE_CN_XDCTTinNguong = {
    ten: 'Cáp phép xây dựng công trình tín ngưỡng',
    giaoDien: CapPhepXDCTTinNguong,
    duongDan: '/cap-phep-xay-dung-cong-trinh-tin-nguong'
}

export const ROUTE_CN_XDCTTuongDai = {
    ten: 'Cấp phép xây dựng công trình tượng đài',
    giaoDien: CapPhepXDCTTuongDai,
    duongDan: '/cap-phep-xay-dung-cong-trinh-tuong-dai'
}
export const ROUTE_CN_XDCTT = {
    ten: 'Cấp phép xây dựng công trình theo tuyến',
    giaoDien: CapPhepXDCTT,
    duongDan: '/cap-phep-xay-dung-cong-trinh-theo-tuyen'
}
export const ROUTE_CN_XDCTNgam = {
    ten: 'Cấp phép xây dựng công trình ngầm',
    giaoDien: CapPhepXDCTNgam,
    duongDan: '/cap-phep-xay-dung-cong-trinh-ngam'
}
export const ROUTE_CN_XDCTDA = {
    ten: 'Cấp phép xây dựng công trình dự án',
    giaoDien: CapPhepXDCTDARender,
    duongDan: '/cap-phep-xay-dung-cong-trinh-du-an'
}
export const ROUTE_CN_SuaChua = {
    ten: 'Cấp phép sửa chữa, cải tạo',
    giaoDien: CapPhepSuaChuaRender,
    duongDan: '/cap-phep-sua-chua-cai-tao'
}
export const ROUTE_CN_DIEUCHINH =
{
    ten: 'Điều chỉnh chi tiết',
    giaoDien: DCCTRender,
    duongDan: '/dieu-chinh-chi-tiet'
}
export const ROUTE_CHITIET =
{
    ten: 'Chi tiết giấy phép xây dựng',
    giaoDien: ChiTietGPXDRender,
    duongDan: '/chi-tiet-giay-phep-xay-dung'
}
export const ROUTE_THUHOIGIAYPHEP =
{
    ten: 'Thu hồi giấy phép xây dựng',
    giaoDien: ThuHoiGPXDRender,
    duongDan: '/thu-hoi-giay-phep-xay-dung'
}
export const ROUTE_HUYGIAYPHEP =
{
    ten: 'Hủy giấy phép xây dựng',
    giaoDien: HuyGPXDRender,
    duongDan: '/huy-giay-phep-xay-dung'
}

export const ROUTE_CN_NHAPDAUKY: RoutePropsI[] = [
    ROUTE_CN_XDCTKTT,
    ROUTE_CN_XDCTGDKTT,
    ROUTE_CN_DDCT,
    ROUTE_CN_XDGDCTT,
    ROUTE_CN_XDCTQC,
    ROUTE_CN_XDCTTG,
    ROUTE_CN_XDCTCTH,
    ROUTE_CN_XDCTTinNguong,
    ROUTE_CN_XDCTTuongDai,
    ROUTE_CN_XDCTT,
    ROUTE_CN_XDCTNgam,
    ROUTE_CN_XDCTDA,
    ROUTE_CN_SuaChua
    // ROUTE_CN_DIEUCHINH
];

export const ROUTE_CN: RoutePropsI[] = [
    ROUTE_CN_XDCTKTT,
    ROUTE_CN_XDCTGDKTT,
    ROUTE_CN_DDCT,
    ROUTE_CN_XDGDCTT,
    ROUTE_CN_XDCTQC,
    ROUTE_CN_XDCTTG,
    ROUTE_CN_XDCTCTH,
    ROUTE_CN_XDCTTinNguong,
    ROUTE_CN_XDCTTuongDai,
    ROUTE_CN_XDCTT,
    ROUTE_CN_XDCTNgam,
    ROUTE_CN_XDCTDA,
    ROUTE_CN_SuaChua,
    ROUTE_CN_DIEUCHINH
];

export const ROUTE_PAGE_HOSOCUATOI = {
    ten: "HỒ SƠ CỦA TÔI",
    duongDan: '/ho-so-cua-toi',
    giaoDien: HoSoCuaToiMgt,
}

export const ROUTE_PAGE: RoutePropsI[] = [
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: HuongDanSuDungComponent,
        duongDan: '/huong-dan-su-dung',
    },
    {
        ten: 'Đồng bộ dữ liệu',
        giaoDien: DongBoDuLieuComponent,
        duongDan: '/dong-bo-du-lieu',
    },
    {
        ten: 'Đồng bộ',
        giaoDien: DongBoMotCuaComponent,
        duongDan: '/dong-bo',
    },
    {
        ten: 'Cấu hình nghiệp vụ',
        giaoDien: CauHinhNghiepVuMgt,
        duongDan: '/cau-hinh-nghiep-vu',
    },
    {
        ten: 'Phân quyền người dùng',
        giaoDien: PhanQuyenNguoiDungComponentV2,
        duongDan: '/phan-quyen-nguoi-dung',
    },
    {
        ten: 'Quản lý vai trò',
        giaoDien: RoleManagementComponent,
        duongDan: '/quan-ly-vai-tro',
    },
    {
        ten: 'Quản lý nhóm vai trò',
        giaoDien: GroupRoleManagementComponent,
        duongDan: '/quan-ly-nhom-vai-tro',
    },
    {
        ten: 'Quản lý menu',
        giaoDien: MenuRoleManagement,
        duongDan: '/quan-ly-menu',

    },
    {
        ten: 'Danh mục biểu mẫu',
        giaoDien: CHBMPRender,
        duongDan: '/danh-muc-bieu-mau'
    },
    ROUTE_PAGE_HOSOCUATOI,
    {
        ten: 'Biểu đồ',
        giaoDien: BieuDoRenderComponent,
        duongDan: '/giay-phep-xay-dung/bieu-do'
    },
    {
        ten: 'Danh sách giấy phép xây dựng',
        giaoDien: GPXDRender,
        duongDan: '/giay-phep-xay-dung'
    },
    ROUTE_CHITIET,
    {
        ten: 'Cập nhật số giấy phép',
        giaoDien: CNSGPRender,
        duongDan: '/cap-nhat-so-giay-phep/'
    },
    {
        ten: 'Báo cáo cấp phép xây dựng',
        giaoDien: BaoCaoCPXDRender,
        duongDan: '/bao-cao-cap-phep-xay-dung'
    },
    {
        ten: 'Thống kê cấp phép xây dựng',
        giaoDien: ThongKeCPXDRender,
        duongDan: '/thong-ke-cap-phep-xay-dung'
    },
    {
        ten: 'Danh mục cấp công trình',
        giaoDien: DMCCTRender,
        duongDan: '/danh-muc-cap-cong-trinh'
    },
    {
        ten: 'Danh mục loại công trình',
        giaoDien: DMLCTRender,
        duongDan: '/danh-muc-loai-cong-trinh'
    },
    {
        ten: 'Danh mục loại bản đồ',
        giaoDien: DMLBDRender,
        duongDan: '/danh-muc-loai-ban-do'
    },
    {
        ten: 'Danh mục loại chi tiết hạng mục',
        giaoDien: DMLCTHMRender,
        duongDan: '/danh-muc-loai-chi-tiet-hang-muc'
    },
    {
        ten: 'Danh mục phân loại xây dựng',
        giaoDien: DMPLXDRender,
        duongDan: '/danh-muc-phan-loai-xay-dung'
    },
    {
        ten: 'Danh mục người ký',
        giaoDien: DMNKRender,
        duongDan: '/danh-muc-nguoi-ky'
    },
    {
        ten: 'Quản lý kiểm tra ngăn chặn',
        giaoDien: PageKiemTraNganChan,
        duongDan: '/quan-ly-ngan-chan'
    },
    {
        ten: 'Import đầu kỳ',
        giaoDien: ImportDauKyComponent,
        duongDan: '/import-dau-ky',
    },
    ROUTE_THUHOIGIAYPHEP,
    ROUTE_HUYGIAYPHEP,
    {
        ten: 'Đồng bộ người dùng',
        giaoDien: DongBoCanBoCongChucComponent,
        duongDan: '/dong-bo-nguoi-dung'
    },
    {
        ten: 'Quản trị người dùng',
        giaoDien: UserManagement,
        duongDan: '/quan-tri-nguoi-dung',
    },
    {
        ten: 'Thông tin người dùng',
        giaoDien: ProfileUpdate,
        duongDan: '/user-info',
    },
    {
        ten: 'Quản lý mật khẩu',
        giaoDien: PasswordManagement,
        duongDan: '/password-mgt',
    },

]

export const ROUTE_ADMIN: RoutePropsI[] = [

];
export const ROUTE_GUEST_ADMIN : RoutePropsI[] =[
    {
        ten: 'Thông tin người dùng',
        giaoDien: ProfileUpdate,
        duongDan: '/user-info',
    },
    {
        ten: 'Quản lý mật khẩu',
        giaoDien: PasswordManagement,
        duongDan: '/password-mgt',
    },
]
export const ROUTE_GUEST: RoutePropsI[] = [
    ROUTE_CHITIET,
    ROUTE_THUHOIGIAYPHEP,
    ROUTE_HUYGIAYPHEP,
    ...ROUTE_CN_NHAPDAUKY,
    ...ROUTE_GUEST_ADMIN
];


export const ROUTE_PAGE_PUBLIC: RoutePropsI[] = [
    {
        ten: 'Biểu đồ',
        giaoDien: BieuDoRender,
        duongDan: '/giay-phep-xay-dung/bieu-do'
    }
];
