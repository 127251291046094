import { InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import 'toastr/build/toastr.min.css';
import UploadFileService from '../../../api/common/UploadFile';
import { CreateGiayPhepDTO } from '../../../api/Init/CapPhepXayDungFinal';
import GiayPhepService from '../../../api/service/CapPhepXDCTHService';
import { bootstrapTextFieldHook } from '../../commons/components/input/bootstrap';
import ButtonGroupGiayPhep from '../CapPhepDungChung/ButtonGroupGiayPhep';
import TableLoDatXDDCCTCreate from '../CapPhepDungChung/DCCT/TableLoDatXDDCCTCreate';
import SelectPhuongXa from '../CapPhepDungChung/InputGroup/SelectPhuongXa';
import SelectQuanHuyen from '../CapPhepDungChung/InputGroup/SelectQuanHuyen';
import ModalClearAll from '../CapPhepDungChung/ModalClearAll';
import ModalXMTD from '../CapPhepDungChung/ModalXMTD';
import ChiTietGPXDWrapper from '../ComponentQuanTriHeThong/ChiTietGPXD/SubChiTietGPXD/SubNoiDungCapPhep/ChiTietGPXDWrapper';
import { createFileType, downLoadFile } from '../../../api/common/exportUtil';
import ModalKiemTraNganChan from '../CapPhepDungChung/KiemTraNganChan/ModalKiemTraNganChan';
import UploadFilesCommon from '../CapPhepDungChung/UploadFilesCommon';
import DatePicker from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../ComponentQuanTriHeThong/DateInputCustome.css";

export default class FormCPDiDoiCongTrinh extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noiDungGiayPhep: { ...CreateGiayPhepDTO },
            search: {
                keywords: this.props.idGiayPhep,
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5
            },
            errors: {},
            danhSachGiayPhepDaCap: []
        }
        //Add By IN
        this.giayPhepService = new GiayPhepService();
    }

    componentDidMount() {
        this.componentDidUpdate();
    }
    componentDidUpdate() {
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep !== this.state.noiDungGiayPhep) {
            this.state.noiDungGiayPhep = this.props.noiDungGiayPhep;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.viTriDiDoi !== this.state.noiDungGiayPhep.viTriDiDoi) {
            this.state.noiDungGiayPhep.viTriDiDoi = this.props.noiDungGiayPhep.viTriDiDoi;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs !== this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs) {
            this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs = this.props.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs;
            this.setState(this.state);
        }
        if (this.props.noiDungGiayPhep !== undefined && this.props.noiDungGiayPhep.loDatXayDungDiDoi !== this.state.noiDungGiayPhep.loDatXayDungDiDoi) {
            this.state.noiDungGiayPhep.loDatXayDungDiDoi = this.props.noiDungGiayPhep.loDatXayDungDiDoi;
            this.setState(this.state);
        }
        if (this.props.errorState !== undefined && this.props.errorState != this.state.errors) {
            this.state.errors = this.props.errorState;
            this.setState(this.state);
        }
    }

    setParams = (event, key) => {
        let name = event.target.name;
        let value = event.target.value;
        let type = event.target.type;
        let text = ""
        if (type === "select-one") {
            text = event.target.selectedOptions[0].text;
        }
        if (key === 'viTriDiDoi') {
            if (!this.state.noiDungGiayPhep.viTriDiDoi) {
                this.state.noiDungGiayPhep.viTriDiDoi = {};
            }
            this.state.noiDungGiayPhep.viTriDiDoi[name] = value;
            this.props.onUpdateDataState('viTriDiDoi', this.state.noiDungGiayPhep.viTriDiDoi);
        }
        if (key === "HuyenThiThanhPho") {
            if (!this.state.noiDungGiayPhep.viTriDiDoi) {
                this.state.noiDungGiayPhep.viTriDiDoi = {};
            }
            this.state.noiDungGiayPhep.viTriDiDoi[name] = value;
            this.state.noiDungGiayPhep.viTriDiDoi.huyenThiThanhPho = text;
            this.state.noiDungGiayPhep.viTriDiDoi.maPhuongXaThiTran = ""
            this.props.onUpdateDataState('viTriDiDoi', this.state.noiDungGiayPhep.viTriDiDoi);
        }
        if (name === "maPhuongXaThiTran") {
            if (!this.state.noiDungGiayPhep.viTriDiDoi) {
                this.state.noiDungGiayPhep.viTriDiDoi = {};
            }
            this.state.noiDungGiayPhep.viTriDiDoi[name] = value;
            this.state.noiDungGiayPhep.viTriDiDoi.phuongXaThiTran = text;
            this.props.onUpdateDataState('viTriDiDoi', this.state.noiDungGiayPhep.viTriDiDoi);
        }
    }

    setParamLoDats = (key, value) => {
        if (key === 'loDatXayDungDiDoi') {
            if (!this.state.noiDungGiayPhep.loDatXayDungDiDoi) {
                this.state.noiDungGiayPhep.loDatXayDungDiDoi = []
            }
            this.state.noiDungGiayPhep.loDatXayDungDiDoi = value;
            this.props.onUpdateDataState('loDatXayDungDiDoi', this.state.noiDungGiayPhep.loDatXayDungDiDoi);
        }
        if (key === 'loDatXayDungDiDoiDropDTOs') {
            if (!this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs) {
                this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs = []
            }
            this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs = value;
            this.props.onUpdateDataState('loDatXayDungDiDoiDropDTOs', this.state.noiDungGiayPhep.loDatXayDungDiDoiDropDTOs);
        }
        if (key === "files") {
            if (this.state.noiDungGiayPhep.files) {
                this.state.noiDungGiayPhep.files = value;
            } else {
                this.state.noiDungGiayPhep.files = [];
                this.state.noiDungGiayPhep.files = value
            }
            this.props.onUpdateDataState('files', this.state.noiDungGiayPhep.files)
        }
        if (key === "fileTrash") {
            if (this.state.noiDungGiayPhep.fileTrash) {
                this.state.noiDungGiayPhep.fileTrash = value;
            } else {
                this.state.noiDungGiayPhep.fileTrash = [];
                this.state.noiDungGiayPhep.fileTrash = value;
            }
            this.props.onUpdateDataState('fileTrash', this.state.noiDungGiayPhep.fileTrash)
        }

    }

    handleModalToggle = (key) => {
        this.state.openModel = key;
        this.setState(this.state);
    }
    handleClearAll = () => {
        this.handleModalToggle('');
        this.props.onClearData();
    }

    loadThongTinTheoSoGiayPhepCu = (newValue) => {
        this.props.onUpdateDataState("state.noiDungGiayPhep", {
            ...newValue,
            giayPhep: {
                ...newValue.giayPhep,
                id: 0,
                maSoGiayPhep: '',
                ngayCapCu: newValue.giayPhep.ngayCap,
                ngayHetHanCu: newValue.giayPhep.ngayHetHan,
                soGiayPhepCu: newValue.giayPhep.maSoGiayPhep
            },
            files: []
        });
        this.setState(this.state);
    }
    onChangAutoComplete = (event) => {
        let soGiayPhep = event.target.value;
        this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu = soGiayPhep;
        this.setState(this.state);
        this.giayPhepService.getByMaSoGiayPhep({ maSoGiayPhep: soGiayPhep }).then(res => {
            if (res != null && res.data.data && res.data.data?.length >= 0) {
                const danhSachGiayPhepDaCapNew = [...res.data.data];
                this.state.danhSachGiayPhepDaCap = [...danhSachGiayPhepDaCapNew];
                this.setState(this.state);
            } else {
                this.state.danhSachGiayPhepDaCap = [];
                this.setState(this.state);
            }
        });
    }
    // handleChangeNgayCapCu(date) {
    //     let name = "ngayCapCu";
    //     var longFormat = date * 1;
    //     if (!this.state.noiDungGiayPhep.thongTinHuyGiayPhep ||
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep === null ||
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep === undefined) {
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = longFormat;
    //     } else {
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep = {}
    //         this.state.noiDungGiayPhep.thongTinHuyGiayPhep[name] = longFormat;
    //     }
    //     this.props.onUpdateDataState('thongTinHuyGiayPhep', this.state.noiDungGiayPhep.thongTinHuyGiayPhep);
    // }
    render() {
        const CustomInputNgayCapCu = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input readOnly style={{backgroundColor:'rgb(239, 239, 246)'}} type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        const CustomInputNgayHetHanCu = ({ value, onClick }) => (
            <div className="input-group mb-3" style={{ width: "315px" }}>
                <input readOnly style={{backgroundColor:'rgb(239, 239, 246)'}} type="text" className="form-control" placeholder="dd/mm/yyyy" value={value} onClick={onClick}></input>
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><i className="fa fa-calendar" /></span>
                </div>
            </div>
        );
        return (
            <>
                <div class="col-md-12">
                    <br />
                    <div class="row">
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="tenSoGiayPhep" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số giấy phép xây dựng cũ</label>
                                <Autocomplete style={{ width: '350px' }}
                                    id="soGiayPhepCu"
                                    name="soGiayPhepCu"
                                    options={this.state.danhSachGiayPhepDaCap}
                                    value={this.state.noiDungGiayPhep}
                                    getOptionLabel={(option) => option.giayPhep.maSoGiayPhep}
                                    onChange={(event, newValue) => this.loadThongTinTheoSoGiayPhepCu(newValue)}
                                    renderInput={(params) => {
                                        return <div className='' ref={params.InputProps.ref} >
                                            <InputBase style={{ width: '312px' }}
                                                {...params.inputProps}
                                                readOnly={this.state.noiDungGiayPhep?.giayPhep.id > 0}
                                                value={this.state.noiDungGiayPhep.giayPhep.soGiayPhepCu}
                                                id="soGiayPhepCu"
                                                name="soGiayPhepCu"
                                                onChange={this.onChangAutoComplete}
                                                classes={bootstrapTextFieldHook.useInput()}
                                            />
                                            <i class="fas fa-search" style={{ marginLeft: '2px' }}></i>
                                        </div>
                                    }}
                                />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="ngayCap" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày cấp</label>
                                {/* <input type="date" className="form-control" id="ngayCap" name="ngayCap" readOnly
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayCapCu ?
                                        moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayCapCu)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    // readOnly={true}
                                    customInput={<CustomInputNgayCapCu />}
                                    selected={this.state.noiDungGiayPhep.giayPhep?.ngayCapCu}
                                    // onChange={this.handleChangeNgayCapCu.bind(this)}
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayCapCu === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayCapCu).format("DD/MM/YYYY") : null}
                                    name="ngayCapCu"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div className="form-group">
                                <label htmlFor="ngayHetHan" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ngày hết hạn</label>
                                {/* <input type="date" className="form-control" id="ngayHetHan" name="ngayHetHan" readOnly
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayHetHanCu ?
                                        moment(new Date(this.state.noiDungGiayPhep.giayPhep?.ngayHetHanCu)).format("yyyy-MM-DD") : ""} style={{ fontSize: '14px' }} /> */}
                                <DatePicker
                                    // readOnly={true}
                                    customInput={<CustomInputNgayHetHanCu />}
                                    selected={this.state.noiDungGiayPhep.giayPhep?.ngayHetHanCu}
                                    // onChange={this.handleChangeNgayCapCu.bind(this)}
                                    value={this.state.noiDungGiayPhep.giayPhep?.ngayHetHanCu === 0 ? moment(this.state.noiDungGiayPhep.giayPhep?.ngayHetHanCu).format("DD/MM/YYYY") : null}
                                    name="ngayHetHanCu"
                                    placeholderText={"dd/mm/yyyy"}
                                    dateFormat={"dd/MM/yyyy"}
                                    className="form-control"
                                    popperClassName="some-custom-class"
                                    popperPlacement="top-end"
                                    locale={vi}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                        }
                                    }}
                                >
                                </DatePicker>
                            </div>
                        </div>
                        <div class="col-md-3">

                        </div>
                    </div>
                </div>

                <div className="col-md-12" style={{ float: 'left', fontSize: '14px' }}>
                    {this.state.noiDungGiayPhep !== null &&
                        <ChiTietGPXDWrapper
                            loaiBienDong={this.props.loaiBienDong}
                            noiDungGiayPhep={this.state.noiDungGiayPhep}
                        ></ChiTietGPXDWrapper>
                    }

                    <div className="row">
                        <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>VỊ TRÍ XÂY DỰNG DI DỜI</h6>
                    </div>
                    <br />
                    <div class="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="tinhThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>Tỉnh/Thành phố <span style={{ color: 'red' }}>(*)</span></label>
                                    <select className="form-control" id="tinhThanhPho"
                                        value={this.state.noiDungGiayPhep.viTriDiDoi?.tinhThanhPho} style={{ fontSize: '14px' }} name="tinhThanhPho" disabled>
                                        <option selected>Thành phố Cần Thơ</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <SelectQuanHuyen
                                        quanHuyenValue={this.state.noiDungGiayPhep.viTriDiDoi?.maHuyenThiThanhPho}
                                        errors={this.state.errors}
                                        setParams={this.setParams}
                                    ></SelectQuanHuyen>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <SelectPhuongXa
                                    maQuanHuyen={this.state.noiDungGiayPhep.viTriDiDoi?.maHuyenThiThanhPho}
                                    phuongXaValue={this.state.noiDungGiayPhep.viTriDiDoi?.maPhuongXaThiTran}
                                    errors={this.state.errors}
                                    setParams={this.setParams}
                                ></SelectPhuongXa>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="duong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Đường</label>
                                    {/* <select className="form-control" id="duong" name="duong" style={{ fontSize: '14px' }}
                                        value={this.state.noiDungGiayPhep.viTriDiDoi?.duong}
                                        onChange={(event) => this.setParams(event, "viTriDiDoi")}>
                                        <option value="">--Chọn đường--</option>
                                        <option value="Đường 1">Đường 1</option>
                                        <option value="Đường 2">Đường 2</option>
                                        <option value="Đường 3">Đường 3</option>
                                    </select> */}
                                    <input type="text" className="form-control" id="duong" name="duong"
                                        // readOnly={this.props.readonly ? true : false}
                                        value={this.state.noiDungGiayPhep.viTriDiDoi?.duong} style={{ fontSize: '14px' }}
                                        onChange={(event) => this.setParams(event, "viTriDiDoi")} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="soNhaDuong" style={{ fontWeight: 'bold', fontSize: '14px' }}>Số nhà, đường</label>
                                    <input type="text" className="form-control" id="soNhaDuong" name="soNhaDuong" style={{ fontSize: '14px' }}
                                        value={this.state.noiDungGiayPhep.viTriDiDoi?.soNhaDuong}
                                        onChange={(event) => this.setParams(event, "viTriDiDoi")} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="diaChiCu" style={{ fontWeight: 'bold', fontSize: '14px' }}>Địa chỉ cũ</label>
                                    <input type="text" className="form-control" id="diaChiCu" name="diaChiCu" style={{ fontSize: '14px' }}
                                        value={this.state.noiDungGiayPhep.viTriDiDoi?.diaChiCu}
                                        onChange={(event) => this.setParams(event, "viTriDiDoi")} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h6 style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>Lô đất xây dựng</h6>
                    </div>

                    <div class="col-md-12">
                        <TableLoDatXDDCCTCreate
                            noiDungGiayPhep={this.state.noiDungGiayPhep}
                            onUpdateDataState={this.setParamLoDats}
                        ></TableLoDatXDDCCTCreate>
                    </div>

                    <br />
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="ranhGioiDiDoi" style={{ fontWeight: 'bold', fontSize: '14px' }}>Ranh giới di dời đến</label>
                            <textarea type="text" className="form-control" id="ranhGioiDiDoi" name="ranhGioiDiDoi" style={{ fontSize: '14px' }}
                                value={this.state.noiDungGiayPhep.viTriDiDoi?.ranhGioiDiDoi}
                                onChange={(event) => this.setParams(event, "viTriDiDoi")} />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <UploadFilesCommon
                        files={this.state.noiDungGiayPhep.files}
                        fileTrash={this.state.noiDungGiayPhep.fileTrash}
                        onUpdateDataState={this.props.onUpdateDataState}
                    />
                    <br></br>
                    <br></br>
                    <ButtonGroupGiayPhep
                        handleModalToggle={this.handleModalToggle}
                        handleAddOrUpdate={this.props.addOrUpdate}
                        maHoSo={this.state.maHoSo}
                        maLoaiGiayPhep={this.state.maLoaiGiayPhep}
                        isNhapDauKy={this.props.isNhapDauKy}
                        idGiayPhep={this.state.noiDungGiayPhep.giayPhep.id}
                    ></ButtonGroupGiayPhep>
                    <br></br>
                    <br></br>
                    {this.state.openModel === 'kiem-tra-ngan-chan'
                        &&
                        <ModalKiemTraNganChan
                            onModalToggle={this.handleModalToggle}
                            show={this.state.openModel === 'kiem-tra-ngan-chan'}
                        ></ModalKiemTraNganChan>
                    }
                    <ModalClearAll onClearData={this.handleClearAll} onModalToggle={this.handleModalToggle} show={this.state.openModel === 'clear-all'}></ModalClearAll>
                    <ModalXMTD maHoSo={this.state.maHoSo} soBienNhan={this.state.soBienNhan} ngayNop={this.state.ngayNop} idGiayPhep = {this.state.noiDungGiayPhep.giayPhep.id}></ModalXMTD>
                </div>
            </>
        )
    }
}