import AXIOS_INSTANCE from "..";
import { TreeNode } from "../../App/commons/components/treeView/checkBoxTree";
import { BaseResponse, BaseService } from "../baseService";
import { Role } from "./Model/PhanQuyen";
export interface MenuRoleItem {
    id: string;
    path_?: string;
    name: string;
    createDate?: string | number | Date;
    modifyDate?: string | number | Date;
    description?: string;
    active?: boolean;
    icon?: string;
    priority?: number;
    parentId?: number;
    parentName?: string;
    childrens?: MenuRoleItem[]
}
export class MenuRoleManagementService extends BaseService {

    delete =  (id: number) => {
        return AXIOS_INSTANCE.post('/menu/delete', {id: id}, this.getTokenRequestHeaders());
    }

    getMenuByUserName = async (userName: string): Promise<MenuRoleItem[]> => {
        let result: MenuRoleItem[] = [];
        await AXIOS_INSTANCE.post('/menu/get-left-menu', { userName: userName }, this.getTokenRequestHeaders())
            .then((response: any) => {
                response.data.data.forEach((p: any) => {
                    result.push({
                        id: String(p.id),
                        name: p.name,
                        path_: p.path_,
                        icon: p.icon,
                        childrens: this.initChildrens(p.child)
                    } as MenuRoleItem)
                });
            })
        return result;
    }

    getMenuTreeByRoleId = async (roleId: string): Promise<TreeNode[]> => {
        let result: TreeNode[] = [];
        await AXIOS_INSTANCE.post('/menu/get-tree', JSON.stringify({ roleId: roleId }), this.getTokenRequestHeaders())
            .then((response: any) => {
                response.data.data.forEach((p: any) => {
                    result.push({
                        value: String(p.id),
                        label: p.name,
                        children: this.initChilds(p.child),
                        checked: p.checked,
                    } as TreeNode)
                });
            })
        return result;
    }

    getRolesByMenuId = async (menuId: string): Promise<TreeNode[]> => {
        let result: TreeNode[] = [];
        await AXIOS_INSTANCE.get('/role/roles-by-menu/' + menuId, this.getTokenRequestHeaders())
            .then((response: any) => {
                response.data.data.forEach((p: any) => {
                    result.push({
                        value: String(p.id),
                        label: p.name,
                        children: this.initChilds(p.roles),
                        checked: p.checked,
                        code: p.code
                    } as TreeNode)
                });
            })
        return result;
    }

    searchMenu = async (pageSize: number, pageNumber: number, keywords: string, active: number): Promise<{ rows: MenuRoleItem[], total: number }> => {
        let total = 0;
        let rows: MenuRoleItem[] = [];
        await AXIOS_INSTANCE.post('/menu/search', JSON.stringify({
            pageSize: pageSize,
            pageNumber: pageNumber,
            keywords: keywords,
            active: active
        }), this.getTokenRequestHeaders()).then(response => {
            total = response.data.data.total;
            if (response.data.data.data) {
                rows = response.data.data.data
            }
        })
        return { total: total, rows: rows };
    }

    getMenuByActive = async (active: boolean): Promise<MenuRoleItem[]> => {
        let result: MenuRoleItem[] = [];
        await AXIOS_INSTANCE.get('/menu/get-by-active/' + active, this.getTokenRequestHeaders())
            .then(response => {
                result = response.data.data
            }).catch()
        return result;
    }

    insertOrUpdateMenu = async (menu: MenuRoleItem, roles: Role[]): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await AXIOS_INSTANCE.post('/menu/addOrUpdate', JSON.stringify({ menu: menu, roles: roles }),
            this.getTokenRequestHeaders()).then(response => {
                result = {
                    isSuccess: response.data.error_code === 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                };
            }).catch(() => {
                result = {
                    isSuccess: false,
                    message: "Lỗi khi thực hiện kết nối server"
                };
            })
        return result;
    }

    private initChilds = (childs: any[]): TreeNode[] => {
        let result: TreeNode[] = [];
        if (childs) {
            childs.forEach(p => {
                result.push({
                    value: String(p.id),
                    label: p.name,
                    path_: p.path_,
                    children: this.initChilds(p.child),
                    checked: p.checked,
                    code: p.code
                })
            })
        }
        return result;
    }

    private initChildrens = (childs: any[]): MenuRoleItem[] => {
        let result: MenuRoleItem[] = [];
        if (childs) {
            childs.forEach(p => {
                let item = {
                    id: p.id, path_: p.path_, name: p.name,
                    priority: p.priority,
                    parentId: p.parentId,
                    childrens: this.initChildrens(p.child)
                } as MenuRoleItem;
                result.push(item);
            })
        }
        return result;
    }

    private leafIds(node: any): string[] {
        return node.checked && (!node.children || node.children.length === 0)
            ? [String(node.id)]
            : node.children.map((item: any) => this.leafIds(item)).flat();
    }
}
