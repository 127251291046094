import React from 'react';
import 'toastr/build/toastr.min.css';
import AXIOS_INSTANCE from '../../../../api/index';

export default class SelectQuanHuyen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quanHuyen: [],
            errors: {},
        }
    }
    componentDidMount() {
        this.danhSachQuanHuyenInfo();
    }
    componentDidUpdate() {
        if (this.props.errors !== undefined && this.props.errors !== this.state.errors) {
            this.state.errors = this.props.errors;
            this.setState(this.state)
        }
    }

    danhSachQuanHuyenInfo = () => {
        const queryParam = {
            "maTinhThanh": "92"
        }
        AXIOS_INSTANCE.get(`/danh-muc-quan-huyen?maTinhThanh=${queryParam.maTinhThanh}`).then(res => {
            if (res != null && res.status == 200 && res.data.error_code === "SUCCESSFUL") {
                this.setState({ quanHuyen: res.data.data || [] });
            } else {
                this.setState({ quanHuyen: [] });
            }
        }).catch(error => {
            this.setState({ quanHuyen: [] });
        });
    }

    render() {
        console.log("this.props.quanHuyenValue : ", this.props.quanHuyenValue)
        return (
            <div className="form-group">
                {(this.props.displayLabel === undefined || this.props.displayLabel) &&
                    <label htmlFor="maHuyenThiThanhPho" style={{ fontWeight: 'bold', fontSize: '14px' }}>Huyện/Thị/TP {this.props.isRequired === undefined && !this.props.isRequired && <span style={{ color: 'red' }}>(*)</span>}</label>
                }
                <select className="form-control" id="maHuyenThiThanhPho" name="maHuyenThiThanhPho"
                    disabled={this.props.disabled ? true : false}
                    value={this.props.quanHuyenValue}
                    onChange={(event) => this.props.setParams(event, "HuyenThiThanhPho")}>
                    <option value="">--Chọn Quận/Huyện--</option>
                    {
                        this.state.quanHuyen.map((e, ind) => {
                            return <option key={e.maQuanHuyen} value={e.maQuanHuyen}>{e.tenQuanHuyen}</option>
                        })
                    }
                </select>
                <div style={Boolean(this.state.errors?.huyenThiThanhPho) ? { display: 'block' } : {}} className="invalid-feedback" >
                    <span style={{ marginBottom: "5px" }}>{this.state.errors?.huyenThiThanhPho}</span>
                </div>
            </div>
        )
    }
}